import axios from "axios";
import getItemFromLocalStorage from "../../Utils/GetItemFromLocalstorage";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (
      response &&
      response.status &&
      (response.status === 401 ||
        response.status === 322 ||
        response.status === 423)
    ) {
      const user = getItemFromLocalStorage("user");
      if (user) {
        localStorage.clear();
        sessionStorage.clear();
        // store.dispatch(
        //   showMessageBox({
        //     title: "SessionExpire",
        //     message: "Session has been expired. login again",
        //     type: true,
        //   })
        // );
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
