import React, { useEffect, useState } from "react";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import {
  ROSTERING_TIMESHEET,
  ROSTERING_TIMESHEET_TABS,
} from "Constants/constant";
import TimeSheetTabs from "pages/Rostering/TimeSheet/TimeSheetTabs/TimesheetTabs";
import TimesheetContent from "pages/Rostering/TimeSheet/TimeSheetTabs/TimesheetContent";
import { useSelector } from "react-redux";
import { getSpSetting } from "store/selector/sp.selector";
import TimesheetHistory from "pages/Rostering/TimeSheet/TimeSheetTabs/TimesheetHistory";
import TimesheetComment from "pages/Rostering/TimeSheet/TimeSheetTabs/TimesheetComment";

const ViewBreakdownTimeSheetModal = ({
  isShow,
  handleClose,
  singleTimesheetDetailsRequest,
  singleTimesheetDetailsLoader,
  singleTimesheetDetails,
  selectedUUID,
}) => {

  const [selectedTab, setSelectedTab] = useState(ROSTERING_TIMESHEET_TABS[0]);
  const currentSpUser = useSelector(getSpSetting);

  useEffect(() => singleTimesheetDetailsRequest({ uuid: selectedUUID }), []);

  const modalFooter = () => (
    <>
      <CustomButton
        variant='danger'
        clickHandler={() => {
          handleClose();
        }}
        label='Close'
      />
    </>
  );

  const loadTabComponent = () => {
    switch (selectedTab.value) {
      case ROSTERING_TIMESHEET.timesheet:
        return (
          <>
            {singleTimesheetDetails && singleTimesheetDetails?.participants && (
              <TimesheetContent
                currentSpUser={currentSpUser}
                selectedParticipant={
                  singleTimesheetDetails?.participants || null
                }
                selectedTimeSheet={singleTimesheetDetails}
              />
            )}
          </>
        );
      case ROSTERING_TIMESHEET.history:
        return (
          <>
            {singleTimesheetDetails && (
              <TimesheetHistory
                scrollHeight={531}
                selectedTimeSheetHistory={singleTimesheetDetails?.history || []}
              />
            )}
          </>
        );
      case ROSTERING_TIMESHEET.comments:
        return (
          <>
            {singleTimesheetDetails && (
              <TimesheetComment
                isViewOnly={true}
                viewOnlyComments={singleTimesheetDetails?.comments || []}
              />
            )}
          </>
        );
      default:
        return (
          <>
            {singleTimesheetDetails && singleTimesheetDetails?.participant && (
              <TimesheetContent
                currentSpUser={currentSpUser}
                selectedParticipant={
                  singleTimesheetDetails?.participant || null
                }
                selectedTimeSheet={singleTimesheetDetails}
              />
            )}
          </>
        );
    }
  };

  // console.log("singleTimesheetDetails", singleTimesheetDetails);

  const viewBreakdownDetailContent = () => (
    <div className='w-[100%] px-6'>
      <TimeSheetTabs
        tabs={ROSTERING_TIMESHEET_TABS}
        handleChange={(e) => setSelectedTab(e)}
        selectedTab={selectedTab}
      />
      <div className='mt-5'>{loadTabComponent()}</div>
    </div>
  );

  return (
    <>
      <div>
        {isShow && (
          <ModalSkeleton
            title='Timesheet Breakdown Details'
            isOpen={isShow}
            cssClass='w-[75%]'
            bodyPadding='px-0'
            closeModal={() => {
              handleClose();
            }}
            modalFooter={singleTimesheetDetailsLoader ? null : modalFooter()}
          >
            {singleTimesheetDetailsLoader ? (
              <div
                className={`flex flex-col w-full bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              viewBreakdownDetailContent()
            )}
          </ModalSkeleton>
        )}
      </div>
    </>
  );
};

export default ViewBreakdownTimeSheetModal;
