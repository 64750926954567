import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { disable } from "workbox-navigation-preload";

const SwitchWithIcon = ({ onChange, checked, isSmall, disabled = false,cssClass }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <Switch
      checked={isChecked}
      onChange={handleToggle}
      className={` bg-blue-100 relative inline-flex items-center rounded-full ${
        isSmall ? "w-9 h-4" : "w-11 h-5"
      } transition-colors duration-300 ${cssClass}`}
      disabled={disabled}
    >
      <span
        className={`${
          isChecked
            ? `${
                isSmall ? "translate-x-5" : "translate-x-7"
              } bg-green-800 transition-transform duration-300`
            : "translate-x-0 bg-red-500 transition-transform duration-300"
        } inline-block ${
          isSmall ? "w-4 h-4" : "w-5 h-5"
        } transform flex justify-center items-center rounded-full`}
      >
        {isChecked ? (
          <CheckIcon
            className={`${isSmall ? "w-3 h-3" : "w-4 h-4"}  text-white`}
          />
        ) : (
          <XIcon className={`${isSmall ? "w-3 h-3" : "w-4 h-4"}  text-white`} />
        )}
      </span>
    </Switch>
  );
};

export default SwitchWithIcon;
