// CheckboxWithLabel.js
import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";

const CheckboxWithLabel = ({ label, checkboxId, checked: initialChecked,setSelectedDays, onChange, indeterminate,cssClass }) => {
  const [isChecked, setIsChecked] = useState(initialChecked);

  useEffect(() => {
    setIsChecked(initialChecked);
  }, [initialChecked]);

  const handleCheckboxChange = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
      setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      [checkboxId]: isChecked,
    }));
    if (onChange) {
      onChange(checkboxId, newChecked);
    }
  };

  const gradientBackground = {
    background: `linear-gradient(to right, #617DC9, #344578)`,
  };

  return (
    <label
      htmlFor={checkboxId}
      onClick={handleCheckboxChange}
      className={` ${cssClass} flex items-center cursor-pointer text-sm border border-[#E4E4E4] p-2 rounded-lg inline-flex px-3`}
    >
      <span className="mr-2 text-[#7D7D7D]">{label}</span>
      <div
        
        className={`w-6 h-6 p-1 border rounded-full transition duration-300 ease-in-out flex items-center justify-center`}
        style={isChecked ? gradientBackground : {}}
      >
        {isChecked && <FaCheck className="text-white" />}
      </div>
      <input
        type="checkbox"
        id={checkboxId}
        className="hidden"
        checked={isChecked}
        onChange={() => {}} // Dummy onChange to suppress React warning
        ref={(input) => {
          if (input) {
            input.indeterminate = indeterminate;
          }
        }}
      />
    </label>
  );
};

export default CheckboxWithLabel;
