// import React, { useState, useEffect } from "react";
// import {
//   downloadFileFromServer,
//   getFileExtension,
//   getFileExtensions,
//   getFileSize,
//   isValidFile,
//   maxFileSize,
//   textTruncate,
// } from "../../../Utils/utils";
// import Style from "../FileUpload/FileUpload.module.css";
// import { useDropzone } from "react-dropzone";
// import { apiCall } from "store/services/service";
// import { BASE_URL } from "store/services/URL";
// import axios from "store/services/axios";
// import FileImage from "assets/file.svg";
// import fileUploadIcon from "../../../assets/svg/file-upload.svg";
// import { PaperClipIcon } from "@heroicons/react/solid";
// import { connect } from "react-redux";
// import { getUser } from "../../../store/selector/auth.selector";
// import * as FileSaver from "file-saver";
// import { getFileExtensionForDocuments } from "Utils/utils";

// const CaseNoteFileInput = (props) => {
//   const {
//     updateFileName,
//     type,
//     handleFile,
//     size,
//     files,
//     handleDelete,
//     isDisabled = false,
//     isVapourFileUpload = false,
//     isFileUploadToS3 = false,
//     handleFileUploadBaseVapour,
//     handleError,
//     handleClear,
//     setFileExt,
//     showDragAndDrop = true,
//     currentLoginUser,
//     isHardClearAllStates = false,
//     clearParentError,
//     selectedFile,
//   } = props;

//   const [fileError, setFileError] = useState(null);
//   const [fileList, setFileList] = useState(files || []);
//   const [progress, setProgress] = useState({});
//   const [showProgress, setShowProgress] = useState(false);
//   const [file, setFile] = useState([]);

//   useEffect(() => {
//     if (isHardClearAllStates) {
//       setFileList([]);
//       setProgress({});
//       setShowProgress(false);
//       if (clearParentError) {
//         clearParentError();
//       }
//     }
//   }, [isHardClearAllStates]);

//   const clearAllStates = () => {
//     setFileList([]);
//     setFileError(null);
//     setProgress({});
//     setShowProgress(false);
//   };

//   const handleChange = (event) => {
//     if (event.currentTarget.files && handleFile) {
//       const newFiles = Array.from(event.currentTarget.files);
//       const totalFiles = fileList.length + newFiles.length;

//       if (totalFiles > 5) {
//         setFileError("You can only upload up to 5 files.");
//         return;
//       }

//       let validFiles = [];
//       for (let file of newFiles) {
//         let message = isValidFile(file, type);
//         if (message.length > 0) {
//           setFileError(message);
//           return;
//         }
//         message = maxFileSize(file, size ? size : 10);
//         if (message.length > 0) {
//           setFileError(message);
//           return;
//         }

//         if (file.name.length > 50) {
//           setFileError(
//             "File name exceeds the limit. It's up to 50 characters!"
//           );
//           return;
//         }

//         validFiles.push(file);
//       }

//       setFileError("");
//       setFileList([...fileList, ...validFiles]);
//       handleFile([...fileList, ...validFiles]);
//     }
//   };

//   const handleRemove = (fileName) => {
//     const updatedFileList = fileList.filter((file) => file.name !== fileName);
//     setFileList(updatedFileList);
//     handleDelete(updatedFileList);
//   };

//   const onDrop = (uploadFiles) => {
//     if (uploadFiles && uploadFiles.length && handleFile) {
//       const totalFiles = fileList.length + uploadFiles.length;

//       if (totalFiles > 5) {
//         setFileError("You can only upload up to 5 files.");
//         return;
//       }

//       let validFiles = [];
//       for (let file of uploadFiles) {
//         let message = isValidFile(file, type);
//         if (message.length > 0) {
//           setFileError(message);
//           return;
//         }
//         message = maxFileSize(file, size ? size : 10);
//         if (message.length > 0) {
//           setFileError(message);
//           return;
//         }

//         if (file.name.length > 50) {
//           setFileError(
//             "File name exceeds the limit. It's up to 50 characters!"
//           );
//           return;
//         }

//         validFiles.push(file);
//       }

//       setFileError("");
//       setFileList([...fileList, ...validFiles]);
//       handleFile([...fileList, ...validFiles]);
//     }
//   };

//   const { getInputProps, getRootProps } = useDropzone({
//     onDrop,
//     disabled: isDisabled,
//   });

//   const uploadFileOnVapour = (file) => {
//     if (!file) return;

//     apiCall
//       .post({
//         url: `${BASE_URL}signed-url`,
//         params: { contentType: file.type },
//         isAuthToken: currentLoginUser?.AccessToken,
//       })
//       .then((response) => {
//         storeFileOnS3(file, {}, response);
//         if (setFileExt) {
//           setFileExt(response.key);
//         }
//       })
//       .catch((err) => {
//         console.error("Error getting signed URL:", err);
//         if (handleError) handleError();
//       });
//   };

//   const storeFileOnS3 = (file, options = {}, response) => {
//     const headers = response.headers;
//     if ("Host" in headers) {
//       delete headers.Host;
//     }
//     if (typeof options.progress === "undefined") {
//       options.progress = () => {};
//     }
//     const cancelToken = options.cancelToken || "";
//     axios
//       .put(response.url, file, {
//         cancelToken: cancelToken,
//         headers: headers,
//         onUploadProgress: (progressEvent) => {
//           options.progress(progressEvent.loaded / progressEvent.total);
//           setShowProgress(true);
//           setProgress((prevProgress) => ({
//             ...prevProgress,
//             [file.name]: (progressEvent.loaded / progressEvent.total) * 100,
//           }));
//         },
//       })
//       .then(() => {
//         setProgress((prevProgress) => ({
//           ...prevProgress,
//           [file.name]: 100,
//         }));

//         handleFileUploadBaseVapour({
//           key: response.key,
//           ext: file.name.split(".").pop(),
//           name: file.name,
//         });
//         if (setFileExt) {
//           setFileExt(response.key);
//         }
//       })
//       .catch((e) => {
//         console.error("Error uploading file to S3:", e);
//         if (handleError) handleError();
//       });
//   };

//   const handleDownload = (file) => {
//     if (file instanceof File) {
//       const blob = new Blob([file], { type: file.type });
//       FileSaver.saveAs(blob, file.name || "downloadedFile");
//     } else {
//       downloadFileFromServer(file, file.name);
//     }
//   };

//   return (
//     <>
//       <div
//         {...getRootProps()}
//         style={{
//           position: "relative",
//           border: "1px solid #ccc",
//           borderRadius: "5px",
//           padding: "10px",
//           backgroundColor: "white",
//         }}
//       >
//         <input
//           {...getInputProps()}
//           disabled={isDisabled}
//           multiple
//           onChange={handleChange}
//         />
//         <div
//           className={`${
//             showDragAndDrop
//               ? "border-2 border-dashed border-gray-300 rounded-lg px-6 pt-5 pb-6"
//               : ""
//           } max-w-full`}
//         >
//           <div
//             className={`${
//               showDragAndDrop
//                 ? "space-y-1 text-center"
//                 : "text-center flex items-center gap-2 truncate"
//             }`}
//           >
//             {showDragAndDrop ? (
//               <>
//                 <svg
//                   className='mx-auto h-12 w-12 text-gray-400'
//                   stroke='currentColor'
//                   fill='none'
//                   viewBox='0 0 48 48'
//                   aria-hidden='true'
//                 >
//                   <path
//                     d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
//                     strokeWidth={2}
//                     strokeLinecap='round'
//                     strokeLinejoin='round'
//                   />
//                 </svg>
//                 <div className='flex text-sm text-gray-600'>
//                   <label
//                     htmlFor='file-upload'
//                     className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
//                   >
//                     <span>Upload files</span>
//                     <input
//                       disabled={isDisabled}
//                       {...getInputProps()}
//                       multiple
//                       onChange={handleChange}
//                     />
//                   </label>
//                   <p className='pl-1'>or drag and drop</p>
//                 </div>
//               </>
//             ) : (
//               <div className='flex items-center'>
//                 <img src={fileUploadIcon} alt='file-upload' className='mr-1' />
//                 <p className='text-xs text-gray-500'>
//                   {`${getFileExtensions(type)
//                     .join(",")
//                     .toString()
//                     .toUpperCase()} up to ${size ? size : "10"}MB`}
//                 </p>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//       {fileError && (
//         <div className='py-3 flex items-center justify-between text-sm'>
//           <span className={`borderError ${Style.fileSizeError}`}>
//             {fileError}
//           </span>
//         </div>
//       )}
//       {!fileError && fileList.length > 0 && (
//         <div className='mt-4'>
//           {fileList.map((file, index) => (
//             <div
//               key={index}
//               className='border rounded-lg border-gray-300 px-2 mt-2'
//             >
//               <li className='py-3 flex justify-between text-sm'>
//                 <div className='flex-1 flex items-center'>
//                   <div className='bg-blue-50 rounded-full h-10 w-10'>
//                     <img
//                       src={FileImage}
//                       alt=''
//                       className='flex-shrink-0 h-7 w-7 pt-[10px] pl-[14px] cursor-pointer'
//                     />
//                   </div>
//                   <div>
//                     <span
//                       onClick={() => handleDownload(file)}
//                       style={{ fontWeight: "500" }}
//                       className='ml-2 flex-1 w-0 font-medium hover:underline cursor-pointer'
//                       title={file.name}
//                     >
//                       {textTruncate(file.name, 30, 29)}
//                     </span>
//                     <div>
//                       <span className='text-sm font-normal ml-2 text-gray-500'>
//                         {getFileSize(file)}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//                 <div className='ml-4 flex-shrink-0'>
//                   <span
//                     role='button'
//                     tabIndex={0}
//                     onClick={() => handleRemove(file.name)}
//                     target='_blank'
//                     className='font-medium text-red-500 hover:text-red-600'
//                   >
//                     Remove
//                   </span>
//                 </div>
//               </li>
//               <div className='px-10 mb-4'>
//                 {showProgress && progress[file.name] > 0 && (
//                   <div className='w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2'>
//                     <div
//                       className='animate-pulse transition duration-300 ease-in-out bg-purple-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full'
//                       style={{ width: `${progress[file.name]}%` }}
//                     >
//                       {progress[file.name].toFixed(0)}%
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//       {updateFileName && !fileError && fileList.length === 0 && (
//         <div
//           onClick={() => {
//             if (file && !(file instanceof File)) {
//               downloadFileFromServer(
//                 file,
//                 `${updateFileName}.${getFileExtension(file)}`
//               );
//             }
//           }}
//           title={
//             file
//               ? `${updateFileName}.${getFileExtension(file)}  `
//               : `${updateFileName}.${getFileExtensionForDocuments(
//                   selectedFile?.link,
//                   30,
//                   29
//                 )}`
//           }
//           className='ml-2 flex-1 w-full font-medium hover:underline cursor-pointer'
//         >
//           {file
//             ? textTruncate(
//                 `${updateFileName}.${getFileExtension(file)}`,
//                 30,
//                 29
//               )
//             : textTruncate(
//                 `${updateFileName}.${getFileExtensionForDocuments(
//                   selectedFile?.link
//                 )}`,
//                 30,
//                 29
//               )}
//         </div>
//       )}
//     </>
//   );
// };

// const mapStateToProps = (state) => {
//   const currentLoginUser = getUser(state);

//   return { currentLoginUser };
// };

// export default connect(mapStateToProps, null)(CaseNoteFileInput);


// above is the code for multiple files upload

import React, { useState, useEffect } from "react";
import {
  downloadFileFromServer,
  getFileExtension,
  getFileExtensions,
  getFileSize,
  isValidFile,
  maxFileSize,
  textTruncate,
} from "../../../Utils/utils";
import Style from "../FileUpload/FileUpload.module.css";
import { useDropzone } from "react-dropzone";
import { apiCall } from "store/services/service";
import { BASE_URL } from "store/services/URL";
import axios from "store/services/axios";
import FileImage from "assets/file.svg";
import fileUploadIcon from "../../../assets/svg/file-upload.svg";
import { PaperClipIcon } from "@heroicons/react/solid";
import { connect } from "react-redux";
import { getUser } from "../../../store/selector/auth.selector";
import * as FileSaver from "file-saver";
import { getFileExtensionForDocuments } from "Utils/utils";

const CaseNoteFileInput = (props) => {
  const {
    updateFileName,
    type,
    handleFile,
    size,
    files,
    handleDelete,
    isDisabled = false,
    isVapourFileUpload = false,
    isFileUploadToS3 = false,
    handleFileUploadBaseVapour,
    handleError,
    handleClear,
    setFileExt,
    showDragAndDrop = true,
    currentLoginUser,
    isHardClearAllStates = false,
    clearParentError,
    selectedFile,
  fileList,setFileList
  } = props;
console.log({files})
  const [fileError, setFileError] = useState(null);
  // const [fileList, setFileList] = useState(files ? [files] : []);
  const [progress, setProgress] = useState({});
  const [showProgress, setShowProgress] = useState(false);
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (isHardClearAllStates) {
      setFileList([]);
      setProgress({});
      setShowProgress(false);
      if (clearParentError) {
        clearParentError();
      }
    }
  }, [isHardClearAllStates]);



  const clearAllStates = () => {
    setFileList([]);
    setFileError(null);
    setProgress({});
    setShowProgress(false);
  };

  const handleChange = (event) => {
    if (event.currentTarget.files && handleFile) {
      const newFile = event.currentTarget.files[0];

      let message = isValidFile(newFile, type);
      if (message.length > 0) {
        setFileError(message);
        return;
      }

      message = maxFileSize(newFile, size ? size : 10);
      if (message.length > 0) {
        setFileError(message);
        return;
      }

      if (newFile.name.length > 50) {
        setFileError("File name exceeds the limit. It's up to 50 characters!");
        return;
      }

      setFileError("");
      setFileList([newFile]);
      handleFile([newFile]);
    }
  };

  const handleRemove = (fileName) => {
    setFileList([]);
    handleDelete([]);
  };

  const onDrop = (uploadFiles) => {
    if (uploadFiles && uploadFiles.length && handleFile) {
      const newFile = uploadFiles[0];

      let message = isValidFile(newFile, type);
      if (message.length > 0) {
        setFileError(message);
        return;
      }

      message = maxFileSize(newFile, size ? size : 10);
      if (message.length > 0) {
        setFileError(message);
        return;
      }

      if (newFile.name.length > 50) {
        setFileError("File name exceeds the limit. It's up to 50 characters!");
        return;
      }

      setFileError("");
      setFileList([newFile]);
      handleFile([newFile]);
    }
  };

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
    disabled: isDisabled,
    multiple: false,
  });

  const uploadFileOnVapour = (file) => {
    if (!file) return;

    apiCall
      .post({
        url: `${BASE_URL}signed-url`,
        params: { contentType: file.type },
        isAuthToken: currentLoginUser?.AccessToken,
      })
      .then((response) => {
        storeFileOnS3(file, {}, response);
        if (setFileExt) {
          setFileExt(response.key);
        }
      })
      .catch((err) => {
        console.error("Error getting signed URL:", err);
        if (handleError) handleError();
      });
  };

  const storeFileOnS3 = (file, options = {}, response) => {
    const headers = response.headers;
    if ("Host" in headers) {
      delete headers.Host;
    }
    if (typeof options.progress === "undefined") {
      options.progress = () => {};
    }
    const cancelToken = options.cancelToken || "";
    axios
      .put(response.url, file, {
        cancelToken: cancelToken,
        headers: headers,
        onUploadProgress: (progressEvent) => {
          options.progress(progressEvent.loaded / progressEvent.total);
          setShowProgress(true);
          setProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: (progressEvent.loaded / progressEvent.total) * 100,
          }));
        },
      })
      .then(() => {
        setProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 100,
        }));

        handleFileUploadBaseVapour({
          key: response.key,
          ext: file.name.split(".").pop(),
          name: file.name,
        });
        if (setFileExt) {
          setFileExt(response.key);
        }
      })
      .catch((e) => {
        console.error("Error uploading file to S3:", e);
        if (handleError) handleError();
      });
  };

  const handleDownload = (file) => {
    if (file instanceof File) {
      const blob = new Blob([file], { type: file.type });
      FileSaver.saveAs(blob, file.name || "downloadedFile");
    } else {
      downloadFileFromServer(file, file.name);
    }
  };

  return (
    <>
      <div
        {...getRootProps()}
        style={{
          position: "relative",
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "10px",
          backgroundColor: "white",
        }}
      >
        <input
          {...getInputProps()}
          disabled={isDisabled}
          onChange={handleChange}
        />
        <div
          className={`${
            showDragAndDrop
              ? "border-2 border-dashed border-gray-300 rounded-lg px-6 pt-5 pb-6"
              : ""
          } max-w-full`}
        >
          <div
            className={`${
              showDragAndDrop
                ? "space-y-1 text-center"
                : "text-center flex items-center gap-2 truncate"
            }`}
          >
            {showDragAndDrop ? (
              <>
                <svg
                  className='mx-auto h-12 w-12 text-gray-400'
                  stroke='currentColor'
                  fill='none'
                  viewBox='0 0 48 48'
                  aria-hidden='true'
                >
                  <path
                    d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <div className='flex text-sm text-gray-600'>
                  <label
                    htmlFor='file-upload'
                    className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                  >
                    <span>Upload file</span>
                    <input
                      disabled={isDisabled}
                      {...getInputProps()}
                      onChange={handleChange}
                    />
                  </label>
                  <p className='pl-1'>or drag and drop</p>
                </div>
              </>
            ) : (
              <div className='flex items-center'>
                <img src={fileUploadIcon} alt='file-upload' className='mr-1' />
                <p className='text-xs text-gray-500'>
                  {`${getFileExtensions(type)
                    .join(",")
                    .toString()
                    .toUpperCase()} up to ${size ? size : "10"}MB`}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {fileError && (
        <div className='py-3 flex items-center justify-between text-sm'>
          <span className={`borderError ${Style.fileSizeError}`}>
            {fileError}
          </span>
        </div>
      )}
      {!fileError && fileList?.length > 0 && (
        <div className='mt-4'>
          {fileList?.map((file, index) => (
            <div
              key={index}
              className='border rounded-lg border-gray-300 px-2 mt-2'
            >
              <li className='py-3 flex justify-between text-sm'>
                <div className='flex-1 flex items-center'>
                  <div className='bg-blue-50 rounded-full h-10 w-10'>
                    <img
                      src={FileImage}
                      alt=''
                      className='flex-shrink-0 h-7 w-7 pt-[10px] pl-[14px] cursor-pointer'
                    />
                  </div>
                  <div>
                    <span
                      onClick={() => handleDownload(file)}
                      style={{ fontWeight: "500" }}
                      className='ml-2 flex-1 w-0 font-medium hover:underline cursor-pointer'
                      title={file.name}
                    >
                      {textTruncate(file.name, 30, 29)}
                    </span>
                    <div>
                      <span className='text-sm font-normal ml-2 text-gray-500'>
                        {getFileSize(file)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='ml-4 flex-shrink-0'>
                  <span
                    role='button'
                    tabIndex={0}
                    onClick={() => handleRemove(file.name)}
                    target='_blank'
                    className='font-medium text-red-500 hover:text-red-600'
                  >
                    Remove
                  </span>
                </div>
              </li>
              <div className='px-10 mb-4'>
                {showProgress && progress[file.name] > 0 && (
                  <div className='w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2'>
                    <div
                      className='animate-pulse transition duration-300 ease-in-out bg-purple-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full'
                      style={{ width: `${progress[file.name]}%` }}
                    >
                      {progress[file.name].toFixed(0)}%
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {updateFileName && !fileError && fileList.length === 0 && (
        <div
          onClick={() => {
            if (file && !(file instanceof File)) {
              downloadFileFromServer(
                file,
                `${updateFileName}.${getFileExtension(file)}`
              );
            }
          }}
          title={
            file
              ? `${updateFileName}.${getFileExtension(file)}  `
              : `${updateFileName}.${getFileExtensionForDocuments(
                  selectedFile?.link,
                  30,
                  29
                )}`
          }
          className='ml-2 flex-1 w-full font-medium hover:underline cursor-pointer'
        >
          {file
            ? textTruncate(
                `${updateFileName}.${getFileExtension(file)}`,
                30,
                29
              )
            : textTruncate(
                `${updateFileName}.${getFileExtensionForDocuments(
                  selectedFile?.link
                )}`,
                30,
                29
              )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const currentLoginUser = getUser(state);

  return { currentLoginUser };
};

export default connect(mapStateToProps, null)(CaseNoteFileInput);
