import React, { useEffect, useMemo, useRef, useState } from "react";
import momentTimeZone from "moment-timezone";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import {
  baseURLImage,
  capitalizeAllLetters,
  checkDisabilityOfShiftStatus,
  checkRunningShiftStatus,
  convertTimeToDatetimeLocal,
  getNameProfile,
  getShiftLength,
  isCheckNextDayShift,
  parseBooleanVal,
  selectedTabState,
  validateCurrentDate,
} from "Utils/utils";
import api from "store/services/rostering.services";
import { Controller, useForm } from "react-hook-form";
import InputField from "components/common/Input/Input";
import moment from "moment";
import {
  DUMMY_REGEX_EXP,
  LOADER_STATUS,
  SHIFT_STATUS,
  SHIFT_TIME_OPTIONS,
} from "Constants/constant";
import _, { isArray, isEmpty } from "lodash";
import { HiOutlineTrash } from "react-icons/hi";
import WorkerShiftDetail from "./WorkerShiftDetail";
import SelectOption from "components/common/Select/Select";
import rosteringApi from "store/services/rostering.services";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { convertStringyfyIntoJSON } from "Utils/utils";
import SwitchWithIcon from "components/common/SwitchWithIcon";
import MultiSelectDropdown from "components/common/MultiSelectDropdown";
import { toast } from "react-toastify";
import { URL_ROSTERING_SHIFT_CHECK_OVERLAPP } from "store/services/URL";
import {
  addFiltersAndPagination,
  apiGetRequest,
  apiPostRequest,
} from "helpers/Requests";
import { FaQuestionCircle } from "react-icons/fa";
import RecurringShift from "./RecurringShift";
import { FaExclamationCircle } from "react-icons/fa";
import { useNavigate } from "react-router";
import { Tooltip } from "react-tooltip";
import { IoWarningOutline } from "react-icons/io5";
import WorkerAvailabiltyModal from "components/Modals/WorkerAvailabiltyModal";
import Loader from "components/common/Loader/Loader";
import { getSearchtextOfScheduleShifts } from "store/selector/rostering.selector";
import { useSelector } from "react-redux";
import WorkerShiftCustomSelect from "./asyncSelect";

const WorkerShift = (props) => {
  const {
    createOrUpdateWorkerShiftRequest,
    workerAndParticipantOptions,
    userItem,
    selectedDate,
    calendarView,
    handleClose,
    isShow,
    selectedShift = null,
    createOrUpdateWorkerShiftLoader,
    createOrUpdateWorkerShiftError,
    refreshCalendar,
    isUserGracePeriodOver,
    workerProfileDetailsFetchRequest,
    workerProfileDetails,
    getWorkerProfileLoader,

    //For Add Shift Props
    isCallFromAddShift = false,
    getParticipantList,
  } = props;
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
    control,
    clearErrors,
  } = useForm();

  const [workerOptions, setWorkerOptions] = useState([]);
  const [participantOptions, setParticipantOptions] = useState([]);
  const [isFetchRecordDetailLoader, setIsFetchRecordDetailLoader] =
    useState(false);
  const [isInitialCreateShiftLoader, setISInitialCreateShiftLoader] = useState(
    LOADER_STATUS.NA
  );
  const [shiftDetailLoader, setShiftDetailLoader] = useState(false);
  const [shiftDetailData, setShiftDetailData] = useState([]);

  const [recurringType, setRecurringType] = useState(null);
  const [recurrInterval, setRecurrInterval] = useState(null);
  const [recurringDates, setRecurringDates] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);

  const [isWorkerLoaded, setIsWorkerLoaded] = useState(false);
  const [isParticipantLoaded, setIsParticipantLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [workerProfileUUID, setWorkerProfileUUID] = useState(null);
  const [recheckAvailability, setRecheckAvailability] = useState(false);
  const [loadingItemId, setLoadingItemId] = useState(null);

  const searchText = useSelector(getSearchtextOfScheduleShifts);

  const navigate = useNavigate();

  const isDateInPast = (date) => {
    if (!date) return false;
    const today = moment().startOf("day");
    const compareDate = moment(date).startOf("day");
    return compareDate.isBefore(today);
  };

  const watchFormData = watch();
  const modalContentRef = useRef(null);

  const participantRatio = watch("pt_id");
  const workerRatio = watch("worker_id");

  const workerAvailabilityData =
    workerProfileDetails?.availability &&
    JSON.parse(workerProfileDetails?.availability);

  useEffect(() => {
    return () => handleClose();
  }, []);

  useEffect(() => {
    if (watchFormData?.is_recurring && modalContentRef.current) {
      modalContentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [watchFormData?.is_recurring, recurringType]);

  useEffect(() => {
    if (selectedShift && selectedShift?.uuid) {
      getShiftDetails(selectedShift.uuid);
    }
  }, [selectedShift]);

  useEffect(() => {
    if (selectedDate && selectedShift === null) {
      setValue("shift_date", selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    const isCheckForIds = Object.keys(watchFormData ?? {}).length;

    if (userItem && calendarView === "worker-view") {
      if (isCheckForIds > 0 && watchFormData?.worker_id) return;
      setValue("worker_id", userItem.uuid);
    } else if (userItem && calendarView === "participant-view") {
      if (isCheckForIds > 0 && watchFormData?.pt_id) return;
      setValue("pt_id", userItem.uuid);
    }
  }, [userItem]);

  useEffect(() => {
    if (workerAndParticipantOptions && !isCallFromAddShift) {
      const { workers, participants } = workerAndParticipantOptions;
      if (calendarView === "worker-view" && workers.length > 0) {
        setWorkerOptions(workers);
      }
      if (calendarView === "participant-view" && participants.length > 0) {
        setParticipantOptions(participants);
      }
    }
  }, [workerAndParticipantOptions]);

  const fetchParticipantsForAddShift = (obj = {}) => {
    console.log("here fetchParticipantsForAddShift");
    getParticipantList({ filters: { ...obj } });
  };

  useEffect(() => {
    if (isCallFromAddShift) {
      fetchParticipantsForAddShift({ page: 1, per_page: 100 });
    }
  }, [isCallFromAddShift]);

  useEffect(() => {
    // if (userItem === null && watchFormData.worker_id) {
    //   setParticipantOptions([]);
    //   setValue("pt_id", null);
    //   handleChangeWorkerOrParticipant({
    //     type: "worker",
    //     uuid: watchFormData.worker_id,
    //   }).then(() => setIsWorkerLoaded(true));
    // }
    if (userItem && watchFormData?.worker_id && watchFormData?.pt_id === null) {
      handleChangeWorkerOrParticipant({
        type: "worker",
        uuid: watchFormData.worker_id,
      }).then(() => setIsWorkerLoaded(true));
    }
  }, [watchFormData.worker_id]);

  useEffect(() => {
    if (userItem && watchFormData?.pt_id && watchFormData?.worker_id === null) {
      handleChangeWorkerOrParticipant({
        type: "participant",
        uuid: watchFormData.pt_id,
      }).then(() => setIsParticipantLoaded(true));
    }
  }, [watchFormData.pt_id]);

  // useEffect(() => {
  //   if (watchFormData?.worker_id && watchFormData?.pt_id === null) {
  //     handleChangeWorkerOrParticipant({
  //       type: "worker",
  //       uuid: watchFormData.worker_id,
  //     });
  //   }
  // }, [watchFormData.worker_id]);

  // useEffect(() => {
  //   if (watchFormData?.pt_id && watchFormData?.worker_id === null) {
  //     handleChangeWorkerOrParticipant({
  //       type: "participant",
  //       uuid: watchFormData.pt_id,
  //     });
  //   }
  // }, [watchFormData.pt_id]);

  const getShiftDetails = async (shiftUuid) => {
    try {
      setShiftDetailLoader(true);
      const response = await rosteringApi.workerShiftDetails({ shiftUuid });

      if (response && response?.shift_detail) {
        const data = response.shift_detail;

        setShiftDetailData(data);

        setValue(
          "shift_date",
          moment(data.shift_date, "YYYY-MM-DD").format("YYYY-MM-DD")
        );

        setValue(
          "end_time",
          data?.end_time
            ? moment(data.end_time, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
            : null
        );

        setValue(
          "start_time",
          data?.start_time
            ? moment(data.start_time, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
            : null
        );

        setValue(
          "worker_start_time",
          data?.worker_start_time
            ? moment(data.worker_start_time, "YYYY-MM-DD HH:mm:ss").format(
                "hh:mm A"
              )
            : null
        );
        setValue(
          "worker_end_time",
          data?.worker_end_time
            ? moment(data.worker_end_time, "YYYY-MM-DD HH:mm:ss").format(
                "hh:mm A"
              )
            : null
        );
        setValue("location_tracking", data?.location_tracking);
        setValue(
          "task",
          data.tasks === null || data.tasks === "[]"
            ? { item: [] }
            : convertStringyfyIntoJSON(data.tasks)
        );
        setValue("note", data.note);
        setValue("is_group_shift", data?.is_group_shift ?? false);
        setValue("shift_accept", data?.shift_accept ?? true);
        setValue("is_signature", data?.is_signature ?? true);
        setValue("invoice_service_type_id", data?.invoice_service_type_id);
        setValue("sleepover", data?.sleepover);
      }
      setShiftDetailLoader(false);
    } catch (err) {
      setShiftDetailLoader(false);
      console.log("err", err);
    }
  };

  const handleChangeWorkerOrParticipant = async ({ type, uuid }) => {
    try {
      setIsFetchRecordDetailLoader(true);
      const response = await api.getWorkerAndParticipantList(
        type === "worker"
          ? { type: type, worker_id: uuid }
          : { type: type, pt_id: uuid }
      );
      if (
        type === "worker" &&
        response &&
        response.worker_participant_listing &&
        response.worker_participant_listing.worker &&
        response.worker_participant_listing.worker.participants
      ) {
        setParticipantOptions([
          ...response.worker_participant_listing.worker.participants.map(
            (x) => ({
              ...x,
              label: `${x.first_name} ${x.last_name}`,
              value: x.uuid,
              icon: x?.profile_picture
                ? baseURLImage(x.profile_picture)
                : getNameProfile(x.first_name, x.last_name || " "),
            })
          ),
        ]);
      } else if (
        type === "participant" &&
        response &&
        response.worker_participant_listing &&
        response.worker_participant_listing.participant &&
        response.worker_participant_listing.participant.sp_workers
      ) {
        setWorkerOptions([
          ...response.worker_participant_listing.participant.sp_workers.map(
            (x) => ({
              ...x,
              label: `${x.first_name} ${x.last_name}`,
              value: x.uuid,
              icon: x?.photo
                ? baseURLImage(x.photo)
                : getNameProfile(x.first_name, x.last_name || " "),
            })
          ),
        ]);
      }
      setIsFetchRecordDetailLoader(false);

      if (selectedShift !== null) {
        if (calendarView === "worker-view" && selectedShift?.participants) {
          if (selectedShift?.participants.length === 1) {
            setValue("pt_id", selectedShift?.participants[0]?.uuid);
          } else {
            setValue("pt_id", selectedShift?.participants);
          }
        } else if (
          calendarView === "participant-view" &&
          selectedShift?.worker
        ) {
          setValue("worker_id", selectedShift?.worker?.uuid);
        }
      }
    } catch (err) {
      setIsFetchRecordDetailLoader(false);
      console.log("err", err.message);
    }
  };

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    const showToast = (message, toastId) => {
      setTimeout(() => {
        if (!toast.isActive(toastId)) {
          toast.warning(message, { toastId: toastId });
        }
      }, 200);
    };

    if (
      watchFormData?.is_group_shift &&
      calendarView === "worker-view" &&
      watchFormData?.pt_id.length < 2
    ) {
      const toastId = "isSingleGroupShift";

      setTimeout(() => {
        if (!toast.isActive(toastId)) {
          toast.warning(
            "Single participant can't be selected for group shift",
            { toastId: toastId }
          );
        }
      }, 200);
      return;
    }

    if (watchFormData?.is_group_shift) {
      if (calendarView === "worker-view" && watchFormData?.pt_id.length < 2) {
        showToast(
          "Single participant can't be selected for group shift",
          "isSingleGroupShift"
        );
        return;
      }

      if (
        calendarView === "participant-view" &&
        watchFormData?.worker_id?.length < 2
      ) {
        showToast(
          "Single worker can't be selected for group shift",
          "isSingleGroupShiftWorker"
        );
        return;
      }
    }

    if (watchFormData?.is_recurring && recurringDates.length === 0) {
      showToast(
        "Choose recurring interval or disable the recurring option",
        "isRecurringShiftToast"
      );
      return;
    }

    if (
      watchFormData?.is_recurring &&
      recurringDates.length >= 0 &&
      watchFormData?.shift_date &&
      !validateCurrentDate(
        moment(watchFormData?.shift_date),
        moment(new Date()),
        ["past"],
        false
      )
    ) {
      showToast(
        "Recurring shifts cannot be created for past date.",
        "isRecurringShiftDateValidationToast"
      );
      return;
    }

    // if (
    //   calendarView === "participant-view" &&
    //   (watchFormData?.worker_id === null ||
    //     watchFormData?.worker_id === undefined)
    // ) {
    //   showToast("Please choose worker", "workerId");
    //   return;
    // }
    const currentTimeZone = momentTimeZone.tz.guess();
    Object.assign(data, {
      timezone: currentTimeZone,
      tasks: JSON.stringify(data.task),
      shift_length: getShiftLength(
        watchFormData?.start_time,
        watchFormData?.end_time,
        true
      ),
      is_group_shift: watchFormData?.is_group_shift || false,
      is_signature: watchFormData?.is_signature,
      shift_date: moment(new Date(watchFormData.shift_date)).toISOString(),
      start_time: convertTimeToDatetimeLocal(
        moment(watchFormData.shift_date),
        watchFormData.start_time
      ),
      end_time:
        isCheckNextDayShift(
          watchFormData?.shift_date,
          watchFormData?.start_time,
          watchFormData?.end_time
        ) !== false
          ? isCheckNextDayShift(
              watchFormData?.shift_date,
              watchFormData?.start_time,
              watchFormData?.end_time
            )
          : convertTimeToDatetimeLocal(
              moment(watchFormData.shift_date),
              watchFormData.end_time
            ),
      worker_start_time: watchFormData?.worker_start_time
        ? moment(watchFormData?.worker_start_time, "hh:mm A").format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          )
        : "",
      worker_end_time: watchFormData?.worker_end_time
        ? moment(watchFormData?.worker_end_time, "hh:mm A").format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          )
        : "",

      location_tracking: data?.location_tracking,
    });
    if (data.hasOwnProperty("task")) delete data["task"];
    if (calendarView === "worker-view") {
      let participantId;

      if (Array.isArray(watchFormData?.pt_id)) {
        //for multiple purpose
        participantId =
          watchFormData?.is_group_shift &&
          watchFormData &&
          watchFormData.pt_id.length > 0
            ? watchFormData.pt_id?.map((item) => {
                const matchingParticipant = participantOptions.find(
                  (option) => option.uuid === item.uuid
                );
                return matchingParticipant?.id;
              })
            : [];
      } else {
        //for one to one
        participantId = participantOptions.find(
          (x) => x.uuid === watchFormData.pt_id
        )?.id;
      }

      let workerId = null;

      if (isCallFromAddShift) {
        workerId = watchFormData?.worker_id;
      } else {
        workerId = workerOptions.find(
          (x) => x.uuid === watchFormData.worker_id
        )?.id;
      }

      Object.assign(data, {
        worker_id: [workerId],
        pt_id: Array.isArray(participantId)
          ? [...participantId]
          : [participantId],
      });
    } else if (calendarView === "participant-view") {
      let participantId = null;
      if (isCallFromAddShift) {
        participantId = watchFormData?.pt_id;
      } else {
        participantId = participantOptions?.find(
          (x) => x.uuid === watchFormData?.pt_id
        )?.id;
      }

      let workerId;
      if (Array.isArray(watchFormData?.worker_id)) {
        workerId =
          parseBooleanVal(watchFormData?.is_group_shift) &&
          watchFormData.worker_id?.length > 0
            ? watchFormData.worker_id?.map((item) => {
                const matchingParticipant = workerOptions.find(
                  (option) => option.uuid === item.uuid
                );
                return matchingParticipant?.id; // Array of matching participant ids
              })
            : [];
      } else {
        workerId = workerOptions?.find(
          (x) => x.uuid === watchFormData?.worker_id
        )?.id;
      }

      Object.assign(data, {
        pt_id: [participantId],
        worker_id: Array.isArray(workerId) ? [...workerId] : [workerId],
      });
    }
    setISInitialCreateShiftLoader(LOADER_STATUS.PENDING);

    if (watchFormData?.is_recurring && recurringDates.length > 0) {
      Object.assign(data, {
        till_dates: recurringDates,
      });
    }

    Object.assign(data, {
      invoice_service_type_id: data?.invoice_service_type_id,
      sleepover: data?.sleepover,
      shift_accept: data?.shift_accept,
      overlap: data?.shift_overlapped,
    });
    // console.log("submit data payload", {
    //   data:
    //     selectedShift !== null
    //       ? { ...data, uuid: selectedShift.uuid, id: selectedShift.id }
    //       : data,
    //   calendarView: calendarView,
    //   isUpdate: selectedShift !== null,
    //   isWorkerChange:
    //     userItem && data.worker_id[0] === userItem?.id ? false : true,
    //   prevWorkerUUID:
    //     userItem && data.worker_id[0] === userItem?.id ? null : userItem?.uuid,
    //   name: capitalizeAllLetters(
    //     `${userItem?.first_name} ${userItem?.last_name}`
    //   ),
    // });
    createOrUpdateWorkerShiftRequest({
      data:
        selectedShift !== null
          ? { ...data, uuid: selectedShift.uuid, id: selectedShift.id }
          : data,
      calendarView: calendarView,
      isUpdate: selectedShift !== null,
      isWorkerChange:
        userItem && data.worker_id[0] === userItem?.id ? false : true,
      prevWorkerUUID:
        userItem && data.worker_id[0] === userItem?.id ? null : userItem?.uuid,
      name: capitalizeAllLetters(
        `${userItem?.first_name} ${userItem?.last_name}`
      ),
    });
    setTimeout(() => {
      if (selectedShift !== null) {
        const payload = { isRefreshCall: true };
        if (!isEmpty(searchText)) {
          Object.assign(payload, {
            search: searchText,
          });
        }
        refreshCalendar(payload);
      }
    }, [2000]);
  };

  useEffect(() => {
    if (
      !createOrUpdateWorkerShiftLoader &&
      (!createOrUpdateWorkerShiftError ||
        createOrUpdateWorkerShiftError.length === 0) &&
      isInitialCreateShiftLoader !== LOADER_STATUS.NA
    ) {
      handleClose();
      setIsFetchRecordDetailLoader(false);
      reset();
    }
  }, [createOrUpdateWorkerShiftLoader]);

  const handleAddTask = () => {
    if (checkRunningShiftStatus(selectedShift?.status)) return;
    const obj = _.clone(watchFormData?.task?.item ?? []);
    obj.push("");
    setValue(`task.item`, [...obj]);
  };

  const handleTaskDelete = (Taskindex) => {
    if (!isNaN(Taskindex) && Taskindex !== null) {
      const data = _.cloneDeep(watchFormData?.task?.item ?? []);
      data.splice(Taskindex, 1);
      setValue(`task.item`, [...data]);
    }
  };

  const getSelectedOption = (value) => {
    const watchFormValue = watchFormData && watchFormData?.[`${value}`];

    if (Array.isArray(watchFormValue)) {
      // If watchFormData.value is an array
      return watchFormValue?.map((val) =>
        workerOptions?.find((x) => x?.value === val?.value)
      );
    } else {
      // If watchFormData.value is a single value
      return watchFormValue
        ? workerOptions?.find((x) => x?.value === watchFormValue)
        : null;
    }
  };

  const getSelectedParticipant = (value) => {
    const watchFormValue = watchFormData && watchFormData?.[`${value}`];

    if (Array.isArray(watchFormValue)) {
      // If watchFormData.value is an array
      return watchFormValue?.map((val) =>
        participantOptions?.find((x) => x?.uuid === val?.uuid)
      );
    } else {
      // If watchFormData.value is a single value
      return watchFormValue
        ? participantOptions?.find((x) => x?.value === watchFormValue)
        : null;
    }
  };

  useEffect(() => {
    if (
      watchFormData?.start_time &&
      watchFormData?.end_time &&
      getShiftLength(
        watchFormData?.start_time,
        watchFormData?.end_time,
        false
      ) !== "00:00"
    ) {
      clearErrors("end_time");
    }
  }, [watchFormData?.end_time]);

  const checkDisabliltyOfShiftForWorker = () => {
    // if (isFetchRecordDetailLoader) return true;
    // else if (selectedShift !== null) {
    //   return selectedShift?.status === SHIFT_STATUS.DID_NOT_ACCEPT
    //     ? false
    //     : selectedShift !== null;
    // }
    return userItem && calendarView === "worker-view" ? true : false;
  };

  /*TO GET SERVICE TYPE */
  useEffect(() => {
    getServiceTypes();
  }, []);

  const getServiceTypes = () => {
    apiGetRequest("/roster-invoices/service-type")
      .then((res) => {
        if (res.data) {
          const modifiedValues = res?.data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          setServiceTypes(modifiedValues);
        }
      })
      .catch((error) => console.log({ error }));
  };

  const handleRedirection = (uuid) => {
    selectedTabState.save(
      JSON.stringify({
        name: "Settings",
        id: 13,
        children: [],
      })
    );
    navigate(`/sp-profile/${uuid}`);
  };

  const isParticipantStateMissing = () => {
    const selectedParticipant = getSelectedParticipant("pt_id");

    if (selectedParticipant) {
      if (Array.isArray(selectedParticipant)) {
        return selectedParticipant.some((item) => !item?.state_id);
      } else {
        return !selectedParticipant.state_id;
      }
    }

    return false;
  };

  const handleLimitTheParticipant = () => {
    const selectedParticipant = getSelectedParticipant("pt_id");
    if (selectedParticipant) {
      if (Array.isArray(selectedParticipant)) {
        return selectedParticipant.length > 4;
      } else {
        return false;
      }
    }

    return false;
  };

  const selectedParticipant = useMemo(
    () => getSelectedParticipant("pt_id"),
    [getSelectedParticipant("pt_id")]
  );

  const selectedWorker = useMemo(
    () => getSelectedOption("worker_id"),
    [getSelectedOption("worker_id")]
  );

  const [shiftOverlap, setShiftOverlap] = useState(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    } else {
      if (
        watchFormData?.shift_date &&
        watchFormData?.end_time &&
        watchFormData?.start_time &&
        (isWorkerLoaded || isParticipantLoaded)
      ) {
        setLoading(true);
        const payloadData = {
          id: selectedShift?.id,
          worker_id: Array.isArray(selectedWorker)
            ? selectedWorker?.map((item) => item.id)
            : [selectedWorker?.id],
          pt_id: Array.isArray(selectedParticipant)
            ? selectedParticipant?.map((item) => item.id)
            : [selectedParticipant?.id],
          end_time:
            isCheckNextDayShift(
              watchFormData?.shift_date,
              watchFormData?.start_time,
              watchFormData?.end_time
            ) !== false
              ? isCheckNextDayShift(
                  watchFormData?.shift_date,
                  watchFormData?.start_time,
                  watchFormData?.end_time
                )
              : convertTimeToDatetimeLocal(
                  moment(watchFormData.shift_date),
                  watchFormData.end_time
                ),
          shift_date: moment(new Date(watchFormData.shift_date)).toISOString(),
          start_time: convertTimeToDatetimeLocal(
            moment(watchFormData.shift_date),
            watchFormData.start_time
          ),
          is_group_shift: watchFormData?.is_group_shift || false,
        };

        apiPostRequest(URL_ROSTERING_SHIFT_CHECK_OVERLAPP, payloadData)
          .then((res) => {
            setLoading(false);
            setShiftOverlap(res?.data?.data);
          })
          .catch((error) => {
            setLoading(false);
            console.log({ error });
          });
      }
    }
  }, [
    watchFormData?.end_time,
    watchFormData?.worker_id,
    watchFormData?.pt_id,
    watchFormData?.is_group_shift,
    watchFormData?.start_time,
    watchFormData?.shift_date,
    recheckAvailability,
  ]);

  const handleShiftUpdatePermissionParticipantView = () => {
    if (
      selectedShift !== null &&
      watchFormData?.is_group_shift &&
      calendarView === "participant-view" &&
      shiftDetailData?.participants?.length > 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getToolTipContent = () => {
    return (
      <div className='flex flex-col gap-4 p-2'>
        <p className='text-xs text-white flex items-center '>
          <SwitchWithIcon checked={true} disabled={true} cssClass='mr-2' />
          Worker need to manually accept this shift
        </p>
        <p className='text-xs text-white flex items-center '>
          <SwitchWithIcon checked={false} disabled={true} cssClass='mr-2' />{" "}
          Shifts will be automatically accepted without requiring worker manual
          confirmation
        </p>
      </div>
    );
  };

  const handleWorkerAvailability = (item) => {
    setLoadingItemId(item.uuid);
    workerProfileDetailsFetchRequest({
      profile_id: item?.uuid,
    });
    setWorkerProfileUUID(item?.uuid);
    setOpenModal(true);
  };

  const createShiftPopup = () => {
    return (
      <div>
        {watchFormData && (
          <>
            <div className='flex justify-between px-3'>
              {
                <WorkerShiftDetail
                  userItem={
                    calendarView === "worker-view"
                      ? getSelectedParticipant("pt_id")
                      : getSelectedOption("worker_id")
                  }
                  shiftDetail={watchFormData}
                  isGroupShift={watchFormData?.is_group_shift}
                />
              }
            </div>
            <div className='divide-y pt-4 border-b border-text-gray-700'></div>
          </>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='px-5 pt-7'>
            <div className='flex  gap-x-2  gap-y-2'>
              <div className='flex flex-col w-full sm:w-1/2 gap-y-4'>
                <div className='flex items-center justify-between'>
                  <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                    Worker Tracking
                  </label>
                  <Controller
                    name='location_tracking'
                    control={control}
                    defaultValue={watchFormData?.location_tracking || true}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("location_tracking", value);
                        }}
                        checked={field.value}
                        disabled={checkRunningShiftStatus(
                          selectedShift?.status
                        )}
                      />
                    )}
                  />
                </div>
                <div className='flex items-center justify-between'>
                  <label className='text-gray-700 lg:text-sm font-medium mb-1  sm:text-xs'>
                    Group Shift
                  </label>
                  <Controller
                    name='is_group_shift'
                    control={control}
                    defaultValue={watchFormData?.is_group_shift || false}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          if (calendarView === "worker-view") {
                            setValue("pt_id", null);
                          } else {
                            setValue("worker_id", null);
                          }
                          field.onChange(value);
                          setValue("is_group_shift", value);
                        }}
                        checked={field.value}
                        disabled={
                          selectedShift &&
                          checkRunningShiftStatus(selectedShift?.status)
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <div className='w-0.5 mx-1 h-15 bg-gray-300'></div>

              <div className='flex flex-col w-full sm:w-1/2 gap-y-4'>
                <div className='flex items-center justify-between'>
                  <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                    Participant's Signature
                  </label>
                  <Controller
                    name='is_signature'
                    control={control}
                    defaultValue={watchFormData?.is_signature || false}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("is_signature", value);
                        }}
                        checked={field.value}
                        disabled={checkRunningShiftStatus(
                          selectedShift?.status
                        )}
                      />
                    )}
                  />
                </div>
                <div className='flex items-center justify-between'>
                  <label className='text-gray-700 lg:text-sm font-medium mb-1  sm:text-xs flex items-center justify-center'>
                    Shift Acceptance Required
                    <a data-tooltip-id='my-tooltip'>
                      <FaQuestionCircle
                        data-tip
                        data-tooltip-id='manualAcceptHelp'
                        className='ml-1 text-gray-500 cursor-pointer'
                      />
                    </a>
                  </label>
                  <Tooltip
                    id='my-tooltip'
                    place='top'
                    content={getToolTipContent()}
                    variant='dark'
                    className='z-20'
                  />
                  <Controller
                    name='shift_accept'
                    control={control}
                    defaultValue={watchFormData?.shift_accept || true}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("shift_accept", value);
                        }}
                        checked={field.value}
                        disabled={checkRunningShiftStatus(
                          selectedShift?.status
                        )}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='w-0.5 h-15 mx-1 bg-gray-300'></div>
              <div className='flex flex-col w-full sm:w-1/2 gap-y-4'>
                <div className='flex items-center justify-between'>
                  <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                    Allow Shift Overlapping
                  </label>
                  <Controller
                    name='shift_overlapped'
                    control={control}
                    defaultValue={watchFormData?.shift_overlapped || false}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("shift_overlapped", value);
                        }}
                        checked={field.value}
                        disabled={checkRunningShiftStatus(
                          selectedShift?.status
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            {shiftOverlap &&
              shiftOverlap?.overlapping_shift &&
              shiftOverlap?.overlapping_shift[0]?.overlapped &&
              !watchFormData?.shift_overlapped && (
                <div className='flex items-center mt-2 bg-red-100 border border-red-400 text-red-700 p-1 rounded'>
                  <FaExclamationCircle className='text-red-500 mr-2 h-6 w-6' />
                  <p className='text-sm font-semibold'>
                    {" "}
                    {shiftOverlap &&
                      shiftOverlap?.overlapping_shift[0]?.worker_name?.length >
                        0 &&
                      shiftOverlap?.overlapping_shift[0]?.worker_name?.map(
                        (item) => `${item},`
                      )}{" "}
                    {shiftOverlap?.overlapping_shift[0]?.worker_name?.length >
                      0 &&
                    shiftOverlap?.overlapping_shift[0]?.worker_name?.length ===
                      1
                      ? "already has"
                      : "already have"}{" "}
                    shift during this time, causing an overlap. To proceed with
                    scheduling, please turn on the "Allow Shift Overlapping"
                    option
                  </p>
                </div>
              )}
            {loading ? (
              <div className='flex justify-center items-center w-full my-2'>
                <Loader isScheduleScreen />{" "}
              </div>
            ) : (
              shiftOverlap &&
              shiftOverlap?.availability?.length > 0 &&
              shiftOverlap?.availability?.map((item) => (
                <div className='flex items-center mt-2 bg-yellow-100 border border-yellow-500 text-yellow-700 p-1 rounded'>
                  <IoWarningOutline className='text-yellow-500 mr-2 h-6 w-6' />
                  <p className='text-sm font-semibold'>
                    {item?.name} is not available for the selected time slot,
                    <span
                      onClick={() => handleWorkerAvailability(item)}
                      className=' text-blue-500 text-sm hover:text-blue-700 cursor-pointer underline'
                    >
                      {getWorkerProfileLoader &&
                      loadingItemId === item?.uuid ? (
                        <Loader cssClass='ml-2' isScheduleScreen isSmallView />
                      ) : (
                        " Please click here to check their availability."
                      )}
                    </span>
                  </p>
                </div>
              ))
            )}{" "}
            <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
              <div className=''>
                <label className='text-gray-700 text-sm font-medium mb-1'>
                  Worker
                </label>
                {watchFormData?.is_group_shift &&
                  calendarView === "participant-view" &&
                  selectedShift && (
                    <MultiSelectDropdown
                      showIcon
                      data={workerOptions}
                      cssClass='z-30'
                      name='worker_id'
                      register={register}
                      setValue={setValue}
                      control={control}
                      watch={watch}
                      selectedOption={getSelectedOption("worker_id")}
                      setSelectedOptions={setSelectedOptions}
                      selectedOptions={selectedOptions}
                      clearErrors={clearErrors}
                      selectedShift={selectedShift}
                      calendarView={calendarView === "participant-view"}
                      // isDisabled={
                      //   checkDisabliltyOfShiftForWorker() ||
                      //   checkDisabilityOfShiftStatus(selectedShift?.status)
                      // }
                      // calendarView={calendarView ==="worker-view"}
                      // errorMessage={
                      //   errors?.worker_id?.type === "required"
                      //     ? "This field is required!"
                      //     : null
                      // }
                    />
                  )}

                {watchFormData?.is_group_shift &&
                  calendarView === "participant-view" &&
                  !selectedShift && (
                    <MultiSelectDropdown
                      showIcon
                      data={workerOptions}
                      cssClass='z-30'
                      name='worker_id'
                      register={register}
                      setValue={setValue}
                      control={control}
                      watch={watch}
                      selectedOption={getSelectedOption("worker_id")}
                      setSelectedOptions={setSelectedOptions}
                      selectedOptions={selectedOptions}
                      clearErrors={clearErrors}
                      calendarView={calendarView === "participant-view"}

                      // isDisabled={
                      //   checkDisabliltyOfShiftForWorker() ||
                      //   checkDisabilityOfShiftStatus(selectedShift?.status)
                      // }
                      // calendarView={calendarView ==="worker-view"}
                      // errorMessage={
                      //   errors?.worker_id?.type === "required"
                      //     ? "This field is required!"
                      //     : null
                      // }
                    />
                  )}

                {isCallFromAddShift && calendarView === "worker-view" && (
                  <>
                    <select
                      className='hidden'
                      {...register("worker_id", {
                        value: watchFormData?.worker_id || null,
                        required: true,
                      })}
                    />
                    <WorkerShiftCustomSelect
                      selectFor='worker'
                      // isMultiple={
                      //   watchFormData?.is_group_shift &&
                      //   calendarView === "worker-view"
                      // }
                      clearErrors={clearErrors}
                      name='worker_id'
                      setValue={setValue}
                      isDisabled={
                        watchFormData?.is_group_shift
                          ? checkDisabliltyOfShiftForWorker() ||
                            checkDisabilityOfShiftStatus(selectedShift?.status)
                          : checkDisabliltyOfShiftForWorker() ||
                            checkRunningShiftStatus(selectedShift?.status)
                      }
                      selectedValue={watchFormData?.worker_id}
                      errorMessage={
                        errors?.worker_id?.type === "required"
                          ? "This field is required!"
                          : null
                      }
                      isValueChanges={(e, val) => {
                        if (
                          userItem === null &&
                          e &&
                          calendarView === "worker-view"
                        ) {
                          setParticipantOptions([]);
                          setValue("pt_id", null);
                          handleChangeWorkerOrParticipant({
                            type: "worker",
                            uuid: val.uuid,
                          }).then(() => setIsWorkerLoaded(true));
                        }
                      }}
                    />
                  </>
                )}

                {(!isCallFromAddShift || calendarView !== "worker-view") &&
                  watchFormData?.is_group_shift &&
                  calendarView === "worker-view" && (
                    <SelectOption
                      isValueChanges={(e, val) => {
                        if (
                          userItem === null &&
                          e &&
                          calendarView === "worker-view"
                        ) {
                          setParticipantOptions([]);
                          setValue("pt_id", null);
                          handleChangeWorkerOrParticipant({
                            type: "worker",
                            uuid: val,
                          }).then(() => setIsWorkerLoaded(true));
                        }
                      }}
                      noOptionsMessage={"No Worker Assigned"}
                      isRequired={false}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      name='worker_id'
                      loading={isFetchRecordDetailLoader}
                      isDisabled={
                        checkDisabliltyOfShiftForWorker() ||
                        checkDisabilityOfShiftStatus(selectedShift?.status)
                      }
                      cssClass='z-30'
                      showIcon={true}
                      isSearch={true}
                      isClearable={false}
                      data={workerOptions}
                      selectedOption={getSelectedOption("worker_id")}
                      errorMessage={
                        errors?.worker_id?.type === "required"
                          ? "This field is required!"
                          : null
                      }
                    />
                  )}
                {!watchFormData?.is_group_shift &&
                  (!isCallFromAddShift || calendarView !== "worker-view") && (
                    <SelectOption
                      isValueChanges={(e, val) => {
                        if (
                          userItem === null &&
                          e &&
                          calendarView === "worker-view"
                        ) {
                          setParticipantOptions([]);
                          setValue("pt_id", null);
                          handleChangeWorkerOrParticipant({
                            type: "worker",
                            uuid: val,
                          }).then(() => setIsWorkerLoaded(true));
                        }
                      }}
                      noOptionsMessage={"No Worker Assigned"}
                      isRequired={false}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      name='worker_id'
                      loading={isFetchRecordDetailLoader}
                      isDisabled={
                        checkDisabliltyOfShiftForWorker() ||
                        checkRunningShiftStatus(selectedShift?.status)
                      }
                      cssClass='z-30'
                      showIcon={true}
                      isSearch={true}
                      isClearable={false}
                      data={workerOptions}
                      selectedOption={getSelectedOption("worker_id")}
                      errorMessage={
                        errors?.worker_id?.type === "required"
                          ? "This field is required!"
                          : null
                      }
                    />
                  )}
              </div>

              <div className=''>
                <label className='text-gray-700 text-sm font-medium mb-1'>
                  Participant
                  {/* {watchFormData?.is_group_shift &&
                  calendarView === "worker-view" && "(Max 4 Participants)"} */}
                </label>

                {watchFormData?.is_group_shift &&
                  calendarView === "worker-view" && (
                    <MultiSelectDropdown
                      showIcon
                      data={participantOptions}
                      cssClass='z-30'
                      name='pt_id'
                      register={register}
                      setValue={setValue}
                      isDisabled={
                        checkRunningShiftStatus(selectedShift?.status) ||
                        isFetchRecordDetailLoader
                      }
                      // isDisabled={
                      //   checkDisabilityOfShiftStatus(selectedShift?.status) ||
                      //   isFetchRecordDetailLoader ||
                      //   (userItem && calendarView === "participant-view"
                      //     ? true
                      //     : false) ||
                      //   selectedShift !== null
                      // }
                      control={control}
                      watch={watch}
                      setSelectedOptions={setSelectedOptions}
                      selectedOptions={selectedOptions}
                      clearErrors={clearErrors}
                      errorMessage={
                        errors?.pt_id?.type === "required"
                          ? "This field is required!"
                          : null
                      }
                      calendarView={calendarView === "worker-view"}
                    />
                  )}

                {!watchFormData?.is_group_shift &&
                  calendarView === "worker-view" && (
                    <SelectOption
                      noOptionsMessage={"No Participant Assigned"}
                      isRequired={true}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      name='pt_id'
                      loading={isFetchRecordDetailLoader}
                      isDisabled={
                        checkRunningShiftStatus(selectedShift?.status) ||
                        isFetchRecordDetailLoader ||
                        (userItem && calendarView === "participant-view"
                          ? true
                          : false)
                      }
                      // isDisabled={
                      //   checkDisabilityOfShiftStatus(selectedShift?.status) ||
                      //   isFetchRecordDetailLoader ||
                      //   (userItem && calendarView === "participant-view"
                      //     ? true
                      //     : false) ||
                      //   selectedShift !== null
                      // }
                      cssClass='z-30'
                      showIcon={true}
                      isSearch={true}
                      isClearable={false}
                      data={participantOptions}
                      selectedOption={
                        watchFormData?.pt_id
                          ? participantOptions.find(
                              (x) => x.value === watchFormData?.pt_id
                            )
                          : null
                      }
                      errorMessage={
                        errors?.pt_id?.type === "required"
                          ? "This field is required!"
                          : null
                      }
                    />
                  )}

                {calendarView === "participant-view" &&
                  (isCallFromAddShift ? (
                    <>
                      <select
                        className='hidden'
                        {...register("pt_id", {
                          value: watchFormData?.pt_id || null,
                          required: true,
                        })}
                      />
                      <WorkerShiftCustomSelect
                        isMultiple={
                          watchFormData?.is_group_shift &&
                          calendarView === "worker-view"
                        }
                        clearErrors={clearErrors}
                        name='pt_id'
                        setValue={setValue}
                        isDisabled={
                          watchFormData?.is_group_shift &&
                          calendarView === "worker-view"
                            ? checkRunningShiftStatus(selectedShift?.status) ||
                              isFetchRecordDetailLoader
                            : checkDisabilityOfShiftStatus(
                                selectedShift?.status
                              ) ||
                              isFetchRecordDetailLoader ||
                              (userItem && calendarView === "participant-view"
                                ? true
                                : false) ||
                              selectedShift !== null
                        }
                        selectedValue={watchFormData?.pt_id}
                        errorMessage={
                          errors?.pt_id?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                        isValueChanges={(e, val) => {
                          if (userItem === null && e) {
                            setWorkerOptions([]);
                            setValue("worker_id", null);
                            handleChangeWorkerOrParticipant({
                              type: "participant",
                              uuid: val.uuid,
                            }).then(() => setIsParticipantLoaded(true));
                          }
                        }}
                      />
                    </>
                  ) : (
                    <SelectOption
                      isValueChanges={(e, val) => {
                        if (userItem === null && e) {
                          setWorkerOptions([]);
                          setValue("worker_id", null);
                          handleChangeWorkerOrParticipant({
                            type: "participant",
                            uuid: val,
                          }).then(() => setIsParticipantLoaded(true));
                        }
                      }}
                      noOptionsMessage={"No Participant Assigned"}
                      isRequired={true}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      name='pt_id'
                      loading={isFetchRecordDetailLoader}
                      isDisabled={
                        checkDisabilityOfShiftStatus(selectedShift?.status) ||
                        isFetchRecordDetailLoader ||
                        (userItem && calendarView === "participant-view"
                          ? true
                          : false) ||
                        selectedShift !== null
                      }
                      cssClass='z-30'
                      showIcon={true}
                      isSearch={true}
                      isClearable={false}
                      data={participantOptions}
                      selectedOption={
                        watchFormData?.pt_id
                          ? participantOptions.find(
                              (x) => x.value === watchFormData?.pt_id
                            )
                          : null
                      }
                      errorMessage={
                        errors?.pt_id?.type === "required"
                          ? "This field is required!"
                          : null
                      }
                    />
                  ))}
              </div>

              {getSelectedParticipant("pt_id") && (
                <div className='col-span-2 '>
                  {Array.isArray(getSelectedParticipant("pt_id"))
                    ? getSelectedParticipant("pt_id").length > 0 &&
                      getSelectedParticipant("pt_id").some(
                        (item) => !item.state_id
                      ) && (
                        <div>
                          {getSelectedParticipant("pt_id")
                            .filter((item) => !item.state_id)
                            .map((el, index, array) => (
                              <div
                                key={index}
                                className='flex items-center bg-red-100 border border-red-400 text-red-700 p-2 rounded'
                                style={{ marginTop: index !== 0 ? "7px" : "" }}
                              >
                                <FaExclamationCircle className='text-red-500 mr-2' />
                                <p className='text-sm font-semibold'>
                                  The Catalog Service State is missing for
                                  <span className='capitalize '>
                                    {el?.first_name} {el?.last_name}{" "}
                                  </span>{" "}
                                  {/* has missing the Catalog Service State,{" "} */}
                                  <span
                                    onClick={() => handleRedirection(el?.uuid)}
                                    className='capitalize text-blue-500 text-sm hover:text-blue-700 cursor-pointer underline'
                                  >
                                    , Please add here
                                  </span>
                                </p>
                              </div>
                            ))}
                        </div>
                      )
                    : !getSelectedParticipant("pt_id")?.state_id && (
                        <div className='flex items-center bg-red-100 border border-red-400 text-red-700 p-2 rounded'>
                          <FaExclamationCircle className='text-red-500 mr-2' />
                          <p className='text-sm font-semibold'>
                            The Catalog Service State is missing for{" "}
                            <span className='capitalize '>
                              {getSelectedParticipant("pt_id")?.first_name}{" "}
                              {getSelectedParticipant("pt_id")?.last_name}
                            </span>{" "}
                            {/* has missing the Catalog Service State, */}
                            <span
                              onClick={() =>
                                handleRedirection(
                                  getSelectedParticipant("pt_id")?.uuid
                                )
                              }
                              className='capitalize text-blue-500 text-sm  hover:text-blue-700 cursor-pointer underline'
                            >
                              {" "}
                              , Please add here
                            </span>
                          </p>
                        </div>
                      )}
                </div>
              )}

              {/* <div className="">
                <label className="text-gray-700 text-sm font-medium mb-1">
                  Hours Type
                </label>
                <SelectOption
                  isSearch={false}
                  handleChange={(e) => {
                    handleClick(e);
                  }}
                  data={[]}
                />
              </div> */}
              <div className='col-span-2'>
                <div className='grid grid-cols-3 gap-x-5'>
                  <div className='col-span-1'>
                    <InputField
                      setValue={setValue}
                      register={register}
                      pattern={
                        validateCurrentDate(
                          moment(watchFormData?.date ?? null),
                          moment(new Date()),
                          ["present", "future", "past"],
                          true
                        )
                          ? DUMMY_REGEX_EXP
                          : null
                      }
                      label={"Shift Date"}
                      type='date'
                      value={
                        watchFormData?.date
                          ? moment(watchFormData?.date).format("DD-MM-YYYY")
                          : null
                      }
                      name='shift_date'
                      isDisabled={
                        selectedShift ||
                        checkDisabilityOfShiftStatus(selectedShift?.status)
                      }
                      isRequired={true}
                      ErrorMessage={
                        errors?.shift_date?.type === "required"
                          ? "This field is required!"
                          : errors?.shift_date?.type === "pattern"
                          ? validateCurrentDate(
                              moment(watchFormData?.shift_date ?? null),
                              moment(new Date()),
                              ["present", "future", "past"],
                              false
                            )
                          : null
                      }
                    />
                  </div>
                  <div className='col-span-1'>
                    <label className='text-gray-700 text-sm font-medium mb-1'>
                      Shift Start Time
                    </label>
                    <SelectOption
                      isDisabled={checkRunningShiftStatus(
                        selectedShift?.status
                      )}
                      isRequired={true}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      name='start_time'
                      cssClass='z-20'
                      data={SHIFT_TIME_OPTIONS}
                      selectedOption={SHIFT_TIME_OPTIONS.find(
                        (x) => x.value === watchFormData?.start_time
                      )}
                      errorMessage={
                        errors?.start_time?.type === "required"
                          ? "This field is required!"
                          : null
                      }
                    />
                  </div>
                  <div className='col-span-1'>
                    <label className='text-gray-700 text-sm font-medium mb-1'>
                      Shift End Time
                    </label>
                    <SelectOption
                      isDisabled={checkRunningShiftStatus(
                        selectedShift?.status
                      )}
                      isRequired={true}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      readOnly={false}
                      name='end_time'
                      cssClass='z-20'
                      data={SHIFT_TIME_OPTIONS}
                      selectedOption={SHIFT_TIME_OPTIONS.find(
                        (x) => x.value === watchFormData?.end_time
                      )}
                      errorMessage={
                        errors?.end_time?.type === "required"
                          ? "This field is required!"
                          : errors?.end_time?.type === "validate" &&
                            getShiftLength(
                              watchFormData?.start_time,
                              watchFormData?.end_time,
                              true
                            ) === "00:00"
                          ? "Shift can't be zero hours & minutes!"
                          : null
                      }
                      validate={getShiftLength(
                        watchFormData?.start_time,
                        watchFormData?.end_time,
                        false,
                        true
                      )}
                    />
                  </div>
                </div>
              </div>
              {shiftDetailData && shiftDetailData?.worker_start_time && (
                <div className='col-span-2'>
                  <div className='grid grid-cols-3 gap-x-5'>
                    <div className='col-span-1'>
                      <InputField
                        setValue={setValue}
                        register={register}
                        pattern={
                          validateCurrentDate(
                            moment(watchFormData?.date ?? null),
                            moment(new Date()),
                            ["present", "future", "past"],
                            true
                          )
                            ? DUMMY_REGEX_EXP
                            : null
                        }
                        label={"Shift Date"}
                        type='date'
                        value={
                          watchFormData?.date
                            ? moment(watchFormData?.date).format("DD-MM-YYYY")
                            : null
                        }
                        name='shift_date'
                        isDisabled={selectedShift}
                        isRequired={true}
                        readOnly={true}
                        ErrorMessage={
                          errors?.shift_date?.type === "required"
                            ? "This field is required!"
                            : errors?.shift_date?.type === "pattern"
                            ? validateCurrentDate(
                                moment(watchFormData?.shift_date ?? null),
                                moment(new Date()),
                                ["present", "future", "past"],
                                false
                              )
                            : null
                        }
                      />
                    </div>
                    <div className='col-span-1'>
                      <label className='text-gray-700 text-sm font-medium mb-1'>
                        Shift Start Time(worker)
                      </label>
                      <InputField
                        readOnly={true}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        name='worker_start_time'
                        cssClass='z-20'
                        data={SHIFT_TIME_OPTIONS}
                        selectedOption={SHIFT_TIME_OPTIONS.find(
                          (x) => x.value === watchFormData?.worker_start_time
                        )}
                        errorMessage={
                          errors?.worker_start_time?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>

                    <div className='col-span-1'>
                      <label className='text-gray-700 text-sm font-medium mb-1'>
                        Shift End Time(worker)
                      </label>
                      <InputField
                        readOnly={true}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        name='worker_end_time'
                        isDisabled={
                          watchFormData?.worker_start_time === null ||
                          watchFormData?.worker_start_time === undefined
                        }
                        cssClass='z-20'
                        data={SHIFT_TIME_OPTIONS}
                        selectedOption={SHIFT_TIME_OPTIONS.find(
                          (x) => x.value === watchFormData?.worker_end_time
                        )}
                        errorMessage={
                          errors?.worker_end_time?.type === "required"
                            ? "This field is required!"
                            : errors?.worker_end_time?.type === "validate" &&
                              getShiftLength(
                                watchFormData?.worker_start_time,
                                watchFormData?.worker_end_time,
                                true
                              ) === "00:00"
                            ? "Shift can't be zero hours & minutes!"
                            : null
                        }
                        validate={getShiftLength(
                          watchFormData?.worker_start_time,
                          watchFormData?.worker_end_time,
                          false,
                          true
                        )}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='grid grid-cols-3 gap-x-5 mt-3'>
              <div className='col-span-1'>
                <label className='text-gray-700 text-sm font-medium mb-1'>
                  Service Category
                </label>
                <SelectOption
                  data={serviceTypes}
                  isDisabled={checkRunningShiftStatus(selectedShift?.status)}
                  isRequired={true}
                  register={register}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  name='invoice_service_type_id'
                  cssClass='z-20'
                  selectedOption={serviceTypes.find(
                    (x) => x.value === watchFormData?.invoice_service_type_id
                  )}
                  errorMessage={
                    errors?.invoice_service_type_id?.type === "required"
                      ? "This field is required!"
                      : null
                  }
                />
              </div>
              <div className='flex items-center gap-x-2'>
                <label className='text-gray-700 text-sm font-medium '>
                  Ratio (Worker: Participants)
                </label>
                {Array.isArray(workerRatio)
                  ? workerRatio?.length
                  : workerRatio
                  ? 1
                  : 0}{" "}
                :{" "}
                {Array.isArray(participantRatio)
                  ? participantRatio?.length
                  : participantRatio
                  ? 1
                  : 0}
              </div>

              <div
                className='flex items-center gap-x-2'
                title='select this option if sleepover shift'
              >
                <label className='text-gray-700 text-sm font-medium mb-1'>
                  Sleepover Shift
                </label>
                <div>
                  <Controller
                    name={`sleepover`}
                    control={control}
                    defaultValue={watchFormData?.sleepover || false}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          field.onChange(value);
                          setValue(`sleepover`, value);
                        }}
                        checked={field.value}
                        disabled={checkRunningShiftStatus(
                          selectedShift?.status
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='col-span-2 mt-2'>
              <label className='text-gray-700 text-sm font-medium mb-1'>
                HR Tasks
              </label>
              {watchFormData?.task &&
                watchFormData?.task?.item &&
                isArray(watchFormData?.task?.item) &&
                watchFormData?.task?.item?.map((x, index) => (
                  <div key={index} className='flex justify-between'>
                    <div className='w-[95%]'>
                      <InputField
                        readOnly={checkRunningShiftStatus(
                          selectedShift?.status
                        )}
                        placeholder='Add your tasks here...'
                        setValue={setValue}
                        register={register}
                        type='text'
                        name={`task.item[${index}]`}
                        ErrorMessage={
                          _.get(errors, `task.item[${index}]`) && (
                            <span className='text-sm text-red-500'>
                              This field is required!
                            </span>
                          )
                        }
                      />
                    </div>
                    <div className='pt-3'>
                      <div
                        onClick={() =>
                          !checkRunningShiftStatus(selectedShift?.status) &&
                          handleTaskDelete(index)
                        }
                        tabIndex={0}
                        role='button'
                        className='icon-delete-wrapper'
                        title='Delete Task'
                      >
                        <HiOutlineTrash className='icon-size' />
                      </div>
                    </div>
                  </div>
                ))}
              <div className='mt-1 text-[#4B6CB7] text-[15px] font-medium max-w-ma'>
                <span
                  onClick={handleAddTask}
                  className={`${
                    !checkRunningShiftStatus(selectedShift?.status) &&
                    "cursor-pointer"
                  }`}
                >
                  + Add More Tasks
                </span>
              </div>
            </div>
            <div className='col-span-2'>
              <label className='text-gray-700 text-sm font-medium mb-1'>
                Worker Shift Notes
              </label>
              <textarea
                disabled={true}
                {...register("note")}
                rows='5'
                className={`${
                  checkDisabilityOfShiftStatus(selectedShift?.status) &&
                  "bg-[#4b6bb710]"
                } bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md resize-none overflow-y-auto `}
                spellCheck='false'
              />
            </div>
            {!isDateInPast(watchFormData?.shift_date) && (
              <div className='col-span-2 flex gap-3 items-center mt-6 '>
                <label className='text-gray-700 text-sm font-medium mb-1'>
                  Do you want to make this shift recurring?
                </label>
                <div>
                  <Controller
                    name={`is_recurring`}
                    control={control}
                    defaultValue={watchFormData?.is_recurring || false}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          field.onChange(value);
                          setValue(`is_recurring`, value);
                          setRecurringType(null);
                          setRecurrInterval(null);
                        }}
                        checked={field.value}
                        // disabled={checkRunningShiftStatus(selectedShift?.status)}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            {watchFormData?.is_recurring && (
              <div className='col-span-2' ref={modalContentRef}>
                <RecurringShift
                  setRecurringType={setRecurringType}
                  recurringType={recurringType}
                  watchFormData={watchFormData}
                  setRecurrInterval={setRecurrInterval}
                  recurrInterval={recurrInterval}
                  setRecurringDates={setRecurringDates}
                />
              </div>
            )}
          </div>

          <div className='hidden'>
            <button
              id='createShiftButton'
              type='submit'
              className='hidden sr-only'
            />
          </div>
        </form>
      </div>
    );
  };

  const modalFooter = () => (
    <>
      {handleShiftUpdatePermissionParticipantView() && (
        <p className='text-sm text-red-400 flex'>
          {" "}
          <FaExclamationCircle className='text-red-500 mr-1 h-5 w-5' /> Group
          shifts for multiple participants can only be updated from worker view.
          Please switch to worker view to make changes.
        </p>
      )}
      <CustomButton
        clickHandler={() => {
          if (isUserGracePeriodOver) {
            return;
          }
          if (checkRunningShiftStatus(selectedShift?.status)) {
            return;
          }
          const buttonRef = document.getElementById("createShiftButton");
          if (buttonRef) {
            buttonRef.click();
          }
        }}
        // showLoading={createOrUpdateWorkerShiftLoader}
        showLoading={loading || createOrUpdateWorkerShiftLoader}
        isDisabled={
          createOrUpdateWorkerShiftLoader ||
          loading ||
          checkRunningShiftStatus(selectedShift?.status) ||
          isUserGracePeriodOver ||
          isParticipantStateMissing() ||
          (shiftOverlap?.overlapping_shift &&
            shiftOverlap?.overlapping_shift[0]?.overlapped &&
            !watchFormData?.shift_overlapped) ||
          handleShiftUpdatePermissionParticipantView()
        }
        label={`${selectedShift !== null ? "Update" : "Create"}`}
      />
    </>
  );

  const getRefereshedShiftdate = () => {
    if (watchFormData?.shift_date && selectedShift === null && !selectedDate) {
      return moment(watchFormData.shift_date, "YYYY-MM-DD").format(
        "dddd, Do MMM YYYY"
      );
    } else if (
      watchFormData?.shift_date &&
      selectedShift === null &&
      selectedDate &&
      !moment(selectedDate).isSame(watchFormData.shift_date)
    ) {
      return moment(watchFormData.shift_date, "YYYY-MM-DD").format(
        "dddd, Do MMM YYYY"
      );
    } else if (selectedDate) {
      return moment(selectedDate, "YYYY-MM-DD").format("dddd, Do MMM YYYY");
    } else if (selectedShift && selectedShift?.shift_date) {
      return moment(selectedShift.shift_date, "YYYY-MM-DD").format(
        "dddd, Do MMM YYYY"
      );
    }
    return moment().format("dddd, Do MMM YYYY");
  };

  return (
    <div>
      {isShow && (
        <ModalSkeleton
          title={
            watchFormData?.shift_date
              ? `New Shift On ${getRefereshedShiftdate()} ${
                  isCheckNextDayShift(
                    watchFormData?.shift_date,
                    watchFormData?.start_time,
                    watchFormData?.end_time
                  ) !== false &&
                  getShiftLength(
                    watchFormData?.start_time,
                    watchFormData?.end_time,
                    true
                  ) !== "00:00"
                    ? ` - ${moment(
                        isCheckNextDayShift(
                          watchFormData?.shift_date,
                          watchFormData?.start_time,
                          watchFormData?.end_time
                        )
                      ).format("dddd, Do MMM YYYY")}`
                    : ""
                }`
              : ``
          }
          isOpen={isShow}
          cssClass='w-[70%]'
          bodyPadding='px-0'
          closeModal={() => {
            // setCreateShift(false);
            handleClose();
            setIsFetchRecordDetailLoader(false);
            reset();
          }}
          modalFooter={shiftDetailLoader ? null : modalFooter()}
        >
          {shiftDetailLoader ? (
            <div
              className={`min-h-screen bg-gray-50 items-center justify-center`}
            >
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            createShiftPopup()
          )}
        </ModalSkeleton>
      )}

      {openModal && workerAvailabilityData && !getWorkerProfileLoader && (
        <WorkerAvailabiltyModal
          isUserGracePeriodOver={isUserGracePeriodOver}
          openModel={openModal}
          handleClose={setOpenModal}
          WorkerId={workerProfileUUID}
          setRefreshPageForWorkerTimeSlot={setRecheckAvailability}
          TimeSlots={workerAvailabilityData}
          workerProfileDetails={workerProfileDetails}
          isWorkerAvailablity={recheckAvailability}
        />
      )}
    </div>
  );
};

export default WorkerShift;
