import React, { useContext, useEffect, useState } from "react";
import Header from "components/common/Header/Header";
import TableHeader from "components/common/Table/TableHeader";
import { LoginContext } from "helpers/LoginContext";
import { BiPlus } from "react-icons/bi";
import SideBarNew from "components/common/SideBar/SideBarNew";
import { useNavigate } from "react-router";
import { apiGetRequest, apiPutRequest } from "helpers/Requests";
import {
  URL_ROSTERING_INVOICE_STATUS_UPDATE,
  URL_ROSTER_INVOICES_LIST,
} from "store/services/URL";
import RosterInvoicesTable from "./RosterInvoicesTable";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import SelectOption from "components/common/Select/Select";
import SelectBox from "components/common/DropDownWithIocns/DropDown";
import CheckBox from "components/common/checkBox/checkbox";
import { connect } from "react-redux";
import { getParticipantsList } from "store/selector/sp.selector";
import { getSPParticipantsListRequest } from "store/actions/sp.actions";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import Pagination from "components/common/Pagination";
import { emptyPaginationObj } from "Constants/constant";

const RosterInvoicing = ({ getParticipantList, spParticipants }) => {
  const navigate = useNavigate();
  const { sideBarPadding } = useContext(LoginContext);

  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);

  const [rosterInvoices, setRosterInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [updatedAction, setUpdatedAction] = useState(null);
  const [participantList, setParticipantList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [loader, setLoader] = useState(false);
  const [statusSelect, setStatus] = useState(null);

  const STATUS_VALUES = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Extract Generated",
      value: "Extract Generated",
    },
    {
      label: "Mark As Paid",
      value: "Mark As Paid",
    },
    {
      label: "Mark As Void",
      value: "Void",
    },
  ];

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };

    getParticipantList({ filters, bodyFilter: null });
  }, []);

  useEffect(() => {
    if (
      rosterInvoices &&
      checkboxes &&
      checkboxes?.length === rosterInvoices?.filter((item)=>item?.status !== "Void").length &&
      rosterInvoices?.length !== 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [checkboxes]);


  useEffect(() => {
    const newParticipants = spParticipants?.participant?.data?.map((item) => ({
      label: `${item?.first_name} ${item?.last_name}`,
      value: item?.id,
    }));
    setParticipantList(newParticipants);
  }, [spParticipants]);

  const handleCheckboxChange = (e, uuid) => {
    const filteredList = checkboxes?.filter((c) => c !== uuid);
    if (e) {
      filteredList.push(uuid);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? rosterInvoices?.filter((item)=>item?.status !== "Void")?.map((el) => el.uuid) : []);
  };
  useEffect(() => {
    setLoading(true);
    fetchData({
      status: statusSelect?.value,
      pt_id: selectedClient?.value,
    });
  }, [selectedClient, statusSelect]);

  const fetchData = (filter = {}) => {
    let url = `${URL_ROSTER_INVOICES_LIST}?per_page=10`;

    if (filter?.status) {
      url = `${url}&status=${filter.status}`;
    }
    if (filter?.pt_id) {
      url = `${url}&pt_id=${filter.pt_id}`;
    }
    url = `${url}&page=${filter?.page ?? 1}`;

    setLoader(true);
    apiGetRequest(url)
      .then((res) => {
        setLoader(false);
        if (res?.status === 200) {
          setRosterInvoices(res?.data?.data?.data);
          setPaginationObj((prev) => ({
            ...prev,
            total: res?.data?.data?.total,
            totalPages: Math.ceil(
              res?.data?.data?.total / res?.data?.data?.per_page
            ),
          }));
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log({ error });
      })
      .finally(() => {
        setLoader(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (updatedAction && checkboxes?.length > 0) {
      handleChangeAction();
    }
  }, [updatedAction]);

  const getText = () => {
    if (updatedAction === "provider_paid") {
      return "Are you sure you want to change the status to  Paid";
    } else if (updatedAction === "pdf") {
      return "Are you sure you want to generate pdf file";
    } else if (updatedAction === "xlsx") {
      return "Are you sure you want to generate excel file";
    } else if (updatedAction === "cancelled") {
      return "Are you sure you want to cancel invoice";
    } else if (updatedAction === "Email Invoice") {
      return "Are you sure you want to Email Invoice";
    } else if (updatedAction === "Void") {
      return "Are you sure you want to mark as void";
    } 
    else {
      return "Are you sure";
    }
  };

  const handleChangeAction = async () => {
    swal({
      title: "Confirm your action?",
      text: `${getText()}`,
      icon: "",
      buttons: ["No, take me back!", "Yes, go ahead!"],
      className: "",
      cancel: {
        text: "No, take me back!",
        value: null,
        visible: false,
        className: "",
        closeModal: true,
      },
      confirm: {
        text: "Yes, go ahead!",
        value: true,
        visible: true,

        closeModal: true,
      },

      dangerMode: updatedAction === "cancelled" ? true : false,
    }).then((willDelete) => {
      if (willDelete) {
        if (checkboxes?.length > 0) {
          setLoading(true);
          apiPutRequest(`${URL_ROSTERING_INVOICE_STATUS_UPDATE}`, {
            status:
              updatedAction === "provider_paid"
                ? "Mark As Paid"
                : updatedAction === "xlsx"
                ? "Excel File"
                : updatedAction === "pdf"
                ? "PDF File"
                : updatedAction === "Email Invoice"
                ? "Email Invoice"
                : updatedAction === "cancelled"
                ? "Cancelled" :
                updatedAction === "Void"
                ? "Void"
                : "",
            uuid: checkboxes,
          })
            .then((res) => {
              if (res?.status === 200) {
                setLoading(false);
                setRosterInvoices(res?.data?.data?.data);
                toast.success(res?.data?.message);
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log({ error });
            });
          setCheckboxes([]);
          setUpdatedAction("");
        }
      } else {
        setCheckboxes([]);
        setUpdatedAction("");
      }
    });
  };

  const handleRedirection = () => {
    navigate("/rostering/create-roster-invoice");
  };

  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col`}
      >
        <main className='h-screen flex-1 bg-white'>
          <Header
            title='Invoices'
            {...(roleAccess(
              [],
              PERMISSION_KEYS.ROSTER_INVOICE,
              PERMISSION_KEYS_CHILDREN.ROSTER_INVOICE.CREATE
            ) && {
              handleClick: handleRedirection,
              btnLabel: "Invoice",
              icon: <BiPlus />,
              isButton: true,
            })}
          />
          <div className='max-w-7xl mx-auto bg-white'>
            {/* <div className='px-4'>
              <Breadcrumb
                pages={ROSTERING_INVOICING_BREADCRUMB(
                  ROSTERING_INVOICING_BREADCRUMB_PAGES.INVOICING
                )}
              />
            </div> */}
            <div className='flex justify-between py-3 items-end bg-white sm:px-6 lg:px-4 py-2 sticky top-[80px] z-[9]'>
              <div className='flex gap-x-2 w-[70%]'>
                <div className='col-span-1 w-[25%]'>
                  <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                    Participant
                  </label>
                  <SelectOption
                    isClearable={true}
                    isDisabled={loading}
                    cssClass=''
                    name='participant'
                    isSearch={true}
                    data={participantList && participantList}
                    selectedOption={selectedClient}
                    handleChange={(e) => {
                      setSelectedClient(e);
                    }}
                  />
                </div>
                <div className='col-span-1 w-[30%]'>
                  <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                    Status
                  </label>
                  <SelectOption
                    isClearable={true}
                    isDisabled={loading}
                    cssClass=''
                    name='status'
                    isSearch={false}
                    data={STATUS_VALUES}
                    selectedOption={statusSelect}
                    handleChange={(e) => {
                      setStatus(e);
                    }}
                  />
                </div>
              </div>
              <div className='w-56 inline-flex flex-col'>
                <SelectBox
                  forRosterInvoicing={true}
                  disabled={checkboxes?.length > 0 ? false : true}
                  setUpdatedAction={setUpdatedAction}
                />
              </div>
            </div>
            <div className='sm:px-6 lg:px-4 py-4'>
              <div className='flex flex-col'>
                <div className='min-w-full'>
                  <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                    <div className='table-wrp block'>
                      <table className='min-w-full divide-y divide-gray-300'>
                        <thead
                          className='bg-blue-50 sticky z-[5] capitalize'
                          style={{ top: "80px" }}
                        >
                          <tr>
                            <th
                              scope='col'
                              className={`pl-3 text-center text-sm font-semibold text-gray-900 bg-blue-50`}
                            >
                              <CheckBox
                                checked={allSelected}
                                handleChange={(e) => {
                                  if (
                                    rosterInvoices &&
                                    rosterInvoices?.length > 0
                                  ) {
                                    setAllSelected(e);
                                    toggleCheckboxes(e);
                                  }
                                }}
                              />
                            </th>
                            <TableHeader
                             
                              isSort={false}
                              label={"Invoice Number"}
                            />
                            <TableHeader
                              isSort={false}
                              label={"Participant"}
                              cssClass='normal-case'
                            />
                            <TableHeader
                              sortName='created_by'
                              isSort={false}
                              label={"Created By"}
                            />
                            <TableHeader
                              isSort={false}
                              sortName='created-at'
                              label={"Created Date"}
                            />
                            <TableHeader
                              isSort={false}
                              sortName='support_item_no'
                              label={"Service Delivered From"}
                              cssClass='text-center'
                            />

                            <TableHeader
                              isSort={false}
                              sortName='created_by'
                              label={"Service Delivered To"}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              label={"Status"}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              sortName='client'
                              label={"Num Delivered Services"}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              sortName='management_style'
                              label={"Total Value"}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              sortName='status'
                              label={"Actions"}
                              cssClass='text-center'
                            />
                            <th>
                              <div className='sr-only'>View</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200 bg-white'>
                          {loading || loader ? (
                            <tr>
                              <td colSpan={13} className='bg-gray-50 py-2'>
                                <CircularLoader
                                  classes='flex justify-center items-center'
                                  classloader='loader-l'
                                />
                              </td>
                            </tr>
                          ) : rosterInvoices && rosterInvoices?.length > 0 ? (
                            rosterInvoices?.map((item, index) => (
                              <RosterInvoicesTable
                                item={item}
                                key={index}
                                handleCheckboxChange={(e) =>
                                  handleCheckboxChange(e, item?.uuid)
                                }
                                checkboxes={checkboxes}
                              />
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={13}
                                className='py-6 text-center text-red-600'
                              >
                                No Data Found !
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='sm:px-6 lg:px-4 bg-white py-4'>
              <Pagination
                page={paginationObj.page}
                activeListLength={rosterInvoices}
                limit={10}
                totalList={paginationObj.total}
                handlePagination={(e) => {
                  const obj = paginationObj;
                  obj.page = e;
                  setPaginationObj({ ...obj });
                  const payload = {
                    page: e,
                  };

                  if (selectedClient && selectedClient?.value) {
                    Object.assign(payload, {
                      pt_id: selectedClient?.value,
                    });
                  }
                  if (statusSelect && selectedClient?.value) {
                    Object.assign(payload, {
                      status: selectedClient?.value,
                    });
                  }
                  fetchData(payload);
                }}
                totalPages={paginationObj.totalPages}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  getParticipantList: getSPParticipantsListRequest,
};

const mapStateToProps = (state) => {
  const spParticipants = getParticipantsList(state);

  return {
    spParticipants,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RosterInvoicing);
