import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { formatDateIntoDMM, formatDateIntoDMMMY } from "Utils/utils";
import Select from "react-select";
import { useLocation } from "react-router";

const DateRangeSelect = (props) => {
  const { dateHandler, isDisabled = false } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const location = useLocation();

  const [currentForthnight, setCurrent] = useState({ start: null, end: null });
  const [previousFortnight, setPrevious] = useState({ start: "", end: "" });

  useEffect(() => {
    const startOfWeek = moment().startOf("iweek").isoWeekday(1);
    const endOfWeek = moment().startOf("iweek").isoWeekday(1).add(13, "days");
    const currentMonday = moment().startOf("iweek").isoWeekday(1);
    const previousMonday = moment(currentMonday).subtract(7, "days");
    setPrevious({
      start: previousMonday,
      end: moment(previousMonday).add(13, "days"),
    });
    setCurrent({ start: startOfWeek, end: endOfWeek });

    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  }, []);

  const [selectedOption, setSelectedOption] = useState(() => {
    const startOfWeek = moment().startOf("iweek").isoWeekday(1);
    const endOfWeek = moment().startOf("iweek").isoWeekday(1).add(13, "days");
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);

    return {
      value: "current",
      label: `Fortnight 1(${formatDateIntoDMM(startOfWeek)}-${formatDateIntoDMM(
        endOfWeek
      )})`,
    };
  });

  useEffect(() => {
    if (startDate && dateHandler) {
      dateHandler({ startDate, endDate, view: selectedOption?.value });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    updateDateRange(selectedOption.value);
  }, [selectedOption]);

  const updateDateRange = (option) => {
    const startOfWeek = moment().startOf("iweek").isoWeekday(1);
    const endOfWeek = moment().startOf("iweek").isoWeekday(1).add(13, "days");

    if (option === "previous") {
      // Calculate the Monday of the current week and the Monday of the previous week
      const currentMonday = moment().startOf("iweek").isoWeekday(1);
      const previousMonday = moment(currentMonday).subtract(7, "days");

      setStartDate(previousMonday);
      setEndDate(moment(previousMonday).add(13, "days"));
    } else if (option === "weekly") {
      const currentMonday = moment().startOf("iweek").isoWeekday(1);
      setStartDate(currentMonday);
      setEndDate(moment(currentMonday).add(6, "days"));
    } else {
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
    }
  };

  const handleDateChange = (value) => {
    let startOfWeek = null,
      endOfWeek = null;

    if (value === "back") {
      if (selectedOption.value === "weekly") {
        startOfWeek = moment(startDate).subtract(7, "days");
        endOfWeek = moment(endDate).subtract(7, "days");
      } else {
        startOfWeek = moment(startDate).subtract(14, "days");
        endOfWeek = moment(endDate).subtract(14, "days");
      }
    } else if (value === "forward") {
      if (selectedOption.value === "weekly") {
        startOfWeek = moment(startDate).add(7, "days");
        endOfWeek = moment(endDate).add(7, "days");
      } else {
        startOfWeek = moment(startDate).add(14, "days");
        endOfWeek = moment(endDate).add(14, "days");
      }
    }

    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    updateDateRange(option.value);
  };

  const options = [
    {
      value: "current",
      label: `Fortnight 1(${formatDateIntoDMM(
        currentForthnight?.start
      )}-${formatDateIntoDMM(currentForthnight?.end)})`,
    },
    {
      value: "previous",
      label: `Fortnight 2(${formatDateIntoDMM(
        previousFortnight?.start
      )}-${formatDateIntoDMM(previousFortnight?.end)})`,
    },
    {
      value: "weekly",
      label: `Weekly Period`,
    },
  ];

  return (
    <div className='flex space-x-4 w-full'>
      <div className='flex-1'>
        <label className='text-sm text-gray-700 font-medium leading-5'>
          Choose Period
        </label>
        <div className='flex items-center w-full rounded text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
          <Select
            className={`w-full text-sm font-medium leading-5 bg-transparent border-none outline-none text-white ${
              location.pathname === "/rostering/schedule" ? "z-20" : "z-9"
            }`}
            value={selectedOption}
            onChange={(option) => handleOptionChange(option)}
            options={options}
            styles={{
              control: (provided, state) => ({
                ...provided,
                backgroundColor: "white",
                borderColor: state.isFocused ? "#63b3ed" : "#ccc",
              }),
              option: (provided, state) => ({
                ...provided,
                color: "#000",
                padding: "15px",
              }),
            }}
          />
        </div>
      </div>
      <div className='flex-1'>
        <label className='text-sm text-gray-700 font-medium leading-5 ml-1'>
          Select Date Range
        </label>
        <div className='flex'>
          <div
            onClick={() =>
              startDate && endDate && !isDisabled
                ? handleDateChange("back")
                : {}
            }
            role='button'
            tabIndex={0}
            className='flex items-center w-[15%] px-2 rounded background-gradient border-gradient text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
          >
            <MdArrowBackIos
              style={{
                padding: "0px 2px 0px 2px",
              }}
            />
          </div>
          <div
            style={{ display: "flex", fontSize: "13px" }}
            className='text-sm w-full flex justify-center mx-1 items-center bg-[#4b6bb710] focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md did-floating-input whitespace-nowrap'
          >
            {`${startDate && formatDateIntoDMMMY(startDate)}  -  ${
              endDate && formatDateIntoDMMMY(endDate)
            }`}
          </div>
          <div
            onClick={() =>
              startDate && endDate && !isDisabled
                ? handleDateChange("forward")
                : {}
            }
            role='button'
            tabIndex={0}
            className='flex items-center w-[15%] px-2 rounded background-gradient border-gradient text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
          >
            <MdArrowForwardIos
              style={{
                padding: "2px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeSelect;
