import React, { useEffect, useState, useMemo } from "react";
import {
  emptyPaginationObj,
  LOADER_STATUS,
  DELIVERED_SERVICE_LIMIT,
} from "../../../../Constants/constant";
import {
  formatDateIntoDMY,
  gethoursAndMins,
  getPaginationObj,
} from "../../../../Utils/utils";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import PaginationComponent from "../../../../components/common/Pagination/index";
import TableHeader from "../../../../components/common/Table/TableHeader";
import moment from "moment";

const DeliveredServiceTable = (props) => {
  const {
    participant,
    fetchLoader,
    removeLoader = false,
    funding,
    deliveredServices,
    getDeliveredServiceList,
  } = props;
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [paginationObj, setPaginationObj] = useState(
    deliveredServices ? getPaginationObj(deliveredServices) : emptyPaginationObj
  );

  useEffect(() => {
    if (deliveredServices) {
      setPaginationObj({
        ...getPaginationObj(deliveredServices),
      });
    }
  }, [deliveredServices]);

  useEffect(() => {
    if (
      !deliveredServices ||
      (deliveredServices &&
        Object.keys(deliveredServices).length > 0 &&
        deliveredServices.hasOwnProperty("data") &&
        deliveredServices.data.length === 0)
    ) {
      fetchDeliveredService({ page: paginationObj.page });
    }
  }, []);

  useEffect(() => {
    if (
      deliveredServices &&
      deliveredServices.current_page !== paginationObj.page
    ) {
      fetchDeliveredService({ page: paginationObj.page });
    }
  }, [paginationObj.page]);

  const deliveredServicesToMap = useMemo(() => {
    if (deliveredServices) {
      return deliveredServices.data;
    }
    return [];
  }, [deliveredServices]);

  const fetchDeliveredService = async (filters) => {
    if (!filters) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: DELIVERED_SERVICE_LIMIT });
    Object.assign(filters, {
      pt_id: participant.uuid || 1,
      funding_id: funding.uuid || 1,
    });
    if (renderLoading === LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.PENDING);
    }
    getDeliveredServiceList(filters);
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="align-middle w-full">
          <div className="overflow-hidden border">
            <table className="w-full divide-y divide-gray-300 ">
              <thead className="bg-gray-50 capitalize">
                <tr>
                  <TableHeader isSort={false} label={"Delivered ID"} />
                  <TableHeader isSort={false} label={"Support Item"} />
                  <TableHeader isSort={false} label={"Support Item Number"} />
                  <TableHeader
                    isSort={false}
                    label={"Unit"}
                    cssClass="text-center"
                  />
                  <TableHeader
                    isSort={false}
                    label={"Delivered Quantity/Hour"}
                  />
                  <TableHeader isSort={false} label={"Total Delivered"} />
                  <TableHeader isSort={false} label={"Status"} />
                  <TableHeader isSort={false} label={"Claim Type"} />
                  <TableHeader isSort={false} label={"Delivered"} />

                  <th className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50">
                    <div className="sr-only">View</div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {fetchLoader ? (
                  <tr>
                    <td colSpan={10} className="bg-gray-50 py-2">
                      <CircularLoader
                        classes="flex justify-center items-center"
                        classloader="loader-l"
                      />
                    </td>
                  </tr>
                ) : deliveredServicesToMap &&
                  deliveredServicesToMap.length > 0 ? (
                  deliveredServicesToMap.map((fund, index) => (
                    <tr key={index} className="text-left hover:bg-blue-50">
                      <td className=" py-4 px-3 text-sm">
                        {fund.delivered_service_number}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        <div
                          title={fund.price_catalog.support_item_name}
                          className="text-gray-500"
                        >
                          {fund.price_catalog.support_item_name}
                        </div>
                        {fund.external_sp && (
                          <div className="text-gray-800 font-bold">
                            {fund.external_sp.name}
                          </div>
                        )}
                      </td>

                      <td
                        title={fund.price_catalog.support_item_number}
                        className=" px-3 py-4 text-sm text-gray-500"
                      >
                        <div className="text-gray-500">
                          {fund.price_catalog.support_item_number}
                        </div>
                      </td>
                      <td className=" px-3 py-4 text-center text-sm text-gray-500">
                        <div className="text-gray-500">{fund.unit}</div>
                      </td>

                      <td className=" px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-500">
                          {fund.unit === "H"
                            ? gethoursAndMins(fund.hours, fund.mints)
                            : fund.quantity}
                        </div>
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-500">
                          {`$${
                            fund.total_amount
                              ? parseFloat(fund.total_amount).toFixed(2)
                              : 0
                          }`}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
                        <div
                          title={fund.status}
                          className="text-gray-500 capitalize truncate"
                        >
                          {fund.status.replaceAll("_", " ")}
                        </div>
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-500 capitalize">
                          {fund.claim_type}
                        </div>
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-500">
                          {fund.delivered_from && fund.delivered_to_date
                            ? `${formatDateIntoDMY(
                                fund.delivered_from
                              )} - ${formatDateIntoDMY(fund.delivered_to_date)}`
                            : fund.delivered_date
                            ? formatDateIntoDMY(fund.delivered_date)
                            : "-"}
                        </div>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <div className="sr-only">Actions</div>
                      </td>
                    </tr>
                  ))
                ) : (
                  renderLoading !== LOADER_STATUS.NA &&
                  deliveredServicesToMap.length === 0 && (
                    <tr>
                      <td
                        colSpan={10}
                        className="py-6 text-center text-red-600"
                      >
                        No Data Found!
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="bg-white py-4">
        <PaginationComponent
          page={paginationObj.page}
          activeListLength={deliveredServicesToMap}
          limit={DELIVERED_SERVICE_LIMIT}
          totalList={
            paginationObj.totalPages === 1
              ? deliveredServicesToMap && deliveredServicesToMap.length
              : paginationObj.total
          }
          handlePagination={(e) => {
            const obj = paginationObj;
            obj.page = e;
            setPaginationObj({ ...obj });
          }}
          totalPages={paginationObj.totalPages}
        />
      </div>
    </>
  );
};

export default DeliveredServiceTable;
