import asyncActionType from "../Utils/reduxActions";

export const FILTER_ACTIONS = {
  SEND_OTP_FOR_CONFIRMATION: asyncActionType(
    "[VERTEX360]SEND_OTP_FOR_CONFIRMATION"
  ),
  SEND_OTP_FOR_CONFIRMATION_CALL: "[VERTEX360]SEND_OTP_FOR_CONFIRMATION_CALL",

  SEND_OTP: asyncActionType("[VERTEX360]SEND_OTP"),
  SEND_OTP_CALL: "[VERTEX360]SEND_OTP_CALL",

  RESEND_VERIFICATION_CODE: asyncActionType(
    "[VERTEX360]RESEND_VERIFICATION_CODE"
  ),
  RESEND_VERIFICATION_CODE_CALL: "[VERTEX360]RESEND_VERIFICATION_CODE_CALL",

  RESEND_OTP: asyncActionType("[VERTEX360]RESEND_OTP"),
  RESEND_OTP_CALL: "[VERTEX360]RESEND_OTP_CALL",

  LOGIN: asyncActionType("[VERTEX360]LOGIN"),
  LOGIN_CALL: "[VERTEX360]LOGIN_CALL",

  LOGOUT_BEGIN: "[VERTEX360]LOGOUT_BEGIN_CALL",
  LOGOUT: asyncActionType("[VERTEX360]LOGOUT"),

  UPDATE_AUTH_USER: "[VERTEX360]UPDATE_AUTH_USER",

  AUTHENTICATED_USER: "[VERTEX360]AUTHENTICATED_USER",

  //Roles and permisisons
  ROLE_AND_PERMISSION_CREATE_OR_UPDATE: asyncActionType(
    "[VERTEX360]ROLE_AND_PERMISSION_CREATE_OR_UPDATE"
  ),
  ROLE_AND_PERMISSION_CREATE_OR_UPDATE_CALL:
    "[VERTEX360]ROLE_AND_PERMISSION_CREATE_OR_UPDATE_CALL",

  ROLE_AND_PERMISSION_LISTED: asyncActionType(
    "[VERTEX360]ROLE_AND_PERMISSION_LISTED"
  ),
  ROLE_AND_PERMISSION_LIST_CALL: "[VERTEX360]ROLE_AND_PERMISSION_LIST_CALL",

  RESET_DEFAULT_ROLE_AND_PERMISSION_LOCALLY:
    "[VERTEX360]RESET_DEFAULT_ROLE_AND_PERMISSION_LOCALLY",

  DEFAULT_ROLE_AND_PERMISSION: asyncActionType(
    "[VERTEX360]DEFAULT_ROLE_AND_PERMISSION"
  ),
  DEFAULT_ROLE_AND_PERMISSION_CALL:
    "[VERTEX360]DEFAULT_ROLE_AND_PERMISSION_CALL",

  ROLE_AUTHENTICATE_USER_CALL: "[VERTEX360]ROLE_AUTHENTICATE_USER_CALL",

  //PAYROLL
  PAYROLL_AUTHENTICATION_UPDATE_LOCALLY:
    "[VERTEX360]PAYROLL_AUTHENTICATION_UPDATE_LOCALLY",
  PAYROLL_EXPORT_CALLBACK: "[VERTEX360]PAYROLL_EXPORT_CALLBACK",
  AUTHENTICATED_PAYROLL_UPDATE_LOCALLY:
    "[VERTEX360]AUTHENTICATED_PAYROLL_UPDATE_LOCALLY",

  //Stripe
  UPDATE_PAYMENT_SETTING_LOCALLY: "[VERTEX360]UPDATE_PAYMENT_SETTING_LOCALLY",

  STRIPE_PRICING_TABLE_LIST: asyncActionType(
    "[VERTEX360]STRIPE_PRICING_TABLE_LIST"
  ),
  STRIPE_PRICING_TABLE_LIST_CALL: "[VERTEX360]STRIPE_PRICING_TABLE_LIST_CALL",

  STRIPE_SAVE_CARD_DATA: asyncActionType("[VERTEX360]STRIPE_SAVE_CARD_DATA"),
  STRIPE_SAVE_CARD_DATA_CALL: "[VERTEX360]STRIPE_SAVE_CARD_DATA_CALL",

  STRIPE_LIST_ALL_CARD: asyncActionType("[VERTEX360]STRIPE_LIST_ALL_CARD"),
  STRIPE_LIST_ALL_CARD_CALL: "[VERTEX360]STRIPE_LIST_ALL_CARD_CALL",

  STRIPE_DEFAULT_PAYMENT_METHOD: asyncActionType(
    "[VERTEX360]STRIPE_DEFAULT_PAYMENT_METHOD"
  ),
  STRIPE_DEFAULT_PAYMENT_METHOD_CALL:
    "[VERTEX360]STRIPE_DEFAULT_PAYMENT_METHOD_CALL",

  STRIPE_DELETE_CARD_LOCALLY: "[VERTEX360]STRIPE_DELETE_CARD_LOCALLY",

  SP_STAFF_COMPLIANCE_SETTING: asyncActionType(
    "[VERTEX360]SP_STAFF_COMPLIANCE_SETTING"
  ),
  SP_STAFF_COMPLIANCE_SETTING_CALL:
    "[VERTEX360]SP_STAFF_COMPLIANCE_SETTING_CALL",

  SP_STAFF_COMPLIANCE_SETTING_UPDATE: asyncActionType(
    "[VERTEX360]SP_STAFF_COMPLIANCE_SETTING_UPDATE"
  ),
  SP_STAFF_COMPLIANCE_SETTING_UPDATE_CALL:
    "[VERTEX360]SP_STAFF_COMPLIANCE_SETTING_UPDATE_CALL",

  //GENERAL
  SHOW_MESSAGE_BOX_FOR_TIME_EXPIRY:
    "[VERTEX360]SHOW_MESSAGE_BOX_FOR_TIME_EXPIRY",
  SHOW_CONFIRMATION_BOX: "[VERTEX360]SHOW_CONFIRMATION_BOX",
  CLEAR_MESSAGE_BOX_FOR_TIME_EXPIRY:
    "[VERTEX360]CLEAR_MESSAGE_BOX_FOR_TIME_EXPIRY",
  SHOW_MESSAGE_BOX: "[VERTEX360]SHOW_MESSAGE_BOX",
  CLEAR_SHOW_MESSAGE_BOX: "[VERTEX360]CLEAR_SHOW_MESSAGE_BOX",

  ADD_TOAST_IN_LIST: "[VERTEX360]ADD_TOAST_IN_LIST",
  REMOVE_TOAST_IN_LIST: "[VERTEX360]REMOVE_TOAST_IN_LIST",

  REMOVE_CASE_NOTES_REVIEW_LIST: "[VERTEX360]REMOVE_CASE_NOTES_REVIEW_LIST",

  REMOVE_SETTINGS_DATA: "[VERTEX360]REMOVE_SETTINGS_DATA",

  STORE_CURRENT_OPEN_TAB: "[VERTEX360]STORE_CURRENT_OPEN_TAB",

  //Sp Admin

  SUBMITTED_FORM_UPDATE_PT_PERCENTAGE_LOCALLY:
    "[VERTEX360]SUBMITTED_FORM_UPDATE_PT_PERCENTAGE_LOCALLY",

  RESET_FOLDER_SUCCESS_VALUE: "[VERTEX360]RESET_FOLDER_SUCCESS_VALUE",

  CREATE_NEWFOLDER: asyncActionType("[VERTEX360]CREATE_NEWFOLDER"),
  CREATE_NEWFOLDER_CALL: "[VERTEX360]CREATE_NEWFOLDER_CALL",

  UPDATE_FOLDER: asyncActionType("[VERTEX360]UPDATE_FOLDER"),
  UPDATE_FOLDER_CALL: "[VERTEX360]UPDATE_FOLDER_CALL",

  GET_COS_DASHBOARD_DATA: asyncActionType("[VERTEX360]GET_COS_DASHBOARD_DATA"),
  GET_COS_DASHBOARD_DATA_CALL: "[VERTEX360]GET_COS_DASHBOARD_DATA_CALL",

  REMOVE_FOLDER: asyncActionType("[VERTEX360]REMOVE_FOLDER"),
  REMOVE_FOLDER_CALL: "[VERTEX360]REMOVE_FOLDER_CALL",

  UPDATE_CASE_NOTE_DETAIL: asyncActionType(
    "[VERTEX360]UPDATE_CASE_NOTE_DETAIL"
  ),
  UPDATE_CASE_NOTE_DETAIL_CALL: "[VERTEX360]UPDATE_CASE_NOTE_DETAIL_CALL",

  REMOVE_FILE: asyncActionType("[VERTEX360]REMOVE_FILE"),
  REMOVE_FILE_CALL: "[VERTEX360]REMOVE_FILE_CALL",

  GET_FOLDER_LIST_FOR_HRM: asyncActionType(
    "[VERTEX360]GET_FOLDER_LIST_FOR_HRM"
  ),

  SET_UPDATED_FILE_CALL: "[VERTEX360]SET_UPDATED_FILE_CALL",

  GET_FOLDER_LIST_FOR_HRM_CALL: "[VERTEX360]GET_FOLDER_LIST_FOR_HRM_CALL",

  GET_CASE_NOTE_DETAIL: asyncActionType("[VERTEX360]GET_CASE_NOTE_DETAIL"),
  GET_CASE_NOTE_DETAIL_CALL: "[VERTEX360]GET_CASE_NOTE_DETAIL_CALL",

  SP_SETTING_FETCHED: asyncActionType("[VERTEX360]SP_SETTING_FETCHED"),
  SP_SETTING_FETCHED_CALL: "[VERTEX360]SP_SETTING_FETCHED_CALL",

  SP_SETTING_UPDATED: asyncActionType("[VERTEX360]SP_SETTING_UPDATED"),
  SP_SETTING_UPDATED_CALL: "[VERTEX360]SP_SETTING_UPDATED_CALL",

  DYNAMIC_BREADCRUMB_FOR_HRM_CALL: "[VERTEX360]DYNAMIC_BREADCRUMB_FOR_HRM_CALL",

  SP_PARTICIPANTS_LIST: asyncActionType("[VERTEX360]SP_PARTICIPANTS_LIST"),
  SP_PARTICIPANTS_LIST_CALL: "[VERTEX360]SP_PARTICIPANTS_LIST_CALL",

  COS_PARTICIPANTS_PROFILE: asyncActionType(
    "[VERTEX360]COS_PARTICIPANTS_PROFILE"
  ),
  COS_PARTICIPANTS_PROFILE_CALL: "[VERTEX360]COS_PARTICIPANTS_PROFILE_CALL",

  COS_CASENOTES_CREATE: asyncActionType("[VERTEX360]COS_CASENOTES_CREATE"),
  CASENOTES_CREATE_CALL: "[VERTEX360]CASENOTES_CREATE_CALL",

  GET_COS_CASENOTES: asyncActionType("[VERTEX360]GET_COS_CASENOTES"),
  GET_CASENOTES_LIST_CALL: "[VERTEX360]GET_CASENOTES_LIST_CALL",

  ADD_CASE_NOTES_TO_CLAIM_LIST: asyncActionType(
    "[VERTEX360]ADD_CASE_NOTES_TO_CLAIM_LIST"
  ),
  ADD_CASE_NOTES_TO_CLAIM_LIST_CALL:
    "[VERTEX360]ADD_CASE_NOTES_TO_CLAIM_LIST_CALL",

  GET_CLAIMED_CASE_NOTES_LIST: asyncActionType(
    "[VERTEX360]GET_CLAIMED_CASE_NOTES_LIST"
  ),
  GET_CLAIMED_CASE_NOTES_LIST_CALL:
    "[VERTEX360]GET_CLAIMED_CASE_NOTES_LIST_CALL",

  GET_CREATED_COS_INVOICES: asyncActionType(
    "[VERTEX360]GET_CREATED_COS_INVOICES"
  ),
  GET_CREATED_COS_INVOICES_CALL: "[VERTEX360]GET_CREATED_COS_INVOICES_CALL",

  GET_CREATED_COS_INVOICES_RIVIEW: asyncActionType(
    "[VERTEX360]GET_CREATED_COS_INVOICES_RIVIEW"
  ),
  GET_CREATED_COS_INVOICES_RIVIEW_CALL:
    "[VERTEX360]GET_CREATED_COS_INVOICES_RIVIEW_CALL",

  CREATE_COS_INVOICE: asyncActionType("[VERTEX360]CREATE_COS_INVOICE"),
  CREATE_COS_INVOICE_CALL: "[VERTEX360]CREATE_COS_INVOICE_CALL",

  CHANGE_NDIS_CLAIM_STATUS: asyncActionType(
    "[VERTEX360]CHANGE_NDIS_CLAIM_STATUS"
  ),
  CHANGE_NDIS_CLAIM_STATUS_CALL: "[VERTEX360]CHANGE_NDIS_CLAIM_STATUS_CALL",

  CHANGE_INVOICE_STATUS: asyncActionType("[VERTEX360]CHANGE_INVOICE_STATUS"),
  CHANGE_INVOICE_STATUS_CALL: "[VERTEX360]CHANGE_INVOICE_STATUS_CALL",

  GET_COS_REPORTING_TABLEDATA: asyncActionType(
    "[VERTEX360]GET_COS_REPORTING_TABLEDATA"
  ),
  GET_COS_REPORTING_FOR_CASE_NOTES_TABLEDATA: asyncActionType(
    "[VERTEX360]GET_COS_REPORTING_FOR_CASE_NOTES_TABLEDATA"
  ),

  GET_COS_REPORTING_TABLEDATA_CALL:
    "[VERTEX360]GET_COS_REPORTING_TABLEDATA_CALL",

  CREATE_NEW_PERIORITY: asyncActionType("[VERTEX360]CREATE_NEW_PERIORITY"),
  CREATE_NEW_PERIORITY_CALL: "[VERTEX360]CREATE_NEW_PERIORITY_CALL",

  GET_PERIORITY_LIST: asyncActionType("[VERTEX360]GET_PERIORITY_LIST"),
  GET_PERIORITY_LIST_CALL: "[VERTEX360]GET_PERIORITY_LIST_CALL",

  GET_CONTACT_LIST: asyncActionType("[VERTEX360]GET_CONTACT_LIST"),
  GET_CONTACT_LIST_CALL: "[VERTEX360]GET_CONTACT_LIST_CALL",

  UPDATE_CONTACT: asyncActionType("[VERTEX360]UPDATE_CONTACT"),
  UPDATE_CONTACT_CALL: "[VERTEX360]UPDATE_CONTACT_CALL",

  UPDATE_PRIORITY: asyncActionType("[VERTEX360]UPDATE_PRIORITY"),
  UPDATE_PRIORITY_CALL: "[VERTEX360]UPDATE_PRIORITY_CALL",

  REMOVE_SETTING_DATA: "[VERTEX360]REMOVE_SETTING_DATA",

  UPDATE_CATEGORY: asyncActionType("[VERTEX360]UPDATE_CATEGORY"),
  UPDATE_CATEGORY_CALL: "[VERTEX360]UPDATE_CATEGORY_CALL",

  GET_CATOGERY_LIST: asyncActionType("[VERTEX360]GET_CATOGERY_LIST"),
  GET_CATOGERY_LIST_CALL: "[VERTEX360]GET_CATOGERY_LIST_CALL",

  CREATE_NEW_CONTACT: asyncActionType("[VERTEX360]CREATE_NEW_CONTACT"),
  CREATE_NEW_CONTACT_CALL: "[VERTEX360]CREATE_NEW_CONTACT_CALL",

  CREATE_NEW_CATEGORY: asyncActionType("[VERTEX360]CREATE_NEW_CATEGORY"),
  CREATE_NEW_CATEGORY_CALL: "[VERTEX360]CREATE_NEW_CATEGORY_CALL",

  GET_COS_INVOICES: asyncActionType("[VERTEX360]GET_COS_INVOICES"),
  GET_COS_INVOICES_CALL: "[VERTEX360]GET_COS_INVOICES_CALL",

  GET_CASE_NOTES_REVIEW: asyncActionType("[VERTEX360]GET_CASE_NOTES_REVIEW"),
  GET_CASE_NOTES_REVIEW_CALL: "[VERTEX360]GET_CASE_NOTES_REVIEW_CALL",

  GET_CASE_NOTES_HISTROY: asyncActionType("[VERTEX360]GET_CASE_NOTES_HISTROY"),
  GET_CASE_NOTES_HISTROY_CALL: "[VERTEX360]GET_CASE_NOTES_HISTROY_CALL",

  GET_CASE_NOTES_LIST_FOR_CLAIM: asyncActionType(
    "[VERTEX360]GET_CASE_NOTES_LIST_FOR_CLAIM"
  ),
  GET_CASE_NOTES_LIST_FOR_CLAIM_CALL:
    "[VERTEX360]GET_CASE_NOTES_LIST_FOR_CLAIM_CALL",

  GET_CASE_NOTES_CLIENT: asyncActionType("[VERTEX360]GET_CASE_NOTES_CLIENT"),
  GET_CASE_NOTES_CLIENT_CALL: "[VERTEX360]GET_CASE_NOTES_CLIENT_CALL",

  GET_SINGLE_SUPPORT_TRACT: asyncActionType(
    "[VERTEX360]GET_SINGLE_SUPPORT_TRACT"
  ),
  GET_SINGLE_SUPPORT_TRACT_CALL: "[VERTEX360]GET_SINGLE_SUPPORT_TRACT_CALL",

  GET_SUPPORT_TRACT_LIST: asyncActionType("[VERTEX360]GET_SUPPORT_TRACT_LIST"),
  GET_SUPPORT_TRACT_LIST_CALL: "[VERTEX360]GET_SUPPORT_TRACT_LIST_CALL",

  GET_CLIENT_TASKS_LIST: asyncActionType("[VERTEX360]GET_CLIENT_TASKS_LIST"),
  GET_CLIENT_TASKS_LIST_CALL: "[VERTEX360]GET_CLIENT_TASKS_LIST_CALL",

  GET_REPORT_DATA: asyncActionType("[VERTEX360]GET_REPORT_DATA"),
  GET_REPORT_DATA_CALL: "[VERTEX360]GET_REPORT_DATA_CALL",

  UPDATE_CLIENT_TASKS_LIST: asyncActionType(
    "[VERTEX360]UPDATE_CLIENT_TASKS_LIST"
  ),
  UPDATE_CLIENT_TASKS_LIST_CALL: "[VERTEX360]UPDATE_CLIENT_TASKS_LIST_CALL",

  DELETE_CLIENT_TASKS: asyncActionType("[VERTEX360]DELETE_CLIENT_TASKS"),
  DELETE_CLIENT_TASKS_CALL: "[VERTEX360]DELETE_CLIENT_TASKS_CALL",

  ADD_PARTICIPANT_LOCALLY: "[VERTEX360]ADD_PARTICIPANT_LOCALLY",

  SP_APPLICANTS_LIST: asyncActionType("[VERTEX360]SP_APPLICANTS_LIST"),
  SP_APPLICANTS_LIST_CALL: "[VERTEX360]SP_APPLICANTS_LIST_CALL",

  SP_EMPLOYEE_DOCUMENTS: asyncActionType("[VERTEX360]SP_EMPLOYEE_DOCUMENTS"),
  SP_EMPLOYEE_DOCUMENTS_CALL: "[VERTEX360]SP_EMPLOYEE_DOCUMENTS_CALL",

  SP_DASHBOARD: asyncActionType("[VERTEX360]SP_DASHBOARD"),
  SP_DASHBOARD_CALL: "[VERTEX360]SP_DASHBOARD_CALL",

  SP_WORKER: asyncActionType("[VERTEX360]SP_WORKER"),
  SP_WORKER_CALL: "[VERTEX360]SP_WORKER_CALL",

  SP_FEEDBACK: asyncActionType("[VERTEX360]SP_FEEDBACK"),
  SP_FEEDBACK_CALL: "[VERTEX360]SP_FEEDBACK_CALL",

  SP_COMPLAINTS: asyncActionType("[VERTEX360]SP_COMPLAINTS"),
  SP_COMPLAINTS_CALL: "[VERTEX360]SP_COMPLAINTS_CALL",

  SP_QUESTIONS: asyncActionType("[VERTEX360]SP_QUESTIONS"),
  SP_QUESTIONS_CALL: "[VERTEX360]SP_QUESTIONS_CALL",

  SP_FORM_CATEGORIES: asyncActionType("[VERTEX360]SP_FORM_CATEGORIES"),
  SP_FORM_CATEGORIES_CALL: "[VERTEX360]SP_FORM_CATEGORIES_CALL",

  SP_ALL_FORM: asyncActionType("[VERTEX360]SP_ALL_FORM"),
  SP_ALL_FORM_CALL: "[VERTEX360]SP_ALL_FORM_CALL",

  CLEAR_CRM_HRM_FORMS_TEMPLATES_LOCALLY:
    "[VERTEX360]CLEAR_CRM_HRM_FORMS_TEMPLATES_LOCALLY",

  UPDATE_ALL_FORMS_TEMPLATES_LOCALLY:
    "[VERTEX360]UPDATE_ALL_FORMS_TEMPLATES_LOCALLY",

  UPDATE_PARTICIPANT_DATA: "[VERTEX360]UPDATE_PARTICIPANT_DATA",

  SP_DOCUMENTS: asyncActionType("[VERTEX360]SP_DOCUMENTS"),
  SP_DOCUMENTS_CALL: "[VERTEX360]SP_DOCUMENTS_CALL",

  SP_DOCUMENTS_UPDATE: asyncActionType("[VERTEX360]SP_DOCUMENTS_UPDATE"),
  SP_DOCUMENTS_UPDATE_CALL: "[VERTEX360]SP_DOCUMENTS_UPDATE_CALL",

  ADD_SP_DOCUMENTS_LOCALLY: "[VERTEX360]ADD_SP_DOCUMENTS_LOCALLY",
  DELETE_SP_DOCUMENTS_LOCALLY: "[VERTEX360]DELETE_SP_DOCUMENTS_LOCALLY",

  SP_RISK_RATING_TAB_LIST: asyncActionType(
    "[VERTEX360]SP_RISK_RATING_TAB_LIST"
  ),
  SP_RISK_RATING_TAB_LIST_CALL: "[VERTEX360]SP_RISK_RATING_TAB_LIST_CALL",

  SUPPORT_PLAN_SUBMITTED_FORMS_LIST: asyncActionType(
    "[VERTEX360]SUPPORT_PLAN_SUBMITTED_FORMS_LIST"
  ),
  SUPPORT_PLAN_SUBMITTED_FORMS_LIST_CALL:
    "[VERTEX360]SUPPORT_PLAN_SUBMITTED_FORMS_LIST_CALL",

  RESET_SUBMITTED_FORMS_LIST: "[VERTEX360]RESET_SUBMITTED_FORMS_LIST",

  CREATE_SP_MANAGER: asyncActionType("[VERTEX360]CREATE_SP_MANAGER"),
  CREATE_SP_MANAGER_CALL: "[VERTEX360]CREATE_SP_MANAGER_CALL",

  SP_MANAGER_LIST: asyncActionType("[VERTEX360]SP_MANAGER_LIST"),
  SP_MANAGER_LIST_CALL: "[VERTEX360]SP_MANAGER_LIST_CALL",

  //Participant
  RESET_SELECTED_PARTICIPANT: "[VERTEX360]RESET_SELECTED_PARTICIPANT",

  RE_SET_PARTICIAPNT: "[VERTEX360]RE_SET_PARTICIAPNT",
  RESET_SELECTED_PARTICIPANT_DOCUMENTS:
    "[VERTEX360]RESET_SELECTED_PARTICIPANT_DOCUMENTS",

  GET_PARTICIPANT_CALL: "[VERTEX360]GET_PARTICIPANT_CALL",
  GET_PARTICIAPNT: asyncActionType("[VERTEX360]GET_PARTICIAPNT"),

  PARTICIPANT_SUPPORT_PLAN_CALL: "[VERTEX360]PARTICIPANT_SUPPORT_PLAN_CALL",
  GET_PARTICIPANT_SUPPORT_PLAN: asyncActionType(
    "[VERTEX360]GET_PARTICIPANT_SUPPORT_PLAN"
  ),

  PARTICIPANT_QUICK_SNAPSHOT_CALL: "[VERTEX360]PARTICIPANT_QUICK_SNAPSHOT_CALL",
  PARTICIPANT_QUICK_SNAPSHOT: asyncActionType(
    "[VERTEX360]PARTICIPANT_QUICK_SNAPSHOT"
  ),

  PARTICIPANT_SETTING_UPDATE_CALL: "[VERTEX360]PARTICIPANT_SETTING_UPDATE_CALL",
  PARTICIPANT_SETTING_UPDATE: asyncActionType(
    "[VERTEX360]PARTICIPANT_SETTING_UPDATE"
  ),

  CREATE_CLIENT_TASK_CALL: "[VERTEX360]CREATE_CLIENT_TASK_CALL",
  CREATE_CLIENT_TASK: asyncActionType("[VERTEX360]CREATE_CLIENT_TASK"),

  GET_CLIENT_TASKS_LIST_UNMOUNT: asyncActionType(
    "[VERTEX360]GET_CLIENT_TASKS_LIST_UNMOUNT"
  ),

  CREATE_CLIENT_TASK_UNMOUNT: asyncActionType(
    "[VERTEX360]CREATE_CLIENT_TASK_UNMOUNT"
  ),

  PARTICIPANT_APPOINTMENT_LIST_CALL:
    "[VERTEX360]PARTICIPANT_APPOINTMENT_LIST_CALL",
  PARTICIPANT_APPOINTMENT_LIST: asyncActionType(
    "[VERTEX360]PARTICIPANT_APPOINTMENT_LIST"
  ),

  PARTICIPANT_APPOINTMENT_CREATE_CALL:
    "[VERTEX360]PARTICIPANT_APPOINTMENT_CREATE_CALL",
  PARTICIPANT_APPOINTMENT_CREATE: asyncActionType(
    "[VERTEX360]PARTICIPANT_APPOINTMENT_CREATE"
  ),

  PARTICIPANT_APPOINTMENT_UPDATE_CALL:
    "[VERTEX360]PARTICIPANT_APPOINTMENT_UPDATE_CALL",
  PARTICIPANT_APPOINTMENT_UPDATE: asyncActionType(
    "[VERTEX360]PARTICIPANT_APPOINTMENT_UPDATE"
  ),

  PARTICIPANT_APPOINTMENT_DELETE_LOCALLY:
    "[VERTEX360]PARTICIPANT_APPOINTMENT_DELETE_LOCALLY",

  //HRM
  HRM_DOCUMENTS: asyncActionType("[VERTEX360]HRM_DOCUMENTS"),
  HRM_DOCUMENTS_CALL: "[VERTEX360]HRM_DOCUMENTS_CALL",

  SUPPORT_PLAN_HRM_SUBMITTED_FORMS_LIST: asyncActionType(
    "[VERTEX360]SUPPORT_PLAN_HRM_SUBMITTED_FORMS_LIST"
  ),
  SUPPORT_PLAN_HRM_SUBMITTED_FORMS_LIST_CALL:
    "[VERTEX360]SUPPORT_PLAN_HRM_SUBMITTED_FORMS_LIST_CALL",

  HRM_TEAM_MANAGMENT_LIST: asyncActionType(
    "[VERTEX360]HRM_TEAM_MANAGMENT_LIST"
  ),
  HRM_TEAM_MANAGMENT_LIST_CALL: "[VERTEX360]HRM_TEAM_MANAGMENT_LIST_CALL",

  HRM_WORKER_HOURLY_SETTING: asyncActionType(
    "[VERTEX360]HRM_WORKER_HOURLY_SETTING"
  ),
  HRM_WORKER_HOURLY_SETTING_CALL: "[VERTEX360]HRM_WORKER_HOURLY_SETTING_CALL",

  HRM_WORKER_STATUS_UPDATE: asyncActionType(
    "[VERTEX360]HRM_WORKER_STATUS_UPDATE"
  ),
  HRM_WORKER_STATUS_UPDATE_CALL: "[VERTEX360]HRM_WORKER_STATUS_UPDATE_CALL",

  HRM_WORKER_PROFILE_DETAIL: asyncActionType(
    "[VERTEX360]HRM_WORKER_PROFILE_DETAIL"
  ),
  HRM_WORKER_PROFILE_DETAIL_CALL: "[VERTEX360]HRM_WORKER_PROFILE_DETAIL_CALL",

  //COS
  CATALOG_SERVICES: asyncActionType("[VERTEX360]CATALOG_SERVICES"),
  CATALOG_SERVICES_CALL: "[VERTEX360]CATALOG_SERVICES_CALL",

  CATALOG_SERVICES_SUB_CATEGORY: asyncActionType(
    "[VERTEX360]CATALOG_SERVICES_SUB_CATEGORY"
  ),
  CATALOG_SERVICES_SUB_CATEGORY_CALL:
    "[VERTEX360]CATALOG_SERVICES_SUB_CATEGORY_CALL",

  CATALOG_VERSIONS: asyncActionType("[VERTEX360]CATALOG_VERSIONS"),
  CATALOG_VERSIONS_CALL: "[VERTEX360]CATALOG_VERSIONS_CALL",

  STORE_AND_UPDATE_FUNDING_SETUP: asyncActionType(
    "[VERTEX360]STORE_AND_UPDATE_FUNDING_SETUP"
  ),
  STORE_AND_UPDATE_FUNDING_SETUP_CALL:
    "[VERTEX360]STORE_AND_UPDATE_FUNDING_SETUP_CALL",

  ALL_FUNDING_SETUP_PARTICIPANT: asyncActionType(
    "[VERTEX360]ALL_FUNDING_SETUP_PARTICIPANT"
  ),
  ALL_FUNDING_SETUP_PARTICIPANT_CALL:
    "[VERTEX360]ALL_FUNDING_SETUP_PARTICIPANT_CALL",

  FUNDING_SETUP_PARTICIPANT_UPDATED: asyncActionType(
    "[VERTEX360]FUNDING_SETUP_PARTICIPANT_UPDATED"
  ),
  FUNDING_SETUP_PARTICIPANT_UPDATE_CALL:
    "[VERTEX360]FUNDING_SETUP_PARTICIPANT_UPDATE_CALL",

  RESET_PARTICIAPNT_ALL_FUNDINGS: "[VERTEX360]RESET_PARTICIAPNT_ALL_FUNDINGS",

  RESET_PARTICIAPNT_PROFILE: "[VERTEX360]RESET_PARTICIAPNT_PROFILE",

  ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS: asyncActionType(
    "[VERTEX360]ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS"
  ),
  ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS_CALL:
    "[VERTEX360]ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS_CALL",

  SELCETED_FUNDING_SETUP: asyncActionType("[VERTEX360]SELCETED_FUNDING_SETUP"),
  SELCETED_FUNDING_SETUP_CALL: "[VERTEX360]SELCETED_FUNDING_SETUP_CALL",

  GET_DASHBOARD_DATA: asyncActionType("[VERTEX360]GET_DASHBOARD_DATA"),
  GET_DASHBOARD_DATA_CALL: "[VERTEX360]GET_DASHBOARD_DATA_CALL",

  PLAN_CATAGORIES: asyncActionType("[VERTEX360]PLAN_CATAGORIES"),
  PLAN_CATAGORIES_CALL: "[VERTEX360]PLAN_CATAGORIES_CALL",

  CREATE_PLAN_BUDGET: asyncActionType("[VERTEX360]CREATE_PLAN_BUDGET"),
  CREATE_PLAN_BUDGET_CALL: "[VERTEX360]CREATE_PLAN_BUDGET_CALL",

  PLAN_BUDGET_LIST: asyncActionType("[VERTEX360]PLAN_BUDGET_LIST"),
  PLAN_BUDGET_LIST_CALL: "[VERTEX360]PLAN_BUDGET_LIST_CALL",

  PLAN_BUDGET_DELETED: "[VERTEX360]PLAN_BUDGET_DELETED",

  SERVICE_CATEGORY_PLAN_BUDGET_DELETE: asyncActionType(
    "[VERTEX360]SERVICE_CATEGORY_PLAN_BUDGET_DELETE"
  ),
  SERVICE_CATEGORY_PLAN_BUDGET_DELETE_CALL:
    "[VERTEX360]SERVICE_CATEGORY_PLAN_BUDGET_DELETE_CALL",

  EXTERNAL_SP_LIST_FETCHED: asyncActionType(
    "[VERTEX360]EXTERNAL_SP_LIST_FETCHED"
  ),
  EXTERNAL_SP_LIST_FETCH_CALL: "[VERTEX360]EXTERNAL_SP_LIST_FETCH_CALL",

  EXTERNAL_SP_CREATED: asyncActionType("[VERTEX360]EXTERNAL_SP_CREATED"),
  EXTERNAL_SP_CREATE_CALL: "[VERTEX360]EXTERNAL_SP_CREATE_CALL",

  PLAN_BUDGET_CAT_AND_SUB_CAT: asyncActionType(
    "[VERTEX360]PLAN_BUDGET_CAT_AND_SUB_CAT"
  ),
  PLAN_BUDGET_CAT_AND_SUB_CAT_CALL:
    "[VERTEX360]PLAN_BUDGET_CAT_AND_SUB_CAT_CALL",

  SERVICE_CATEGORY_PLAN_BUDGET_STORE: asyncActionType(
    "[VERTEX360]SERVICE_CATEGORY_PLAN_BUDGET_STORE"
  ),
  SERVICE_CATEGORY_PLAN_BUDGET_STORE_CALL:
    "[VERTEX360]SERVICE_CATEGORY_PLAN_BUDGET_STORE_CALL",

  SERVICE_CATEGORY_PLAN_BUDGET_FETCH: asyncActionType(
    "[VERTEX360]SERVICE_CATEGORY_PLAN_BUDGET_FETCH"
  ),
  SERVICE_CATEGORY_PLAN_BUDGET_FETCH_CALL:
    "[VERTEX360]SERVICE_CATEGORY_PLAN_BUDGET_FETCH_CALL",

  FUNDING_NOTES_LIST: asyncActionType("[VERTEX360]FUNDING_NOTES_LIST"),
  FUNDING_NOTES_LIST_CALL: "[VERTEX360]FUNDING_NOTES_LIST_CALL",

  COS_PARTICIPANT_PROFILE: asyncActionType(
    "[VERTEX360]COS_PARTICIPANT_PROFILE"
  ),
  COS_PARTICIPANT_PROFILE_CALL: "[VERTEX360]COS_PARTICIPANT_PROFILE_CALL",

  CREATE_FUNDING_NOTES: asyncActionType("[VERTEX360]CREATE_FUNDING_NOTES"),
  CREATE_FUNDING_NOTES_CALL: "[VERTEX360]CREATE_FUNDING_NOTES_CALL",

  // FUNDING_NOTES_DELETE: asyncActionType("[VERTEX360]FUNDING_NOTES_DELETE"),
  // FUNDING_NOTES_DELETE_CALL: "[VERTEX360]FUNDING_NOTES_DELETE_CALL",

  FUNDING_NOTES_DELETED: "[VERTEX360]FUNDING_NOTES_DELETED",

  SERVICE_SUPPORT_BUDGET_CREATE: asyncActionType(
    "[VERTEX360]SERVICE_SUPPORT_BUDGET_CREATE"
  ),
  SERVICE_SUPPORT_BUDGET_CREATE_CALL:
    "[VERTEX360]SERVICE_SUPPORT_BUDGET_CREATE_CALL",

  SERVICE_SUPPORT_BUDGET_FETCH: asyncActionType(
    "[VERTEX360]SERVICE_SUPPORT_BUDGET_FETCH"
  ),
  SERVICE_SUPPORT_BUDGET_FETCH_CALL:
    "[VERTEX360]SERVICE_SUPPORT_BUDGET_FETCH_CALL",

  SERVICE_SUPPORT_FOR_DELIVERED_SERVICE: asyncActionType(
    "[VERTEX360]SERVICE_SUPPORT_FOR_DELIVERED_SERVICE"
  ),
  SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CALL:
    "[VERTEX360]SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CALL",
  CLEAR_SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CASE_NOTE:
    "[VERTEX360]SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CALL",

  COS_PARTICIPANT_LIST: asyncActionType("[VERTEX360]COS_PARTICIPANT_LIST"),
  COS_PARTICIPANT_LIST_CALL: "[VERTEX360]COS_PARTICIPANT_LIST_CALL",

  SUPPORT_COORDINATORS: asyncActionType("[VERTEX360]SUPPORT_COORDINATORS"),
  SUPPORT_COORDINATORS_CALL: "[VERTEX360]SUPPORT_COORDINATORS_CALL",

  COS_SETTING_CATEGORIES: asyncActionType("[VERTEX360]COS_SETTING_CATEGORIES"),
  COS_SETTING_CATEGORIES_CALL: "[VERTEX360]COS_SETTING_CATEGORIES_CALL",

  COS_SETTING_CONTACT: asyncActionType("[VERTEX360]COS_SETTING_CONTACT"),
  COS_SETTING_CONTACT_CALL: "[VERTEX360]COS_SETTING_CONTACT_CALL",

  COS_SETTING_PRIORITIES: asyncActionType("[VERTEX360]COS_SETTING_PRIORITIES"),
  COS_SETTING_PRIORITIES_CALL: "[VERTEX360]COS_SETTING_PRIORITIES_CALL",

  DELIVERED_SERVICE_CREATE: asyncActionType(
    "[VERTEX360]DELIVERED_SERVICE_CREATE"
  ),
  DELIVERED_SERVICE_CREATE_CALL: "[VERTEX360]DELIVERED_SERVICE_CREATE_CALL",

  MANUAL_DELIVERED_SERVICE_CREATE: asyncActionType(
    "[VERTEX360]MANUAL_DELIVERED_SERVICE_CREATE"
  ),
  MANUAL_DELIVERED_SERVICE_CREATE_CALL:
    "[VERTEX360]MANUAL_DELIVERED_SERVICE_CREATE_CALL",

  DELIVERED_SERVICE_FETCH: asyncActionType(
    "[VERTEX360]DELIVERED_SERVICE_FETCH"
  ),
  DELIVERED_SERVICE_FETCH_CALL: "[VERTEX360]DELIVERED_SERVICE_FETCH_CALL",

  MANUAL_DELIVEREIES_FETCH: asyncActionType(
    "[VERTEX360]MANUAL_DELIVEREIES_FETCH"
  ),
  MANUAL_DELIVEREIES_FETCH_CALL: "[VERTEX360]MANUAL_DELIVEREIES_FETCH_CALL",

  SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CASE_NOTES:
    "[VERTEX360]SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CASE_NOTES",
  //Notification Actions
  HAS_UNSEEN_NOTIFICATION: "HAS_UNSEEN_NOTIFICATION",
  //Rostering Actions
  FETCH_WORKER_AND_PARTICIPANT_LIST: asyncActionType(
    "[VERTEX360]FETCH_WORKER_AND_PARTICIPANT_LIST"
  ),
  FETCH_WORKER_AND_PARTICIPANT_LIST_CALL:
    "[VERTEX360]FETCH_WORKER_AND_PARTICIPANT_LIST_CALL",

  SORT_CALENDAR_SHIFTS_ITEM: "[VERTEX360]SORT_CALENDAR_SHIFTS_ITEM",

  CALENDAR_DATE_CHANGE_LOCALLY: "[VERTEX360]CALENDAR_DATE_CHANGE_LOCALLY",

  CREATE_OR_UPDATE_WORKER_SHIFT: asyncActionType(
    "[VERTEX360]CREATE_OR_UPDATE_WORKER_SHIFT"
  ),
  CREATE_OR_UPDATE_WORKER_SHIFT_CALL:
    "[VERTEX360]CREATE_OR_UPDATE_WORKER_SHIFT_CALL",

  WORKER_AND_PARTICIPANT_SHIFT_DELETE_LOCALLY:
    "[VERTEX360]WORKER_AND_PARTICIPANT_SHIFT_DELETE_LOCALLY",

  WORKER_AND_PARTICIPANT_SHIFT_STATUS_CHANGED_LOCALLY:
    "[VERTEX360]WORKER_AND_PARTICIPANT_SHIFT_STATUS_CHANGED_LOCALLY",

  WORKER_AND_PARTICIPANT_SHFIT_COPY: asyncActionType(
    "[VERTEX360]WORKER_AND_PARTICIPANT_SHFIT_COPY"
  ),
  WORKER_AND_PARTICIPANT_SHFIT_COPY_CALL:
    "[VERTEX360]WORKER_AND_PARTICIPANT_SHFIT_COPY_CALL",

  SHOW_RECURRING_SHIFT_MODAL: "[VERTEX360]SHOW_RECURRING_SHIFT_MODAL",

  WORKER_TIMESHEETS: asyncActionType("[VERTEX360]WORKER_TIMESHEETS"),
  WORKER_TIMESHEETS_CALL: "[VERTEX360]WORKER_TIMESHEETS_CALL",
  WORKER_TIMESHEETS_LOCALLY_SEARCH:
    "[VERTEX360]WORKER_TIMESHEETS_LOCALLY_SEARCH",

  PARTICIPANT_LIST_FOR_TIMESHEET: asyncActionType(
    "[VERTEX360]PARTICIPANT_LIST_FOR_TIMESHEET"
  ),
  PARTICIPANT_LIST_FOR_TIMESHEET_CALL:
    "[VERTEX360]PARTICIPANT_LIST_FOR_TIMESHEET_CALL",

  HRM_FORM_CATEGORIES_LIST: asyncActionType(
    "[VERTEX360]HRM_FORM_CATEGORIES_LIST"
  ),
  HRM_FORM_CATEGORIES_LIST_CALL: "[VERTEX360]HRM_FORM_CATEGORIES_LIST_CALL",

  HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY: asyncActionType(
    "[VERTEX360]HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY"
  ),
  HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY_CALL:
    "[VERTEX360]HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY_CALL",

  SET_SELECTED_TIMESHEET_LOCALLY: "[VERTEX360]SET_SELECTED_TIMESHEET_LOCALLY",

  TIMESHEET_COMMENTS_FETCHED: asyncActionType(
    "[VERTEX360]TIMESHEET_COMMENTS_FETCHED"
  ),
  TIMESHEET_COMMENTS_FETCH_CALL: "[VERTEX360]TIMESHEET_COMMENTS_FETCH_CALL",

  TIMESHEET_COMMENT_CREATED: asyncActionType(
    "[VERTEX360]TIMESHEET_COMMENT_CREATED"
  ),
  TIMESHEET_COMMENT_CREATE_CALL: "[VERTEX360]TIMESHEET_COMMENT_CREATE_CALL",

  TIMESHEET_DATA_UPDATED: asyncActionType("[VERTEX360]TIMESHEET_DATA_UPDATED"),
  TIMESHEET_DATA_UPDATE_CALL: "[VERTEX360]TIMESHEET_DATA_UPDATE_CALL",

  TIMESHEET_STATUS_CHANGED: asyncActionType(
    "[VERTEX360]TIMESHEET_STATUS_CHANGED"
  ),
  TIMESHEET_STATUS_CHANGE_CALL: "[VERTEX360]TIMESHEET_STATUS_CHANGE_CALL",

  RESET_IS_ANY_PENDING_EXIST_FOR_WORKER:
    "[VERTEX360]RESET_IS_ANY_PENDING_EXIST_FOR_WORKER",

  WORKER_TIMESHEET_EXPORT_LIST: asyncActionType(
    "[VERTEX360]WORKER_TIMESHEET_EXPORT_LIST"
  ),
  WORKER_TIMESHEET_EXPORT_LIST_CALL:
    "[VERTEX360]WORKER_TIMESHEET_EXPORT_LIST_CALL",

  SINGLE_TIMESHEET_DETAILS: asyncActionType(
    "[VERTEX360]SINGLE_TIMESHEET_DETAILS"
  ),
  SINGLE_TIMESHEET_DETAILS_CALL: "[VERTEX360]SINGLE_TIMESHEET_DETAILS_CALL",

  EXPORT_TIMESHEET_TO_PAYROLL: asyncActionType(
    "[VERTEX360]EXPORT_TIMESHEET_TO_PAYROLL"
  ),
  EXPORT_TIMESHEET_TO_PAYROLL_CALL:
    "[VERTEX360]EXPORT_TIMESHEET_TO_PAYROLL_CALL",

  TIMESHEET_STATUS_CHANGED_LOCALLY:
    "[VERTEX360]TIMESHEET_STATUS_CHANGED_LOCALLY",
  GET_ROSTER_INVOICE_CALL: "[VERTEX360]GET_ROSTER_INVOICE_CALL",
  GET_ROSTER_INVOICE: asyncActionType("[VERTEX360]GET_ROSTER_INVOICE"),

  UPDATE_CALENDER_SEARCH_LOCALLY: "[VERTEX360]UPDATE_CALENDER_SEARCH_LOCALLY",
};

export const FETCH_STATUS = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export default {
  ...FILTER_ACTIONS,
};

export const LOADER_STATUS = {
  NA: -1,
  PENDING: 1,
  COMPLETE: 0,
};

export const TOAST_IDS = {
  PUBLISH_SHIFT_MODAL_BUTTON_ID: 1,
  EXPORT_SELECTED_TIMESHEET_ID: 2,
  MARK_SELECTED_TIMESHEET_PAID_ID: 3,
  WORKER_SHIFT_DELETE_CHECK_ON_STATUS_ID: 4,
  EXPIRY_DATE_IN_FUNDING: 5,
  SUBSCRIPTION_PLAN_BUY: 6,
};

export const NOTIFICATION_IDS = {
  PAYROLL_AUTHENTICATION: "auth",
  PAYROLL_TIMESHEET_CALLBACK: "timesheet",
  ROLE_UPDATE: "role_update",
  STRIPE_SUBSCRIPTION: "stripe_subscription",
};

export const PAYROLL_PLATFORMS = {
  XERO: "xero",
  QUICKBOOKS: "quick_books",
  MYOB: "myob",
};

export const PERMISSION_KEYS = {
  //Blue portal keys or common
  DASHBOARD: "dashboard",
  PARTICIPANT: "participant",
  CRM_FORM: "crm_form",
  HRM_FORM: "hrm_form",
  HRM: "hrm",
  COS: "cos",
  ROSTERING: "rostering",
  POLICy: "policies",
  COMPLAINT: "complaints",
  FEEDBACK: "feedback",
  ASK_QUESTION: "askquestion",
  ROSTER_INVOICE: "roster",
REPORTING:"reporting",
  //black portal keys
  DOCUMENT: "document",
  SUBMITTED: "submitted",
  //ASK_QUESTION: "askquestion",
};

export const PERMISSION_KEYS_CHILDREN = {
  PARTICIPANT: {
    SHOW: "participant.list",
    NDIS_NUMBER: "participant.ndis.number",
    DETAIL: "participant.detail",
    DOB: "participant.dob",
    PRIMARY_PHONE: "participant.primary.phone",
    PRIMARY_EMAIL: "participant.primary.email",
    EMERGENCY_CONTACT_NAME: "participant.emergency.contact.name",
    EMERGENCY_CONTACT_PHONE: "participant.emergency.contact.phone",
    SETTING: {
      SHOW: "participant.setting",
      UPDATE: "participant.setting.update",
    },
    DOCUMEMT: {
      SHOW: "participant.document.list",
      UPLOAD: "participant.document.upload",
      UPDATE: "participant.document.update",
      DELETE: "participant.document.delete",
      FOLDER: {
        CREATE: "participant.document.folder.create",
        UPDATE: "participant.document.folder.update",
        DELETE: "participant.document.folder.delete",
      },
    },
    FUNDING: {
      SHOW: "participant.funding.list",
      CREATE: "participant.funding.store",
      UPDATE: "participant.funding.update",
    },
    CASE_NOTES: {
      SHOW: "participant.casenote.list",
      STORE: "participant.casenote.store",
    },
    FORM: {
      ASSESSMENT: "participant.form.assessment",
    },
  },
  FEEDBACK: {
    SHOW: "feedback.list",
    CREATE: "feedback.store",
    UPDATE: "feedback.update", // not exist
    DELETE: "feedback.delete", // not exist
  },
  POLICY: {
    SHOW: "policies.list",
    CREATE: "policies.store",
    UPDATE: "policies.update", // not exist
    DELETE: "policies.delete",
  },
  COMPLAINT: {
    SHOW: "complaints.list",
    CREATE: "complaints.store",
    UPDATE: "complaints.update", // not exist
    DELETE: "complaints.delete", // not exist
  },
  ASK_QUESTION: {
    SHOW: "askquestion.list",
    CREATE: "askquestion.store",
  },
  ROSTERING: {
    SHOW: "rostering",
    SCHEDULE: {
      SHOW: "rostering.schedule",
    },
    TIMESHEET: {
      SHOW: "rostering.timesheet",
    },
    TIMESHEET_EXPORT: {
      SHOW: "rostering.timesheet.export",
    },
  },
  ROSTER_INVOICE: {
    SHOW: "roster.invoice",
    CREATE: "roster.invoice.create",
  },
  COS: {
    SHOW: "cos",
    TEAM_STATUS: {
      SHOW: "cos.team.status",
    },
    NDIS_CLAIM: { SHOW: "cos.nidis.claim" },
    INVOICES: { SHOW: "cos.invoices" },
    REPORTING: { SHOW: "cos.reporting" },
    SETTING: { SHOW: "cos.settings" },
  },
  HRM: {
    SHOW: "hrm",
    APPLICANT: {
      SHOW: "hrm.applicant.list",
      CREATE: "hrm.applicant.store",
      ASSESSMENT: "hrm.applicant.assessment",
    },
    WORKER: {
      SHOW: "hrm.work.list",
      CREATE: "hrm.work.store",
    },
    MANAGER: {
      SHOW: "hrm.manager.list",
      CREATE: "hrm.manager.store",
    },
  },
  HRM_FORM: {
    SHOW: "hrm.form.list",
    UPDATE: "hrm.form.update",
    CREATE: "hrm.form.store",
  },
  CRM_FORM: {
    SHOW: "crm.form.list",
    UPDATE: "crm.form.update",
    CREATE: "crm.form.store",
  },
};

export const BLK_PORTAL_PREMISSION_KEYS_CHILDREN = {
  COMPLAINT: { ...PERMISSION_KEYS_CHILDREN.COMPLAINT },
  DASHBOARD: {
    SHOW: "participant.dashboard",
  },
  DOCUMEMT: {
    SHOW: "document.list",
    UPLOAD: "document.upload",
    FOLDER: {
      CREATE: "document.folder.create",
      UPDATE: "document.folder.update",
      DELETE: "document.folder.delete",
    },
  },
  ASK_QUESTION: { ...PERMISSION_KEYS_CHILDREN.ASK_QUESTION },
  FEEDBACK: { ...PERMISSION_KEYS_CHILDREN.FEEDBACK },
  POLICY: { ...PERMISSION_KEYS_CHILDREN.POLICY },
  SUBMITTED: {
    SHOW: "submitted.form.list",
    FORM: {
      CREATE: "submit.form",
      VIEW: "submit.form.view",
    },
  },
  PARTICIPANT: { ...PERMISSION_KEYS_CHILDREN.PARTICIPANT },
  COS: { ...PERMISSION_KEYS_CHILDREN.COS },
};

//Recurring options
export const RECURRING_INTERVAL = [
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Fortnightly",
    value: "fortnight",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

//Recurring options
export const TYPE_OF_SERVICES = [
  {
    label: "Self-Care",
    value: "Self-Care",
  },
  {
    label: "Community Participation",
    value: "Community Participation",
  },
  {
    label: "House Cleaning",
    value: "House Cleaning",
  },
  {
    label: "Yard-Maintenance",
    value: "Yard-Maintenance",
  },
  {
    label: "Enrolled Nurse",
    value: "Enrolled Nurse",
  },
  {
    label: " Registered Nurse",
    value: " Registered Nurse",
  },
  {
    label: "Therapy Assistant",
    value: "Therapy Assistant",
  },
  {
    label: "Therapist",
    value: "Therapist",
  },
];

//Birth Month
export const GET_MONTH = [
  { label: "All", value: "all" },
  { label: "January", value: "01" },
  { label: "February", value: "02" },
  { label: "March", value: "03" },
  { label: "April", value: "04" },
  { label: "May", value: "05" },
  { label: "June", value: "06" },
  { label: "July", value: "07" },
  { label: "August", value: "08" },
  { label: "September", value: "09" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" }
];

