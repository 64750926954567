import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import closeIcon from "../../assets/close-icon.png";
import { classNames } from "Utils/utils";

const ModalSkeleton = ({
  title,
  children,
  isOpen,
  onClose,
  closeModal,
  modalFooter = null,
  cssClass,
  bodyPadding,
}) => {
  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog
        as='div'
        className='relative z-[500]'
        onClose={
          () => {}
          // closeModal
        }
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ${cssClass}`}
              >
                <div className='bg-white'>
                  <div
                    className={classNames(
                      "bg-gradient p-4 relative border-b border-gray-400",
                      title === "" && "h-[57px]"
                    )}
                  >
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-semibold pr-4 leading-6 tracking-wider text-white'
                    >
                      {title}
                    </Dialog.Title>
                    <div
                      className='absolute right-4 top-4 text-white cursor-pointer'
                      onClick={closeModal}
                    >
                      <img
                        src={closeIcon}
                        alt='close icon'
                        className='h-5 max-w-[20px] w-5'
                      />
                    </div>
                  </div>
                  <div
                    className={`p-3 max-h-[70vh] overflow-y-auto custom-scroll ${bodyPadding}`}
                  >
                    <div className='mt-2'>{children}</div>
                  </div>
                </div>
                {modalFooter && (
                  <div className='bg-gray-50 p-3 border-t border-gray-200 flex justify-end gap-x-2'>
                    {modalFooter}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ModalSkeleton;
