import React from "react";
import { TIMESHEET_STATUS } from "Constants/constant";
import {
  capitalizeFirstLetter,
  formatDateIntoDMY,
  getHrAndMinsFormatting,
  getTimesheetProgressLabel,
  textTruncate,
} from "Utils/utils";
import AvatarGroup from "components/common/Avatar/AvatarGroup";

const TimeSheetCardItem = (props) => {
  const { item, index, selectedTimeSheet, handleSelectTimeSheet } = props;

  const getStatusIconColor = (status) => {
    switch (status) {
      case TIMESHEET_STATUS.PENDING:
        return "border-orange-700 bg-[#FFFAEB] text-[#B54708]";
      case TIMESHEET_STATUS.APPROVED:
        return "border-green-700 bg-green-50 text-green-800";
      case TIMESHEET_STATUS.DISCARDED:
        return "bg-red-200 bg-red-50 text-red-800";
      case TIMESHEET_STATUS.REJECTED:
        return "bg-[#f7e1e0] border-[#f5b7b4] text-[#e44841]";
      default:
        return "";
    }
  };

  return (
    <div
      onClick={() => handleSelectTimeSheet(item)}
      key={index}
      className={`${
        selectedTimeSheet && selectedTimeSheet.uuid === item?.uuid
          ? "bg-[#e3e3e387] border border-gray-400"
          : "bg-[#fff] hover:border-gray-400 hover:bg-gray-200"
      } border w-[85%] m-w-[70%] mx-auto rounded-[10px] p-3 mt-3 cursor-pointer`}
    >
      <div className='time-sheet-01 flex items-center justify-between'>
        <p className='text-[#344054] text-base font-medium capitalize'>
          {`Timesheet ${Number(index + 1)
            .toString()
            .padStart(2, "0")}`}
        </p>
        <span
          className={`${getStatusIconColor(
            item.status
          )} border px-2 py-1 rounded-[16px] text-xs font-medium capitalize`}
        >
          {item.status}
        </span>
      </div>
      <div className='time-sheet-01-date flex items-center'>
        <p className='text-[#344054] text-sm font-medium'>Participants:</p>
        <span
          title={`${capitalizeFirstLetter(
            item?.participants?.[0]?.first_name
          )} ${capitalizeFirstLetter(item?.participants?.[0]?.last_name)}`}
          className='px-1 rounded-[16px] text-[#667085] text-sm font-medium flex justify-start items-center m-0 pl-2'
        >
          {textTruncate(
            `${capitalizeFirstLetter(
              item?.participants?.[0]?.first_name
            )} ${capitalizeFirstLetter(item?.participants?.[0]?.last_name)}`,
            15,
            16
          )}
        </span>
      </div>
      <div className='py-1'>
        <div className='time-sheet-01-date flex items-center'>
          <p className='text-[#344054] text-sm font-medium'>
            Timesheet Length:
          </p>
          <span className='px-1 rounded-[16px] text-[#667085] text-sm font-medium lowercase'>
            {getHrAndMinsFormatting(item?.timesheet_length)}
          </span>
        </div>
        <div className='time-sheet-01-date flex items-center'>
          <p className='text-[#344054] text-sm font-medium'>Date:</p>
          <span className='px-1 rounded-[16px] text-[#667085] text-sm font-medium'>
            {formatDateIntoDMY(item.time_sheet_date)}
          </span>
        </div>
        <div className='time-sheet-01-date flex items-center'>
          <p className='text-[#344054] text-sm font-medium'>Progress:</p>
          <span className='px-1 rounded-[16px] text-[#667085] text-sm font-medium'>
            {getTimesheetProgressLabel(item.progress)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TimeSheetCardItem;
