import React, { useState, useEffect, useContext } from "react";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import PaginationComponent from "components/common/Pagination/index";
import {
  emptyPaginationObj,
  FUNDING_CASE_NOTES_LIMIT,
} from "Constants/constant";
import { getPaginationObj } from "Utils/utils";
import CaseNoteReportModal from "./CaseNoteReportModal";
import CaseNoteTable from "./caseNotesTable";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { LoginContext } from "helpers/LoginContext";

const ParticipantFundingCaseNote = (props) => {
  const {
    setOpenUpdateCaseNoteModal,
    getCaseNoteDetailRequest,
    reportLoading,
    caseNotesList,
    getCaseNotesRequest,
    casNotesListLoading,
    getCatogeryListRequest,
    uuid,
    getReportRequest,
    selectedFunding,
    cosParticipantsProfile,
  } = props;
  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [openReportModal, setOpenReportModal] = useState(false);
  const [openCaseNoteModal, setOpenCaseNoteModal] = useState(false);
  const [paginationObj, setPaginationObj] = useState(
    caseNotesList ? getPaginationObj(caseNotesList) : emptyPaginationObj
  );

  // useEffect(() => {
  //   if (!caseNotesCreateLoading && openCaseNoteModal && !caseNoteerror) {
  //     setOpenCaseNoteModal(false);
  //     setSelectedServiceSupport(null);
  //   }
  // }, [caseNotesCreateLoading]);

  // const caseNotesToMap = useMemo(() => {
  //   if (caseNotesList && caseNotesList.data && caseNotesList.data.length > 0) {
  //     return caseNotesList.data;
  //   }
  //   return [];
  // }, [caseNotesList]);

  useEffect(() => {
    if (caseNotesList) {
      setPaginationObj({
        ...getPaginationObj(caseNotesList),
      });
    }
  }, [caseNotesList]);

  const handleFetch = (page) => {
    if (
      uuid &&
      caseNotesList &&
      caseNotesList.current_page !== page &&
      selectedFunding
    ) {
      getCaseNotesRequest({
        funding_id: selectedFunding.value,
        id: uuid,
        page,
        per_page: FUNDING_CASE_NOTES_LIMIT,
      });
    }
  };

  useEffect(() => {
    if (selectedFunding && !openCaseNoteModal) {
      getCaseNotesRequest({
        funding_id: selectedFunding.value,
        id: uuid,
        page: paginationObj.page,
        per_page: FUNDING_CASE_NOTES_LIMIT,
      });
    }
  }, [selectedFunding, openCaseNoteModal]);

  return (
    <>
      {casNotesListLoading ? (
        <div
          className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
        >
          <CircularLoader
            classes='flex justify-center items-center'
            classloader='loader-l'
          />
        </div>
      ) : (
        <>
          <div className=' bg-gray-100 font-medium h-20 py-4 pl-4 border col-span-2 flex justify-between rounded-t'>
            <div className='text-[#101828] font-semibold text-lg pt-2'>
              Case Notes
            </div>
            <div className='flex space-x-2 mr-2'>
              <div className='pt-1'>
                {/* <button
                  onClick={() => setCreateCaseNotesKmModel(true)}
                  type="button"
                  className="inline-flex items-center border h-10 text-white w-36 border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium  shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2 pl-7 text-sm rounded-lg bg-[#4B6CB7]"
                >
                  Create KM Calm
                </button> */}
                <CustomButton
                  isDisabled={isUserGracePeriodOver}
                  clickHandler={() => {
                    if (isUserGracePeriodOver) {
                      return;
                    }
                    setOpenReportModal(true);
                  }}
                  label='Generate Report'
                  Icon={<HiOutlineDocumentReport />}
                  type='button'
                  className='inline-flex items-center border h-10  w-20 border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-[#344054] shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2 pl-5 rounded-lg'
                />
              </div>
            </div>
          </div>
          <div className='relative overflow-hidden shadow ring-1 ring-black ring-opacity-5'>
            <CaseNoteTable
              selectedFunding={selectedFunding}
              setOpenUpdateCaseNoteModal={setOpenUpdateCaseNoteModal}
              caseNotesList={caseNotesList && caseNotesList.data}
              getCaseNoteDetailRequest={getCaseNoteDetailRequest}
              cosParticipantsProfile={cosParticipantsProfile}
            />
          </div>
          <div className='py-2'>
            <PaginationComponent
              page={paginationObj.page}
              activeListLength={caseNotesList && caseNotesList.data}
              limit={FUNDING_CASE_NOTES_LIMIT}
              totalList={
                paginationObj.totalPages === 1
                  ? caseNotesList && caseNotesList.data.length
                  : paginationObj.total
              }
              handlePagination={(e) => {
                const obj = paginationObj;
                obj.page = e;
                //   setPaginationObj({ ...obj });
                handleFetch(e);
              }}
              totalPages={paginationObj.totalPages}
            />
          </div>
        </>
      )}
      {openReportModal && (
        <CaseNoteReportModal
          selectedFunding={selectedFunding}
          reportLoading={reportLoading}
          getCatogeryListRequest={getCatogeryListRequest}
          openReportModal={openReportModal}
          closeModal={() => setOpenReportModal(false)}
          uuid={uuid}
          getReportRequest={getReportRequest}
        />
      )}
    </>
  );
};

export default ParticipantFundingCaseNote;
