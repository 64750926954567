import React, { useEffect, useMemo, useState } from "react";
import SideBar from "../../../../../components/common/SideBar/SideBarNew";
import { Link, useNavigate } from "react-router-dom";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
} from "@heroicons/react/solid";
import { BiPlus } from "react-icons/bi";
import Tabs from "../../../Components/Tabs/Tabs";
import {
  ALL_APPLICANT_TABS,
  APPLICANT_STATUS_UUID,
} from "../../../../../Constants/constant";
import CircularLoader from "../../../../../components/common/CircularLoader/CircularLoader";
import { baseURLImage, getNameProfile } from "../../../../../Utils/utils";
import moment from "moment";
import Breadcrumb from "../../../../../components/common/Breadcrumb/Breadcrumb";
import {
  HRM_BREADCRUMB,
  HRM_BREADCRUMB_PAGES,
} from "../../../../../Constants/BreadcrumbConstant";
import { LoginContext } from "../../../../../helpers/LoginContext";
import Header from "../../../../../components/common/Header/Header";
import AddNewApplicantModal from "../AddNewApplicantModal";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const AllApplicants = (props) => {
  const { loading, spApplicants, getApplicantsList } = props;

  const { sideBarPadding, isUserGracePeriodOver } =
    React.useContext(LoginContext);
  const [selectedTab, setSelectedTab] = useState(APPLICANT_STATUS_UUID.Applied);
  const [applicants, setApplicants] = useState(spApplicants || []);

  useEffect(() => {
    getApplicantsList();
  }, []);

  useEffect(() => {
    setApplicants(spApplicants);
  }, [spApplicants]);

  const navigate = useNavigate();

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  const selectedTabCount = useMemo(() => {
    const tabCounts = applicants.reduce((obj, data) => {
      if (data.uuid === APPLICANT_STATUS_UUID.Applied) {
        obj[APPLICANT_STATUS_UUID.Applied] = data.applicants_count;
      } else if (data.uuid === APPLICANT_STATUS_UUID.OnBoardingHired) {
        obj[APPLICANT_STATUS_UUID.OnBoardingHired] = data.applicants_count;
      } else if (data.uuid === APPLICANT_STATUS_UUID.Interview) {
        obj[APPLICANT_STATUS_UUID.Interview] = data.applicants_count;
      } else if (data.uuid === APPLICANT_STATUS_UUID.Offer) {
        obj[APPLICANT_STATUS_UUID.Offer] = data.applicants_count;
      } else if (data.uuid === APPLICANT_STATUS_UUID.PhoneScreening) {
        obj[APPLICANT_STATUS_UUID.PhoneScreening] = data.applicants_count;
      } else if (data.uuid === APPLICANT_STATUS_UUID.Disqualified) {
        obj[APPLICANT_STATUS_UUID.Disqualified] = data.applicants_count;
      } else if (data.uuid === APPLICANT_STATUS_UUID.Pending) {
        obj[APPLICANT_STATUS_UUID.Pending] = data.applicants_count;
      }
      return obj;
    }, {});
    return tabCounts;
  }, [applicants]);

  const applicantsToMap = useMemo(() => {
    const data = applicants.find((f) => f.uuid === selectedTab);

    return data ? data.applicants : [];
  }, [applicants, selectedTab]);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const [isOpenModel, setIsModelOpen] = useState(false);

  return (
    <>
      <div className='min-full'>
        <SideBar classes='bg-gradient' />
        <div
          className={`${
            sideBarPadding ? "lg:pl-[4rem]" : "lg:pl-64"
          } flex flex-col fullScreenHeight duration-300`}
        >
          {isOpenModel && (
            <AddNewApplicantModal
              isUserGracePeriodOver={isUserGracePeriodOver}
              getApplicantsList={getApplicantsList}
              openModel={isOpenModel}
              handleClose={setIsModelOpen}
            />
          )}
          <main className='flex-1 bg-white'>
            <Header
              title='All Applicants'
              desc='All your applied applicants in one place'
              {...(roleAccess(
                [],
                PERMISSION_KEYS.HRM,
                PERMISSION_KEYS_CHILDREN.HRM.APPLICANT.CREATE
              ) && {
                handleClick: () => setIsModelOpen(true),
                btnLabel: "Add Applicant",
                icon: <BiPlus />,
                isButton: true,
              })}
            />
            <div>
              {loading ? (
                <div
                  style={{ justifyContent: "center" }}
                  className='flex items-center min-h-screen'
                >
                  <CircularLoader
                    classes='flex justify-center items-center'
                    classloader='loader-l'
                  />
                </div>
              ) : (
                <div className='w-full'>
                  <div className='max-w-7xl mx-auto'>
                    <div className='mb-5 border-gray-200 px-4 py-3'>
                      <Breadcrumb
                        pages={HRM_BREADCRUMB(
                          HRM_BREADCRUMB_PAGES.ALL_APPLICANT
                        )}
                      />
                    </div>
                    <div className='pt-0 mb-3 ml-4 max-w-7xl sm:px-6 lg:px-8 bg-white rounded-md shadow-md py-4 custom-scroll11 overflow-y-auto11 allApplicantHeight11'>
                      <div className='px-4 sm:px-0 mt-4 bg-white sticky top-[80px] z-[7] pt-1'>
                        <Tabs
                          tabs={ALL_APPLICANT_TABS}
                          handleChange={handleTabChange}
                          selectedTab={selectedTab}
                          count={selectedTabCount}
                        />
                      </div>
                      <ul className='mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0'>
                        {applicantsToMap
                          .sort((a, b) =>
                            a.first_name.toLowerCase() >
                            b.first_name.toLowerCase()
                              ? 1
                              : -1
                          )
                          .map((candidate, index) => (
                            <li
                              key={index}
                              role='button'
                              tabIndex={0}
                              onClick={() =>
                                navigate("/hrm/applicant-profile", {
                                  state: { applicantId: candidate.uuid },
                                })
                              }
                              onKeyDown={() =>
                                navigate("/hrm/applicant-profile", {
                                  state: { applicantId: candidate.uuid },
                                })
                              }
                            >
                              <div className='flex items-center py-5 px-4 sm:py-6 sm:px-0'>
                                <div className='min-w-0 flex-1 flex items-center'>
                                  <div className='flex-shrink-0'>
                                    <img
                                      onError={(e) =>
                                        handleImageError(
                                          e,
                                          candidate.first_name,
                                          candidate.last_name
                                        )
                                      }
                                      className='object-cover h-12 w-12 rounded-full group-hover:opacity-75'
                                      src={
                                        candidate.photo
                                          ? baseURLImage(candidate.photo)
                                          : getNameProfile(
                                              candidate.first_name,
                                              candidate.last_name
                                            )
                                      }
                                      alt='avatar'
                                    />
                                  </div>
                                  <div className='min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4'>
                                    <div>
                                      <Link
                                        to='/hrm/applicant-profile'
                                        state={{
                                          applicantId: candidate.uuid,
                                        }}
                                      >
                                        <p className='capitalize text-sm font-medium text-blue-600 truncate'>
                                          {`${candidate.first_name} ${candidate.last_name}`}
                                        </p>
                                      </Link>
                                      <p className='mt-2 flex items-center text-sm text-gray-500'>
                                        <MailIcon
                                          className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                                          aria-hidden='true'
                                        />
                                        <span className='truncate'>
                                          {candidate.email}
                                        </span>
                                      </p>
                                    </div>
                                    <div className='hidden md:block'>
                                      <div>
                                        <p className='capitalize text-sm text-gray-900 truncate'>
                                          {candidate.job_type}
                                        </p>
                                        <p className='mt-2 flex items-center text-sm text-gray-500'>
                                          <CheckCircleIcon
                                            className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
                                            aria-hidden='true'
                                          />
                                          {`Applied on ${moment(
                                            candidate.applied_on
                                          ).format("Do MMMM, YYYY")}`}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <ChevronRightIcon
                                    className='h-5 w-5 text-gray-400 group-hover:text-gray-700'
                                    aria-hidden='true'
                                  />
                                </div>
                              </div>
                            </li>
                          ))}
                        {applicantsToMap.length <= 0 && (
                          <div className='text-center py-5 px-4 sm:py-6 sm:px-0'>
                            <p className='text-red-500'>No Data found!</p>
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default AllApplicants;
