import CircularLoader from 'components/common/CircularLoader/CircularLoader'
import TableHeader from 'components/common/Table/TableHeader'
import React from 'react';
import { formatDateIntoDMY, gethoursAndMins, getPlanManagedBy, textTruncate } from 'Utils/utils';
import { DELIVERED_SERVICE_STATUS_OPTIONS } from 'Constants/constant';

const CaseNotesTable = ({flipSort,sortBy,sortDescending,reportList,loader}) => {

  return (
    <div className='overflow-x-auto custom-scroll'>
    <table className='border min-w-full divide-y divide-gray-300'>
      <thead
        // style={{ top: "130px" }}
        className='bg-gray-50 sticky z-[5]'
      >
        <tr>
          <TableHeader
             isSort={false}
            label={"Participant Name"}
          />

          <TableHeader
           isSort={false}
            label={"Delivery Serial No."}
          />

          <TableHeader
           isSort={false}
            label={"Support Item"}
          />

          <TableHeader
           isSort={false}
            label={"Created By"}
          />

          <TableHeader
           isSort={false}
            label={"Created Date"}
          />

          <TableHeader
             isSort={false}
            label={"Mgmt Style"}
          />

          <TableHeader
          isSort={false}
            label={"Delivered Date"}
          />

          <TableHeader
       isSort={false}
            label={"Hours"}
          />
          <TableHeader
             isSort={false}
            label={"Rate"}
          />
          <TableHeader
             isSort={false}
            label={"Total Delivered Amount"}
          />
          <TableHeader
          isSort={false}
            label={"Status"}
          />
          <TableHeader
           isSort={false}
            label={"Notes"}
          />
        </tr>
      </thead>
      <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
        {loader ? (
          <tr className='bg-white'>
            <td colSpan={12} className='py-6 text-center bg-white'>
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            </td>
          </tr>
        ) :reportList !== null &&
        reportList?.casenotes?.data?.length > 0 ? (
        reportList?.casenotes?.data?.map(
            (caseNoteItem, index) => (
              <tr
                key={index}
                className='even:bg-white odd:bg-gray-50'
              >
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate capitalize">
                  {`${caseNoteItem?.participant?.first_name} ${caseNoteItem?.participant?.last_name}`}
                </td>

                <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                {
                  caseNoteItem.delivered_service_number || "-"
                }
                </td>
                <td
                  title={caseNoteItem?.support_item_name}
                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate"
                >
                  {textTruncate(caseNoteItem?.support_item_name, 40, 39)}
                </td>
                <td
                  title={caseNoteItem?.created_by}
                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate capitalize"
                >
                  {textTruncate(caseNoteItem?.created_by, 30, 29)}
                </td>

                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate capitalize">
                  {formatDateIntoDMY(caseNoteItem?.created_at)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
                  {getPlanManagedBy(caseNoteItem?.manged_by)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
                  {formatDateIntoDMY(caseNoteItem?.delivered_date)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
                  {gethoursAndMins(caseNoteItem?.hours, caseNoteItem?.mints)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
                  {`$${caseNoteItem?.original_rate}`}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
                  {`$${caseNoteItem?.total_amount}`}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate capitalize">
                  {caseNoteItem?.status === null
                    ? DELIVERED_SERVICE_STATUS_OPTIONS.find((arr) => {
                        return caseNoteItem?.invoice_status === arr.value;
                      }).label
                    : DELIVERED_SERVICE_STATUS_OPTIONS.find((arr) => {
                        return caseNoteItem?.status === arr?.value;
                      }).label}
                </td>
                <td
                  title={caseNoteItem?.notes}
                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate w-[20%]"
                >
                  {textTruncate(caseNoteItem?.notes, 40, 39)}
                </td>
              </tr>
            )
          )
        ) : (
          <tr>
            <td
              colSpan={12}
              className='py-6 text-center text-red-600'
            >
              No data found. Please adjust your filters to get
              relevant results
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
  )
}

export default CaseNotesTable
