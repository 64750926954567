import ACTIONS, { FETCH_STATUS } from "../constants";

export const getSPParticipantsList = {
  pending: {
    type: ACTIONS.SP_PARTICIPANTS_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_PARTICIPANTS_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_PARTICIPANTS_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSPParticipantsListRequest = (payload) => ({
  type: ACTIONS.SP_PARTICIPANTS_LIST_CALL,
  payload,
});

export const addParticipantLocally = (data) => ({
  type: ACTIONS.ADD_PARTICIPANT_LOCALLY,
  data,
});

export const getSPApplicantsList = {
  pending: {
    type: ACTIONS.SP_APPLICANTS_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_APPLICANTS_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_APPLICANTS_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSPApplicantsListRequest = () => ({
  type: ACTIONS.SP_APPLICANTS_LIST_CALL,
});

export const getSPEmployeeDocuments = {
  pending: {
    type: ACTIONS.SP_EMPLOYEE_DOCUMENTS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_EMPLOYEE_DOCUMENTS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_EMPLOYEE_DOCUMENTS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSPEmployeeDocumentsRequest = () => ({
  type: ACTIONS.SP_EMPLOYEE_DOCUMENTS_CALL,
});

export const getSPDashboard = {
  pending: {
    type: ACTIONS.SP_DASHBOARD.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_DASHBOARD.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_DASHBOARD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSPDashboardRequest = () => ({
  type: ACTIONS.SP_DASHBOARD_CALL,
});

export const getSPWorker = {
  pending: {
    type: ACTIONS.SP_WORKER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_WORKER.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_WORKER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSPWorkerRequest = () => ({
  type: ACTIONS.SP_WORKER_CALL,
});

export const getFeedback = {
  pending: {
    type: ACTIONS.SP_FEEDBACK.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_FEEDBACK.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_FEEDBACK.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getFeedbackRequest = (payload) => ({
  type: ACTIONS.SP_FEEDBACK_CALL,
  payload,
});

export const getComplaints = {
  pending: {
    type: ACTIONS.SP_COMPLAINTS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_COMPLAINTS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_COMPLAINTS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const getComplaintsRequests = (payload) => ({
  type: ACTIONS.SP_COMPLAINTS_CALL,
  payload,
});

export const getQuestions = {
  pending: {
    type: ACTIONS.SP_QUESTIONS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_QUESTIONS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_QUESTIONS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const getQuestionsRequests = (payload) => ({
  type: ACTIONS.SP_QUESTIONS_CALL,
  payload,
});

export const SpFormCategories = {
  pending: {
    type: ACTIONS.SP_FORM_CATEGORIES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_FORM_CATEGORIES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_FORM_CATEGORIES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const SpFormCategoriesRequest = (payload) => ({
  type: ACTIONS.SP_FORM_CATEGORIES_CALL,
  payload,
});

export const spAllForm = {
  pending: {
    type: ACTIONS.SP_ALL_FORM.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_ALL_FORM.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_ALL_FORM.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const spAllFormRequest = (payload) => ({
  type: ACTIONS.SP_ALL_FORM_CALL,
  payload,
});

export const updateAllFormTemplatesLocally = (data) => ({
  type: ACTIONS.UPDATE_ALL_FORMS_TEMPLATES_LOCALLY,
  data,
});

export const updateParticipantData = (data) => ({
  type: ACTIONS.UPDATE_PARTICIPANT_DATA,
  data,
});

export const getSPDocuments = {
  pending: {
    type: ACTIONS.SP_DOCUMENTS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_DOCUMENTS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_DOCUMENTS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSPDocumentsRequest = (payload) => ({
  type: ACTIONS.SP_DOCUMENTS_CALL,
  payload,
});

export const deleteSPDocumentsLocally = (data) => ({
  type: ACTIONS.DELETE_SP_DOCUMENTS_LOCALLY,
  data,
});

export const addSPDocumentsLocally = (data) => ({
  type: ACTIONS.ADD_SP_DOCUMENTS_LOCALLY,
  data,
});

export const updateSPDocuments = {
  pending: {
    type: ACTIONS.SP_DOCUMENTS_UPDATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_DOCUMENTS_UPDATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_DOCUMENTS_UPDATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateSPDocumentsRequest = (payload) => ({
  type: ACTIONS.SP_DOCUMENTS_UPDATE_CALL,
  payload,
});

export const getHRMDocuments = {
  pending: {
    type: ACTIONS.HRM_DOCUMENTS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.HRM_DOCUMENTS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.HRM_DOCUMENTS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getHRMDocumentRequest = (payload) => ({
  type: ACTIONS.HRM_DOCUMENTS_CALL,
  payload,
});

export const getSPRiskRatingList = {
  pending: {
    type: ACTIONS.SP_RISK_RATING_TAB_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_RISK_RATING_TAB_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_RISK_RATING_TAB_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSPRiskRatingListRequest = (payload) => ({
  type: ACTIONS.SP_RISK_RATING_TAB_LIST_CALL,
  payload,
});

export const createSPManager = {
  pending: {
    type: ACTIONS.CREATE_SP_MANAGER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data, isUpdateCall) => ({
    type: ACTIONS.CREATE_SP_MANAGER.SUCCESS,
    data,
    isUpdateCall,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_SP_MANAGER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createSPManagerRequest = (payload, isUpdateCall) => ({
  type: ACTIONS.CREATE_SP_MANAGER_CALL,
  payload,
  isUpdateCall,
});

export const SPManagerList = {
  pending: {
    type: ACTIONS.SP_MANAGER_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_MANAGER_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_MANAGER_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getPManagerRequest = () => ({
  type: ACTIONS.SP_MANAGER_LIST_CALL,
});

export const getSupportPlanSubmittedFormsList = {
  pending: {
    type: ACTIONS.SUPPORT_PLAN_SUBMITTED_FORMS_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SUPPORT_PLAN_SUBMITTED_FORMS_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SUPPORT_PLAN_SUBMITTED_FORMS_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSupportPlanSubmittedFormsListRequest = (payload) => ({
  type: ACTIONS.SUPPORT_PLAN_SUBMITTED_FORMS_LIST_CALL,
  payload,
});

export const resetSubmittedFormsList = () => ({
  type: ACTIONS.RESET_SUBMITTED_FORMS_LIST,
});

export const getHRMSupportPlanSubmittedFormsList = {
  pending: {
    type: ACTIONS.SUPPORT_PLAN_HRM_SUBMITTED_FORMS_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SUPPORT_PLAN_HRM_SUBMITTED_FORMS_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SUPPORT_PLAN_HRM_SUBMITTED_FORMS_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getHRMSupportPlanSubmittedFormsListRequest = (payload) => ({
  type: ACTIONS.SUPPORT_PLAN_HRM_SUBMITTED_FORMS_LIST_CALL,
  payload,
});

export const getHRMTeamManagmentList = {
  pending: {
    type: ACTIONS.HRM_TEAM_MANAGMENT_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.HRM_TEAM_MANAGMENT_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.HRM_TEAM_MANAGMENT_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getHRMTeamManagmentRequest = (payload) => ({
  type: ACTIONS.HRM_TEAM_MANAGMENT_LIST_CALL,
  payload,
});

export const getSpSettingFetched = {
  pending: {
    type: ACTIONS.SP_SETTING_FETCHED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_SETTING_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_SETTING_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSpSettingFetchRequest = (payload) => ({
  type: ACTIONS.SP_SETTING_FETCHED_CALL,
  payload,
});

export const spSettingupdated = {
  pending: {
    type: ACTIONS.SP_SETTING_UPDATED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_SETTING_UPDATED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_SETTING_UPDATED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const spSettingUpdateRequest = (payload) => ({
  type: ACTIONS.SP_SETTING_UPDATED_CALL,
  payload,
});

export const workerHourlyRateSettingUpdated = {
  pending: {
    type: ACTIONS.HRM_WORKER_HOURLY_SETTING.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.HRM_WORKER_HOURLY_SETTING.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.HRM_WORKER_HOURLY_SETTING.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const workerHourlyRateSettingUpdateRequest = (payload) => ({
  type: ACTIONS.HRM_WORKER_HOURLY_SETTING_CALL,
  payload,
});

export const workerProfileDetailsFetched = {
  pending: {
    type: ACTIONS.HRM_WORKER_PROFILE_DETAIL.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.HRM_WORKER_PROFILE_DETAIL.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.HRM_WORKER_PROFILE_DETAIL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const workerProfileDetailsFetchRequest = (payload) => ({
  type: ACTIONS.HRM_WORKER_PROFILE_DETAIL_CALL,
  payload,
});

export const workerStatusUpdated = {
  pending: {
    type: ACTIONS.HRM_WORKER_STATUS_UPDATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.HRM_WORKER_STATUS_UPDATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.HRM_WORKER_STATUS_UPDATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const workerStatusUpdatedRequest = (payload) => ({
  type: ACTIONS.HRM_WORKER_STATUS_UPDATE_CALL,
  payload,
});

export const clearCrmHrmformstemplatesLocally = () => ({
  type: ACTIONS.CLEAR_CRM_HRM_FORMS_TEMPLATES_LOCALLY,
});

export const stripePricingTableList = {
  pending: {
    type: ACTIONS.STRIPE_PRICING_TABLE_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.STRIPE_PRICING_TABLE_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.STRIPE_PRICING_TABLE_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const stripePricingTableListRequest = () => ({
  type: ACTIONS.STRIPE_PRICING_TABLE_LIST_CALL,
});

export const stripeSaveCardData = {
  pending: {
    type: ACTIONS.STRIPE_SAVE_CARD_DATA.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data, callbackData) => ({
    type: ACTIONS.STRIPE_SAVE_CARD_DATA.SUCCESS,
    data,
    card: callbackData || null,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.STRIPE_SAVE_CARD_DATA.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const stripeSaveCardDataRequest = (payload, data) => ({
  type: ACTIONS.STRIPE_SAVE_CARD_DATA_CALL,
  payload,
  data,
});

export const stripeListAllCard = {
  pending: {
    type: ACTIONS.STRIPE_LIST_ALL_CARD.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.STRIPE_LIST_ALL_CARD.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.STRIPE_LIST_ALL_CARD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const stripeListAllCardRequest = () => ({
  type: ACTIONS.STRIPE_LIST_ALL_CARD_CALL,
});

export const stripeGetDefaultPaymentCard = {
  pending: {
    type: ACTIONS.STRIPE_DEFAULT_PAYMENT_METHOD.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.STRIPE_DEFAULT_PAYMENT_METHOD.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.STRIPE_DEFAULT_PAYMENT_METHOD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const stripeDefaultPaymentMethod = () => ({
  type: ACTIONS.STRIPE_DEFAULT_PAYMENT_METHOD_CALL,
});

export const stripeDeleteCardLocally = (data) => ({
  type: ACTIONS.STRIPE_DELETE_CARD_LOCALLY,
  data,
});

// Staff Compliance setting
export const spStaffComplianceSettings = {
  pending: {
    type: ACTIONS.SP_STAFF_COMPLIANCE_SETTING.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_STAFF_COMPLIANCE_SETTING.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_STAFF_COMPLIANCE_SETTING.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const spStaffComplianceSettingsRequest = () => ({
  type: ACTIONS.SP_STAFF_COMPLIANCE_SETTING_CALL,
});

export const spStaffComplianceSettingsUpdate = {
  pending: {
    type: ACTIONS.SP_STAFF_COMPLIANCE_SETTING_UPDATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SP_STAFF_COMPLIANCE_SETTING_UPDATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SP_STAFF_COMPLIANCE_SETTING_UPDATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const spStaffComplianceSettingsUpdateRequest = (data) => ({
  type: ACTIONS.SP_STAFF_COMPLIANCE_SETTING_UPDATE_CALL,
  payload: data,
});
