import { apiCall } from "./service";
import {
  URL_GET_ROSTER_INVOICES,
  URL_ROSTERING_CHANGE_TIMESHEET_STATUS,
  URL_ROSTERING_CHECK_PAYROLL_AUTHENTICATION,
  URL_ROSTERING_CREATE_TIMESHEET_COMMENT,
  URL_ROSTERING_EXPORT_TIMESHEET_TO_PAYROLL,
  URL_ROSTERING_LATEST_SHIFT_PARTICIPANTS,
  URL_ROSTERING_PARTICIPANT_LIST_FOR_TIMESHEETS,
  URL_ROSTERING_SHIFT,
  URL_ROSTERING_SHIFT_COPY,
  URL_ROSTERING_SHIFT_STATUS_CHANGE,
  URL_ROSTERING_SINGLE_TIMESHEET_DETAILS,
  URL_ROSTERING_TIMESHEET_COMMENTS,
  URL_ROSTERING_UPDATE_TIMESHEET_DATA,
  URL_ROSTERING_WORKER_AND_PARTICIPANT_LIST,
  URL_ROSTERING_WORKER_TIMESHEETS,
  URL_ROSTERING_WORKERS_TIMESHEET_EXPORT_LIST,
} from "./URL";

const token = localStorage.getItem("accessToken");

export const api = {
  getWorkerAndParticipantList(params) {
    return apiCall.post({
      url: URL_ROSTERING_WORKER_AND_PARTICIPANT_LIST,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  createWorkerShift(params) {
    return apiCall.post({
      url: URL_ROSTERING_SHIFT,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  updateWorkerShift(params) {
    return apiCall.patch({
      url: `${URL_ROSTERING_SHIFT}/${params.uuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  workerShiftDetails(params) {
    return apiCall.get({
      url: `${URL_ROSTERING_SHIFT}/${params.shiftUuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },

  deleteWorkerAndParticipantShift(params) {
    return apiCall.delete({
      url: `${URL_ROSTERING_SHIFT}/${params.shiftUuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },

  changeStatusWorkerAndParticipantShift(params) {
    return apiCall.post({
      url: `${URL_ROSTERING_SHIFT_STATUS_CHANGE}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  copyWorkerAndParticipantShift(params) {
    return apiCall.post({
      url: `${URL_ROSTERING_SHIFT_COPY}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  getParticipantListForTimesheet() {
    return apiCall.get({
      url: `${URL_ROSTERING_PARTICIPANT_LIST_FOR_TIMESHEETS}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  getWorkerTimeSheets(params) {
    return apiCall.post({
      url: `${URL_ROSTERING_WORKER_TIMESHEETS}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: params,
    });
  },
  getTimesheetComments(params) {
    return apiCall.get({
      url: `${URL_ROSTERING_TIMESHEET_COMMENTS}/${params.timeSheetUuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  createTimesheetComment(params) {
    return apiCall.post({
      url: `${URL_ROSTERING_CREATE_TIMESHEET_COMMENT}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  updateTimesheetData(params) {
    return apiCall.patch({
      url: `${URL_ROSTERING_UPDATE_TIMESHEET_DATA}/${params?.uuids.timeSheetUuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: params.data,
    });
  },
  changeTimesheetStatus(params) {
    return apiCall.post({
      url: `${URL_ROSTERING_CHANGE_TIMESHEET_STATUS}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  latestShiftParticipants() {
    return apiCall.get({
      url: `${URL_ROSTERING_LATEST_SHIFT_PARTICIPANTS}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  workerTimeSheetExportList(params) {
    return apiCall.post({
      url: `${URL_ROSTERING_WORKERS_TIMESHEET_EXPORT_LIST}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  singleTimesheetDetails(params) {
    return apiCall.get({
      url: `${URL_ROSTERING_SINGLE_TIMESHEET_DETAILS}?uuid=${params.uuid}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  checkPayrollAuthentication() {
    return apiCall.get({
      url: `${URL_ROSTERING_CHECK_PAYROLL_AUTHENTICATION}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  exportTimesheetToPayroll(params) {
    return apiCall.post({
      url: `${URL_ROSTERING_EXPORT_TIMESHEET_TO_PAYROLL}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  getRosterInvoicing(params) {

    return apiCall.get({
      url: `${URL_GET_ROSTER_INVOICES}?participant_id=${params?.participant_id?params?.participant_id : "" }&start_date=${params?.start_date}&end_date=${params?.end_date}&worker_id=${params?.worker_id ?params?.worker_id : "" }`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

};

export default api;
