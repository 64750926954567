import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { capitalizeFirstLetter, getNameProfile } from "Utils/utils";
import "./Select.css";
import { isArray } from "lodash";

const SelectOption = (props) => {
  const {
    data,
    selectedOption,
    handleChange,
    placeHolder,
    isSearch,
    name,
    cssClass,
    isDisabled,
    isMulti = false,
    defaultValue,
    isClearable = false,
    loading = false,
    showIcon = false,
    profileFieldMargin,
    register = null,
    isRequired = false,
    errorMessage = null,
    setValue = null,
    clearErrors,
    validate,
    noOptionsMessage = "No Options",
    setIsTimeChanged,
    setActualDate,
    isValueChanges,
  } = props;

  const [inComponentSelectedOpt, setInComponentSelectedOpt] = useState(null);

  useEffect(() => {
    if (
      selectedOption !== null &&
      selectedOption !== undefined &&
      selectedOption &&
      setValue &&
      register
    ) {
      if (isMulti && isArray(selectedOption) && selectedOption.length > 0) {
        setInComponentSelectedOpt(selectedOption);
        return;
      } else if (
        isMulti === false &&
        inComponentSelectedOpt &&
        inComponentSelectedOpt.value === selectedOption.value
      ) {
      } else if (isMulti === false && inComponentSelectedOpt === null) {
        setInComponentSelectedOpt(selectedOption);
      } else {
        setValue(name, null);
        setInComponentSelectedOpt(null);
      }
    } else if (
      (selectedOption == null || selectedOption === undefined) &&
      inComponentSelectedOpt !== null &&
      setValue &&
      register
    ) {
      setValue(name, null);
      setInComponentSelectedOpt(null);
    }

    if (register === null && setValue === null) {
      setInComponentSelectedOpt(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (
      setValue &&
      setValue !== null &&
      register &&
      register !== null &&
      inComponentSelectedOpt &&
      inComponentSelectedOpt.hasOwnProperty("value")
    ) {
      if (
        isValueChanges &&
        inComponentSelectedOpt.value !== selectedOption?.value
      ) {
        isValueChanges(true, inComponentSelectedOpt.value);
      }
      setValue(name, inComponentSelectedOpt.value);

      if (clearErrors && errorMessage && name) {
        clearErrors(name, "");
      }
    }
    // else if (
    //   setValue &&
    //   setValue !== null &&
    //   register &&
    //   register !== null &&
    //   inComponentSelectedOpt === null &&
    //   !loading &&
    //   !isDisabled
    // ) {
    //   setValue(name, null);
    //   // if (clearErrors && name) {
    //   //   clearErrors(name, "");
    //   // }
    // }
  }, [inComponentSelectedOpt]);

  const getIcon = (type) => {
    // console.log("here");
    return type;
  };

  const handleImageError = (e, fname, lname) => {
    // color=fff
    return (e.target.src = getNameProfile(fname, lname, "0D8ABC"));
  };

  const addRegisterComponent = () => {
    if (register && register !== null) {
      return {
        ...register(name, {
          value:
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null,
          // disabled: isDisabled,
          required: isRequired,
          validate: () => {
            return validate;
          },
        }),
      };
    } else {
      return;
    }
  };

  // const ValueContainer = (containerProps) => {
  //   const { children } = containerProps;
  //   if (children[0]) {
  //     const { length } = children[0];
  //     const tmpChildren = [...children];
  //     if (length >= 2) {
  //       tmpChildren[0] = `${length} selected`;
  //     }
  //     return (
  //       <components.ValueContainer {...containerProps}>
  //         {tmpChildren}
  //       </components.ValueContainer>
  //     );
  //   }
  //   return (
  //     <components.ValueContainer {...containerProps}>
  //       {children}
  //     </components.ValueContainer>
  //   );
  // };

  if (showIcon) {
    const CustomSelectValue = (csprops) => {
      return (
        <components.SingleValue {...csprops}>
          <div className='flex items-center capitalize'>
            {csprops.data.label && csprops.data.icon && (
              <img
                onError={(e) => handleImageError(e, csprops.data.label, " ")}
                className='w-7 h-7 bg-gray-300 rounded-full flex-shrink-0 object-cover mr-2'
                src={getIcon(csprops.data.icon || csprops.data.value)}
                alt=''
              />
            )}
            {csprops.data.label}
          </div>
        </components.SingleValue>
      );
    };

    const CustomOption = (props) => {
      const { data } = props;
      return (
        <components.Option {...props}>
          <div className='flex items-center capitalize'>
            {data.label && data.icon && (
              <img
                className='w-7 h-7 bg-gray-300 rounded-full flex-shrink-0 object-cover mr-2'
                src={getIcon(data.icon || data.value)}
                alt=''
              />
            )}
            {data.label}
          </div>
        </components.Option>
      );
    };

    return (
      <>
        <Select
          {...addRegisterComponent()}
          value={
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null
          }
          defaultValue={
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null
          }
          onChange={(e) => {
            if (handleChange) handleChange(e);
            else {
              setInComponentSelectedOpt(e);
            }
          }}
          options={data}
          components={{
            Option: CustomOption,
            SingleValue: CustomSelectValue,
          }}
          isSearchable={isSearch}
          isLoading={loading}
          isClearable={isClearable}
          isDisabled={isDisabled}
          placeholder={placeHolder}
          className={`w-100 roleSelector scrollOptions capitalize ${cssClass}`}
          noOptionsMessage={() => noOptionsMessage}
        />
        {errorMessage && (
          <span style={{ fontSize: "13px", color: "red", marginLeft: "5px" }}>
            {errorMessage}
          </span>
        )}
      </>
    );
  }

  return (
    <>
      {isMulti ? (
        <Select
          {...addRegisterComponent()}
          defaultValue={
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null
          }
          value={
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null
          }
          closeMenuOnSelect={false}
          // components={animatedComponents}
          isMulti
          options={data}
          onChange={(e) => {
            if (handleChange) handleChange(e);
            else {
              setInComponentSelectedOpt(e);
            }
          }}
          isDisabled={isDisabled}
          name={name}
          isClearable={isClearable}
          isSearchable={isSearch}
          placeholder={placeHolder}
          // isClearable={isClearable}
          className={`w-100 roleSelector scrollOptions capitalize ${cssClass}`}
          // hideSelectedOptions
          // components={{ ValueContainer }}
          noOptionsMessage={() => noOptionsMessage}
        />
      ) : (
        <Select
          {...addRegisterComponent()}
          isLoading={loading}
          isClearable={isClearable}
          isDisabled={isDisabled}
          name={name}
          getOptionLabel={(option) => capitalizeFirstLetter(option.label)}
          isSearchable={isSearch}
          options={data}
          blurInputOnSelect={true}
          menuPlacement='auto'
          placeholder={placeHolder}
          value={
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null
          }
          onChange={(e) => {
            if (handleChange) {
              if (setIsTimeChanged) {
                setIsTimeChanged(true);
              }
              if (setActualDate) {
                setActualDate(true);
              }
              handleChange(e);
            } else {
              setInComponentSelectedOpt(e);
              clearErrors(name, ""); 
            }
          }}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), input:(provided) => ({
            ...provided,
            width: '100%', 
            minWidth: '2px', 
          }), }}
          menuPortalTarget={document.body}
          className={`select-basic-single w-100 roleSelector scrollOptions  ${cssClass}`}
          noOptionsMessage={() => noOptionsMessage}
        />
      )}

      {errorMessage ? (
        <span
          style={{
            fontSize: "13px",
            color: "red",
            // marginLeft: "5px"
          }}
          className='capitalize'
        >
          {errorMessage}
        </span>
      ) : (
        ""
      )}
    </>
  );
};

export default SelectOption;
