import CircularLoader from 'components/common/CircularLoader/CircularLoader'
import TableHeader from 'components/common/Table/TableHeader'
import React from 'react';
import _ from 'lodash';
import { roleAccess } from 'helpers/RolesPermission';
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from 'store/constants';
import NumberFormatter from 'components/common/NumberFormatter/NumberFormatter';
import { textTruncate } from 'Utils/utils';

const FundingTable = ({flipSort,sortBy,sortDescending,reportList,loader,fundingData,isInitialRender=false}) => {

  return (
    <div className='overflow-x-auto custom-scroll'>
    <table className='border min-w-full divide-y divide-gray-300'>
      <thead
        // style={{ top: "130px" }}
        className='bg-gray-50 sticky z-[5]'
      >
        <tr>
          <TableHeader
           isSort={false}
            label={"Participant Name"}
          />
{
  roleAccess(
    [],
    PERMISSION_KEYS.PARTICIPANT,
    PERMISSION_KEYS_CHILDREN.PARTICIPANT.NDIS_NUMBER
  ) &&    <TableHeader
  isSort={false}
  label={"NDIS No"}
/>
}
       

          <TableHeader
           isSort={false}
            label={"Funding Start Date"}
          />

          <TableHeader
           isSort={false}
            label={"Funding End Date"}
          />

          <TableHeader
           isSort={false}
            label={"Total Funding"}
          />

          <TableHeader
         isSort={false}
            label={"Funding Remaining"}
          />

          <TableHeader
           isSort={false}
            label={"Funding Delivered"}
          />

          <TableHeader
            isSort={false}
            label={"Notes"}
      
          />
            <th
              className={`px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
            >
              <div>
                <tr className='h-28 mx-4'>
                <TableHeader
           isSort={false}
            label={"Service Name"}
          />
          <TableHeader
            isSort={false}
            label={"Service Total Budget"}
          />
          <TableHeader
             isSort={false}
            label={"Service Remaining"}
          />
          <TableHeader
             isSort={false}
            label={"Service Delivered"}
          />
                </tr>
              </div>
            </th>
         
        </tr>
      </thead>
      <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
        {loader ? (
          <tr className='bg-white'>
            <td colSpan={12} className='py-6 text-center bg-white'>
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            </td>
          </tr>
        ) : reportList !== null &&
        reportList?.fundings?.data?.length > 0 ? (
        reportList?.fundings?.data?.map(
            (fund, index) => (
              <tr
                key={index}
                className='even:bg-white odd:bg-gray-50'
              >
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate capitalize'>
                <div className='flex'>
                  {`${fund.participant?.first_name} ${fund.participant?.last_name}`}
                  {fund.participant?.status ? (
                    <div
                      className={`${
                        fund.participant?.status === "inactive"
                          ? "text-red-500"
                          : "text-green-500"
                      } flex items-center ml-2`}
                    >
                      <span
                        className={`${
                          fund.participant?.status === "inactive"
                            ? "bg-red-50"
                            : "bg-green-50"
                        } text-xs inline-flex items-center px-2 py-0.5 rounded-full font-medium`}
                      >
                        {fund.participant?.status === "inactive"
                          ? "In Active"
                          : "Active"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </td>
              {roleAccess(
                [],
                PERMISSION_KEYS.PARTICIPANT,
                PERMISSION_KEYS_CHILDREN.PARTICIPANT.NDIS_NUMBER
              ) && (
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                  {fund?.participant?.ndis_no || "-"}
                </td>
              )}
               <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                  {fund?.start_date || "-"}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                  {fund?.end_date || "-"}
                </td>
 <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                <NumberFormatter
                  cssClass={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } p-0 text-sm`}
                  prefix={"$"}
                  value={
                    fund?.total_service_contract_amount
                      ? fund?.total_service_contract_amount
                          .toString()
                          .replace("$", "")
                      : 0
                  }
                />
              </td>
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                <NumberFormatter
                  cssClass={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } p-0 text-sm`}
                  prefix={"$"}
                  value={
                    fund?.amount_remaining
                      ? fund?.amount_remaining.toString().replace("$", "")
                      : 0
                  }
                />
              </td>
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                <NumberFormatter
                  cssClass={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } p-0 text-sm`}
                  prefix={"$"}
                  value={
                    fund?.amount_delivered
                      ? fund?.amount_delivered.toString().replace("$", "")
                      : 0
                  }
                />
              </td>
              <td
                title={fund?.notes}
                className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate text-start'
              >
                {fund?.notes ?textTruncate(fund?.notes, 40, 39) : "-"}
              </td>
              <td>
                {fund?.categories &&
                  fund?.categories?.length > 0 &&
                  fund?.categories?.map((item, childIndex) => (
                    <div
                      className={`grid grid-cols-4   ${
                        fund?.categories.length > 1 &&
                        childIndex % 2 === 1 &&
                        " divide-y"
                      }`}
                      key={childIndex}
                    >
                      <div className=' text-sm text-gray-500 py-4 px-2'>
                        {item?.service_sub_category?.title}
                      </div>
                      <div className='whitespace-nowrap text-sm py-4  text-gray-500 truncate px-2'>
                        <NumberFormatter
                          cssClass={`${
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          } p-0 text-sm`}
                          prefix={"$"}
                          value={
                            item?.total_budget_amount
                              ? item?.total_budget_amount
                                  .toString()
                                  .replace("$", "")
                              : 0
                          }
                        />
                      </div>
                      <div className='whitespace-nowrap py-4  text-sm text-gray-500 truncate px-2'>
                        <NumberFormatter
                          cssClass={`${
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          } p-0 text-sm`}
                          prefix={"$"}
                          value={
                            item?.amount_remaining
                              ? item?.amount_remaining
                                  .toString()
                                  .replace("$", "")
                              : 0
                          }
                        />
                      </div>
                      <div className='whitespace-nowrap py-4 text-sm text-gray-500 truncate px-2'>
                        <NumberFormatter
                          cssClass={`${
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          } p-0 text-sm`}
                          prefix={"$"}
                          value={
                            item?.amount_delivered
                              ? item?.amount_delivered
                                  .toString()
                                  .replace("$", "")
                              : 0
                          }
                        />
                      </div>
                    </div>
                  ))}
              </td>
              </tr>
            )
          )
        ) : (
          <tr>
            <td
              colSpan={12}
              className='py-6 text-center text-red-600'
            >
              No data found. Please adjust your filters to get
              relevant results
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
  )
}

export default FundingTable
