import React, { useState, useEffect } from "react";
import {
  downloadFileFromServer,
  getFileExtension,
  getFileExtensions,
  getFileSize,
  isValidFile,
  maxFileSize,
  textTruncate,
} from "../../../Utils/utils";
import Style from "../FileUpload/FileUpload.module.css";
import { useDropzone } from "react-dropzone";
import { apiCall } from "store/services/service";
import { BASE_URL } from "store/services/URL";
import axios from "store/services/axios";
import FileImage from "assets/file.svg";
import fileUploadIcon from "../../../assets/svg/file-upload.svg";
import { PaperClipIcon } from "@heroicons/react/solid";
import { connect } from "react-redux";
import { getUser } from "../../../store/selector/auth.selector";
import * as FileSaver from "file-saver";
import { getFileExtensionForDocuments } from "Utils/utils";

const FileUploadInput = (props) => {
  const {
    updateFileName,
    type,
    handleFile,
    size,
    file,
    handleDelete,
    isDisabled = false,
    isVapourFileUpload = false,
    isFileUploadToS3 = false,
    handleFileUploadBaseVapour,
    handleError,
    handleClear,
    setFileExt,
    showDragAndDrop = true,
    currentLoginUser,
    isHardClearAllStates = false,
    clearParentError,
    selectedFile,
  } = props;

  const [fileError, setFileError] = useState(null);
  const [fileName, setFileName] = useState(file ? file.name : "");
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [showDragDrop, setShowDragDrop] = useState(showDragAndDrop);

  const [vapourFile, setVapourFile] = useState(null);

  useEffect(() => {
    clearAllStates();

    return () => {
      clearAllStates();
    };
  }, []);

  useEffect(() => {
    if (isHardClearAllStates) {
      setProgress(0);
      setShowProgress(false);
      if (file) setVapourFile(file);
      if (clearParentError) {
        clearParentError();
      }
    }
  }, [isHardClearAllStates]);

  useEffect(() => {
    if (isFileUploadToS3 && vapourFile && isVapourFileUpload) {
      uploadFileOnVapour(vapourFile);
    }
  }, [isFileUploadToS3, isVapourFileUpload, vapourFile]);

  useEffect(() => {
    if (file) setFileName(file.name);
    else {
      setShowDragDrop(false);
      setFileName("");
      setVapourFile(null);
      setProgress(0);
    }
  }, [file]);

  const clearAllStates = () => {
    setVapourFile(null);
    setFileError("");
    setFileName("");
    setProgress(0);
    setShowProgress(false);
  };

  const handleChange = (event) => {
    if (event.currentTarget.files && handleFile) {
      const file = event.currentTarget.files[0];
      if (file) {
        let message = isValidFile(file, type);
        if (message.length > 0) {
          handleRemove();
          setFileError(message);
          return;
        }
        message = maxFileSize(file, size ? size : 10);
        if (message.length > 0) {
          handleRemove();
          setFileError(message);
          return;
        }

        if (event.currentTarget.files[0].name.length > 150) {
          handleRemove();
          setFileError(
            "File name exceeds the limit. It's up to 150 characters!"
          );
          return;
        }

        setFileError("");
        setFileName(event.currentTarget.files[0].name);
        handleFile(event.currentTarget.files[0]);
        setVapourFile(event.currentTarget.files[0]);
      }
    }
  };

  const handleRemove = () => {
    if (handleDelete) handleDelete();
    setFileName("");
    setVapourFile(null);
    setShowDragDrop(showDragAndDrop);
  };

  const onDrop = (uploadFiles) => {
    if (uploadFiles && uploadFiles[0] && handleFile) {
      const file = uploadFiles[0];
      if (file) {
        let message = isValidFile(file, type);
        if (message.length > 0) {
          handleRemove();
          setFileError(message);
          return;
        }
        message = maxFileSize(file, size ? size : 10);
        if (message.length > 0) {
          handleRemove();
          setFileError(message);
          return;
        }

        if (uploadFiles[0].name.length > 150) {
          handleRemove();
          setFileError(
            "File name exceeds the limit. It's up to 150 characters!"
          );
          return;
        }
        setFileError("");
        setFileName(uploadFiles[0].name);
        setShowDragDrop(false);
        handleFile(uploadFiles[0]);
        setVapourFile(uploadFiles[0]);
      }
    }
  };

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
    disabled: isDisabled,
  });

  const uploadFileOnVapour = (file) => {
    if (!file) return;

    apiCall
      .post({
        url: `${BASE_URL}signed-url`,
        params: { contentType: file.type },
        isAuthToken: currentLoginUser?.AccessToken,
      })
      .then((response) => {
        storeFileOnS3(file, {}, response);
        if (setFileExt) {
          setFileExt(response.key);
        }
      })
      .catch((err) => {
        console.error("Error getting signed URL:", err);
        if (handleError) handleError();
      });
  };

  const storeFileOnS3 = (file, options = {}, response) => {
    const headers = response.headers;
    if ("Host" in headers) {
      delete headers.Host;
    }
    if (typeof options.progress === "undefined") {
      options.progress = () => {};
    }
    const cancelToken = options.cancelToken || "";
    axios
      .put(response.url, file, {
        cancelToken: cancelToken,
        headers: headers,
        onUploadProgress: (progressEvent) => {
          options.progress(progressEvent.loaded / progressEvent.total);
          setShowDragDrop(false);
          setShowProgress(true);
          setProgress((progressEvent.loaded / progressEvent.total) * 100);
        },
      })
      .then(() => {
        setProgress(100);
        setVapourFile(null);

        handleFileUploadBaseVapour({
          key: response.key,
          ext: file.name.split(".").pop(),
          name: file.name,
        });
        if (setFileExt) {
          setFileExt(response.key);
        }
      })
      .catch((e) => {
        console.error("Error uploading file to S3:", e);
        if (handleError) handleError();
      });
  };

  const progressInstance = (
    <div
      className={`w-full bg-gray-200 rounded-full dark:bg-gray-700 ${
        fileName ? "" : "mt-4"
      }`}
    >
      <div
        className='animate-pulse transition duration-300 ease-in-out bg-purple-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full'
        style={{ width: `${progress}%` }}
      >
        {progress.toFixed(0)}%
      </div>
    </div>
  );

  const handleDownload = () => {
    if (file && file instanceof File) {
      const blob = new Blob([file], { type: file.type });
      FileSaver.saveAs(blob, file.name || "downloadedFile");
    }
  };

  return (
    <>
      <div
        {...getRootProps()}
        style={{
          position: "relative",
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "10px",
          backgroundColor: "white",
        }}
      >
        <input
          {...getInputProps()}
          disabled={isDisabled}
          onChange={handleChange}
        />
        <div
          className={`${
            showDragAndDrop
              ? "border-2 border-dashed border-gray-300 rounded-lg px-6 pt-5 pb-6"
              : ""
          } max-w-full`}
        >
          <div
            className={`${
              showDragAndDrop
                ? "space-y-1 text-center"
                : "text-center flex items-center gap-2 truncate"
            }`}
          >
            {showDragAndDrop ? (
              <>
                <svg
                  className='mx-auto h-12 w-12 text-gray-400'
                  stroke='currentColor'
                  fill='none'
                  viewBox='0 0 48 48'
                  aria-hidden='true'
                >
                  <path
                    d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <div className='flex text-sm text-gray-600'>
                  <label
                    htmlFor='file-upload'
                    className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                  >
                    <span>Upload a file</span>
                    <input
                      disabled={isDisabled}
                      {...getInputProps()}
                      onChange={handleChange}
                    />
                  </label>
                  <p className='pl-1'>or drag and drop</p>
                </div>
              </>
            ) : (
              <div className='flex items-center'>
                {" "}
                {/* Added container for icon and text */}
                <img
                  src={fileUploadIcon}
                  alt='file-upload'
                  className='mr-1'
                />{" "}
                {/* Added margin-right */}
                <p className='text-xs text-gray-500'>
                  {`${getFileExtensions(type)
                    .join(",")
                    .toString()
                    .toUpperCase()} up to ${size ? size : "10"}MB`}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {!fileError && fileName && (
        <>
          {showDragAndDrop ? (
            <div className='border rounded-lg border-gray-300 px-2 mt-2'>
              <li className='py-3 flex justify-between text-sm'>
                <div className='flex-1 flex items-center '>
                  <div className='bg-blue-50 rounded-full h-10 w-10'>
                    <img
                      src={FileImage}
                      alt=''
                      className='flex-shrink-0 h-7 w-7 pt-[10px] pl-[14px] cursor-pointer'
                    />
                  </div>

                  <div>
                    <span
                      onClick={handleDownload}
                      style={{ fontWeight: "500" }}
                      className='ml-2 flex-1 w-0 font-medium hover:underline cursor-pointer'
                      title={fileName}
                    >
                      {textTruncate(fileName, 30, 29)}
                    </span>
                    <div>
                      {file && (
                        <span className='text-sm font-normal ml-2 text-gray-500'>
                          {getFileSize(file)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className=' ml-4 flex-shrink-0'>
                  <span
                    role='button'
                    tabIndex={0}
                    onClick={handleRemove}
                    target='_blank'
                    className='font-medium text-red-500 hover:text-red-600'
                  >
                    Remove
                  </span>
                </div>
              </li>
              <div className='px-10 mb-4 '>
                {showProgress && progress > 0 && (
                  <span>{progressInstance}</span>
                )}
              </div>
            </div>
          ) : (
            <div>
              <li className='py-2 flex items-center justify-between text-sm'>
                <div className='flex-1 flex items-center'>
                  <PaperClipIcon
                    className='flex-shrink-0 h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                  <span
                    onClick={handleDownload}
                    style={{ fontWeight: "500" }}
                    className='ml-2 flex-1 truncate cursor-pointer hover:underline'
                    title={fileName}
                  >
                    {textTruncate(fileName, 18, 17)}
                  </span>
                  {file && (
                    <span className='font-bold'>{getFileSize(file)}</span>
                  )}
                </div>
                <div className=' ml-4 flex-shrink-0'>
                  <span
                    role='button'
                    tabIndex={0}
                    onClick={handleRemove}
                    target='_blank'
                    className='font-medium text-blue-600 hover:text-blue-500'
                  >
                    Remove
                  </span>
                </div>
              </li>
            </div>
          )}
        </>
      )}
      {fileError && (
        <div className='py-3 flex items-center justify-between text-sm'>
          <span className={`borderError ${Style.fileSizeError}`}>
            {fileError}
          </span>
        </div>
      )}
      {updateFileName && !fileError && !fileName && (
        <div
          onClick={() => {
            if (file && !(file instanceof File)) {
              downloadFileFromServer(
                file,
                `${updateFileName}.${getFileExtension(file)}`
              );
            }
          }}
          title={
            file
              ? `${updateFileName}.${getFileExtension(file)}  `
              : `${updateFileName}.${getFileExtensionForDocuments(
                  selectedFile?.link,
                  30,
                  29
                )}`
          }
          className='ml-2 flex-1 w-full font-medium hover:underline cursor-pointer'
        >
          {file
            ? textTruncate(
                `${updateFileName}.${getFileExtension(file)}`,
                30,
                29
              )
            : textTruncate(
                `${updateFileName}.${getFileExtensionForDocuments(
                  selectedFile?.link
                )}`,
                30,
                29
              )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const currentLoginUser = getUser(state);

  return { currentLoginUser };
};

export default connect(mapStateToProps, null)(FileUploadInput);
