import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { GoPrimitiveDot } from "react-icons/go";
import "./NotificationDropDown.css";
import { FaRegBell } from "react-icons/fa";
import { apiGetRequest, apiPostRequest } from "../../../helpers/Requests";
import Loader from "../Loader/Loader";
import { baseURLImage, getNameProfile } from "../../../Utils/utils";
import { useNavigate } from "react-router";
import pusher from "./Pusher";
import { connect } from "react-redux";
import { getUser } from "../../../store/selector/auth.selector";
import { USER_ROLE } from "../../../Constants/constant";
import { roleAccess } from "../../../helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const NotificationDropDown = ({ currentLoginUser }) => {
  const [notfications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [type, setType] = useState("unread");
  const [blink, setBlink] = useState(null);
  const [totalNotifications, setTotalNotifications] = useState(0);

  const [notificationsOnPage, setNotificationPage] = useState(1);
  const [unseenNotification, setUnseenNotification] = useState(
    JSON.parse(localStorage.getItem("user"))?.has_unseen_notification
  );

  const navigate = useNavigate();
  const dropdownRef = React.useRef(null);
  const scrollRef = React.useRef(null);

  //to use diffrent api for worker
  const apiURL = roleAccess([USER_ROLE.Sp_Standard_User]) ? "hrm" : "sp";

  //get notifications
  const getNotifications = async (type = "unread", notificationsOnPage) => {
    localStorage.removeItem("pusherData");

    setBlink(null);
    try {
      setIsLoading(true);

      const res = await apiGetRequest(
        `${apiURL}/notifications?type=${type}&page=${notificationsOnPage}`
      );
      if (res && res.status === 200) {
        setNotifications(res.data?.notifications?.data);
        scrollRef.current.scrollTop = 0;
        setTotalNotifications(res.data?.notifications.total_pages);
        // setType("all");
      }
      setIsLoading(false);
    } catch (Err) {
      setIsLoading(false);
      console.log(Err);
    }
  };

  /* to set back the list to default list of notifications*/
  useEffect(() => {
    const closeDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNotificationPage(1); // Reset the notificationsOnPage state to 1 when dropdown is closed
        setType("unread");
      }
    };

    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  /*for view of notifications*/
  // const notificationsToMap = useMemo(() => {
  //   if (type !== "all") return notfications.filter((x) => x.read_at === null);
  //   return notfications;
  // }, [type, notfications]);

  useEffect(() => {
    const ViewedIds =
      notfications && notfications?.map((item) => item?.notification_id);
    if (ViewedIds?.length > 0) {
      try {
        const res = apiPostRequest(`/${apiURL}/seen-notifications`, {
          ids: ViewedIds,
        });
        res.then((res) => {
          if (res.status === 200) {
            if (res.data) {
              setUnseenNotification(res.data?.data?.has_unseen_notification);
              const userObjectString = localStorage.getItem("user");

              const userObject = JSON.parse(userObjectString);

              userObject.has_unseen_notification =
                res.data?.data?.has_unseen_notification; // Set the new value here

              const updatedUserObjectString = JSON.stringify(userObject);

              localStorage.setItem("user", updatedUserObjectString);
            }
          }
        });
      } catch (err) {
        console.log("err on read notification", err);
      }
    }
  }, [notfications, apiURL]);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  //For pusher Notification 
  useEffect(() => {
    const channel = pusher.subscribe(`notification-${currentLoginUser?.uuid}`);
    channel.bind("notify", (data) => {
      if (data && data?.message && data?.message !== "undefined") {
        setBlink(data?.channel);
        localStorage.setItem("pusherData", JSON.stringify(data?.message));
      }
    });

    return () => {
      pusher.unsubscribe(`notification-${currentLoginUser?.uuid}`);
    };
  });

  /*handle to redirect the user on notification action */
  const handleRedirection = (item) => {
    if (item.read_at === null) {
      ReadNotification(item?.notification_id);
    }
    switch (item.data?.type) {
      case "crm_form":
        if (
          !roleAccess([USER_ROLE.Sp_Standard_User]) &&
          roleAccess(
            [],
            PERMISSION_KEYS.CRM_FORM,
            PERMISSION_KEYS_CHILDREN.CRM_FORM.UPDATE
          )
        ) {
          navigate(`/edit-template/${item.data?.slug}`);
        }
        break;
      case "worker":
        if (
          !roleAccess([USER_ROLE.Sp_Standard_User]) &&
          roleAccess(
            [],
            PERMISSION_KEYS.HRM,
            PERMISSION_KEYS_CHILDREN.HRM.WORKER.SHOW
          )
        ) {
          navigate(`/worker-profile/${item.data?.link_id}`);
        }
        break;
      case "participant":
        roleAccess([USER_ROLE.Sp_Standard_User])
          ? navigate(`/participant-profile/${item.data?.link_id}`)
          : roleAccess(
              [],
              PERMISSION_KEYS.PARTICIPANT,
              PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
            ) && navigate(`/sp-profile/${item.data?.link_id}`);
        break;
      case "feedback":
        roleAccess([USER_ROLE.Sp_Standard_User])
          ? navigate("/feedback")
          : roleAccess([], PERMISSION_KEYS.FEEDBACK) && navigate("/feedbacks");
        break;
      case "complaint":
        roleAccess([USER_ROLE.Sp_Standard_User])
          ? navigate("/complaint")
          : roleAccess([], PERMISSION_KEYS.COMPLAINT) &&
            navigate("/complaints");
        break;
      case "question":
        roleAccess([USER_ROLE.Sp_Standard_User])
          ? navigate("/ask-a-question")
          : roleAccess([], PERMISSION_KEYS.ASK_QUESTION) &&
            navigate("/askquestion");
        break;
      case "policy":
        roleAccess([USER_ROLE.Sp_Standard_User])
          ? navigate("/employee-policies")
          : roleAccess([], PERMISSION_KEYS.POLICy) && navigate("/policies");
        break;

      case "participant_form":
        roleAccess([USER_ROLE.Sp_Standard_User])
          ? navigate(
              `/participant-profile/${item.data?.category_slug}/${item.data?.form_id}/view`
            )
          : roleAccess(
              [],
              PERMISSION_KEYS.PARTICIPANT,
              PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
            ) &&
            navigate(
              `/sp-profile/${item.data?.category_slug}/${item.data?.form_id}/view`
            );
        break;
      case "card":
        navigate("/subscription?tab=card");
        break;
      case "choose_plan":
        navigate("/subscription/plans");
        break;
      case "subscription_banner":
        navigate("/subscription");
        break;
      default:
        break;
    }
  };

  /*Read Notification*/
  const ReadNotification = async (id) => {
    const arrayOfId = [id];
    try {
      const res = await apiPostRequest(`/${apiURL}/read-notifications`, {
        ids: arrayOfId,
      });
      if (res.status === 200) {
        // setType("all");
        getNotifications(notificationsOnPage);
      }
    } catch (err) {
      console.log("err on read notification", err);
    }
  };

  /*view more*/
  const handleViewMore = React.useCallback(async () => {
    if (notificationsOnPage !== totalNotifications) {
      setNotificationPage(notificationsOnPage + 1);
      getNotifications(type, notificationsOnPage + 1);
    }
  }, [notificationsOnPage, totalNotifications, type]);

  /*view less*/
  const handleViewLess = React.useCallback(async () => {
    if (notificationsOnPage > 0 && notificationsOnPage !== 1) {
      setNotificationPage(notificationsOnPage - 1);
      getNotifications(type, notificationsOnPage - 1);
    }
  }, [notificationsOnPage, apiURL, type]);
  return (
    <Menu
      as='div'
      className='mt-1 relative inline-block  text-left'
      ref={dropdownRef}
    >
      <div>
        <Menu.Button>
          <div
            className='relative'
            onClick={() => getNotifications(type, notificationsOnPage)}
          >
            <FaRegBell className='h-6 w-6' />
            {((localStorage.getItem("pusherData") &&
              localStorage.getItem("pusherData") !== null &&
              JSON.parse(localStorage.getItem("pusherData"))) ||
              blink ||
              unseenNotification) && (
              <div className='absolute bg-green-500 h-2.5 w-2.5 rounded-full top-0 right-0 text-white text-sm text-center'></div>
            )}
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='w-[330px]  origin-top-right absolute z-50 right-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div id='notificationContainer' className='py-1'>
            <div className='flex justify-between items-center px-4 py-2'>
              <p className='text-xl font-semibold text-gray-900 py-2'>
                Notifications
              </p>
              {/* <span>
                <BsThreeDots />

              </span> */}
            </div>
            {/* <Menu.Item> */}
            <div className='py-1 px-4 mb-1 space-x-4'>
              <span
                role='button'
                tabIndex={0}
                onClick={() => {
                  setType("unread");
                  getNotifications("unread", 1);
                  setNotificationPage(1);
                }}
                className={`inline-flex items-center px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-full shadow-sm text-blue-900 bg-blue-100 hover:bg-blue-200 
              ${
                type === "unread"
                  ? "outline-none ring-2 ring-offset-2 ring-blue-500"
                  : ""
              }`}
              >
                Unread
              </span>
              <span
                role='button'
                tabIndex={0}
                onClick={() => {
                  setType("all");
                  getNotifications("all", 1);
                  setNotificationPage(1);
                }}
                className={`inline-flex items-center px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-full shadow-sm text-blue-900 bg-blue-100 hover:bg-blue-200 
              ${
                type === "all"
                  ? "outline-none ring-2 ring-offset-2 ring-blue-500"
                  : ""
              }`}
              >
                All
              </span>
            </div>
            <ul className='notificatio-height ' ref={scrollRef}>
              {isLoading && notfications && notfications?.length <= 0 ? (
                <li className='flex items-center justify-center mt-3 py-1 px-1.5'>
                  <Loader cssClass='text-black' />
                </li>
              ) : (
                notfications &&
                notfications.length > 0 &&
                notfications?.map((item, i) => (
                  <li key={i} className='py-1 px-1.5'>
                    <span
                      onClick={() => handleRedirection(item)}
                      role='button'
                      tabIndex={0}
                      className={`rounded-md ${
                        item?.read_at === null ? "bg-blue-100" : " "
                      } hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm`}
                    >
                      <div className='flex space-x-3'>
                        <img
                          onError={(e) =>
                            handleImageError(
                              e,
                              item.participant
                                ? item.participant?.first_name
                                : item.data.name?.split(" ")[0],
                              item.participant
                                ? item.participant?.last_name || " "
                                : item.data.name?.split(" ")[1] || " "
                            )
                          }
                          className='h-10 w-10 rounded-full object-cover'
                          src={
                            item?.participant &&
                            item?.participant?.profile_picture
                              ? baseURLImage(item.participant?.profile_picture)
                              : item.data?.profile_picture
                              ? baseURLImage(item.data?.profile_picture)
                              : getNameProfile(
                                  item.participant &&
                                    item.participant?.first_name
                                    ? item.participant?.first_name
                                    : item.data.name?.split(" ")[0],
                                  item.participant &&
                                    item.participant?.last_name
                                    ? item.participant?.last_name || " "
                                    : item.data.name?.split(" ")[1] || " "
                                )
                          }
                          alt=''
                        />

                        <div className='flex-1'>
                          <div className='flex items-center justify-between'>
                            <div>
                              <h3 className='capitalize font-bold text-sm font-medium'>
                                {item.participant && item.participant.first_name
                                  ? `${item.participant.first_name} ${item.participant.last_name}`
                                  : item.data.name}
                              </h3>

                              <p className='text-sm text-gray-500 capitalize'>
                                {item.data.form_title}
                              </p>
                              <p
                                className={`text-sm ${
                                  item.read_at === null &&
                                  item.data?.severity === "h"
                                    ? "font-extrabold notification-blink"
                                    : item.read_at === null
                                    ? "font-extrabold"
                                    : item.read_at === null &&
                                      item.data?.severity === "m"
                                    ? "text-red-500"
                                    : "text-gray-500"
                                } message-text`}
                                dangerouslySetInnerHTML={{
                                  __html: item.data.message,
                                }}
                              />
                            </div>
                            {/* {item?.read_at === null && (
                              <p className="text-sm text-gray-500">
                                <GoPrimitiveDot className="text-blue-700 h-4 w-4" />
                              </p>
                            )} */}
                          </div>
                          <p className='text-sm text-gray-500'>
                            {item.data.date}
                          </p>
                        </div>
                      </div>
                    </span>
                  </li>
                ))
              )}
              {!isLoading && notfications && notfications?.length <= 0 && (
                <li className='flex items-center justify-center mt-3 py-1 px-1.5'>
                  {type === "unread"
                    ? "You've read it all!"
                    : "There are no notifications!"}
                </li>
              )}
            </ul>

            <div className='flex items-center justify-evenly mt-1 py-1 px-1.5'>
              {notfications && notfications?.length > 0 && (
                <button
                  className={`${
                    notificationsOnPage === 1
                      ? "opacity-40 "
                      : "hover:underline"
                  }   text-blue-900 font-medium text-sm  bg-blue-200 px-4 py-1 rounded-md shadow-sm transition-colors duration-200 ease-in-out hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                  onClick={handleViewLess}
                  disabled={notificationsOnPage === 1 ? true : false}
                >
                  <span className='flex items-center '>
                    <svg
                      className='w-4 h-4 ml-1 text-blue-900'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path d='M19 12H5M12 19L5 12l7-7' />
                    </svg>
                    Previous
                  </span>
                </button>
              )}
              {notfications && notfications?.length > 0 && (
                <button
                  className={` ${
                    notificationsOnPage === totalNotifications
                      ? "opacity-40"
                      : "hover:underline"
                  } text-blue-900 font-medium text-sm  bg-blue-200 px-8 py-1 rounded-md shadow-sm transition-colors duration-200 ease-in-out hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                  onClick={handleViewMore}
                  disabled={
                    notificationsOnPage === totalNotifications ? true : false
                  }
                >
                  <span className='flex items-center'>
                    Next
                    <svg
                      className='w-4 h-4 ml-1 text-blue-900'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='currentColor'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path d='M5 12h14M12 5l7 7-7 7' />
                    </svg>
                  </span>
                </button>
              )}
            </div>

            {/* </Menu.Item> */}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const mapStateToProps = (state) => {
  const currentLoginUser = getUser(state);
  return { currentLoginUser };
};

export default connect(mapStateToProps, null)(NotificationDropDown);
