import React from "react";
import { CheckIcon } from "@heroicons/react/solid";
import "./Stepper.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Stepper = ({ template, onStepClickHandler }) => {
  return (
    <nav aria-label="Progress">
      <ol className="flex items-center">
        {template.map((step, stepIdx) => (
          <li
            key={stepIdx}
            className={classNames(
              stepIdx !== template.length - 1 ? "pr-10" : "",
              "relative"
            )}
          >
            {step.status === "complete" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center "
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-blue-dark" />
                </div>
                <button
                  type="button"
                  onClick={() => onStepClickHandler(stepIdx)}
                  className="tooltip relative w-8 h-8 flex items-center justify-center bg-blue-dark rounded-full hover:bg-blue-dark"
                >
                  <CheckIcon
                    className="w-5 h-5 text-white absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                  <span className="tooltiptext absolute z-50">
                    {step.section_title}
                  </span>
                </button>
              </>
            ) : step.status === "current" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <div className="flex flex-col relative">
                  <button
                    type="button"
                    style={{ border: "1px solid blue" }}
                    className="tooltip relative w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-dark rounded-full"
                    onClick={() => onStepClickHandler(stepIdx)}
                  >
                    <div
                      className="h-2.5 w-2.5 bg-blue-dark rounded-full absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                      aria-hidden="true"
                    ></div>
                  </button>
                  {/* <span className="tooltiptext absolute z-10 top-10 -left-1/2 bg-white border shadow rounded-lg p-2">
                    {step.section_title}
                  </span> */}
                </div>
              </>
            ) : step.status === "active" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center "
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-green-600" />
                </div>
                <button
                  type="button"
                  onClick={() => onStepClickHandler(stepIdx)}
                  className="tooltip relative w-8 h-8 flex items-center justify-center bg-green-600 rounded-full hover:bg-green-600"
                >
                  <CheckIcon
                    className="w-5 h-5 text-white absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                  <span className="tooltiptext">{step.section_title}</span>
                </button>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center "
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <button
                  disabled
                  style={{ border: "1px solid #D3D3D3" }}
                  className="tooltip group relative w-8 h-8 flex items-center justify-center bg-white rounded-full hover:border-gray-400"
                >
                  <div
                    className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300 bg-gray-200 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                    aria-hidden="true"
                  ></div>
                  <span className="sr-only">{step.name}</span>
                  <span className="tooltiptext">{step.section_title}</span>
                </button>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Stepper;
