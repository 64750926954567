import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";
import Search from "components/common/Search/Search";

import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  emptyPaginationObj,

  REPORTING_LIMIT,
  SORT_TYPE,
  
  StatusOptions,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiPostRequest } from "helpers/Requests";
import { URL_REPORTING_PARTICIPANT_REPORTS } from "store/services/URL";
import moment from "moment";
import { FiDelete } from "react-icons/fi";
import _ from "lodash";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { toast } from "react-toastify";
import {exportTOExcel } from "Utils/utils";

const FundingReports = ({
  getTeamManagmentListCall,
  teamManagementList,
  getParticipantList,
  spParticipants,
}) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [selectedWorker, setSelectedWorker] = useState(null);

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
    getTeamManagmentListCall({ filters, bodyFilter: null });
  }, []);

  const [workerList, setWorkerList] = useState([
    { label: "All", value: "all" },
  ]);
  const [participantList, setParticipant] = useState([
    { label: "All", value: "all" },
  ]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [status, setStatus] = useState(null);
  const [BirthDate, setBirthDate] = useState(null);
  const [primaryDisability, setPrimaryDisability] = useState(null);
  const [monthOfBorn, setMonthOfBorn] = useState(null);
  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [state, setState] = useState(null);
  const [suburb, setSuburb] = useState("");
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedFromDate,setSelectedFromDate]=useState(null)

  useEffect(() => {
    const Workers =
      teamManagementList?.employees?.data?.map((item) => ({
        label: `${item?.first_name} ${item?.last_name}`,
        value: item?.id,
      })) || [];
    setWorkerList(Workers);
    const participants =
      spParticipants?.participant?.data?.map((item) => ({
        label: `${item?.first_name} ${item?.last_name}`,
        value: item?.id,
      })) || [];
    setParticipant(participants);
  }, [teamManagementList, spParticipants]);

  const handleCancelButton = () => {
    setBirthDate(null);
  };

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    fetchParticipantListReports();
  }, [page]);

  const fetchParticipantListReports = async (
    isClearAll = false,
    report = null,
    filters = null
  ) => {
    if (!fromDate && !toDate) {
      toast("Select create date range to get data");
      return;
    }


    setLoader(true);
    let url = `${URL_REPORTING_PARTICIPANT_REPORTS}?per_page=10&page=${page}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_PARTICIPANT_REPORTS}?per_page=10&page=${page}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",
      participant: isClearAll
        ? "all"
        : selectedParticipant
        ? selectedParticipant?.value
        : "all",
      worker: isClearAll
        ? "all"
        : selectedWorker
        ? selectedWorker?.value
        : "all",
      status: isClearAll ? "all" : status ? status.value : "all",
      dob: isClearAll ? "all" : BirthDate ? BirthDate : "all",
      primary_disability: isClearAll
        ? "all"
        : primaryDisability
        ? primaryDisability?.value
        : "all",
      created_date: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
      born_month: isClearAll ? "all" : monthOfBorn ? monthOfBorn?.value : "all",
      suburb: suburb ? suburb : "",
      state: isClearAll ? "all" : state ? state?.value : "all",
    };
    if (report) {
      payload.report = report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            window.open(`${DOC_DOWNLOAD_URL}${res?.data?.data}`, "_blank");
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.participants?.total,
              totalPages: Math.ceil(
                res?.data?.data?.participants?.total /
                  res?.data?.data?.participants?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    if (e === "xlsx") {
      exportTOExcelFile();
    } else {
      fetchParticipantListReports(false, e);
    }
  };

  const exportTOExcelFile = () => {
    const transformedData =
      reportList &&
      reportList.participants &&
      reportList?.participants?.data?.map((item) => {
        const workersString =
          item.workers && item?.workers?.length > 0
            ? item.workers
                .map((worker) => `${worker.first_name} ${worker.last_name}`)
                .join(", ")
            : "";

        return {
          ...item,

          workers: workersString,
        };
      });
    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchParticipantListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <div className='min-h-full bg-red-500'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header title='Funding Reports' />
          <div className='min-w-full mx-auto my-6'>
          <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.FUNDING_REPORTS
                )}
              />
              <div className="flex  items-end gap-x-2">
              <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by name(Start typing participant name)
                  </label>
                  <Search setSearch={setSearch} search={search} width="w-full" />
                </div>
                <div>
                <SelectBox
                  isUserGracePeriodOver={isUserGracePeriodOver}
                  isLoading={loader}
                  setReportType={(e) => {
                    if (isUserGracePeriodOver) {
                      return;
                    }
                    handleFileDownLoad(e);
                  }}
                />
                </div>
              </div>
            </div>
            {/* <div className='sm:px-6 lg:px-8 flex d-flex justify-between py-1'>
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.PARTICIPANT_LIST_REPORTS
                )}
              />
              <div>
                <SelectBox
                  isUserGracePeriodOver={isUserGracePeriodOver}
                  isLoading={loader}
                  setReportType={(e) => {
                    if (isUserGracePeriodOver) {
                      return;
                    }
                    handleFileDownLoad(e);
                  }}
                />
              </div>
            </div> */}
            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                
                <section>
                  <div className='flex pt-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant Name
                        </label>
                        <SelectOption
                          name='pt_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedParticipant}
                          handleChange={(e) => {
                            setSelectedParticipant(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                         Plan Start Date
                        </label>
                        <SelectOption
                          name='worker_id'
                          isSearch={true}
                          data={workerList || []}
                          selectedOption={selectedWorker}
                          handleChange={(e) => {
                            setSelectedWorker(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                        Funding Start Date
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={StatusOptions || []}
                          handleChange={(e) => {
                            setStatus(e);
                          }}
                          selectedOption={status}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                        Plan Catogery
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={StatusOptions || []}
                          handleChange={(e) => {
                            setStatus(e);
                          }}
                          selectedOption={status}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='flex py-2 w-full justify-between items-end space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label
                          htmlFor='from-date-to-to-date'
                          className='block text-sm font-medium text-gray-700'
                        >
                          External Service Provider
                        </label>
                        <DateRangePickerNew
                          isSingleDate
                          isClearable
                          handleCancel={handleCancelButton}
                          handleChange={(obj) => {
                            setBirthDate(obj);
                          }}
                          singleSelectedDate={BirthDate && new Date(BirthDate)}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                     
                    </div>
                    <div className='w-full'>
                 
                    </div>
                    <div className='w-full'>
                    <div className='w-full'>
                    <div
                      className='flex d-flex justify-end  space-x-2'
                    
                    >
                      <CustomButton
                        Icon={<BsFilterCircle />}
                        clickHandler={() => fetchParticipantListReports(false)}
                        label='Search'
                        showLoading={loader || false}
                        isDisabled={loader || false}
                      />
                      <CustomButton
                        isDisabled={loader || false}
                        Icon={<FiDelete />}
                        clickHandler={() => {
                          setBirthDate(null);
                          setToDate(null);
                          setFromDate(null);
                          setMonthOfBorn(null);
                          setStatus(null);
                          setSelectedWorker(null);
                          setPrimaryDisability(null);
                          setSearch(null);
                          setReports([]);
                          setState(null);
                          setSuburb("");
                          setSelectedParticipant(null);
                        }}
                        label='Clear Filter'
                        cssClass='whitespace-nowrap'
                      />
                    </div>
                  </div>
                    </div>
                  </div>
                </section>
                {/* <section>
                  <div className='flex py-2 w-full justify-between space-x-2'>
                
                   
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                         Participant Created Date Range (Max 3 Months) <span className="text-red-400">*</span>
                        </label>
                        <DateRangePickerNew
                          // isDisabled={clientsListLoader}
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                          }}
                          handleChange={(obj) => {
                            if (obj ) {
                             obj.fromDate && setFromDate(obj.fromDate);
                             obj.toDate && setToDate(obj.toDate);

                            
                            }
                          }}
                         
                          setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          maxDate={addMonths(selectedFromDate, 3)}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                    </div>
                    <div className='w-full'>
                    </div>

                   
                  </div>
                </section> */}
              
              </div>
              <div className='overflow-x-auto custom-scroll'>
                <table className='border divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader
                        sortName='funding_id'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Funding Id"}
                      />

                      <TableHeader
                        sortName='participant'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Participant Name"}
                      />

                      <TableHeader
                        sortName='plan_start_date'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Plan start Date"}
                      />

                      <TableHeader
                        sortName='plan_end_date'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Plan end Date"}
                      />

                      <TableHeader
                        sortName='funding_start_date'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Funding Start Date"}
                      />

                      <TableHeader
                        sortName='funding_end_date'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Funding End Date"}
                      />

                      <TableHeader
                        sortName='plan_catogery'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Plan Catogery"}
                      />

                      <TableHeader
                        sortName='support_item_name'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Support Item Name"}
                      />

                      <TableHeader
                        sortName='service_provider'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"External Service Provider"}
                      />

                      <TableHeader
                        sortName='total_quantity'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Total Quantity"}
                      />
                      <TableHeader
                        sortName='total_funding'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Total Funding"}
                      />

                      <TableHeader
                        sortName='total_delivered'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Total Delivered"}
                      />

                      <TableHeader
                        sortName='rate'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Rate"}
                      />

                      <TableHeader
                        sortName='remaining_quantity'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Remaining Quantity"}
                      />

                      <TableHeader
                        sortName='remaining_amount'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Remaining Amount"}
                      />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={15} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.participants?.data?.length > 0 ? (
                      reportList?.participants?.data?.map(
                        (participant, index) => (
                          <tr
                            key={index}
                            className='even:bg-white odd:bg-gray-50'
                          >
                            <td className='whitespace-nowrap py-4 text-sm'>
                              <div className='flex items-center'>
                                <div className='capitalize pl-1'>
                                  <span
                                    title={`${participant.first_name} ${participant.last_name}`}
                                  >
                                    <p
                                      className={`w-40 truncate cursor-pointer `}
                                    >{`${participant.first_name} ${participant.last_name}`}</p>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                              <div>
                                {(participant?.workers &&
                                  _.isArray(participant?.workers) && (
                                    <AvatarGroup
                                      type='worker'
                                      arrayData={participant?.workers || []}
                                      cssClass='mt-0'
                                      maxLength={10}
                                    />
                                  )) ||
                                  "-"}
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                              {participant?.primary_no || "-"}
                            </td>
                            <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                              {participant?.email ? participant?.email : "-"}
                            </td>
                            <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                              <span
                                className={`${
                                  participant.status === "active"
                                    ? "bg-green-100 text-green-700"
                                    : "bg-red-100 text-red-700"
                                } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize `}
                              >
                                {participant.status}
                              </span>
                            </td>

                            <td className='px-2 py-[11px] text-sm text-gray-500'>
                              {participant?.dob
                                ? moment(participant.dob).format("DD-MM-YYYY")
                                : "-"}
                            </td>
                            <td className='whitespace-nowrap py-4  px-3 text-sm'>
                              {participant?.primary_disability || "-"}
                            </td>
                            <td
                              className=' py-4 text-sm'
                              style={{
                                maxWidth: "10px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <div
                                className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                                style={{
                                  maxWidth: "150px",
                                  textOverflow: "ellipsis",
                                }}
                                title={`${participant?.address}`}
                              >
                                {participant?.address || "-"}
                              </div>
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.suburb || "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.state || "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.ndis_no || "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.emergency_contact || "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.emergency_contact_name || "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.created_at
                                ? moment(participant.created_at).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {participant?.born_month || "-"}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td
                          colSpan={15}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please choose the date range to get
                          data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className='mt-3'>
                <PaginationComponent
                  page={paginationObj?.page || 1}
                  activeListLength={reportList?.participants?.data || []}
                  limit={REPORTING_LIMIT}
                  totalList={paginationObj?.total}
                  handlePagination={(e) => {
                    setPage(e);
                    const obj = paginationObj;
                    obj.page = e;
                    setPaginationObj({ ...obj });
                  }}
                  totalPages={paginationObj?.totalPages}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default FundingReports;
