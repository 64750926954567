import React from "react";
import CheckBox from "components/common/checkBox/checkbox";
import moment from "moment";
import { formatDateIntoDMY } from "Utils/utils";
import AvatarGroup from "components/common/Avatar/AvatarGroup";

const PublishShiftItem = ({ shift, handleCheckboxChange, checkboxes,calendarView }) => {


  return (
    <tr className="text-center even:bg-white odd:bg-gray-50">
      <td className="whitespace-nowrap pl-3 py-4 text-sm text-gray-500">
        <div className={`text-gray-500 $`} title={`${!shift?.worker_id ? "Unassigned worker shift can not be published":""}`}>
          <CheckBox
            handleChange={handleCheckboxChange}
            isDisabled={!shift?.worker_id}
            cssClass={!shift?.worker_id ? "cursor-not-allowed" : "cursor-pointer"} 
            checked={
              checkboxes.find((x) => x.uuid === shift.uuid) ? true : false
            }
          />
        </div>
      </td>
      <td className="text-start px-3 py-4 text-sm text-gray-500">
        <div className="capitalize text-gray-500">{shift.worker.name !== "undefined undefined" ? shift?.worker?.name: <span className="text-red-300">No worker assigned</span>}</div>
      </td>
      <td className="text-start px-3 py-4 text-sm text-gray-500">
        <div className="capitalize text-gray-500">
          {
          shift &&  calendarView === "worker-view" && shift?.participants.length > 0 && <AvatarGroup
          type="worker"
          arrayData={shift?.participants || []}
          cssClass="mt-0"
          maxLength={10}
        
        />
         }
         {shift && calendarView === "participant-view" && shift?.participant?.name}
        </div>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">
          {formatDateIntoDMY(shift.shift_date)}
        </div>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">
          {shift.shift_length && shift.shift_length.length > 4
            ? shift?.shift_length?.slice(0, 5)?.split(":").length >= 1 &&
              `${shift?.shift_length?.slice(0, 5)?.split(":")[0]}h ${
                shift?.shift_length?.slice(0, 5)?.split(":")[1]
              }m`
            : shift.shift_length}
        </div>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">
          {moment(shift.start_time, "YYYY-MM-DD HH:mm:ss").format("hh:mm a")}
        </div>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">
          {moment(shift.end_time, "YYYY-MM-DD HH:mm:ss").format("hh:mm a")}
        </div>
      </td>
    </tr>
  );
};

export default PublishShiftItem;
