import { fromJS } from "immutable";
import ACTIONS, { FETCH_STATUS } from "../constants";

const initialState = fromJS({
  selectedParticipant: {},
  loading: false,
  error: "",
});

export default function ParticpantReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.RESET_SELECTED_PARTICIPANT: {
      return state.set("selectedParticipant", {});
    }
    case ACTIONS.GET_PARTICIAPNT.PENDING: {
      return state.set("loading", action.status === FETCH_STATUS.LOADING);
    }
    case ACTIONS.GET_PARTICIAPNT.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .setIn(["selectedParticipant", "participant"], {
          ...action.data.participant,
        })
        .setIn(
          ["selectedParticipant", "participantOnBoarding"],
          action.data.on_boarding_progress
        );
    }
    case ACTIONS.GET_PARTICIAPNT.ERROR: {
      return state.set("loading", false).set("error", action);
    }
    case ACTIONS.RE_SET_PARTICIAPNT: {
      return state
        .set("loading", false)
        .set("error", "")
        .setIn(["selectedParticipant", "participant"], action.data.participant)
        .setIn(
          ["selectedParticipant", "participantOnBoarding"],
          action.data.participantOnBoarding
        );
    }
    case ACTIONS.GET_PARTICIPANT_SUPPORT_PLAN.PENDING: {
      return state.setIn(
        ["selectedParticipant", "fetchSupportPlanLoading"],
        action.status === FETCH_STATUS.LOADING
      );
    }
    case ACTIONS.GET_PARTICIPANT_SUPPORT_PLAN.SUCCESS: {
      return state
        .setIn(["selectedParticipant", "fetchSupportPlanLoading"], false)
        .setIn(["selectedParticipant", "fetchSupportPlanError"], "")
        .setIn(
          ["selectedParticipant", "participantSupportPlan"],
          action.data.data
        );
    }
    case ACTIONS.GET_PARTICIPANT_SUPPORT_PLAN.ERROR: {
      return state
        .setIn(["selectedParticipant", "fetchSupportPlanLoading"], false)
        .setIn(["selectedParticipant", "fetchSupportPlanError"], action);
    }

    case ACTIONS.PARTICIPANT_QUICK_SNAPSHOT.PENDING: {
      return state
        .setIn(["selectedParticipant", "fetchQuickSnapshotLoading"], true)
        .setIn(["selectedParticipant", "fetchQuickSnapshotError"], "");
    }
    case ACTIONS.PARTICIPANT_QUICK_SNAPSHOT.SUCCESS: {
      return state
        .setIn(["selectedParticipant", "fetchQuickSnapshotLoading"], false)
        .setIn(["selectedParticipant", "fetchQuickSnapshotError"], "")
        .setIn(
          ["selectedParticipant", "participantQuickSnapshot"],
          action.data.data
        );
    }
    case ACTIONS.PARTICIPANT_QUICK_SNAPSHOT.ERROR: {
      return state
        .setIn(["selectedParticipant", "fetchQuickSnapshotLoading"], false)
        .setIn(["selectedParticipant", "fetchQuickSnapshotError"], action);
    }

    case ACTIONS.PARTICIPANT_SETTING_UPDATE.PENDING: {
      return state
        .setIn(["selectedParticipant", "updateSettingLoading"], true)
        .setIn(["selectedParticipant", "updateSettingError"], "");
    }
    case ACTIONS.PARTICIPANT_SETTING_UPDATE.SUCCESS: {
      return state
        .setIn(["selectedParticipant", "updateSettingLoading"], false)
        .setIn(["selectedParticipant", "updateSettingError"], "")
        .setIn(["selectedParticipant", "participant"], {
          ...action.data,
        });
    }
    case ACTIONS.PARTICIPANT_SETTING_UPDATE.ERROR: {
      return state
        .setIn(["selectedParticipant", "updateSettingLoading"], false)
        .setIn(["selectedParticipant", "updateSettingError"], action);
    }

    case ACTIONS.PARTICIPANT_APPOINTMENT_LIST.PENDING: {
      return state
        .setIn(["selectedParticipant", "appiontmentsLoading"], true)
        .setIn(["selectedParticipant", "appiontmentsError"], "");
    }
    case ACTIONS.PARTICIPANT_APPOINTMENT_LIST.SUCCESS: {
      return state
        .setIn(["selectedParticipant", "appiontmentsLoading"], false)
        .setIn(["selectedParticipant", "appiontmentsError"], "")
        .setIn(
          ["selectedParticipant", "participantAppointments"],
          action.data.participant_appointments
        );
    }
    case ACTIONS.PARTICIPANT_APPOINTMENT_LIST.ERROR: {
      return state
        .setIn(["selectedParticipant", "appiontmentsLoading"], false)
        .setIn(["selectedParticipant", "appiontmentsError"], action);
    }

    case ACTIONS.PARTICIPANT_APPOINTMENT_CREATE.PENDING: {
      return state
        .setIn(["selectedParticipant", "createAppiontmentsLoading"], true)
        .setIn(["selectedParticipant", "createAppiontmentsError"], "");
    }
    case ACTIONS.PARTICIPANT_APPOINTMENT_CREATE.SUCCESS: {
      const appointments =
        state.getIn(["selectedParticipant", "participantAppointments"]) || [];
      appointments.unshift({ ...action.data.participant_appointments });
      return state
        .setIn(["selectedParticipant", "createAppiontmentsLoading"], false)
        .setIn(["selectedParticipant", "createAppiontmentsError"], "")
        .setIn(
          ["selectedParticipant", "participantAppointments"],
          [...appointments]
        );
    }
    case ACTIONS.PARTICIPANT_APPOINTMENT_CREATE.ERROR: {
      return state
        .setIn(["selectedParticipant", "createAppiontmentsLoading"], false)
        .setIn(["selectedParticipant", "createAppiontmentsError"], action);
    }

    case ACTIONS.PARTICIPANT_APPOINTMENT_UPDATE.PENDING: {
      return state
        .setIn(["selectedParticipant", "createAppiontmentsLoading"], true)
        .setIn(["selectedParticipant", "createAppiontmentsError"], "");
    }
    case ACTIONS.PARTICIPANT_APPOINTMENT_UPDATE.SUCCESS: {
      const appointments =
        state.getIn(["selectedParticipant", "participantAppointments"]) || [];
      if (appointments && appointments.length > 0) {
        const index = appointments.findIndex(
          (x) => x.uuid === action.data.uuid
        );
        if (index !== -1) {
          appointments.splice(index, 1, { ...action.data });
        }
      }
      return state
        .setIn(["selectedParticipant", "createAppiontmentsLoading"], false)
        .setIn(["selectedParticipant", "createAppiontmentsError"], "")
        .setIn(
          ["selectedParticipant", "participantAppointments"],
          [...appointments]
        );
    }
    case ACTIONS.PARTICIPANT_APPOINTMENT_UPDATE.ERROR: {
      return state
        .setIn(["selectedParticipant", "createAppiontmentsLoading"], false)
        .setIn(["selectedParticipant", "createAppiontmentsError"], action);
    }

    case ACTIONS.PARTICIPANT_APPOINTMENT_DELETE_LOCALLY: {
      const appointments =
        state.getIn(["selectedParticipant", "participantAppointments"]) || [];
      if (appointments && appointments.length > 0) {
        const index = appointments.findIndex(
          (x) => x.id === action.data.appointmentId
        );
        if (index !== -1) {
          appointments.splice(index, 1);
        }
      }
      return state.setIn(
        ["selectedParticipant", "participantAppointments"],
        [...appointments]
      );
    }

    case ACTIONS.SUBMITTED_FORM_UPDATE_PT_PERCENTAGE_LOCALLY: {
      const prevParticipant =
        state.getIn(["selectedParticipant", "participant"]) || null;
      if (
        prevParticipant &&
        action.data &&
        "on_boarding_progress_percentage" in action.data
      ) {
        Object.assign(prevParticipant, {
          on_boarding_progress: action.data.on_boarding_progress_percentage,
        });
      }
      const prevOnBoarding =
        state.getIn(["selectedParticipant", "participantOnBoarding"]) || null;
      if (
        prevOnBoarding &&
        action.data &&
        "on_boarding_progress" in action.data
      ) {
        Object.assign(prevOnBoarding, {
          ...action.data.on_boarding_progress,
        });
      }
      return state
        .setIn(["selectedParticipant", "participant"], { ...prevParticipant })
        .setIn(
          ["selectedParticipant", "participantOnBoarding"],
          [...prevOnBoarding]
        );
    }

    default: {
      return state;
    }
  }
}
