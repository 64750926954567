import React, { useState, useMemo, useEffect, useRef, useContext } from "react";
import { useForm } from "react-hook-form";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import _ from "lodash";
import Stepper from "../Stepper/Stepper";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import SideBar from "components/common/SideBar/SideBarNew";
import AlertModal from "components/Modals/alertModal";
import {
  baseURLImage,
  convertLabelIntoName,
  getFormSubTabForParticipantProfile,
  getNameProfile,
  getUrlRole,
  parseBooleanVal,
  selectedTabState,
} from "Utils/utils";
import { LoginContext } from "helpers/LoginContext";
import CustomButton from "components/common/Button/CustomButton";
import HumanBodyModal from "components/Modals/HumanBody/HumanBodyModal";
import { USER_ROLE } from "Constants/constant";
import { roleAccess } from "helpers/RolesPermission";
import SectionInputFields from "components/DynamicForms/SectionAndSubSectionFields/SectionsInputFields";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import NotificationDropDown from "components/common/NotificationDropDown/NotificationDropDown";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const ViewFormTemplate = (props) => {
  const {
    selectedParticipant,
    getParticipant,
    submittedFormUpdatePtPercentageLocally,
    currentLoginUser,
    role,
  } = props;
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const {
    slug,
    parentSlug,
    ptuuid,
    isComplete,
    formTemplateSlug,
    templateStatus,
  } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const formIsComplete = isComplete
    ? decodeURIComponent(window.atob(isComplete))
    : null;

  const scrollRef = useRef();
  const [openBodyModal, setOpenBodyModal] = useState(false);
  const [selectedBody, setSelectedBody] = useState("");
  const [template, setTemplate] = useState([]);
  const [templateForm, setTemplateForm] = useState([]);
  const [formTemplateId, setFormTemplateId] = useState("");
  const [participant, setParticipant] = useState(selectedParticipant);
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const [fileUploadChecker, setFileUploadChecker] = useState(true);
  const [formDataId, setFormDataId] = useState(
    isComplete && !parseBooleanVal(formIsComplete) ? slug : null
  );
  const [Step, setStep] = useState(0);
  const [Index, setIndex] = useState(0);
  const [participantList, setParticipantList] = useState([]);
  const [selectedLegal, setSelectedLegal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [signatures, setSignatures] = useState([]);
  const [CloneSignatures, setCloneSignatures] = useState([]);
  const [fileLoader, setFileLoader] = useState("");
  const sigCanvasManager = useRef({});

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const watchFormData = watch();

  useEffect(() => setParticipant(selectedParticipant), [selectedParticipant]);

  useEffect(() => {
    if (roleAccess([USER_ROLE.Super_Admin]) && slug && parentSlug) {
      getFormTemplate(parentSlug, slug);
    } else if (
      !roleAccess([USER_ROLE.Super_Admin]) &&
      state &&
      state.hasOwnProperty("isCloneFormUUID")
    ) {
      getSubmittedFormForCloneData();
    } else if (
      !roleAccess([USER_ROLE.Super_Admin]) &&
      formTemplateSlug &&
      templateStatus &&
      (templateStatus === "publish" || templateStatus === "draft")
    ) {
      getFormTemplate(formTemplateSlug, templateStatus);
    } else if (!roleAccess([USER_ROLE.Super_Admin]) && slug && parentSlug) {
      fetchTemplate();
    }
  }, [location]);

  const getSubmittedFormForCloneData = async () => {
    try {
      const routeRoleUrl = role === "hrm" ? "employee" : "participant";
      const res = await apiGetRequest(
        `${getUrlRole()}/${routeRoleUrl}/submitted-forms-view?form_id=${
          state.isCloneFormUUID
        }`
      );
      if (res.status !== 200) {
        setIsLoading(false);
        throw new Error(res.data.error);
      }
      if (res.status === 200 && res.data) {
        console.log("res.data", res.data);
        if (res?.data?.submitted_form?.template?.length > 0) {
          const data = JSON.parse(res?.data?.submitted_form?.template).map(
            (step) => {
              step.status = "";
              return step;
            }
          );
          data[Index].status = "active";
          setTemplateForm(res?.data?.submitted_form);
          const signatures = JSON.parse(res?.data?.submitted_form?.signatures);
          setSignatures(signatures ? [...signatures] : []);
          setTemplate(data);
          console.log("data", data);
          // countTotalSignatureFields(data);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err.message);
    }
  };

  const getFormTemplate = async (form_uuid, form_type) => {
    setIsLoading(true);
    try {
      let routeUrl = null;
      if (roleAccess([USER_ROLE.Super_Admin])) {
        routeUrl = `${
          role === "hrm" ? "super-admin/hrm" : "super-admin"
        }/form-template/show?form_uuid=${form_uuid}&form_type=${form_type}`;
      } else if (!roleAccess([USER_ROLE.Super_Admin])) {
        routeUrl = `${
          role === "hrm" ? "sp/hrm" : "sp"
        }/form_template?form_template_slug=${form_uuid}&form_type=${form_type}`;
      }

      if (routeUrl === null) return;

      const res = await apiGetRequest(`${routeUrl}`);
      if (res.status !== 200) {
        setIsLoading(false);
        throw new Error(res.data.error);
      }
      if (res.status === 200 && res.data.data) {
        setTemplateForm(res.data.data);
        const templateData = JSON.parse(res.data.data.template);
        setSignatures(signatures ? [...signatures] : []);
        setTemplate(templateData);
        console.log("data", templateData);
        countTotalSignatureFields(templateData);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (
      ptuuid &&
      role === "crm" &&
      slug &&
      slug !== "publish" &&
      slug !== "draft"
    ) {
      getParticipant({ participantUUID: ptuuid });
    }

    return () => {
      if (
        state === null ||
        (state &&
          !state.hasOwnProperty("redirectTo") &&
          getFormSubTabForParticipantProfile(parentSlug))
      ) {
        selectedTabState.save(
          JSON.stringify(getFormSubTabForParticipantProfile(parentSlug))
        );
      }
    };
  }, []);

  const handleClear = () => {
    setStep(0);
    setIndex(0);
    setParticipantList([]);
    setSelectedLegal("");
    reset();
    setFormTemplateId("");
  };

  const countTotalSignatureFields = (formData) => {
    if (!formData) return;

    formData.forEach((obj) => {
      obj.input_fields.forEach((x) => {
        if (x.type === "signature") {
          sigCanvasManager.current[`${x.name}`] = { ...x, current: null };
        } else if (x.type === "sub_section") {
          x.sub_fields.forEach((y) => {
            if (y.type === "signature") {
              sigCanvasManager.current[`${y.name}`] = { ...y, current: null };
            }
          });
        }
      });
    });
  };

  const fetchTemplate = async () => {
    setIsLoading(true);
    try {
      let url = null;

      if (role === "hrm") {
        url = isComplete
          ? `${getUrlRole()}/employee/submitted-forms-view?form_id=${slug}`
          : `${getUrlRole()}/hrm/form_template?form_template_slug=${slug}`;
      } else if (role === "crm") {
        url = isComplete
          ? `${getUrlRole()}/participant/submitted-forms-view?form_id=${slug}`
          : `${getUrlRole()}/form_template?form_template_slug=${slug}`;
      } else if (role === "worker") {
        url = formIsComplete
          ? `worker/hrm/submitted-forms-view?form_id=${slug}`
          : `worker/hrm/form_template?form_template_slug=${slug}`;
      }

      if (url === null) return;

      const res = await apiGetRequest(url);
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      }
      if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        if (isComplete) {
          const response = res.data.submitted_form;
          if (response.template.length > 0) {
            const data = JSON.parse(response.template).map((step, index) => {
              if (response.step >= index) step.status = "complete";
              else if (response.step + 1 === index) step.status = "current";
              else step.status = "";
              return step;
            });
            setTemplateForm(response);
            const signatures = JSON.parse(response.signatures);
            setSignatures(signatures ? [...signatures] : []);
            setTemplate(data);

            countTotalSignatureFields(data);
            setFormTemplateId(response.form_template_id);
            setStep(response.step + 1);
            setIndex(response.step + 1);
          }
        } else {
          if (res?.data?.data?.template?.length > 0) {
            const data =
              JSON.parse(res?.data?.data?.template).length > 0 &&
              JSON.parse(res?.data?.data?.template).map((step) => {
                step.status = "";
                return step;
              });
            data[Index].status = "active";
            setTemplateForm(res?.data?.data);
            setTemplate(data);

            countTotalSignatureFields(data);
          }
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err.message);
    }
  };

  const fileUploadHandler = async (
    e,
    sectionIndex,
    fieldIndex,
    subSectionIndex = null
  ) => {
    if (
      roleAccess([USER_ROLE.Super_Admin]) ||
      (roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager]) &&
        formTemplateSlug &&
        templateStatus)
    ) {
      return;
    }
    setFileUploadChecker(false);
    let fileLoaderName = `${sectionIndex}-${fieldIndex}`;
    if (subSectionIndex !== null) {
      fileLoaderName = `${fileLoaderName}-${subSectionIndex}`;
    }
    setFileLoader(fileLoaderName);
    const formData = new FormData();
    formData.append("form_file", e.target.files[0]);
    try {
      const res = await apiPostRequest(
        `${role === "worker" ? "worker/hrm" : getUrlRole()}${
          role === "hrm" ? "hrm/" : "/"
        }form-data-file`,
        formData
      );
      if (res.status !== 200) {
        toast.error("File upload Failed");
        setFileUploadChecker(true);
      }
      if (res.status === 200) {
        toast.success("File uploaded successfully");
        const data = _.cloneDeep(template);
        if (subSectionIndex === null) {
          data[sectionIndex].input_fields[fieldIndex].path = `${res.data.path}`;
          data[sectionIndex].input_fields[
            fieldIndex
          ].value = `${e.target.files[0].name}`;
        } else {
          data[sectionIndex].input_fields[fieldIndex].sub_fields[
            subSectionIndex
          ].path = `${res.data.path}`;
          data[sectionIndex].input_fields[fieldIndex].sub_fields[
            subSectionIndex
          ].value = `${e.target.files[0].name}`;
        }
        setTemplate(data);
        setFileUploadChecker(true);
      }
      setFileLoader(null);
    } catch (err) {
      console.log(err);
      setFileLoader(null);
      setFileUploadChecker(true);
    }
  };

  const handleSupportPlanFields = (formatedData) => {
    const supportPlan = [];
    let is_reportable_incident = false;
    // console.log("formatedData", formatedData);
    formatedData.forEach((obj) => {
      obj.input_fields.forEach((x) => {
        if (
          x.type !== "sub_section" &&
          x.hasOwnProperty("is_reportable_incident") &&
          parseBooleanVal(x.is_reportable_incident)
        ) {
          is_reportable_incident = true;
        }

        if (
          x.type !== "sub_section" &&
          parseBooleanVal(x.isSupportPlan) &&
          x.isSupportPlan
        ) {
          supportPlan.push(x);
        } else if (x.type === "sub_section") {
          x.sub_fields.forEach((y) => {
            if (
              y.hasOwnProperty("is_reportable_incident") &&
              parseBooleanVal(y.is_reportable_incident)
            ) {
              is_reportable_incident = true;
            }
            if (parseBooleanVal(y.isSupportPlan) && y.isSupportPlan) {
              supportPlan.push(y);
            }
          });
        }
      });
    });
    return { supportPlan, is_reportable_incident };
  };

  const handleNotificationFields = (formatedData) => {
    const notiData = [];
    formatedData.forEach((obj) => {
      obj.input_fields.forEach((x) => {
        if (
          x.type !== "sub_section" &&
          parseBooleanVal(x.isRiskNotification) &&
          x.hasOwnProperty("riskNotificationEnable") &&
          x.value === x.options[parseInt(x.riskNotificationEnable)].lable
        ) {
          notiData.push(x);
        } else if (x.type === "sub_section") {
          x.sub_fields.forEach((y) => {
            if (
              parseBooleanVal(y.isRiskNotification) &&
              y.hasOwnProperty("riskNotificationEnable") &&
              y.value === y.options[parseInt(y.riskNotificationEnable)].lable
            ) {
              notiData.push(y);
            }
          });
        }
      });
    });
    return notiData;
  };

  const submitFormData = async (formData, formatedData, isBool) => {
    const filteredSignatures = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => key !== "template")
    );

    const data1 = [];
    if (filteredSignatures && Object.keys(filteredSignatures).length > 0) {
      Object.entries(filteredSignatures).forEach(
        ([keys, value]) =>
          value !== undefined && value !== null && data1.push({ ...value })
      );
    }

    const intersectSigs = CloneSignatures.filter(
      (x) => signatures.findIndex((y) => y.name === x.name) !== -1
    );
    // console.log("intersactionSigs", intersectSigs);

    setSignatures([...intersectSigs]);

    const { supportPlan, is_reportable_incident } =
      handleSupportPlanFields(formatedData);

    const notificationFields = handleNotificationFields(formatedData);
    const form_timeplate_id =
      isComplete && !parseBooleanVal(formIsComplete)
        ? formTemplateId
        : state && state.hasOwnProperty("isCloneFormUUID")
        ? templateForm.form_template_id
        : templateForm.id;

    const data = {
      is_complete: isBool,
      step: Step,
      created_by: currentLoginUser.id,
      template: formatedData,
    };
    if (role === "hrm") {
      Object.assign(data, {
        worker_id: localStorage.getItem("selectedWorkerID") || "1",
        hrm_sp_form_temp_id: form_timeplate_id,
      });
    } else if (role === "crm") {
      Object.assign(data, {
        pt_id: participant ? participant.id : "1",
        sp_form_temp_id: form_timeplate_id,
      });
    } else if (role === "worker") {
      Object.assign(data, {
        hrm_sp_form_temp_id: form_timeplate_id,
      });
    }

    if (data1.length > 0) {
      Object.assign(data, { signatures: [...data1] });
    }

    if (isBool) {
      if (
        supportPlan.length > 0 &&
        templateFormToMap &&
        templateFormToMap?.title?.toLowerCase() === "initial assessment form"
      ) {
        Object.assign(data, { support_plan_data: JSON.stringify(supportPlan) });
      }

      if (is_reportable_incident) {
        Object.assign(data, {
          is_reportable_incident,
        });
      }
      if (notificationFields.length > 0) {
        Object.assign(data, { is_risk_notification: true });
      }
    }
    // console.log("data", data);
    if (formDataId) {
      if (role === "worker" || role === "hrm") {
        Object.assign(data, { hrm_form_data_uuid: formDataId });
      } else {
        Object.assign(data, { form_data_uuid: formDataId });
      }
    }
    setRequest({ ...request, isRequested: true });
    setTemplate([...formatedData]);
    try {
      const routeRoleUrl =
        role === "hrm" || role === "worker" ? "hrm-form_data" : "form_data";
      let url = null;

      if (role === "worker") {
        url = `worker/${routeRoleUrl}`;
      } else {
        url = `${getUrlRole()}/${routeRoleUrl}`;
      }

      const res = await apiPostRequest(url, data);

      if (res.status !== 201) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      }
      if (res.status === 201) {
        if (scrollRef.current) {
          scrollRef.current.scrollTo(0, 0);
        }
        nextStep(isBool, formatedData);
        setRequest({ ...request, isRequested: false, isSuccess: true });
        if (!formDataId && res && res.data) {
          setFormDataId(
            role === "hrm" || role === "worker"
              ? res.data.data.hrm_form_data_uuid
              : res.data.data?.form_data_uuid
          );
        }

        if (isBool) {
          role === "crm" &&
            submittedFormUpdatePtPercentageLocally(res?.data?.data);
          toast.success("Form submitted Successfully");
          handleClear();
        }
        if (ptuuid && isBool) {
          if (role === "worker") {
            navigate("/submitted-forms");
            return;
          }
          if (getFormSubTabForParticipantProfile(parentSlug)) {
            selectedTabState.save(
              JSON.stringify(getFormSubTabForParticipantProfile(parentSlug))
            );
          }
          navigate(
            `/${
              role === "hrm"
                ? "worker-profile"
                : roleAccess([USER_ROLE.Sp_Standard_User])
                ? "participant-profile"
                : "sp-profile"
            }/${ptuuid}`
          );
          return;
        }
        if (
          isBool &&
          formIsComplete === null &&
          !roleAccess([USER_ROLE.Sp_Standard_User])
        ) {
          navigate("/forms-list");
        } else if (
          isBool &&
          formIsComplete === null &&
          roleAccess([USER_ROLE.Sp_Standard_User])
        ) {
          role === "worker"
            ? navigate("/submitted-forms")
            : navigate("/forms-list-hrm");
        }
      }
    } catch (err) {
      toast.error("Something went wrong, Try again!");
      setRequest({ ...request, isRequested: false, isFailed: true });
    }
  };

  const dataFormatter = (data) => {
    const temp = _.cloneDeep(template);
    for (let j = 0; j < temp[Index].input_fields.length; j++) {
      if (temp[Index].input_fields[j].type === "address") {
        const address = data.template[Index].input_fields[j]["address"];
        temp[Index].input_fields[j].address = { ...address };
      }
      if (temp[Index].input_fields[j].type === "sub_section") {
        temp[Index].input_fields[j].sub_fields.forEach((item, k) => {
          if (temp[Index].input_fields[j].sub_fields[k].type === "address") {
            const address =
              data.template[Index].input_fields[j].sub_section[k]["address"];
            temp[Index].input_fields[j].sub_fields[k].address = { ...address };
          }
          if (
            temp[Index].input_fields[j].sub_fields[k].type === "file" ||
            temp[Index].input_fields[j].sub_fields[k].type === "signature" ||
            temp[Index].input_fields[j].sub_fields[k].type === "content"
          ) {
            return null;
          }
          if (item.type === "table") {
            const rowDataTemp = _.cloneDeep(
              temp[Index].input_fields[j].sub_fields[k].rows
            );
            let rows = [];
            temp[Index].input_fields[j].sub_fields[k].rows.forEach(
              (rowData, rowIndex) => {
                rows.push([]);
                rowDataTemp[rowIndex].forEach((columnData, columnIndex) => {
                  rows[rowIndex].push({});

                  const name = convertLabelIntoName(
                    rowDataTemp[rowIndex][columnIndex].lable
                  );
                  Object.assign(rowDataTemp[rowIndex][columnIndex], {
                    value:
                      data.template[Index].input_fields[j].sub_fields[k].rows[
                        rowIndex
                      ].columns[columnIndex][name],
                  });
                  Object.assign(rows[rowIndex][columnIndex], {
                    ...rowDataTemp[rowIndex][columnIndex],
                  });
                });
              }
            );
            Object.assign(temp[Index].input_fields[j].sub_fields[k], {
              rows: [...rows],
            });
          } else {
            const name = convertLabelIntoName(item.lable);
            if (data.template[Index].input_fields[j].sub_section[k]) {
              item.value =
                data.template[Index].input_fields[j].sub_section[k][`${name}`];
            }
          }
        });
      }
      if (
        temp[Index].input_fields[j].type === "signature" ||
        temp[Index].input_fields[j].type === "file" ||
        temp[Index].input_fields[j].type === "content"
      ) {
        continue;
      }
      if (temp[Index].input_fields[j].type === "table") {
        const rowDataTemp = _.cloneDeep(temp[Index].input_fields[j].rows);
        let rows = [];
        temp[Index].input_fields[j].rows.forEach((rowData, rowIndex) => {
          rows.push([]);
          rowDataTemp[rowIndex].forEach((columnData, columnIndex) => {
            rows[rowIndex].push({});
            const name = convertLabelIntoName(
              rowDataTemp[rowIndex][columnIndex].lable
            );
            Object.assign(rowDataTemp[rowIndex][columnIndex], {
              value:
                data.template[Index].input_fields[j].rows[rowIndex].columns[
                  columnIndex
                ][name],
            });
            Object.assign(rows[rowIndex][columnIndex], {
              ...rowDataTemp[rowIndex][columnIndex],
            });
          });
        });
        Object.assign(temp[Index].input_fields[j], { rows: [...rows] });
      } else {
        if (data.template && data.template[Index]) {
          if (
            data.template[Index].input_fields &&
            data.template[Index].input_fields.length > 0 &&
            temp[Index].input_fields[j] &&
            data.template[Index].input_fields[j]
          ) {
            const name = convertLabelIntoName(
              temp[Index].input_fields[j].lable
            );
            temp[Index].input_fields[j].value =
              data.template[Index].input_fields[j][`${name}`];
          }
        }
      }
    }
    return [...temp];
  };

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (selectedBody) return;
    const formatedData = dataFormatter(data);
    if (
      roleAccess([USER_ROLE.Super_Admin]) ||
      (roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager]) &&
        formTemplateSlug &&
        templateStatus)
    ) {
      nextStep(Index === template.length - 1, formatedData);
      return;
    }
    if (Index === template.length - 1) {
      submitFormData(data, formatedData, true);
    } else {
      submitFormData(data, formatedData, false);
    }
  };

  const formatIntoPngManager = (
    name,
    templateIndex,
    inputFieldIndex,
    SubFieldIndex = null
  ) => {
    const dataURL = sigCanvasManager.current[`${name}`].current.toDataURL();
    return {
      image: dataURL,
      name,
      templateIndex,
      inputFieldIndex,
      SubFieldIndex,
    };
  };

  const clearSignatureManager = (e) => {
    if (sigCanvasManager.current[`${e}`].current) {
      sigCanvasManager.current[`${e}`].current.clear();
      setValue(`${e}`, null);
    }
    if (signatures.find((x) => x.name === e)) {
      setCloneSignatures([...signatures]);
      const sigs = _.clone(signatures);
      const index = signatures.findIndex((x) => x.name === e);
      if (index !== -1) {
        sigs.splice(index, 1);
        setSignatures([...sigs]);
      }
    }
  };

  const addRowHandler = (si, fi, sf = null) => {
    const data = _.cloneDeep(template);
    if (sf >= 0 && sf !== null) {
      data[si].input_fields[fi].sub_fields[sf].rows.push(
        data[si].input_fields[fi].sub_fields[sf].columns
      );
    } else {
      data[si].input_fields[fi].rows.push(data[si].input_fields[fi].columns);
    }
    setTemplate([...data]);
  };

  const delteRowHandler = (si, fi, ri, sf = null) => {
    const data = _.cloneDeep(template);
    if (sf !== null) {
      const rows = data[si].input_fields[fi].sub_fields[sf].rows;
      const rowsData =
        watchFormData.template[si].input_fields[fi].sub_fields[sf].rows;
      rows.splice(ri, 1);
      rowsData.splice(ri, 1);
      Object.assign(data[si].input_fields[fi].sub_fields[sf], {
        rows: [...rows],
      });
      setValue(`template[${si}].input_fields[${fi}].sub_fields[${sf}].rows`, [
        ...rowsData,
      ]);
    } else {
      const rows = data[si].input_fields[fi].rows;
      const rowsData = watchFormData.template[si].input_fields[fi].rows;
      rows.splice(ri, 1);
      rowsData.splice(ri, 1);
      Object.assign(data[si].input_fields[fi], {
        rows: [...rows],
      });
      setValue(`template[${si}].input_fields[${fi}].rows`, [...rowsData]);
    }
    setTemplate([...data]);
  };

  const nextStep = (isBool = false, formatedData) => {
    const data = _.cloneDeep(formatedData);
    data[Index].status = "complete";
    if (!isBool) data[Index + 1].status = "current";
    setTemplate([...data]);
    setIndex(Index + 1);
    setStep(Step + 1);
  };

  const handleAddOther = (sectionIndex = null, subSectionIndex = null) => {
    if (
      !isNaN(sectionIndex) &&
      !isNaN(subSectionIndex) &&
      sectionIndex !== null &&
      subSectionIndex !== null
    ) {
      const data = _.cloneDeep(
        template[sectionIndex].input_fields[subSectionIndex]
      );
      const newObj = { ...data, isAddOther: false, isAddOtherDelete: true };
      setTemplate(
        [...template],
        template[sectionIndex].input_fields.push(newObj)
      );
    }
  };

  const handleRemoveOther = (sectionIndex, subSectionIndex) => {
    if (
      !isNaN(sectionIndex) &&
      !isNaN(subSectionIndex) &&
      sectionIndex !== null &&
      subSectionIndex !== null
    ) {
      const data = _.cloneDeep(template);
      const inputFieldsData = data[sectionIndex].input_fields;
      const filledData = watchFormData.template[sectionIndex].input_fields;
      inputFieldsData.splice(subSectionIndex, 1);
      filledData.splice(subSectionIndex, 1);
      Object.assign(data[sectionIndex], {
        input_fields: [...inputFieldsData],
      });
      setValue(`template[${sectionIndex}].input_fields`, [...filledData]);
      setTemplate([...data]);
    }
  };

  const templateToMap = useMemo(() => template, [template]);

  const templateFormToMap = useMemo(
    () => templateForm,
    [templateForm, template]
  );

  return (
    <div className='min-h-screen bg-gray-50 pb-4'>
      <SideBar classes='bg-gradient' />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }`}
      >
        {isLoading ? (
          <div className='flex justify-center items-center min-h-screen'>
            <CircularLoader
              classes='flex justify-center items-center'
              classloader='loader-l'
            />
          </div>
        ) : (
          <>
            <main className='flex-1 bg-gray-50'>
              <div className='bg-white sticky top-0 z-[9] items-center flex border-b border-gray-200 px-4 py-[20px] h-[80px] sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8'>
                <div className='flex-1 min-w-0'>
                  <h1 className='capitalize text-lg font-medium leading-6 text-gray-900 sm:truncate'>
                    {templateFormToMap.title}
                  </h1>
                </div>
                <div className='mt-4 flex items-center sm:mt-0 sm:ml-4'>
                  <div className='mr-2'>
                    <NotificationDropDown />
                  </div>
                  {participant && Object.keys(participant).length > 0 && (
                    <div className='flex items-center'>
                      <div className='h-10 w-10 flex-shrink-0'>
                        <img
                          className='h-10 w-10 rounded-full object-cover'
                          src={
                            participant.profile_picture
                              ? baseURLImage(participant.profile_picture)
                              : getNameProfile(
                                  participant.first_name,
                                  participant.last_name || " "
                                )
                          }
                          alt=''
                        />
                      </div>
                      <div className='ml-4'>
                        <div className='font-medium  text-gray-900 capitalize'>
                          {`${participant.first_name} ${participant.last_name}`}
                        </div>
                        {roleAccess(
                          [],
                          PERMISSION_KEYS.PARTICIPANT,
                          PERMISSION_KEYS_CHILDREN.PARTICIPANT.PRIMARY_EMAIL
                        ) && (
                          <div className='text-gray-500'>
                            {participant.email}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='max-w-5xl mx-auto'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    {templateToMap.length > 0 &&
                      templateToMap.map((section, index) => {
                        return (
                          <div key={`${index}-${section.section_title}`}>
                            {index === Index && (
                              <div
                                // key={`${index}-${section.section_title}`}
                                className='bg-white border rounded-lg shadow-lg pb-4 grid grid-cols-12 gap-x-8 my-4'
                              >
                                <div className='col-span-12 flex flex-col py-2 sticky top-[80px] rounded-t-lg bg-gradient z-[7]'>
                                  <div className='flex justify-center'>
                                    <Stepper
                                      template={template}
                                      onStepClickHandler={(index) => {
                                        setIndex(index);
                                        setStep(index);
                                        let templateArray =
                                          _.cloneDeep(template);
                                        templateArray = templateArray.map(
                                          (templete) =>
                                            templete.status === "active"
                                              ? {
                                                  ...templete,
                                                  status: "complete",
                                                }
                                              : templete
                                        );
                                        templateArray[index].status = "active";
                                        setTemplate([...templateArray]);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className='col-span-12 flex flex-col py-2 px-8'>
                                  <p className='font-semibold'>
                                    {section.section_title}
                                  </p>
                                </div>
                                <div
                                  ref={scrollRef}
                                  className='grid grid-cols-12 col-span-12 gap-x-8 mb-4 px-8'
                                >
                                  <SectionInputFields
                                    key={`${index}-${section.section_title}`}
                                    isViewable={false}
                                    section={section}
                                    register={register}
                                    control={control}
                                    watchFormData={watchFormData}
                                    index={index}
                                    errors={errors}
                                    fileUploadHandler={fileUploadHandler}
                                    fileUploadChecker={fileUploadChecker}
                                    fileLoader={fileLoader}
                                    signatures={signatures}
                                    formatIntoPngManager={formatIntoPngManager}
                                    sigCanvasManager={sigCanvasManager}
                                    clearSignatureManager={
                                      clearSignatureManager
                                    }
                                    setSelectedLegal={setSelectedLegal}
                                    delteRowHandler={delteRowHandler}
                                    addRowHandler={addRowHandler}
                                    participantList={participantList}
                                    setSelectedBody={setSelectedBody}
                                    setOpenBodyModal={setOpenBodyModal}
                                    participant={participant}
                                    handleRemoveOther={handleRemoveOther}
                                    handleAddOther={handleAddOther}
                                  />
                                </div>

                                <div className='col-span-12 flex justify-between items-center py-2 px-8'>
                                  <CustomButton
                                    type='button'
                                    label='Clear & Exit'
                                    variant='danger'
                                    clickHandler={(e) => {
                                      e.preventDefault();

                                      if (
                                        roleAccess([USER_ROLE.Super_Admin]) ||
                                        (roleAccess([
                                          USER_ROLE.Sp_Admin,
                                          USER_ROLE.Sp_manager,
                                        ]) &&
                                          formTemplateSlug &&
                                          templateStatus)
                                      ) {
                                        role === "hrm"
                                          ? navigate(`/forms-list-hrm`)
                                          : navigate(`/forms-list`);
                                        return;
                                      }

                                      if (
                                        state &&
                                        !state.hasOwnProperty("redirectTo") &&
                                        getFormSubTabForParticipantProfile(
                                          parentSlug
                                        )
                                      ) {
                                        selectedTabState.save(
                                          JSON.stringify(
                                            getFormSubTabForParticipantProfile(
                                              parentSlug
                                            )
                                          )
                                        );
                                      }
                                      if (role === "worker") {
                                        navigate("/submitted-forms");
                                        return;
                                      }
                                      navigate(
                                        `/${
                                          role === "hrm"
                                            ? "worker-profile"
                                            : roleAccess([
                                                USER_ROLE.Sp_Standard_User,
                                              ])
                                            ? "participant-profile"
                                            : "sp-profile"
                                        }/${ptuuid}`
                                      );
                                    }}
                                  />
                                  {roleAccess([USER_ROLE.Super_Admin]) ||
                                  (roleAccess([
                                    USER_ROLE.Sp_Admin,
                                    USER_ROLE.Sp_manager,
                                  ]) &&
                                    formTemplateSlug &&
                                    templateStatus) ? (
                                    Index < template.length - 1 && (
                                      <CustomButton
                                        isDisabled={isUserGracePeriodOver}
                                        type='submit'
                                        label={
                                          Index < template.length - 1
                                            ? "Next Section"
                                            : "Submit"
                                        }
                                      />
                                    )
                                  ) : (
                                    <CustomButton
                                      type='submit'
                                      label={
                                        Index < template.length - 1
                                          ? "Save & Next"
                                          : "Submit"
                                      }
                                      isDisabled={
                                        request.isRequested ||
                                        !fileUploadChecker ||
                                        isUserGracePeriodOver
                                      }
                                      showLoading={request.isRequested && true}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </form>
                {selectedLegal && (
                  <AlertModal
                    title='Legal Reference'
                    show={selectedLegal !== "" ? true : false}
                    handleClose={() => setSelectedLegal("")}
                  >
                    {selectedLegal}
                  </AlertModal>
                )}
                {openBodyModal && (
                  <HumanBodyModal
                    view={false}
                    selectedValue={selectedBody.value}
                    handleSubmit={(e) => {
                      if (selectedBody) {
                        const obj =
                          template[selectedBody.index].input_fields[
                            selectedBody.input_field_index
                          ];
                        setValue(
                          `template[${selectedBody.index}].input_fields[${selectedBody.input_field_index}].${obj.name}`,
                          e
                        );
                      }
                      setOpenBodyModal(false);
                      setSelectedBody("");
                    }}
                    isOpen={openBodyModal}
                    closeModal={() => {
                      setOpenBodyModal(false);
                      setSelectedBody("");
                    }}
                  />
                )}
              </div>
            </main>
          </>
        )}
      </div>
    </div>
  );
};

export default ViewFormTemplate;
