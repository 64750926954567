import React, { useState, useRef, useEffect, useContext } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { useParams } from "react-router-dom";
import {
  apiDeleteRequest,
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
} from "../../../../helpers/Requests";
import { useForm } from "react-hook-form";
import CircularLoader from "../../../common/CircularLoader/CircularLoader";
import {
  baseURLImage,
  getNameProfile,
  getPaginationObj,
  getUrlRole,
} from "../../../../Utils/utils";
import { BiPencil, BiPlus } from "react-icons/bi";
import CustomButton from "../../../common/Button/CustomButton";
import { toast } from "react-toastify";
import LoaderButton from "../../../common/LoaderButton/LoaderButton";
import { USER_ROLE, emptyPaginationObj } from "../../../../Constants/constant";
import { useSelector } from "react-redux";
import { getUser } from "../../../../store/selector/auth.selector";
import { roleAccess } from "helpers/RolesPermission";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS,
} from "store/constants";
import { LoginContext } from "helpers/LoginContext";
import {
  HiOutlineEye,
  HiOutlinePencil,
  HiOutlineTrash,
  HiPencil,
} from "react-icons/hi";
import { DownloadIcon } from "@heroicons/react/outline";
import SwitchInput from "components/common/Input/SwitchInput";
import FileUploadInput from "components/common/FileUpload/FileUploadInput";
//import { FaFontAwesome } from "react-icons/fa";
import { FaEye, FaDownload } from "react-icons/fa";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import moment from "moment";
import CaseNoteFileInput from "components/common/FileUpload/CaseNoteFileInput";
import axios from "axios";
import { saveAs } from "file-saver";

const CaseNotes = (props) => {
  const { participantInfo, isCaseNoteCall } = props;
  const { uuid } = useParams();
  const [author, setauthor] = useState(null);
  const [date, setdate] = useState(null);
  const [notes, setnotes] = useState(null);
  const [attachments, setattachment] = useState(null);

  const { isUserGracePeriodOver } = useContext(LoginContext);
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState(null);
  const [fileName, setFileName] = useState("");
  const [enabled, setEnabled] = useState(false); // State for the toggle button outside the modal
  const [modalEnabled, setModalEnabled] = useState(false);

  const myRef = useRef(null);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [reachedLast, setReachedLast] = useState(false);
  const [shouldTruncate, setShouldTruncate] = useState(true);
  const [selectedComment, setSelectedComment] = useState(null);

  const [postRequest, setPostRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // State for delete confirmation modal
  const [deletingNote, setDeletingNote] = useState(null);

  const currentUser = useSelector(getUser);
  const [fetchNotes, setFetchNotes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // Add this flag to avoid multiple
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [open, setOpen] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
const [isCaseNoteSubmitted,setIsSubmitted]=useState(false)
const [fileList, setFileList] = useState([]);
const [submitLoader,setIsSubmitLoader] = useState(false)


  useEffect(() => {
    scrollToRef(myRef);
  }, [myRef]);

  useEffect(() => fetchTemplate(), []);

  useEffect(() => {
    if (reachedLast && paginationObj.totalPages > paginationObj.page) {
      const obj = paginationObj;
      obj.page = paginationObj.page + 1;
      setPaginationObj({ ...obj });
      fetchTemplate();
    }
  }, [reachedLast]);

  const scrollToRef = (ref) => {
    if (ref?.current) {
      ref.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (isCaseNoteCall) handleFocusOnCaseNote();
  }, [isCaseNoteCall]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleFocusOnCaseNote = () => {
    if (document.getElementById("caseNote")) {
      document.getElementById("caseNote").focus();
    }
  };

  const fetchTemplate = async () => {
    if (!reachedLast) setRequest({ ...request, isRequested: true });
    try {
      let res = await apiGetRequest(
        `/${getUrlRole()}/case-notes?pt_id=${uuid}&page=${
          paginationObj.page
        }&worker_to_worker=${modalEnabled ? "true" : "false"}`
      );
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      }
      if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        if (res?.data?.case_notes?.data?.length > 0) {
          const data = res?.data?.case_notes?.data.map((step) => {
            step.status = "";
            return step;
          });
          const obj = fetchNotes;
          obj.push(...data);
          setFetchNotes(
            [...new Set(obj.map((item) => item.id))].map((id) =>
              obj.find((item) => item.id === id)
            )
          ); // Remove duplicates
        }
        setPaginationObj(getPaginationObj(res.data.case_notes));
        setReachedLast(false);
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      console.log(err.message);
    }
  };

  const onSubmit = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setIsSubmitLoader(true)
    try {
      const formData = new FormData();
      formData.append("pt_id", participantInfo.id);
      formData.append("notes", data.comment);

      if (file && file.length > 0) {
        file.forEach((fl) => {
          formData.append("attachments[]", fl);
        });
      }

      formData.append("show_to_worker", enabled);
      formData.append("worker_to_worker", modalEnabled);

      let response = await apiPostRequest(
        `/${getUrlRole()}/case-notes`,
        formData
      );

      if (response.status !== 201) {
        setPostRequest({ ...postRequest, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      } else {
        setPostRequest({ ...postRequest, isRequested: false, isSuccess: true });
        reset();
        setnotes(false);
        setIsSubmitted(true)
        setEnabled(false);
        setModalEnabled(false);
        setFile(null)
        setFileList([])
        setattachment(false);
        const obj = fetchNotes;
        obj.unshift(response.data.case_notes);

        // setFetchNotes([...obj]);

        const newNote = response.data.case_notes;
        setFetchNotes([...newNote]);
        const attachments = file ? file.map((f) => f.name) : [];
        newNote.attachments = attachments;

        toast.success("Case note added successfully");
      }
    } catch (err) {
      console.log(err);
      setPostRequest({ ...postRequest, isRequested: false, isFailed: true });
    } finally {
      setIsSubmitting(false); 
      setIsSubmitLoader(false)
    }
  };

  const [showToWorker, setShowToWorker] = useState(null);
  const [workerToWorker, setWorkerToWorker] = useState(null);

  useEffect(() => {
    if (selectedComment) {
      setShowToWorker(selectedComment.show_to_worker);
      setWorkerToWorker(selectedComment.worker_to_worker);
    }
  }, [selectedComment]);

  //const [updateStatus, setUpdateStatus] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);


  const Update = async (data) => {
    setIsUpdating(true);
    try {
      const response = await apiPutRequest(
        `/${getUrlRole()}/case-notes/${data.id}`,
        {
          pt_id: participantInfo.id,
          notes: data.notes,
          attachment: data.attachment,
          show_to_worker: data.show_to_worker,
          worker_to_worker: data.worker_to_worker,
        }
      );
      if (response.status !== 200) {
        toast.error("Failed to update case note. Please try again later.");
      } else {
        const updatedNotes = fetchNotes.map((note) =>
          note.id === data.id ? { ...note, ...data } : note
        );
        setFetchNotes(updatedNotes);
        toast.success("Case note updated successfully");
        setOpen(false);
      }
    } catch (err) {
      console.error("Error updating case note:", err);
      toast.error(
        "An error occurred while updating case note. Please try again later."
      );
    }
  };

  const handleDeleteClick = (note) => {
    setSelectedNote(note);
    setDeleteModalOpen(true);
  };

  const onDelete = async () => {
    setDeleteModalOpen(false);
    try {
      const response = await apiDeleteRequest(
        `/${getUrlRole()}/case-notes/${selectedNote.id}`
      );

      if (response.status === 403) {
        toast.error("You do not have permission to delete this case note.");
        console.log("Failed to delete case note. Status:", response.status);
      } else if (response.status !== 200) {
        toast.error("Failed to delete case note. Please try again later.");
        console.log("Failed to delete case note. Status:", response.status);
      } else {
        const updatedNotes = fetchNotes.filter(
          (note) => note.id !== selectedNote.id
        );
        setFetchNotes(updatedNotes);
        toast.success("Case note deleted successfully");
        setDeleteModalOpen(false);
      }
    } catch (err) {
      console.error("Error deleting case note:", err);
      toast.error(
        "An error occurred while deleting case note. Please try again later."
      );
    }
  };
  // const handleDeleteAttachment = async (case_note_id, attachmentId) => {
  //   try {
  //     const response = await apiDeleteRequest(
  //       `/${getUrlRole()}/case-notes/{case_note_id}/attachments`
  //     );

  //     if (response.status !== 200) {
  //       toast.error("Failed to delete attachment. Please try again later.");
  //       console.log("Failed to delete attachment. Status:", response.status);
  //     } else {
  //       // Update local state to remove the deleted attachment
  //       const updatedNotes = fetchNotes.map((note) =>
  //         note.id === case_note_id
  //           ? {
  //               ...note,
  //               attachments: note.attachments.filter(
  //                 (attachment) => attachment.id !== attachmentId
  //               ),
  //             }
  //           : note
  //       );
  //       setFetchNotes(updatedNotes);
  //       toast.success("Attachment deleted successfully");
  //     }
  //   } catch (err) {
  //     console.error("Error deleting attachment:", err);
  //     toast.error(
  //       "An error occurred while deleting the attachment. Please try again later."
  //     );
  //   }
  // };

  const closeModalTable = () => {
    setShowButton(true);
    setShouldTruncate(true);
    setDeleteModalOpen(false);
    setOpen(false);
  };
  const modalUpdateFooterButton = (updateFunction) => (
    <div className='flex space-x-2'>
      <CustomButton
        clickHandler={() =>
          updateFunction({
            id: selectedComment.id,
            notes,
            attachments: file, // Include file state
            show_to_worker: showToWorker,
            worker_to_worker: workerToWorker,
          })
        }
        type='button'
        disabled={isUpdating}
        label='Update'
        variant='primary'
      />
      <CustomButton
        clickHandler={closeModalTable}
        type='button'
        label='Close'
        variant='danger'
      />
    </div>
  );

  const modalDeleteFooterButton = () => (
    <div className='flex space-x-2'>
      <CustomButton
        clickHandler={onDelete}
        type='button'
        label='Delete'
        variant='primary'
      />
      <CustomButton
        clickHandler={closeModalTable}
        type='button'
        label='Close'
        variant='danger'
      />
    </div>
  );

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const handleScroll = () => {
    if (myRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = myRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight &&
        paginationObj.totalPages > paginationObj.page
      ) {
        setReachedLast(true);
      }
    }
  };

  const handleViewAll = (attachments) => {
    attachments.attachments.forEach((el) => {
      const fileName = el.name || el.fileName || "";
      const fileUrl = `https://docs.vertex360.io/${el.attachments}`;

      const isImage =
        fileName.endsWith(".jpg") ||
        fileName.endsWith(".png") ||
        fileName.endsWith(".jpeg") ||
        fileName.endsWith(".gif");

      if (isImage) {
        window.open(fileUrl, "_blank");
      } else {
        //const googleDocsUrl = `https://docs.google.com/viewer?url=${fileUrl}`;
        window.open(fileUrl, "_blank");
      }
    });
  };

  const [Loader, setLoader] = React.useState(false);

  async function handleDownloadAll(attachments) {
    setLoader(true); 

    for (const el of attachments) {
        const fileName = el.fileName || "downloaded-file";
        const fileExtension = el.attachments.split('.').pop().toLowerCase();
        const downloadLink = `https://docs.vertex360.io/${el.attachments}`;
        

        const openInNewTabExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv', 'txt', 'html', 'htm'];

        try {
            if (openInNewTabExtensions.includes(fileExtension)) {
  
                window.open(downloadLink, "_blank");
            } else {
   
                const response = await fetch(downloadLink, {
                    mode: 'cors',
                });

                const blob = await response.blob();
                saveAs(blob, `${fileName}.${fileExtension}`);
            } 
        } catch (error) {
            console.error("There was an error downloading the file:", error);
        }
    }

    setLoader(false); 
}

  // function handleDownloadAll(attachments) {
  //   // setLoader(true);
  //   attachments.forEach(async (el) => {

  //     const fileName = el.fileName || "";
  //     const downloadLink = `https://docs.vertex360.io/${el.attachments}`;
  //     console.log({downloadLink})
  //     window.open(downloadLink, "_blank");
  //     try {
  //       const response = await fetch(downloadLink);

  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }

  //       const blob = await response.blob();
  //       const downloadUrl = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = downloadUrl;
  //       a.download = fileName || "downloaded-file.pdf"; // Provide a default filename if not specified
  //       document.body.appendChild(a);
  //       a.click();
  //       a.remove();
  //       window.URL.revokeObjectURL(downloadUrl);
  //       setLoader(false);
  //     } catch (error) {
  //       setLoader(false);
  //       console.error("There was an error downloading the file:", error);
  //     }
  //   });
  // }

  // const handleDownloadAll = (attachments) => {
  //   attachments.forEach((el) => {
  //     const fileName = el.name || el.fileName || "";
  //     const downloadLink = `https://docs.vertex360.io/${el.attachments}`;
  //     console.log({ downloadLink });
  //     fetch(downloadLink)
  //       .then((response) => {
  //         console.log("response");
  //         if (!response.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  //         return response.blob();
  //       })
  //       .then((blob) => {
  //         const blobUrl = URL.createObjectURL(blob);
  //         console.log({ blobUrl });
  //         const a = document.createElement("a");
  //         a.href = blobUrl;
  //         a.download = fileName || el.attachments; // Use fileName or el.attachments for a more descriptive download
  //         document.body.appendChild(a);
  //         a.click();
  //         document.body.removeChild(a);
  //         URL.revokeObjectURL(blobUrl);
  //       })
  //       .catch((error) => {
  //         console.error("Error downloading file:", error);
  //       });
  //   });
  // };

  const handleView = (attachments) => {
    const fileName = attachments.name || attachments.fileName || "";
    const fileUrl = `https://docs.vertex360.io/${attachments.attachments}`;
    if (
      fileName.endsWith(".jpg") ||
      fileName.endsWith(".png") ||
      fileName.endsWith(".jpeg") ||
      fileName.endsWith(".gif")
    ) {
      window.open(baseURLImage(fileUrl), "_blank");
    } else {
      //const googleDocsUrl = `https://docs.google.com/viewer?url=https://docs.vertex360.io/${fileUrl}`;
      window.open(fileUrl, "_blank");
    }
  };

  const handleDownload = (attachments) => {
    const fileName = attachments.name || attachments.fileName || "";
    const downloadLink = `https://docs.vertex360.io/${attachments.attachments}`;

    fetch(downloadLink)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = fileName || attachments.attachments;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const renderItem = (item, index) => {
    return (
      <div
        key={index}
        className='flex border-b last:border-b-0 px-2 py-4 border-b-neutral-200'
      >
        {item.attachments &&
        Array.isArray(item.attachments) &&
        item.attachments.length > 0 ? (
          <div className='flex items-center gap-2'>
            <CustomButton
              type='button'
              label={
                <>
                  <FaEye style={{ color: "white", marginRight: "5px" }} />
                  View 
                </>
              }
              onClick={() => handleViewAll(item)}
              style={{
                fontSize: "0.8em",
                padding: "5px 10px",
                marginRight: "10px",
              }}
            />

            <CustomButton
              type='button'
              // isDisabled={Loader}
              label={
                <>
                  <FaDownload style={{ color: "white", marginRight: "5px" }} />
                  Download 
                </>
              }
              onClick={() => handleDownloadAll(item.attachments)} // Pass all attachments
              style={{
                fontSize: "0.8em",
                padding: "5px 10px",
              }}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const renderItems = (item, index) => {
    return (
      <div
        key={index}
        className='flex flex-col gap-2 border-b last:border-b-0 px-2 py-4 border-b-neutral-200'
      >
        {item.attachments &&
        Array.isArray(item.attachments) &&
        item.attachments.length > 0
          ? item.attachments.map((attachments, idx) => (
              <div key={idx} className='flex items-center gap-2'>
                <span className='text-sm text-gray-700'>
                  File {idx + 1}: {attachments.name}
                </span>
                <div className='flex space-y-2 items-end'>
                  <CustomButton
                    type='button'
                    label={
                      <>
                        <FaEye style={{ color: "white", marginRight: "5px" }} />
                        View
                      </>
                    }
                    onClick={() => handleView(attachments)} // Pass the entire attachment object
                    style={{
                      fontSize: "0.8em",
                      padding: "5px 10px",
                      marginRight: "10px",
                    }}
                  />
                  <CustomButton
                    type='button'
                    label={
                      <>
                        <FaDownload
                          style={{ color: "white", marginRight: "5px" }}
                        />
                        Download
                      </>
                    }
                    onClick={() => handleDownload(attachments)} // Ensure handleDownload gets the correct object too
                    style={{
                      fontSize: "0.8em",
                      padding: "5px 10px",
                    }}
                  />
                </div>
              </div>
            ))
          : null}
      </div>
    );
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // covers 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formatDateWithSuffix = (date) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.toLocaleString("en-AU", { month: "long" });
    const year = d.getFullYear();
    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;

    return `${dayWithSuffix} ${month} ${year}`;
  };

  return (
    <>
      <section aria-labelledby='notes-title'>
        <div className='bg-white shadow sm:rounded-lg sm:overflow-hidden'>
          <div>
            {(roleAccess(
              [USER_ROLE.Sp_Standard_User],
              PERMISSION_KEYS.PARTICIPANT,
              BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT.CASE_NOTES.STORE
            ) ||
              roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) && (
              <div className='bg-gray-50 px-4 py-6 sm:px-6'>
                <div className='flex space-x-3'>
                  {currentUser && (
                    <div className='flex-shrink-0'>
                      <img
                        onError={(e) =>
                          handleImageError(e, currentUser.name || " ", " ")
                        }
                        className='h-10 w-10 rounded-full object-cover'
                        src={
                          currentUser.profile_picture
                            ? baseURLImage(currentUser.profile_picture)
                            : getNameProfile(currentUser.name || " ", " ")
                        }
                        alt='profilePicture'
                      />
                    </div>
                  )}
                  <div className='min-w-0 flex-1'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                        <label htmlFor='comment' className='sr-only'>
                          About
                        </label>
                        <textarea
                          id='caseNote'
                          name='comment'
                          rows={3}
                          className='shadow-sm block w-full resize-none overflow-y-auto focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md p-2 focus:bg-gray-50'
                          defaultValue={""}
                          {...register("comment", { required: true })}
                        />
                      </div>
                      {errors.comment && (
                        <span className='text-red-600 text-sm'>
                          Case note is required
                        </span>
                      )}
                      <br />
                      <div className='col-span-1'>
                        <label className='did-floating-label text-start'>
                          Attachment
                        </label>
                        <div>
                          <CaseNoteFileInput
                            type='any'
                            handleFile={(e) => setFile(e)}
                            size='1'
                            file={file}
                            handleDelete={() => setFile("")}
                            showDragAndDrop={false}
                            setFileList={setFileList}
                            fileList={fileList}
                       
                            // multiple
                          />
                        </div>
                      </div>
                      <div className='mt-3 flex items-center justify-between'>
                        {/* <div className='group inline-flex items-start text-sm space-x-2 text-gray-500'>
                          <QuestionMarkCircleIcon
                            className='flex-shrink-0 h-5 w-5 text-gray-400'
                            aria-hidden='true'
                          />
                          <span>Add important information for the team</span>
                        </div> */}
                        <div className='flex gap-x-4'>
                          {roleAccess([
                            USER_ROLE.Sp_Admin,
                            USER_ROLE.Sp_manager,
                          ]) && (
                            <SwitchInput
                              handleChange={setEnabled}
                              checked={enabled}
                              label='Show to worker'
                            />
                          )}

                          {roleAccess([USER_ROLE.Sp_Standard_User]) && (
                            <SwitchInput
                              label='Show to other workers'
                              checked={modalEnabled}
                              handleChange={(checked) =>
                                setModalEnabled(checked)
                              }
                            />
                          )}
                        </div>
                        {postRequest.isRequested ? (
                          <CustomButton
                            type='submit'
                            disabled={isSubmitting}
                            label='Submit'
                            // isDisabled={submitLoader}

                            showLoading={true}
                            isDisabled={isUserGracePeriodOver || submitLoader}
                          />
                        ) : (
                          <CustomButton
                            type='submit'
                            disabled={isSubmitting}
                            label='Submit'
                            isDisabled={isUserGracePeriodOver || submitLoader}
                            showLoading={submitLoader}
                          />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            <div
              onScroll={handleScroll}
              ref={myRef}
              className='px-4 py-6 sm:px-6 overflow-y-auto custom-scroll caseNotesContainer'
            >
              {request.isRequested ? (
                <div
                  style={{
                    justifyContent: "center",
                    minHeight: "100%",
                    height: "100%",
                  }}
                  className='flex items-center'
                >
                  <CircularLoader
                    classes='flex justify-center items-center'
                    classloader='loader-l'
                  />
                </div>
              ) : (
                <ul className='space-y-8'>
                  {fetchNotes &&
                    fetchNotes?.map((comment, index) => (
                      <li key={index}>
                        <div className='flex space-x-3'>
                          <div className='flex-shrink-0'>
                            <img
                              onError={(e) =>
                                handleImageError(e, comment?.author || " ", " ")
                              }
                              alt='avatar'
                              className='h-10 w-10 rounded-full object-cover'
                              src={
                                comment.profile_picture
                                  ? baseURLImage(comment.profile_picture)
                                  : getNameProfile(comment?.author || " ", " ")
                              }
                            />
                          </div>
                          <div className='flex-1'>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className='mt-2 text-sm space-x-2'
                                style={{ textAlign: "right" }}
                              >
                                <p>{comment.author} </p>
                              </div>

                              {/* <div
                                className='flex border-b last:border-b-0 px-2 py-4 border-b-neutral-200'
                                style={{ textAlign: "left" }}
                              >
                                <button
                                  className='mr-3'
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedComment(comment);
                                    setauthor(comment.author);
                                    setnotes(comment.notes);
                                    //setdate(comment.date);
                                    setattachment(comment.attachments);
                                    setShowToWorker(comment.show_to_worker);
                                    setWorkerToWorker(comment.worker_to_worker);

                                    //console.log("Edit button clicked", comment);
                                  }}
                                >
                                  <div
                                    className='icon-pencil-wrapper'
                                    title='Edit'
                                  >
                                    <HiOutlinePencil className='icon-size' />
                                  </div>
                                </button>
                                <button
                                  className=''
                                  onClick={() => handleDeleteClick(comment)}
                                >
                                  <div
                                    className='icon-delete-wrapper'
                                    title='Delete'
                                  >
                                    <HiOutlineTrash className='icon-size' />
                                  </div>
                                </button>
                              </div> */}
                            </div>

                            <div className='mt-1 text-sm text-gray-700'>
                              <p style={{ wordBreak: "break-word" }}>
                                {comment.notes}
                              </p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className='mt-2 text-sm space-x-2'
                                style={{ textAlign: "right" }}
                              >
                                <strong>
                                  {formatDateWithSuffix(comment?.date)} at{" "}
                                  {new Date(comment?.date).toLocaleTimeString(
                                    "en-AU",
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }
                                  )}
                                </strong>
                              </div>
                              <div
                                className='flex-shrink-0'
                                style={{ textAlign: "left" }}
                              >
                                {renderItem(comment, index)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  {reachedLast &&
                    paginationObj.totalPages >= paginationObj.page && (
                      <div className='flex justify-center items-center'>
                        <LoaderButton
                          classes='flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-dark hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
                          svgClasses='inline mr-3 w-5 h-5 text-white animate-spin'
                          textbutton='Loading More...'
                        />
                      </div>
                    )}
                  {fetchNotes &&
                    fetchNotes.length === 0 &&
                    request.isSuccess && (
                      <div className='bg-white'>
                        <div className='max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
                          <h2 className='block pt-6 text-xl font-semibold text-gray-900'>
                            No Data Found!
                          </h2>
                          <div className='p-6 flex justify-center'>
                            <div className='mt-2 text-sm text-gray-700 inline-flex'>
                              Get started by creating a new case Note
                            </div>
                          </div>
                          {(roleAccess(
                            [USER_ROLE.Sp_Standard_User],
                            PERMISSION_KEYS.PARTICIPANT,
                            BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT
                              .CASE_NOTES.STORE
                          ) ||
                            roleAccess([
                              USER_ROLE.Sp_Admin,
                              USER_ROLE.Sp_manager,
                            ])) && (
                            <div className='mt-8 flex justify-center'>
                              <div className='ml-3 inline-flex'>
                                <CustomButton
                                  label='Case Notes'
                                  clickHandler={handleFocusOnCaseNote}
                                  Icon={<BiPlus />}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  <ModalSkeleton
                    isOpen={open}
                    closeModal={closeModalTable}
                    modalFooter={modalUpdateFooterButton(Update)}
                    cssClass='w-[50%]'
                    title='Edit CaseNote'
                  >
                    <div className='bg-gray-50 px-4 py-6 sm:px-6'>
                      <div className='flex space-x-3'>
                        {currentUser && (
                          <div className='flex-shrink-0'>
                            <img
                              onError={(e) =>
                                handleImageError(
                                  e,
                                  currentUser.name || " ",
                                  " "
                                )
                              }
                              className='h-10 w-10 rounded-full object-cover'
                              src={
                                currentUser.profile_picture
                                  ? baseURLImage(currentUser.profile_picture)
                                  : getNameProfile(currentUser.name || " ", " ")
                              }
                              alt='profilePicture'
                            />
                          </div>
                        )}
                        <div className='min-w-0 flex-1'>
                          <form action=''>
                            <div>
                              <textarea
                                type='text'
                                value={notes}
                                rows={3}
                                name='notes'
                                onChange={(e) => setnotes(e.target.value)}
                                className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                              />
                            </div>
                            {errors.notes && (
                              <span className='text-red-600 text-sm'>
                                Case note is required
                              </span>
                            )}
                            <br />
                            <div className='col-span-1'>
                              <label className='did-floating-label text-start'>
                                Attachment
                              </label>
                              <div>
                                <CaseNoteFileInput
                                  type='any'
                                  handleFile={(e) => setFile(e)}
                                  size='1'
                                  file={file}
                                  handleDelete={() => setFile("")}
                                  
                                  showDragAndDrop={false}
                                  // multiple
                                />
                              </div>
                            </div>
                            <br />
                            <div className='space-y-4'>
                              <div>
                                <label className='block text-sm font-medium text-gray-700'>
                                  Attach Files
                                </label>
                                <div className='flex flex-col space-y-2 item'>
                                  {renderItems({ attachments }, 0)}
                                </div>
                              </div>
                            </div>
                            <div className='mt-3 flex items-center justify-between'>
                              <div className='flex gap-x-4'>
                                {roleAccess([
                                  USER_ROLE.Sp_Admin,
                                  USER_ROLE.Sp_manager,
                                ]) &&
                                  selectedComment && (
                                    <SwitchInput
                                      handleChange={(checked) =>
                                        setShowToWorker(checked)
                                      }
                                      checked={showToWorker}
                                      label='Show to worker'
                                    />
                                  )}

                                {roleAccess([USER_ROLE.Sp_Standard_User]) &&
                                  selectedComment && (
                                    <SwitchInput
                                      label='Show to other workers'
                                      checked={workerToWorker}
                                      handleChange={(checked) =>
                                        setWorkerToWorker(checked)
                                      }
                                    />
                                  )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </ModalSkeleton>

                  {deleteModalOpen && (
                    <ModalSkeleton
                      isOpen={deleteModalOpen}
                      title='Delete CaseNote'
                      closeModal={() => setDeleteModalOpen(false)}
                      cssClass='w-[50%]'
                      modalFooter={modalDeleteFooterButton()}
                    >
                      <div>
                        <p>Are you sure you want to delete this Case Note?</p>
                      </div>
                    </ModalSkeleton>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseNotes;
