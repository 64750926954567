import React from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
// import { HiEye } from "react-icons/hi";

const ParticipantProgressBar = (props) => {
  const { progressBarDetails } = props;

  return (
    <div className='tooltip-progressbar rounded shadow-lg p-1 bg-gray-100 mt-6 w-[400px]'>
      <div className='flex justify-between items-center px-4 py-2 bg-gray-100'>
        <p>Onboarding Update</p>
      </div>
      <div className='max-h-64 overflow-y-auto'>
        {progressBarDetails &&
          progressBarDetails.length > 0 &&
          progressBarDetails
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map((x, index) => (
              <div
                key={index}
                className='cursor-default flex justify-between items-center space-x-6 px-4 py-2 text-gray-700 bg-white hover:bg-gray-100 border border-gray-200'
              >
                <p className='capitalize mt-2 flex items-center text-sm text-gray-500'>
                  {x.submitted ? (
                    <CheckCircleIcon
                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
                      aria-hidden='true'
                    />
                  ) : (
                    <XCircleIcon
                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-red-400'
                      aria-hidden='true'
                    />
                  )}
                  {x.name}
                </p>
              </div>
            ))}
        {/* <button class="inline-flex items-center justify-center w-8 h-8 mr-2 text-gray-700 hover:text-white transition-colors duration-150 bg-white rounded-full focus:shadow-outline hover:bg-blue-800"> */}
        {/* <HiEye className="rounded-md text-center h-4 w-4" /> */}
        {/* </button> */}
      </div>
    </div>
  );
};

export default ParticipantProgressBar;
