import React from "react";

import moment from "moment";
import { formatDateIntoDMY, getWorkerTimesheetLength } from "Utils/utils";

const ExportCSVItem = ({ shift, index }) => {

  return (
    <tr className='text-center even:bg-white odd:bg-gray-50' key={index}>
      <td className='text-start px-3 py-4 text-sm text-gray-500'>
        <div className='capitalize text-gray-500 text'>{shift?.participant_name}</div>
      </td>
      <td className='text-start px-3 py-4 text-sm text-gray-500'>
        <div className='capitalize text-gray-500'>
          {shift?.worker_name}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {shift?.shift_date ? formatDateIntoDMY(shift?.shift_date):"N/A"}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {shift?.shift_length && shift?.shift_length.length > 4
            ? shift?.shift_length?.slice(0, 5)?.split(":").length >= 1 &&
              `${shift?.shift_length?.slice(0, 5)?.split(":")[0]}h ${
                shift?.shift_length?.slice(0, 5)?.split(":")[1]
              }m`
            : shift?.shift_length}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {shift?.shift_start_time ? moment(shift?.shift_start_time, "YYYY-MM-DD HH:mm:ss").format(
            "hh:mm a"
          ): "N/A" }
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {shift?.shift_end_time ? moment(shift?.shift_end_time, "YYYY-MM-DD HH:mm:ss").format(
            "hh:mm a"
          ) : "N/A"}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {shift?.worker_start_time ? moment(shift?.worker_start_time, "YYYY-MM-DD HH:mm:ss").format(
            "hh:mm a"
          ):"N/A"}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {shift?.worker_end_time ? moment(shift?.worker_end_time, "YYYY-MM-DD HH:mm:ss").format(
            "hh:mm a"
          ) : "N/A"}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {(shift?.worker_start_time && shift?.worker_end_time) ? getWorkerTimesheetLength(shift?.worker_start_time,shift?.worker_end_time) : "N/A"}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {shift?.travel_km ? shift?.travel_km : "N/A"}
        </div>
      </td>
    </tr>
  );
};

export default ExportCSVItem;
