import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";
import Search from "components/common/Search/Search";
import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  DOCUMENTS_TYPE,
  emptyPaginationObj,

  REPORTING_LIMIT,
  SORT_TYPE,
  StatusOptions,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import {
  URL_REPORTING_PARTICIPANT_EXPIRING_DOCUMENT_REPORTS,

} from "store/services/URL";
import { useNavigate } from "react-router";
import moment from "moment";
import { FiDelete } from "react-icons/fi";
import _ from "lodash";

import { toast } from "react-toastify";
import { exportTOExcel, formatDateReporting, getUrlRole } from "Utils/utils";
import api from "store/services/auth.services";

const DetailedInvoicingReport = ({
  getTeamManagmentListCall,
  teamManagementList,
  getParticipantList,
  spParticipants,
}) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
  }, []);

  const [participantList, setParticipant] = useState([
    { label: "All", value: "all" },
  ]);

  const [status, setStatus] = useState(null);
  const [BirthDate, setBirthDate] = useState(null);

  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);

  const [selectedDocumentType, setSelectedDocType] = useState(
    DOCUMENTS_TYPE[0]
  );
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isMaxDate, setIsMaxDate] = useState(false);

  useEffect(() => {
    const participants =
      spParticipants?.participant?.data?.map((item) => ({
        label: `${item?.first_name} ${item?.last_name}`,
        value: item?.id,
      })) || [];
    setParticipant(participants);
  }, [teamManagementList, spParticipants]);

  useEffect(() => {
    getDocCategory();
  }, []);

  const [isDocTypeLoader, setIsDocTypeLoader] = useState(false);
  const [fileTypeOptions, setFileTypeOptions] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const getDocCategory = async () => {
    try {
      setIsDocTypeLoader(true);
      const res = await apiGetRequest(`${getUrlRole()}/doc-types`);
      if (res.status === 200) {
        const options = res.data.doc_types.map((doc) => {
          const obj = {
            label: doc.name,
            value: doc.name,
          };
          return obj;
        });
        setFileTypeOptions([...options]);
      }
      setIsDocTypeLoader(false);
    } catch (err) {
      console.log("err", err);
      setIsDocTypeLoader(false);
    }
  };

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    fetchListReports();
  }, [page]);

  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = null
  ) => {
    if (!fromDate && !toDate) {
      toast("Select create date range to get data");
      return;
    }
    if (isMaxDate) {
      toast("The selected date range cannot exceed three months.");

      return;
    }

    setLoader(true);
    let url = `${URL_REPORTING_PARTICIPANT_EXPIRING_DOCUMENT_REPORTS}?per_page=10&page=${page}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_PARTICIPANT_EXPIRING_DOCUMENT_REPORTS}?per_page=10&page=${page}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",

      participant: isClearAll
        ? "all"
        : selectedParticipant
        ? selectedParticipant?.value
        : "all",
      status: isClearAll ? "all" : status ? status.value : "all",
      document_type: isClearAll
        ? "all"
        : selectedDocumentType
        ? selectedDocumentType?.value
        : "all",

      category: isClearAll
        ? "all"
        : selectedFileType
        ? selectedFileType?.value
        : "all",
      expire_date: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
    };
    if (report) {
      payload.report = report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            window.open(`${DOC_DOWNLOAD_URL}${res?.data?.data}`, "_blank");
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.documents?.total,
              totalPages: Math.ceil(
                res?.data?.data?.documents?.total /
                  res?.data?.data?.documents?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    if (e === "xlsx") {
      exportTOExcelFile();
    } else {
      fetchListReports(false, e);
    }
  };

  const exportTOExcelFile = () => {
    const transformedData =
      reportList &&
      reportList.documents &&
      reportList?.documents?.data?.map((item) => {
        return {
          Support_Worker: `${item.worker.first_name} ${item.worker.last_name}`,
          Document: item.name,
          Expiry_Date:
            item.expire_date && moment(item.expire_date).format("DD-MM-YYYY"),
          Contact_Number: item.worker.phone,
          Email: item.worker.email,
          Worker_Status: item.worker.status ? "Active" : "InActive",
          Role: item?.worker.role,
          Employee_Type: item.worker.employee_type,
          Employee_Level: item.worker.salary_slab,
          Employee_Joining_Date:
            item.worker.joining_date &&
            moment(item.worker.joining_date).format("DD-MM-YYYY"),
        };
      });
    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <div className='min-h-full bg-red-500'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header title='Detailed Invoicing Reports' />
          <div className='min-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between py-1'>
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.DETAILED_INVOICING_REPORTS
                )}
              />
              <div>
                <SelectBox
                  isUserGracePeriodOver={isUserGracePeriodOver}
                  isLoading={loader}
                  setReportType={(e) => {
                    if (isUserGracePeriodOver) {
                      return;
                    }
                    handleFileDownLoad(e);
                  }}
                />
              </div>
            </div>
            <div className='p-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <div className='w-full'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search
                  </label>
                  <Search setSearch={setSearch} search={search} />
                </div>
                <section>
                  <div className='flex py-4 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant
                        </label>
                        <SelectOption
                          name='worker_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedParticipant}
                          handleChange={(e) => {
                            setSelectedParticipant(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Document Type
                        </label>
                        <SelectOption
                          name='document_type'
                          isSearch={true}
                          data={DOCUMENTS_TYPE || []}
                          selectedOption={selectedDocumentType}
                          handleChange={(e) => {
                            setSelectedDocType(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Category
                        </label>
                        <SelectOption
                          name='category'
                          isDisabled={isDocTypeLoader}
                          isSearch={true}
                          data={fileTypeOptions || []}
                          selectedOption={selectedFileType}
                          handleChange={(e) => {
                            setSelectedFileType(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='flex py-4 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label
                          htmlFor='from-date-to-to-date'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Select Expiry Date Range
                        </label>
                        <DateRangePickerNew
                          // isDisabled={clientsListLoader}
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                          }}
                          handleChange={(obj) => {
                            if (obj && obj.fromDate && obj.toDate) {
                              const threeMonthsLater = new Date(obj.fromDate);
                              threeMonthsLater.setMonth(
                                threeMonthsLater.getMonth() + 3
                              );
                              const formattedThreeMonthsLater =
                                formatDateReporting(threeMonthsLater);

                              if (
                                obj.toDate &&
                                obj.toDate <= formattedThreeMonthsLater
                              ) {
                                setIsMaxDate(false);
                                setFromDate(obj.fromDate);
                                setToDate(obj.toDate);
                              } else {
                                setIsMaxDate(true);
                                toast(
                                  "The selected date range cannot exceed three months."
                                );
                              }
                            }
                          }}
                          fromDate={fromDate}
                          toDate={toDate}
                        />
                      </div>
                    </div>

                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Status
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={StatusOptions || []}
                          handleChange={(e) => {
                            setStatus(e);
                          }}
                          selectedOption={status}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end pt-4 space-x-2'
                        style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={() => fetchListReports(false)}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || false}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setBirthDate(null);

                            setStatus(null);
                            setSelectedParticipant(null);
                            setSelectedFileType(null);
                            setSearch(null);
                            setReports([]);

                            // fetchListReports(true);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className='min-w-full  flex justify-center overflow-x-auto custom-scroll'>
                <table className='border min-w-full divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader
                        sortName='participant'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Participant"}
                      />

                      <TableHeader
                        sortName='document'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Document"}
                      />
                      <TableHeader
                        sortName='category'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Category"}
                      />

                      <TableHeader
                        sortName='expiry_date'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Expiry Date"}
                      />

                      <TableHeader
                        sortName='contact_number'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Contact Number"}
                      />

                      <TableHeader
                        sortName='email'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Email"}
                      />

                      <TableHeader
                        sortName='status'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Status"}
                      />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={7} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.documents?.data?.length > 0 ? (
                      reportList?.documents?.data?.map((document, index) => (
                        <tr
                          key={index}
                          className='even:bg-white odd:bg-gray-50'
                        >
                          <td className='whitespace-nowrap py-4 text-sm'>
                            <div className='flex items-center'>
                              <div className='capitalize'>
                                <span
                                  title={`${document.worker.first_name} ${document.worker.last_name}`}
                                >
                                  <p
                                    className={`w-40 truncate cursor-pointer pl-1`}
                                  >{`${document.worker.first_name} ${document.worker.last_name}`}</p>
                                </span>
                              </div>
                            </div>
                          </td>

                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            {document?.name || "-"}
                          </td>
                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            {document?.expire_date
                              ? moment(document.expire_date).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {document?.worker?.phone || "-"}
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {document?.worker?.email || "-"}
                          </td>

                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            <span
                              className={`${
                                document.status
                                  ? "bg-green-100 text-green-700"
                                  : "bg-red-100 text-red-700"
                              } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize `}
                            >
                              {document.status ? "Active" : "InActive"}
                            </span>
                          </td>
                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {document?.worker?.role || "-"}
                          </td>
                          <td className='px-2 py-[11px] text-sm text-gray-500'>
                            {document?.worker.employee_type || "-"}
                          </td>

                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {document?.worker?.salary_slab || "-"}
                          </td>

                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {document?.worker?.joining_date
                              ? moment(document.worker.joining_date).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={7}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please adjust your filters to get
                          relevant results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className='mt-3'>
                <PaginationComponent
                  page={paginationObj?.page || 1}
                  activeListLength={reportList?.documents?.data || []}
                  limit={REPORTING_LIMIT}
                  totalList={paginationObj?.total}
                  handlePagination={(e) => {
                    setPage(e);
                    const obj = paginationObj;
                    obj.page = e;
                    setPaginationObj({ ...obj });
                  }}
                  totalPages={paginationObj?.totalPages}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default DetailedInvoicingReport;
