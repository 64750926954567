import React, { useState, useEffect } from "react";

import { apiGetRequest } from "helpers/Requests";
import CircularLoader from "components/common/CircularLoader/CircularLoader";

const SystemNews = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [newsData, setNews] = useState([]);

  useEffect(() => {
    getNewsList();
  }, []);

  //get notifications
  const getNewsList = async (page) => {
    try {
      setIsLoading(true);

      const res = await apiGetRequest(`/sp/news/list?page=1&per_page=50`);
      if (res && res.status === 200) {
        setNews(res.data?.data?.news?.data);
      }
      setIsLoading(false);
    } catch (Err) {
      setIsLoading(false);
      console.log(Err);
    }
  };

  return (
    <div className="w-full custom-scroll" style={{ maxHeight: "450px", overflowY: "auto" }}>
      {isLoading ? (
        <div className={`flex flex-col w-full  items-center justify-center`}>
          <CircularLoader
            className="flex justify-center items-center"
            classloader="loader-l"
          />
        </div>
      ) : newsData && newsData.length > 0 ? (
        newsData.map((item, index) => (
          <div className="bg-white p-6 m-2 rounded-lg shadow-md" key={index}>
            <h3 className="text-xl font-semibold mb-2">{item?.title || "-"}</h3>
            <p className="p-2 text-sm text-gray-500">
              {item?.description || ""}
            </p>
          </div>
        ))
      ) : (
        <p className="text-sm font-semibold mb-2 text-center">
          No news available
        </p>
      )}
    </div>
  );
};

export default SystemNews;
