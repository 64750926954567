import React, { useCallback, useState } from "react";
import ReadOnlyStarRating from "./Component/KeyStregnthsReadOnly";
import {
  WORKER_PROFILE_TABS,
} from "Constants/constant";
import {
  ImArrowUpRight2,
  ImArrowDown2,
  ImArrowUp2,
} from "react-icons/im";
import { baseURLImage, capitalizeFirstLetter, getNameProfile } from "Utils/utils";
import { HiOutlineEye } from "react-icons/hi";
import WorkerPicture from "../../../../assets/workerProfile/profile.png";
import { apiGetRequest } from "helpers/Requests";
import { HRM_FORM_NAME } from "Constants/constant";
import { formatDateToAustralian } from "Utils/utils";
import { connect } from "react-redux";
import { getSupportPlanSubmittedFormsListRequest } from "store/actions/sp.actions";
import { getSubmittedFormsList } from "store/selector/sp.selector";
import QuickView from "pages/ServiceProvider/ParticipantsList/ParticipantQuickView";
import WorkerAvailabilty from "./WorkerAvailabilty";
import TrainingTable from "./TrainingDevelopmentTable";

const WorkerProfile = ({
  worker,
  setSelectedTab,
  setRefreshPageForWorkerTimeSlot,
  isWorkerAvailablity,
  isUserGracePeriodOver,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState({});
  const [trainingList, setTrainingList] = useState([]);
  const parsedKeyStrengths = React.useMemo(
    () => JSON.parse(worker?.key_strengths || "[]"),
    [worker]
  );

  const dataLimit = 150; // Set your data limit here
  const [isExpanded, setIsExpanded] = useState(false);

  const content =
    worker?.quick_snapshot && worker.quick_snapshot.length > dataLimit
      ? isExpanded
        ? worker.quick_snapshot
        : `${worker.quick_snapshot.slice(0, dataLimit)}...`
      : worker?.quick_snapshot || "-";

  React.useEffect(() => {
    if (worker) {
      setSelectedParticipant(worker);
      getTrainingDevelopment();
    }
  }, [worker]);

  const handleViewClick = useCallback(() => {
    setSelectedTab(WORKER_PROFILE_TABS[2].id);
  }, [setSelectedTab]);

  const getTrainingDevelopment = async () => {
    try {
      const res = await apiGetRequest(
        `sp/employee/submitted-form-by-slug?profile_id=${worker?.uuid}&slug=${HRM_FORM_NAME.label}`
      );

      if (res.status === 200) {
        const res_data = res.data?.submitted_form?.template;
        const convertToObject = JSON.parse(res_data);
        setTrainingList(convertToObject);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const workerDetails = [
    { label: "Worker Phone Number", value: worker?.phone || "-" },
    { label: "Email", value: worker?.contact_email || "-" },
    {
      label: "Emergency Contact Name",
      value: worker?.emergency_contact_name || "-",
    },
    {
      label: "Emergency Contact Phone",
      value: worker?.emergency_contact_phone_number || "-",
    },

    {
      label: "Role",
      value: capitalizeFirstLetter(worker?.role) || "-",
    },
    {
      label: "Employment Type",
      value: capitalizeFirstLetter(worker?.employee_type) || "-",
    },
    {
      label: "Level",
      value: worker?.salary_slab || "-",
    },
    {
      label: "Joining Date",
      value: formatDateToAustralian(worker?.joining_date) || "-",
    },
    {
      label: "Status",
      value: worker?.status ? "Active" : "InActive",
    },
    {
      label: "Xero ID",
      value: worker?.xero_worker_id || "-",
    },
    {
      label: "Suburb",
      value: worker?.city || "-",
    },
  ];

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <div className='min-h-full '>
      <main className='p-3  mt-4 rounded-md'>
        <div className='grid grid-cols-12 gap-4'>
          <div className='col-span-4 bg-white rounded-md border'>
            <div className='  rounded-t-md'>
              <img src={WorkerPicture} alt='worker profile' />
            </div>
            <div className='p-6 bg-gray-100'>
              <label className='font-bold text-md'>Quick Snapshot</label>
              <div
                className='mt-1 custom-scroll'
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                <p className='text-sm text-gray-500'>{content}</p>
              </div>
              {worker?.quick_snapshot &&
                worker.quick_snapshot.length > dataLimit && (
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className='text-blue-500 cursor-pointer text-sm font-semi-bold '
                  >
                    {isExpanded ? (
                      <div className='flex items-center gap-1 justify-center'>
                        Read Less
                        <ImArrowUp2 />
                      </div>
                    ) : (
                      <div className='flex items-center gap-1 justify-center'>
                        Read More
                        <ImArrowDown2 />
                      </div>
                    )}
                  </button>
                )}
            </div>
            <div className='py-6 px-4'>
              {workerDetails.map((item, index) => {
                const maxLength = 30;
                const truncatedValue =
                  item.value.length > maxLength
                    ? `${item.value.slice(0, maxLength)}...`
                    : item.value;
                return (
                  <div
                    className='flex justify-between items-start py-2'
                    key={index}
                  >
                    <label className='text-sm font-semibold'>
                      {item.label}
                    </label>
                    <div className='flex justify-start text-left'>
                      {" "}
                      <p className='ml-2 text-gray-600 text-left text-sm overflow-hidden whitespace-nowrap'>
                        {truncatedValue}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className=' w-full   text-center'>
              <div className='flex items-center gap-1 justify-center   '>
                <div
                  className='flex items-center justify-center cursor-pointer'
                  onClick={handleViewClick}
                >
                  <span className='text-md font-bold text-blue-400'>
                    Add Information
                  </span>
                  <ImArrowUpRight2 className='text-blue-400' />
                </div>
              </div>
            </div>
            <div className='flex justify-between mt-5 bg-[#e1e5f0] rounded-full py-3 px-5 mx-2'>
              <div className='flex items-center gap-1'>
                <h3 className='font-bold text-sm'>Participant</h3>{" "}
              </div>
            </div>

            {worker?.participants && worker?.participants.length > 0 ? (
              <div className='py-4 px-6 max-h-80 overflow-y-auto custom-scroll '>
                {worker?.participants &&
                  worker?.participants.map((item, index) => {
                    return (
                      <div
                        className='flex justify-between items-center mt-5'
                        key={index}
                      >
                        <div className='flex items-center gap-2'>
                          <img
                            onError={(e) =>
                              handleImageError(
                                e,
                                item?.first_name,
                                item?.last_name
                              )
                            }
                            className='capitalize object-cover h-10 w-10 rounded-full'
                            src={
                              item?.profile_picture
                                ? baseURLImage(item?.profile_picture)
                                : getNameProfile(
                                    item?.first_name,
                                    item?.last_name || " "
                                  )
                            }
                            alt=''
                          />
                          <p className='text-sm capitalize'>
                            {item.first_name} {item?.last_name}
                          </p>
                        </div>
                        <div
                          onClick={() => {
                            setIsOpen(true);
                            setSelectedParticipant(item);
                          }}
                          tabIndex={0}
                          role='button'
                          className='icon-eye-wrapper'
                          title='View'
                        >
                          <HiOutlineEye className='icon-size' />
                          <span className='sr-only'></span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className='text-center'>
                <p className='text-sm text-gray-500 my-3'>
                  No participant assigned
                </p>
              </div>
            )}
          </div>
          <div className='col-span-8 bg-gray-100 rounded-t-md'>
            <div className='bg-[#e1e5f0] py-4 px-5 rounded-t-md'>
              <label className='text-md font-bold'>
                Key Strengths, Likes & Areas of Improvement
              </label>
              <p className='text-sm text-gray-500'>
                Quick glance over {worker?.first_name}'s skillset
              </p>
            </div>

            <div className='px-12 py-6'>
              {parsedKeyStrengths && parsedKeyStrengths?.length > 0 ? (
                <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-5 mt-2 mb-5'>
                  {parsedKeyStrengths?.map((item, index) => {
                    return (
                      <div key={index} className='mt-3'>
                        <ReadOnlyStarRating
                          key={index}
                          label={Object.keys(item)[0]}
                          value={Object.values(item)[0]}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className='text-center'>
                  <p className='text-sm text-gray-500 mt-3'>
                    No data available
                  </p>
                </div>
              )}

              <div className='grid sm:grid-cols-1 md:grid-cols-1 gap-y-4 gap-x-5 mt-2 mb-5'>
                <div className='flex items-center gap-1 justify-end '>
                  <div
                    className='flex items-center justify-center cursor-pointer'
                    onClick={handleViewClick}
                  >
                    <span className='text-md font-bold text-blue-400'>
                      Add Information
                    </span>
                    <ImArrowUpRight2 className='text-blue-400' />
                  </div>
                </div>
              </div>
              <div>
                <WorkerAvailabilty
                  isUserGracePeriodOver={isUserGracePeriodOver}
                  isWorkerAvailablity={isWorkerAvailablity}
                  worker={worker}
                  WorkerId={worker?.uuid}
                  setRefreshPageForWorkerTimeSlot={
                    setRefreshPageForWorkerTimeSlot
                  }
                />
              </div>

              <div>
                <label className='text-md font-bold'>
                  Training & Development
                </label>
                <TrainingTable data={trainingList || []} />
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* side over Modal */}
      {isOpen && (
        <QuickView
          paddingLeft='px-0'
          selectedParticipant={selectedParticipant}
          isOpen={isOpen}
          setIsOpen={() => {
            setIsOpen(false);
            setSelectedParticipant({});
          }}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  getSupportPlanFormsList: getSupportPlanSubmittedFormsListRequest,
};
const mapStateToProps = (state) => {
  const getTrainingFormsStatus = getSubmittedFormsList(state);

  return { getTrainingFormsStatus };
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkerProfile);
