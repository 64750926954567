import React, { useState, useEffect, useContext } from "react";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import ParticipantProfileTabs from "../../../components/ServiceProvider/Participant/ParticipantProfileTabs/ParticipantProfileTabs";
import { useLocation, useParams } from "react-router-dom";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import { LoginContext } from "../../../helpers/LoginContext";
import ProgressBarNew from "../../../components/common/ProgressBarNew/ProgressBarNew";
import { apiGetRequest } from "../../../helpers/Requests";
import {
  PARTICIPANT_PROFILE_TABS,
  USER_ROLE,
} from "../../../Constants/constant";
import ParticipantProgressBar from "../../../components/common/ProgressBarNew/ParticipantProgressBar";
import { roleAccess } from "../../../helpers/RolesPermission";
import { selectedTabState } from "../../../Utils/utils";
import ParticipantProfileHeader from "components/ServiceProvider/Participant/ParticipantProfileHeader/ParticipantProfileHeader";

const ParticipantProfile = (props) => {
  const {
    getFolderListForHRMLoading,
    FolderListForHRM,
    FileListForHRM,
    isLoading,
    getParticipant,
    selectedParticipant,
    resetSelectedParticipant,
    participantOnBoarding,
    resetSelectedParticipantDocuments,
    resetParticipantFundings,
    storeCurrentOpenTab,
    // currentUser,
  } = props;

  const { sideBarPadding, refreshNavigations } = useContext(LoginContext);
  const { uuid } = useParams();
  const location = useLocation();

  const { state } = location;
  const [selectedTab, setSelectedTab] = useState(0);
  const [onBoardingProcess, setOnBoardingProcess] = useState([]);
  const [addCaseNoteCall, setAddCaseNoteCall] = useState(false);
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const [toggleState, setToggleState] = useState(null);

  useEffect(() => {
    if (uuid) {
      if (roleAccess([USER_ROLE.Sp_Standard_User]) && uuid) {
        fetchParticipantDataForWorker();
      }
    }
    setToggleState(
      selectedTabState.get()
        ? { ...selectedTabState.get() }
        : PARTICIPANT_PROFILE_TABS[0]
    );

    return () => {
      resetSelectedParticipant();
      resetParticipantFundings();
      resetSelectedParticipantDocuments();
      sessionStorage.clear();
    };
  }, [uuid, location.pathname]);

  useEffect(() => {
    if (state && state.hasOwnProperty("selectedTab") && state.selectedTab) {
      selectedTabState.save(JSON.stringify(state.selectedTab));
      storeCurrentOpenTab(state.selectedTab);
      setSelectedTab(state.selectedTab.id);
    }
  }, [state]);

  useEffect(() => {
    if (selectedParticipant === null) {
      getParticipant({ participantUUID: uuid });
    } else if (
      selectedParticipant &&
      selectedParticipant.uuid !== uuid &&
      !roleAccess([USER_ROLE.Sp_Standard_User])
    ) {
      getParticipant({ participantUUID: uuid });
    }
  }, [selectedParticipant]);

  useEffect(() => {
    if (toggleState && toggleState.hasOwnProperty("isOpen")) {
      selectedTabState.save(JSON.stringify(toggleState));
    }
    if (toggleState) {
      storeCurrentOpenTab(toggleState);
      setSelectedTab(toggleState.id);
    }
  }, [toggleState]);

  useEffect(() => {
    if (addCaseNoteCall && toggleState.id !== 16) {
      setSelectedTab(16);
      setToggleState(PARTICIPANT_PROFILE_TABS[1]);
    } else {
      setAddCaseNoteCall(false);
    }
  }, [addCaseNoteCall]);

  const fetchParticipantDataForWorker = async () => {
    setRequest({ ...request, isRequested: true });
    try {
      let res = await apiGetRequest(`hrm/participants/${uuid}`);
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      } else if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        setOnBoardingProcess(res?.data?.data?.on_boarding_progress);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding ? "lg:pl-[4rem]" : "lg:pl-64"
          } flex flex-col duration-300`}
        >
          {toggleState &&
          selectedParticipant &&
          Object.keys(selectedParticipant).length > 0 &&
          isLoading === false ? (
            <main className='flex-1 w-full'>
              <div className='sticky top-0 z-[9]'>
                <ParticipantProfileHeader
                  handleAddCaseNote={() => {
                    setAddCaseNoteCall(true);
                  }}
                  selectedTab={selectedTab}
                  participantInfo={selectedParticipant}
                />
              </div>
              <div className='max-w-7xl mx-auto print:hidden'>
                <div className='px-6 has-tooltip-progress bg-white flex justify-center cursor-pointer print:hidden'>
                  <ParticipantProgressBar
                    progressBarDetails={
                      roleAccess([USER_ROLE.Sp_Standard_User])
                        ? onBoardingProcess?.map((x) => ({
                            submitted: x.status,
                            name: x.form_title,
                          }))
                        : participantOnBoarding?.map((x) => ({
                            submitted: x.status,
                            name: x.form_title,
                          }))
                    }
                  />
                  {selectedParticipant && (
                    <ProgressBarNew progressline={selectedParticipant} />
                  )}
                </div>
              </div>
              <div className='max-w-7xl mx-auto px-6 bg-white'>
                <ParticipantProfileTabs
                  // currentUser={currentUser}
                  refreshNavigations={refreshNavigations}
                  getFolderListForHRMLoading={getFolderListForHRMLoading}
                  FolderListForHRM={FolderListForHRM}
                  FileListForHRM={FileListForHRM}
                  setToggleState={setToggleState}
                  toggleState={toggleState}
                  resetCaseNoteCall={() => setAddCaseNoteCall(false)}
                  isCaseNoteCall={addCaseNoteCall}
                  participantInfo={selectedParticipant}
                  tabClass='flex space-x-4 max-w-2xl min-w-full w-full overflow-x-auto'
                />
              </div>
            </main>
          ) : (
            <div className='px-0 flex justify-center items-center min-h-screen bg-gray-50'>
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ParticipantProfile;
