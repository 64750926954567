import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoClose } from "react-icons/io5";
import CustomButton from "components/common/Button/CustomButton";
import SelectOption from "components/common/Select/Select";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import MultipleSelection from "components/common/MultipleSelect";
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import { apiPostRequest } from "helpers/Requests";
import { URL_ROSTERING_FORTNIGHTLY_COPY_SHIFT } from "store/services/URL";
import { toast } from "react-toastify";

const CopyShift = ({
  isOpen,
  setCopyShifts,
  startDate,
  endDate,
  setIsMessage,
  setForthnightlyCopyShiftsMessage,
  recurringMessageModal,
  isUserGracePeriodOver,
}) => {
  const [activeButton, setActiveButton] = useState(null);
  const [selectedToShift, setToShift] = useState([]);
  const [selectedFromShift, setFromShift] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [dateUptoOptions, setDateUptoOptions] = useState([]);
  const [nextForthnights, setNextForthnights] = useState([]);
  const [forSingleNextFortnight, setSingleForthnight] = useState([]);
  const [currentStart, setCurrentStart] = useState(null);
  const [currentEnd, setCurrentEnd] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // const today = moment(startDate);
    const today = moment();
    const startOfThisWeek = today.clone().startOf("isoWeek");
    const options = Array.from({ length: 15 }, (_, index) => {
      if (index === 0) return null;
      const startDate = startOfThisWeek.clone().add(index * 14, "days");
      const endDate = startDate.clone().add(13, "days");
      return {
        value: `${startDate.format("YYYY-MM-DD")} - ${endDate.format(
          "YYYY-MM-DD"
        )}`,
        label: `${startDate.format("Do MMM YYYY")} - ${endDate.format(
          "Do MMM YYYY"
        )}`,
      };
    }).filter((option) => option !== null);
    setDateUptoOptions(options);
  }, [startDate]);

  useEffect(() => {
    const today = moment();
    const startOfThisWeek = today.clone().startOf("isoWeek");
    const numberOfOptions = 14;
    const options = [];
    for (let i = 0; i < numberOfOptions; i++) {
      const startDate = startOfThisWeek.clone().subtract(i * 14, "days");
      const endDate = startDate.clone().add(13, "days");
      options.push({
        value: `${startDate.format("YYYY-MM-DD")} - ${endDate.format(
          "YYYY-MM-DD"
        )}`,
        label: `${startDate.format("Do MMM YYYY")} - ${endDate.format(
          "Do MMM YYYY"
        )}`,
      });
    }
    setDateOptions(options);
  }, []);

  const generateDateObjects = (dateRanges) => {
    return dateRanges.map((dateRange) => {
      const [startDate, endDate] = dateRange.split(" - ");
      return {
        start_date: moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        end_date: moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
    });
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName !== "advanced") {
      setFromShift([]);
      setToShift([]);
    }
  };

  useEffect(() => {
    if (selectedFromShift && selectedFromShift.value) {
      const dates = selectedFromShift.value.split(" ");
      const startDate = moment(dates[0]);
      const endDate = moment(dates[2]);
      setCurrentDates(startDate, endDate);
    }
    if (selectedToShift) {
      setNextForthnights(generateDateObjects(selectedToShift));
    }
  }, [selectedFromShift, selectedToShift]);

  const setCurrentDates = (startDate, endDate) => {
    setCurrentStart(startDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    setCurrentEnd(endDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
  };

  const handleDatesNextForthnightly = () => {
    const selectedStartDate = moment(startDate);
    const endDateCurrentFortnight = selectedStartDate.clone().add(13, "days");
    const today = moment();
    const startOfThisWeek = today.clone().startOf("isoWeek");
    const selectedStartDateOfCurrentFortnight = moment(startOfThisWeek);
    const endDateOfCurrentFortnight = selectedStartDateOfCurrentFortnight
      .clone()
      .add(13, "days");
    const nextStartDate = endDateOfCurrentFortnight.clone().add(1, "day");
    const endDateNextFortnight = nextStartDate.clone().add(13, "days");
    setCurrentDates(selectedStartDate, endDateCurrentFortnight);
    const nextForthnight = {
      start_date: nextStartDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end_date: endDateNextFortnight.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    };
    setSingleForthnight([nextForthnight]);
  };

  const handleSubmit = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    setLoading(true);

    const payload = {
      start_date: currentStart,
      end_date: currentEnd,
      till_dates:
        activeButton !== "advanced" ? forSingleNextFortnight : nextForthnights,
    };

    try {
      const response = await apiPostRequest(
        URL_ROSTERING_FORTNIGHTLY_COPY_SHIFT,
        payload
      );
      if (response && response.data) {
        setForthnightlyCopyShiftsMessage(response?.data);
        setCopyShifts(false);
        setIsMessage(true);
        setLoading(false);
        recurringMessageModal(true);
      }
    } catch (error) {
      setLoading(false);

      toast.error("No Shift(s) found");
      console.error("Error occurred:", error);
    }
  };

  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog as='div' className='relative z-[999]' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                style={{
                  minWidth: "350px",
                  maxWidth: "350px",
                  overflowY: "auto",
                }}
                className={`relative py-2 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all `}
              >
                <div className='p-2 bg-white'>
                  <div className='w-full flex justify-end'>
                    <IoClose
                      className='rounded-full  w-6 h-6 text-[#545454] bg-[#F3F3F3] cursor-pointer'
                      onClick={() => setCopyShifts(false)}
                    />
                  </div>
                  <div className='py-4 px-4 flex flex-col gap-y-4'>
                    <label className='text-[#000] font-bold text-xl'>
                      Copy Shift
                    </label>
                    <p className='text-md font-bold text-[#000] '>Dates</p>
                    <div>
                      <CustomButton
                        variant={null}
                        cssClass={`cursor-pointer w-full border relative ${
                          activeButton === "nextWeek"
                            ? "bg-[#516CB8] text-white "
                            : "bg-[#ffffff] text-[#7D7D7D] border-[#E4E4E4]"
                        }  gap-3  flex items-center justify-center p-2 text-base  rounded-lg`}
                        label={
                          <>
                            Copy to next fortnightly
                            <div
                              className={`mx-2 h-6 w-6 rounded-full flex items-center cursor-pointer justify-center border ${
                                activeButton === "nextWeek" ? "bg-white" : ""
                              } `}
                            >
                              {activeButton === "nextWeek" && (
                                <FaCheck className='bg-white text-[#516cb8]' />
                              )}
                            </div>
                          </>
                        }
                        clickHandler={() => {
                          handleButtonClick("nextWeek");
                          handleDatesNextForthnightly();
                        }}
                      />
                    </div>
                    <div>
                      <CustomButton
                        variant={null}
                        cssClass={`cursor-pointer w-full border ${
                          activeButton === "advanced"
                            ? "bg-[#516CB8] text-white "
                            : "bg-[#ffffff] text-[#7D7D7D] border-[#E4E4E4]"
                        }  gap-3  flex items-center justify-center p-2 text-base  rounded-lg`}
                        label={
                          <>
                            More Options
                            {activeButton === "advanced" ? (
                              <IoIosArrowDown className='ml-2 font-bold' />
                            ) : (
                              <IoIosArrowForward className='ml-2 font-bold' />
                            )}
                          </>
                        }
                        clickHandler={() => handleButtonClick("advanced")}
                      />
                    </div>
                  </div>
                </div>

                {activeButton === "advanced" && (
                  <div className='p-6 bg-[#F5F5F5]'>
                    <div>
                      <p className='text-md font-bold text-[#000] '>
                        Copy shifts from
                      </p>

                      <div className='mt-2'>
                        <SelectOption
                          cssClass=''
                          isSearch={false}
                          data={dateOptions}
                          selectedOption={selectedFromShift}
                          handleChange={(e) => setFromShift(e)}
                        />
                      </div>
                    </div>
                    <div className='mt-4'>
                      <p className='text-md font-bold text-[#000] '>
                        Copy shifts to
                      </p>

                      <div className='mt-2'>
                        <MultipleSelection
                          isSearch={false}
                          data={dateUptoOptions}
                          isMulti
                          selectedOption={selectedToShift}
                          handleChange={(e) => setToShift(e)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className='text-end w-full px-6 mt-2'>
                  <CustomButton
                    variant={null}
                    cssClass={`cursor-pointer  border  gap-3  flex items-center justify-center p-2 text-base  rounded-lg bg-[#516CB8] text-white`}
                    label={loading ? "loding..." : "Apply"}
                    clickHandler={() => handleSubmit()}
                    isDisabled={
                      (activeButton === "nextWeek"
                        ? forSingleNextFortnight.length === 0
                        : nextForthnights.length === 0) || isUserGracePeriodOver
                    }
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CopyShift;
