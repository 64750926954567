import React from "react";
import { Link } from "react-router-dom";
import { formatDateIntoDMY, textTruncate } from "Utils/utils";
import { HiOutlineEye } from "react-icons/hi";

const COSInvoiceTabel = (props) => {
  const { item } = props;
  return (
    <tr className="text-center even:bg-white odd:bg-gray-50">
      <td className=" px-3 py-4 text-sm text-gray-500 text-start ">
        <div className="text-gray-500">{item.ref_no}</div>
      </td>
      <td className=" px-3 py-4 text-sm text-gray-500 text-start capitalize">
        <div className="text-gray-500 capitalize" title={item.created_by}>
          {textTruncate(item.created_by, 30, 29)}
        </div>
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start">
        <div className="text-gray-500">
          {formatDateIntoDMY(item.extraction_date)}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
        <div className="text-gray-500">
          {formatDateIntoDMY(item.delivered_from)}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
        <div className="text-gray-500">
          {formatDateIntoDMY(item.delivered_to)}
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
        <div className="text-gray-500">{item?.num_delivered_ser}</div>
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
        <div className="text-gray-500">
          {`$${parseFloat(item.total_amount).toFixed(2) || 0}`}
        </div>
      </td>
      <td>
        <div>
          <Link
            className="icon-eye-wrapper inline-block"
            to={`/cos/invoice-preview/${item.uuid}`}
            title="View"
          >
            <HiOutlineEye className="icon-size" />
          </Link>
        </div>
      </td>
    </tr>
  );
};

export default COSInvoiceTabel;
