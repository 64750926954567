import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";

import Search from "components/common/Search/Search";

import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  emptyPaginationObj,
  PRIMARY_DISABILITY_OPTIONS,
  REPORTING_LIMIT,
  SORT_TYPE,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import {
  URL_GEL_REPORTING,
  URL_REPORTING_COS_REPORTS,
  URL_REPORTING_KYP_REPORTS,
  URL_REPORTING_WORKER_REPORTS,
} from "store/services/URL";

import moment from "moment";
import { FiDelete } from "react-icons/fi";
import _ from "lodash";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { toast } from "react-toastify";

import { addMonths, exportTOExcel, formatDateReporting } from "Utils/utils";
import FundingTable from "./FundingTable";
import CaseNotesTable from "./CaseNotesTable";

const COSReports = ({
  clientsList,
  getClientList,

  supportCoordinator,
  getSupportCoordinatoreList,
  tableDataForReportingLoading,
}) => {
  console.log({ supportCoordinator });
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  useEffect(() => {
    getClientList();
    getSupportCoordinatoreList();
  }, []);

  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [workerList, setWorkerList] = useState([]);
  const [participantList, setParticipant] = useState([]);

  useEffect(() => {
    const updateLists = async () => {
      const workers =
        supportCoordinator?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedWorkers = workers.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setWorkerList(sortedWorkers);

      const participants =
        clientsList?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedParticipants = participants.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setParticipant(sortedParticipants);

      setParticipant((prevParticipantList) => [
        { label: "All", value: "all" },
        ...prevParticipantList,
      ]);

      setWorkerList((prevWorkerList) => [
        { label: "All", value: "all" },
        ...prevWorkerList,
      ]);
    };

    updateLists();
  }, [clientsList, supportCoordinator]);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [selectedClient, setSelectedClient] = useState(null);
  const [serviceContract, setserviceContract] = useState(null);
  const [reportType, setReportType] = useState("funding");
  const [selectedFromDate, setSelectedFromDate] = useState(null);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    fetchListReports();
  }, [page]);

  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = null
  ) => {
    if (!fromDate && !toDate) {
      toast("Select  date range to get data");
      return;
    }

    setLoader(true);
    let url = `${URL_REPORTING_COS_REPORTS}?per_page=25&page=${page}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_COS_REPORTS}?per_page=25&page=${page}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",
      pt_id: isClearAll
        ? "all"
        : selectedClient
        ? selectedClient?.value
        : "all",
      sp_cos_id: isClearAll
        ? "all"
        : serviceContract
        ? serviceContract?.value
        : "all",
      from_date: fromDate ? fromDate : "",
      to_date: toDate ? toDate : "",
      type: reportType,
    };
    if (report) {
      payload.report = report=== "xlsx" ? "excel" : report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            if(report === "xlsx"){
              const data=res?.data?.data;
              exportTOExcelFile(data)
            }else{
              window.open(`${res?.data?.data}`, "_blank");
            }
            // window.open(`${DOC_DOWNLOAD_URL}${res?.data?.data}`, "_blank");
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => {
              const isFundings = reportType === "funding";
              const dataSource = isFundings
                ? res?.data?.data?.fundings
                : res?.data?.data?.casenotes;

              return {
                ...prev,
                total: dataSource?.total,
                totalPages: Math.ceil(dataSource?.total / dataSource?.per_page),
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    fetchListReports(false, e);
    // if (e === "xlsx") {
    //   exportTOExcelFile();
    // } else {
    //   fetchListReports(false, e);
    // }
  };

  const exportTOExcelFile = (reportData) => {
    const isFundings = reportType === "funding";
    let transformedData = null;
    if (isFundings) {
      transformedData =
        reportData &&
        reportData.fundings?.data?.length > 0 &&
        reportData?.fundings?.data?.flatMap((item) => {
          return {
            Service_Contract_Ref_No: item?.service_contract_ref_no,
            Participant: `${item?.participant?.first_name} ${item?.participant?.last_name}`,
            Ndis_No: item?.participant?.ndis_no,
            Client_Status: item?.participant?.status,
            Service_Contract_Status: item.service_contract_status,
            Start_Date: moment(item.start_date).format("DD/MM/YYYY"),
            End_Date: moment(item.end_date).format("DD/MM/YYYY"),
            Total_Service_Contract_Amount: item?.total_service_contract_amount,
            Total_Delivered: item.amount_delivered,
            Total_Remaining: item.amount_remaining,
            Notes: item?.notes,
          };
        });
    } else {
      transformedData =
        reportData &&
        reportData.casenotes?.data?.length > 0 &&
        reportData?.casenotes?.data?.map((item) => {
          return {
            Ref_No: item?.delivered_service_number,
            Participant: `${item?.participant?.first_name} ${item?.participant?.last_name}`,
            Ndis_No: item?.participant?.ndis_no,
            Hours: item.hours,
            Rates: item?.original_rate,
            Notes: item?.notes,
          };
        });
    }

    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header title='COS Reports' />
          <div className='min-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.COS_REPORTS
                )}
              />
            </div>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between py-2'>
              <div className='w-[20%]'>
                <label className='text-sm font-medium text-gray-700 text-start'>
                  Select Report
                </label>
                <SelectOption
                  cssClass=''
                  name='report_type'
                  isSearch={false}
                  data={[
                    { value: "funding", label: "Funding" },
                    { value: "case-notes", label: "Case Notes" },
                  ]}
                  selectedOption={[
                    { value: "funding", label: "Funding" },
                    { value: "case-notes", label: "Case Notes" },
                  ].find((x) => x.value === reportType)}
                  handleChange={(e) => {
                    setReportType(e.value);
                  }}
                  // placeHolder="Select One"
                />
              </div>
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by participant name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='flex py-2 w-full justify-between items-end space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant
                        </label>
                        <SelectOption
                          isDisabled={tableDataForReportingLoading}
                          name='pt_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedClient}
                          handleChange={(e) => {
                            setSelectedClient(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Support Coordinators
                        </label>
                        <SelectOption
                          isDisabled={tableDataForReportingLoading}
                          name='contact_type_id'
                          isSearch={true}
                          data={workerList || []}
                          handleChange={(e) => {
                            setserviceContract(e);
                          }}
                          selectedOption={serviceContract}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-sm font-medium text-gray-700 text-start'>
                          Create Date Range (Max 3 months){" "}
                          <span className='text-red-400'>*</span>
                        </label>
                        <DateRangePickerNew
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                            setReports([]);
                          }}
                          handleChange={(obj) => {
                            if (obj) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          maxDate={addMonths(selectedFromDate, 3)}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end  space-x-2'
                        style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={() => fetchListReports(false)}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || false}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setSearch(null);
                            setReports([]);
                            setFromDate(null)
                            setToDate(null)
                            setSelectedClient(null);
                            setserviceContract(null);
                            setSelectedFromDate(null)
                            // fetchListReports(true);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div>
                {reportType === "funding" ? (
                  <FundingTable
                    flipSort={flipSort}
                    sortDescending={sortDescending}
                    sortBy={sortBy}
                    loader={loader}
                    reportList={reportList}
                  />
                ) : (
                  reportType === "case-notes" && (
                    <CaseNotesTable
                      flipSort={flipSort}
                      sortDescending={sortDescending}
                      sortBy={sortBy}
                      loader={loader}
                      reportList={reportList}
                    />
                  )
                )}
              </div>

              {reportList && (
                <>
                  {(reportType === "funding" &&
                    reportList?.fundings?.data?.length > 0) ||
                  (reportType === "case-notes" &&
                    reportList?.casenotes?.data?.length > 0) ? (
                    <div className='mt-3'>
                      <PaginationComponent
                        page={paginationObj?.page || 1}
                        activeListLength={
                          reportType === "funding"
                            ? reportList?.fundings?.data
                            : reportList?.casenotes?.data || []
                        }
                        limit={REPORTING_LIMIT}
                        totalList={paginationObj?.total}
                        handlePagination={(e) => {
                          setPage(e);
                          setPaginationObj((prev) => ({ ...prev, page: e }));
                        }}
                        totalPages={paginationObj?.totalPages}
                      />
                    </div>
                  ) : null}
                </>
              )}

              {/* <div className='mt-3'>
                <PaginationComponent
                  page={paginationObj?.page || 1}
                  activeListLength={
                    reportType === "funding"
                      ? reportList?.fundings?.data
                      : reportList?.casenotes?.data || []
                  }
                  limit={REPORTING_LIMIT}
                  totalList={paginationObj?.total}
                  handlePagination={(e) => {
                    setPage(e);
                    const obj = paginationObj;
                    obj.page = e;
                    setPaginationObj({ ...obj });
                  }}
                  totalPages={paginationObj?.totalPages}
                />
              </div> */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default COSReports;
