import createSelector from "../../Utils/reselect";

const participantStateData = (state) => {
  const { participant } = state;
  return participant || {};
};

export const getLoading = createSelector(
  participantStateData,
  (participant) => participant.get("loading") || false
);

export const getSupportPlanLoading = createSelector(
  participantStateData,
  (participant) =>
    participant.getIn(["selectedParticipant", "fetchSupportPlanLoading"]) ||
    false
);

export const getSelectedParticipant = createSelector(
  participantStateData,
  (participant) => {
    const participantObj =
      participant.getIn(["selectedParticipant", "participant"]) || null;
    if (participantObj) {
      const state_id_int = participantObj.state_id
        ? typeof participantObj.state_id === "string"
          ? parseInt(participantObj.state_id, 10)
          : participantObj.state_id
        : null;
      return { ...participantObj, state_id: state_id_int };
    }
    return participantObj;
  }
);

export const getSelectedParticipantOnBoardingProgress = createSelector(
  participantStateData,
  (participant) =>
    participant.getIn(["selectedParticipant", "participantOnBoarding"]) || null
);

export const getSelectedParticipantOnBoarding = createSelector(
  participantStateData,
  (participant) =>
    participant.getIn(["selectedParticipant", "participantOnBoarding"]) || null
);

export const getParticipantSupportPlan = createSelector(
  participantStateData,
  (participant) =>
    participant.getIn(["selectedParticipant", "participantSupportPlan"]) || null
);

export const getQuickSnapshotLoading = createSelector(
  participantStateData,
  (participant) =>
    participant.getIn(["selectedParticipant", "fetchQuickSnapshotLoading"]) ||
    false
);

export const getParticipantQuickSnapshot = createSelector(
  participantStateData,
  (participant) =>
    participant.getIn(["selectedParticipant", "participantQuickSnapshot"]) ||
    null
);

export const getSettingLoading = createSelector(
  participantStateData,
  (participant) =>
    participant.getIn(["selectedParticipant", "updateSettingLoading"]) || false
);

export const getAppointmentFetchLoading = createSelector(
  participantStateData,
  (participant) =>
    participant.getIn(["selectedParticipant", "appiontmentsLoading"]) || false
);

export const getAppointmentsList = createSelector(
  participantStateData,
  (participant) =>
    participant.getIn(["selectedParticipant", "participantAppointments"]) || []
);

export const getCreateAppointmentLoading = createSelector(
  participantStateData,
  (participant) =>
    participant.getIn(["selectedParticipant", "createAppiontmentsLoading"]) ||
    false
);
