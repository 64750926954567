import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS_CHILDREN,
} from "store/constants";

export const PARTICIPANT_LIST_LIMIT = 20;
export const SP_PARTICIPANTS_LIST_LIMIT = 10;
export const WORKER_DOCUMENT_LIST_LIMIT = 10;
export const ALL_FORM_LIST_LIMIT = 25;
export const POLICIES_LIST_LIMIT = 10;
export const CATEGORY_LIST_LIMIT = 10;
export const WORKER_DOCUMENT_LIMIT = 10;
export const FIVE_PAGE_LIMIT = 5;
export const CATALOG_SERVICES_LIST_LIMIT = 100;
export const FUNDING_SETUP_LIMIT = 10;
export const PLAN_BUDGET_LIMIT = 5;
export const COS_PARTICIPANT_LIST = 10;
export const SERVICE_SUPPORT_LIMIT = 5;
export const DELIVERED_SERVICE_LIMIT = 5;
export const FUNDING_CASE_NOTES_LIMIT = 10;

export const NDIS_CLAIM_NEW_LISTING_LIMIT = 10;

export const ROSTERING_CALENDAR_DATA_LIMIT = 20;
export const REPORTING_LIMIT = 25;

export const EMAIL_REGEX_EXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const DUMMY_REGEX_EXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const REGEX_HHH_MM =
  /^(?!0{1,3}:00$)(?:(?:\d{1,2}|1\d{2}|200|0\d\d):(?:[012345]\d))$/i;

export const REGEX_HH_MM =
  /^(?!00:00$)(?:[0123456789]\d|9[0123456789]):(?:[012345]\d)$/i;

export const REGEX_ALPHABETS_ONLY = /^[A-Za-z\d\s]+$/i;

// export const REGEX_PHONE_NUMBER = /^(04|1300|1800)[0-9]{6,8}$/i;
export const REGEX_PHONE_NUMBER = /^\d+$/;

export const REGEX_NDIS_NUMBER = /^(43\d{7}|5\d{8})$/i;

export const DUMMY_ADD_SERVICE_PROVIDER_VALUE = "add_service_provider";

export const FORM_TEMPLATE_INITIAL_ASSESMENT_UUID =
  "89dc0853-6cbf-4e03-8764-6750b289d75f";
export const FORM_TEMPLATE_INITIAL_ASSESMENT_SLUG = "initial-assessment-form";

export const CASE_NOTES_TIME_VALUES = [
  {
    label: "3 Minutes",
    value: "00:03",
  },
  {
    label: "5 Minutes",
    value: "00:05",
  },
  {
    label: "15 Minutes",
    value: "00:15",
  },
  {
    label: "30 Minutes",
    value: "00:30",
  },
  {
    label: "45 Minutes",
    value: "00:45",
  },
  {
    label: "1 hour",
    value: "01:00",
  },
  {
    label: "1 hour 15 minutes",
    value: "01:15",
  },
  {
    label: "1 hour 30 minutes",
    value: "01:30",
  },
  {
    label: "1 hour 45 minutes",
    value: "01:45",
  },
  {
    label: "2 hours",
    value: "02:00",
  },
  {
    label: "2 hour 15 minutes",
    value: "02:15",
  },
  {
    label: "2 hour 30 minutes",
    value: "02:30",
  },
  {
    label: "2 hour 45 minutes",
    value: "02:45",
  },
  {
    label: "3 hours",
    value: "03:00",
  },
  {
    label: "3 hour 15 minutes",
    value: "03:15",
  },
  {
    label: "3 hour 30 minutes",
    value: "03:30",
  },
  {
    label: "3 hour 45 minutes",
    value: "03:45",
  },
  {
    label: "4 hours",
    value: "04:00",
  },
  {
    label: "4 hour 15 minutes",
    value: "04:15",
  },
  {
    label: "4 hour 30 minutes",
    value: "04:30",
  },
  {
    label: "4 hour 45 minutes",
    value: "04:45",
  },
];

export const USER_ROLE = {
  Super_Admin: "super_admin",
  Sp_Admin: "sp_admin",
  Sp_manager: "sp_manager",
  Public: "public",
  Sp_Standard_User: "sp_standard_user",
};

export const USER_ROLE_TITLE = {
  super_admin: "Super Admin",
  sp_admin: "SP Admin",
  sp_manager: "SP Manager",
  // public: "Public",
  sp_standard_user: "Standard User",
};

export const APPLICANT_STATUS = {
  phoneScren: "phoneScreen",
  applied: "applied",
  interview: "interview",
  offer: "offer",
  hired: "hired",
  rejected: "rejected",
};

export const STATES_LIST = [

  { label: "ACT", value: "Australian Capital Territory" },
  { label: "NSW", value: "New south Wales" },
  { label: "QLD", value: "Queensland" },
  { label: "TAS", value: "Tasmania" },
  { label: "VIC", value: "Victoria" },
  { label: "WA", value: "Western Australia" },
  { label: "SA", value: "South Australia" },
  { label: "NT", value: "Northern Territory" },
];
export const SERVICE_CATALOG_STATES = [
  { label: "ACT", value: "Australian Capital Territory" },
  { label: "NSW", value: "New south Wales" },
  { label: "QLD", value: "Queensland" },
  { label: "TAS", value: "Tasmania" },
  { label: "VIC", value: "Victoria" },
  { label: "WA", value: "Western Australia" },
  { label: "SA", value: "South Australia" },
  { label: "Remote", value: "Remote" },
  { label: "Very Remote", value: "Very Remote" },
];
export const CATEGORY_ITEM_NAME_AND_NOTES = [
  { label: "High Intensity", value: "hi" },
  { label: "Standard", value: "s" },
  { label: "Standard TTP", value: "st" },
  { label: "High-Intensity TTP", value: "hit" },
];
export const NUMBER_OF_PARTICIPANTS = [
  { label: "Up to 20", value: "Up to 20" },
  { label: "21 to 100", value: "21 to 100" },
  { label: "100 to 200", value: "100 to 200" },
  { label: "200+", value: "200+" },
];

export const PARENT_FORM_CATEGORY_SLUG = {
  SUPPORT_PLAN: {
    NAME: "Support Plan",
    SLUG: "support-plan",
    ID: 4,
    UUID: "a73c7862-5fdf-47d3-981e-01fb1342e0ee",
  },
  MEDICATION: {
    NAME: "Medication",
    SLUG: "medication",
    ID: 3,
    UUID: "56b6c752-5556-4150-bc38-fda751552a8f",
  },
  PARTICIPANT_ASSESSMENT: {
    NAME: "Participant Onboarding",
    SLUG: "participant-on-boarding",
    ID: 1,
    UUID: "4ba21b10-336c-47c2-a253-8722de61881f",
  },
  RISK_MANAGEMENT: {
    NAME: "Risk Management",
    SLUG: "risk-management",
    ID: 2,
    UUID: "065c0296-3855-46a9-8c2e-1dd5d33d04eb",
  },
  PERSON_CENTERED_PLAN: {
    NAME: "Person Centered Plan",
    SLUG: "person-centered-plan",
    ID: 8,
    UUID: "6acf5293-12ba-4315-8c2a-ea437cf0c06b",
  },
  HIGH_CARE: {
    NAME: "High Care",
    SLUG: "high-care",
    ID: 5,
    UUID: "9adfe93b-4d0e-4164-87ca-29b5d5843200",
  },
  INJURY_MANAGEMENT: {
    NAME: "Injury Management",
    SLUG: "injury-management",
    ID: 7,
    UUID: "b61eaeac-c1d1-44e3-9817-0810ca0fff24",
  },
  Exit_Transaction: {
    NAME: "Exit/Transition",
    SLUG: "exit-transition",
    ID: 6,
    UUID: "c87cc0d9-5297-4294-9e50-5be2a475825c",
  },
  Compliance: {
    NAME: "Compliance",
    SLUG: "compliance",
    ID: 9,
    UUID: "ccc82e90-e98a-4aab-8dc7-5a6e46a78215",
  },
  Performance_Management: {
    NAME: "Performance management",
    SLUG: "performance-management",
    ID: 10,
    UUID: "59e9e626-2a38-42bb-bf5f-1da2f875a916",
  },
  Training_And_Development: {
    NAME: "Training-And-Development",
    SLUG: "training-and-development",
    ID: 11,
    UUID: "99331260-82e5-4cc7-9e7b-3aa222e1f0f2",
  },
  Other: {
    NAME: "Other",
    SLUG: "other",
    ID: 12,
    UUID: "848bdb7d-5a8d-430d-ab41-8b1670c0deae",
  },
  Onboarding: {
    NAME: "Onboarding",
    SLUG: "onboarding",
    ID: 13,
    UUID: "4b8d5670-62f8-46bb-a293-bf0adaf3b5d1",
  },
};

export const APPLICANT_STATUS_UUID = {
  Applied: "7f294437-417a-4d08-89bb-4c9f1ffe059b",
  PhoneScreening: "d8fa4a48-a67f-4d95-95d7-fdeb8f913a9f",
  Disqualified: "0fd4fe1e-f06f-4d8c-9991-074cb90ff17c",
  Interview: "7e563ec1-c576-41fa-9895-5763c98d589a",
  Pending: "dc40b516-db30-4ac0-b774-77e70994ed7c",
  Offer: "312924a3-cda0-48bd-a180-000caf9ba316",
  OnBoardingHired: "b627f4dd-f458-4f38-8171-4402de2200d6",
};

export const COS_STATUS = {
  Category: "category",
  Contact: "contact",
  Periority: "periority",
};

export const APPLICANT_PROFILE_TAB = {
  profile: "profile",
  resume: "resume",
  notes: "notes",
  questions: "questions",
};

export const WORKER_PROFILE_TAB = {
  profile: "profile",
  documents: "documents",
  settings: "settings",
};

export const ROSTERING_TIMESHEET = {
  timesheet: "timesheet",
  history: "history",
  comments: "comments",
};

export const APPLICANT_ASSESSMENT_STATUS = {
  hired: "hired",
  rejected: "reject",
  pending: "pending",
  Hired: "Hired",
  Rejected: "Reject",
  Pending: "Pending",
};

export const DateValidaionOptions = [
  { label: "Past", value: "past" },
  { label: "Present", value: "present" },
  { label: "Future", value: "future" },
];

export const statusOption = [
  { label: "Hired", value: "hired" },
  { label: "Reject", value: "reject" },
  { label: "Pending", value: "pending" },
];

export const roleOption = [
  { label: "Worker", value: "worker" },
  { label: "provider", value: "provider" },
];

export const portalOption = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const internalRoleOption = [
  { label: "Worker", value: "worker" },
  // { label: "HR officer", value: "hrOfficer" },
  { label: "Support coordinator", value: "supportCoordination" },
];

export const planManagedByOptions = [
  { label: "Self Managed", value: "selfManaged" },
  { label: "Plan Managed", value: "planManaged" },
  { label: "NDIA Managed", value: "ndiaManaged" },
];

export const employeeTypeOption = [
  { label: "Casual", value: "casual" },
  { label: "Part Time", value: "parttime" },
  { label: "Full Time", value: "fulltime" },
];

export const ALL_APPLICANT_TABS = [
  {
    name: "Applied",
    value: APPLICANT_STATUS_UUID.Applied,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Phone Screening",
    value: APPLICANT_STATUS_UUID.PhoneScreening,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Interview",
    value: APPLICANT_STATUS_UUID.Interview,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Pending",
    value: APPLICANT_STATUS_UUID.Pending,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Offer",
    value: APPLICANT_STATUS_UUID.Offer,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Hired",
    value: APPLICANT_STATUS_UUID.OnBoardingHired,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Rejected",
    value: APPLICANT_STATUS_UUID.Disqualified,
    href: "#",
    count: "",
    current: false,
  },
];

export const COS_SETTINGS_TABS = [
  {
    name: "Catogery",
    value: COS_STATUS.Category,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Contact",
    value: COS_STATUS.Contact,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Periority",
    value: COS_STATUS.Periority,
    href: "#",
    count: "",
    current: false,
  },
];

export const PARTICIPANT_PROFILE_TABS = [
  {
    name: "Overview",
    id: 1,
    children: [],
    roleAccess: [
      USER_ROLE.Sp_Admin,
      USER_ROLE.Sp_Standard_User,
      USER_ROLE.Sp_manager,
    ],
  },
  // {
  //   name: "Activity",
  //   id: 2,
  //   children: [],
  // },
  {
    name: "Case Notes",
    id: 16,
    children: [],
    roleAccess: [
      USER_ROLE.Sp_Admin,
      USER_ROLE.Sp_Standard_User,
      USER_ROLE.Sp_manager,
    ],
    permissions: BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT.CASE_NOTES,
  },
  // {
  //   name: "Support Plan",
  //   id: 3,
  //   children: [],
  // },
  {
    name: "Funding",
    id: 17,
    children: [],
    roleAccess: [
      USER_ROLE.Sp_Admin,
      USER_ROLE.Sp_manager,
      USER_ROLE.Sp_Standard_User,
    ],
    permissions: PERMISSION_KEYS_CHILDREN.PARTICIPANT.FUNDING,
  },
  {
    name: "Forms",
    id: 4,
    roleAccess: [
      USER_ROLE.Sp_Admin,
      USER_ROLE.Sp_Standard_User,
      USER_ROLE.Sp_manager,
    ],
    children: [
      {
        name: "Participant Assessment",
        id: 6,
        children: [],
        slug: PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG,
        roleAccess: [
          USER_ROLE.Sp_Admin,
          USER_ROLE.Sp_Standard_User,
          USER_ROLE.Sp_manager,
        ],
        permissions:
          BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT.FORM.ASSESSMENT,
      },
      {
        name: "Medication",
        id: 5,
        children: [],
        slug: PARENT_FORM_CATEGORY_SLUG.MEDICATION.SLUG,
        roleAccess: [
          USER_ROLE.Sp_Admin,
          USER_ROLE.Sp_Standard_User,
          USER_ROLE.Sp_manager,
        ],
      },
      {
        name: "High Care",
        id: 8,
        children: [],
        slug: PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG,
        roleAccess: [
          USER_ROLE.Sp_Admin,
          USER_ROLE.Sp_Standard_User,
          USER_ROLE.Sp_manager,
        ],
      },
      {
        name: "Injury/Incident Management",
        id: 9,
        children: [],
        slug: PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.SLUG,
        roleAccess: [
          USER_ROLE.Sp_Admin,
          USER_ROLE.Sp_Standard_User,
          USER_ROLE.Sp_manager,
        ],
      },
      {
        name: "Exit/Transition",
        id: 18,
        children: [],
        slug: PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.SLUG,
        roleAccess: [
          USER_ROLE.Sp_Admin,
          USER_ROLE.Sp_Standard_User,
          USER_ROLE.Sp_manager,
        ],
      },
    ],
  },
  {
    name: "Documents",
    id: 10,
    children: [],
    roleAccess: [
      USER_ROLE.Sp_Admin,
      USER_ROLE.Sp_Standard_User,
      USER_ROLE.Sp_manager,
    ],
    permissions: PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOCUMEMT,
  },
  {
    name: "Risk Assessment",
    id: 11,
    slug: PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG,
    children: [],
    roleAccess: [
      USER_ROLE.Sp_Admin,
      USER_ROLE.Sp_Standard_User,
      USER_ROLE.Sp_manager,
    ],
  },
  {
    name: "Appointments",
    id: 12,
    children: [],
    roleAccess: [
      USER_ROLE.Sp_Admin,
      USER_ROLE.Sp_Standard_User,
      USER_ROLE.Sp_manager,
    ],
  },
  {
    name: "Settings",
    id: 13,
    children: [],
    roleAccess: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    permissions: PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING,
  },
];

export const APPLICANT_REQ_STATUS_OPTIONS = Object.keys(APPLICANT_STATUS_UUID)
  .filter((x) => {
    if (x === "Disqualified" || x === "OnBoardingHired") return null;
    return x;
  })
  .map((c) => {
    const obj = {
      label: c,
      value: APPLICANT_STATUS_UUID[c],
      isCheck: false,
    };
    return obj;
  });

export const GenderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
  { label: "Preferred not to answer", value: "Preferred not to answer" },
];

export const SalarySlabOptions = [
  { label: "Level 1 - pay point 1", value: "Level 1 - pay point 1" },
  { label: "Level 1 - pay point 2", value: "Level 1 - pay point 2" },
  { label: "Level 1 - pay point 3", value: "Level 1 - pay point 3" },
  { label: "Level 2 - pay point 1", value: "Level 2 - pay point 1" },
  { label: "Level 2 - pay point 2", value: "Level 2 - pay point 2" },
  { label: "Level 2 - pay point 3", value: "Level 2 - pay point 3" },
  { label: "Level 2 - pay point 4", value: "Level 2 - pay point 4" },
  { label: "Level 3 - pay point 1", value: "Level 3 - pay point 1" },
  { label: "Level 3 - pay point 2", value: "Level 3 - pay point 2" },
  { label: "Level 3 - pay point 3", value: "Level 3 - pay point 3" },
  { label: "Level 3 - pay point 4", value: "Level 3 - pay point 4" },
  { label: "Level 4 - pay point 1", value: "Level 4 - pay point 1" },
  { label: "Level 4 - pay point 2", value: "Level 4 - pay point 2" },
  { label: "Level 4 - pay point 3", value: "Level 4 - pay point 3" },
  { label: "Level 4 - pay point 4", value: "Level 4 - pay point 4" },
  { label: "Level 5 - pay point 1", value: "Level 5 - pay point 1" },
  { label: "Level 5 - pay point 2", value: "Level 5 - pay point 2" },
  { label: "Level 5 - pay point 3", value: "Level 5 - pay point 3" },
  { label: "Level 6 - pay point 1", value: "Level 6 - pay point 1" },
  { label: "Level 6 - pay point 2", value: "Level 6 - pay point 2" },
  { label: "Level 6 - pay point 3", value: "Level 6 - pay point 3" },
  { label: "Level 7 - pay point 1", value: "Level 7 - pay point 1" },
  { label: "Level 7 - pay point 2", value: "Level 7 - pay point 2" },
  { label: "Level 7 - pay point 3", value: "Level 7 - pay point 3" },
  { label: "Level 8 - pay point 1", value: "Level 8 - pay point 1" },
  { label: "Level 8 - pay point 2", value: "Level 8 - pay point 2" },
  { label: "Level 8 - pay point 3", value: "Level 8 - pay point 3" },
];

export const emptyPaginationObj = {
  page: 1,
  totalPages: 0,
  total: 0,
  sort_type: null,
  sort_field: null,
  filter: null,
};

export const SORT_TYPE = {
  ASC: "asc",
  DESC: "desc",
};

export const formTemplateStatusOptions = [
  { label: "Publish", value: "publish" },
  { label: "Draft", value: "draft" },
];

export const StatusOptions = [
  { label: "Both", value: "both" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const documentStatus = [
  { label: "All", value: "all" },
  { label: "Uploaded", value: "uploaded" },
  { label: "Not Uploaded", value: "notuploaded" },
  { label: "Expired", value: "expired" },
];

export const employeeStatusOptions = [
  { label: "Select Status", value: "both" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const activeAndDeactiveOptions = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "In Active",
    value: "inactive",
  },
];

export const LOADER_STATUS = {
  NA: -1,
  PENDING: 1,
  COMPLATE: 0,
};

export const WORKER_CATEGORY_DOCUMENT = [
  { id: 1, label: "Identification", value: "Identification" },
  { id: 2, label: "Qualification", value: "Qualification" },
  { id: 3, label: "Screening", value: "Screening" },
  { id: 4, label: "Other", value: "Other" },
];

export const WORKER_PROFILE_TABS = [
  {
    id: 1,
    name: "Profile",
    href: "#",
    current: true,
    value: "Profile",
  },
  {
    id: 2,
    name: "Documents",
    href: "#",
    current: false,
    value: "Documents",
  },

  {
    id: 3,
    name: "Settings",
    href: "#",
    current: false,
    value: "setting",
  },
  {
    name: "Forms",
    id: 4,
    roleAccess: [
      USER_ROLE.Sp_Admin,
      USER_ROLE.Sp_Standard_User,
      USER_ROLE.Sp_manager,
    ],
    children: [
      {
        name: "Compliance",
        id: 5,
        children: [],
        slug: PARENT_FORM_CATEGORY_SLUG.Compliance.SLUG,
        roleAccess: [
          USER_ROLE.Sp_Admin,
          USER_ROLE.Sp_Standard_User,
          USER_ROLE.Sp_manager,
        ],
      },
      {
        name: "Onboarding",
        id: 6,
        children: [],
        slug: PARENT_FORM_CATEGORY_SLUG.Onboarding.SLUG,
        roleAccess: [
          USER_ROLE.Sp_Admin,
          USER_ROLE.Sp_Standard_User,
          USER_ROLE.Sp_manager,
        ],
      },
      {
        name: "Other",
        id: 8,
        children: [],
        slug: PARENT_FORM_CATEGORY_SLUG.Other.SLUG,
        roleAccess: [
          USER_ROLE.Sp_Admin,
          USER_ROLE.Sp_Standard_User,
          USER_ROLE.Sp_manager,
        ],
      },

      {
        name: "Performance Management",
        id: 18,
        children: [],
        slug: PARENT_FORM_CATEGORY_SLUG.Performance_Management.SLUG,
        roleAccess: [
          USER_ROLE.Sp_Admin,
          USER_ROLE.Sp_Standard_User,
          USER_ROLE.Sp_manager,
        ],
      },
      {
        name: PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.NAME,
        id: 19,
        children: [],
        slug: PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.SLUG,
        roleAccess: [
          USER_ROLE.Sp_Admin,
          USER_ROLE.Sp_Standard_User,
          USER_ROLE.Sp_manager,
        ],
      },
    ],
  },
  {
    id: 9,
    name: "Training And Development",
    href: "#",
    current: false,
    value: "training and development",
  },
  // {
  //   name: "Training And Development",
  //   id: 9,
  //   children: [],
  //   slug: PARENT_FORM_CATEGORY_SLUG.Training_And_Development.SLUG,
  //   roleAccess: [
  //     USER_ROLE.Sp_Admin,
  //     USER_ROLE.Sp_Standard_User,
  //     USER_ROLE.Sp_manager,
  //   ],
  // },
];

export const ROSTERING_TIMESHEET_TABS = [
  {
    name: "Timesheet",
    value: ROSTERING_TIMESHEET.timesheet,
    current: true,
  },
  {
    name: "History",
    value: ROSTERING_TIMESHEET.history,
    current: false,
  },
  {
    name: "Comments",
    value: ROSTERING_TIMESHEET.comments,
    current: false,
  },
];

export const PARTICIPANT_TABLE_TYPE = {
  teamManagement: "teamManagement",
  participant: "participant",
};

export const formOptions = [
  { id: "both", label: "Both", value: "both" },
  { id: false, label: "Incomplete", value: "Incomplete" },
  { id: true, label: "Complete", value: "Complete" },
];
export const docTypes = [
  { id: 0, label: "Select category", value: "both" },
  { id: 1, label: "Current Documents", value: "Current Documents" },
  { id: 2, label: "Inactive Plan Documents", value: "Inactive Plan Documents" },
  { id: 3, label: "Medical Documents", value: "Medical Documents" },
  {
    id: 4,
    label: "Intake Related Documents",
    value: "Intake Related Documents",
  },
  { id: 5, label: "Reporting Documents", value: "Reporting Documents" },
  { id: 6, label: "Other Documents", value: "Other Documents" },
  { id: 7, label: "General Documents", value: "General Documents" },
];

export const TOAST_TYPE = {
  DEFAULT: "default",
  SUCCESS: "success",
  ERROR: "error",
};

export const RISK_IMPACT_OPTIONS = [
  { value: "Negligible", label: "Negligible" },
  { value: "Minor", label: "Minor" },
  { value: "Moderate", label: "Moderate" },
  { value: "Significant", label: "Significant" },
  { value: "Sever", label: "Severe" },
];

export const RISK_LIKELEHOOD_OPTIONS = [
  { value: "Very unlikely", label: "Very unlikely" },
  { value: "Unlikely", label: "Unlikely" },
  { value: "Possible", label: "Possible" },
  { value: "Likely", label: "Likely" },
  { value: "Very Likely", label: "Very likely" },
];

export const SelectTypeOptions = [
  { value: "text", label: "Text" },
  { value: "number", label: "Number" },
  { value: "date", label: "Date" },
  { value: "email", label: "Email" },
  { value: "checkbox", label: "Checkbox" },
  { value: "select", label: "Select" },
  { value: "radio", label: "Radio" },
  { value: "textarea", label: "Textarea" },
  { value: "phone", label: "Phone" },
  { value: "address", label: "Address" },
  { value: "file", label: "File" },
  { value: "signature", label: "Signature" },
  { value: "table", label: "Table" },
  { value: "content", label: "Content" },
];

export const PRIMARY_DISABILITY_OPTIONS = [

  { label: "Acute Brain Injury", value: "Acute Brain Injury" },
  { label: "Amputation", value: "Amputation" },
  { label: "Anxiety", value: "Anxiety" },
  { label: "Aquired Brain Injury", value: "Aquired Brain Injury" },
  {
    label: "Attention Deficit Disorder (ADHD)",
    value: "Attention Deficit Disorder (ADHD)",
  },
  { label: "Autism", value: "Autism" },
  { label: "Bi-polar Disorder", value: "Bi-polar Disorder" },
  { label: "Blind", value: "Blind" },
  {
    label: "Borderline Autism, Learning disability",
    value: "Borderline Autism, Learning disability",
  },
  {
    label: "Borderline Personality Disorder",
    value: "Borderline Personality Disorder",
  },
  { label: "Cerebral Palsy", value: "Cerebral Palsy" },
  { label: "Chronic Lung Disease", value: "Chronic Lung Disease" },
  { label: "Collapse Of Vertibra", value: "Collapse Of Vertibra" },
  { label: "Craniosynostosis", value: "Craniosynostosis" },
  { label: "Depression", value: "Depression" },
  { label: "Developmental Delay", value: "Developmental Delay" },
  { label: "Down Syndrome", value: "Down Syndrome" },
  { label: "Epileps", value: "Epileps" },
  { label: "Hearing Impairment", value: "Hearing Impairment" },
  {
    label: "Hemiparesis On The Left Side Of The Body After A Stroke Incident",
    value: "Hemiparesis On The Left Side Of The Body After A Stroke Incident",
  },
  { label: "Hypothyroidism", value: "Hypothyroidism" },
  { label: "Intellectual Disability", value: "Intellectual Disability" },
  { label: "Kabuki Syndrome", value: "Kabuki Syndrome" },
  { label: "Level 2 Autism", value: "Level 2 Autism" },
  { label: "Major Depressive Disorder", value: "Major Depressive Disorder" },
  { label: "Mental Health Issues", value: "Mental Health Issues" },
  { label: "Monoplegia", value: "Monoplegia" },
  { label: "Multiple Sclerosis", value: "Multiple Sclerosis" },
  { label: "Muscular Dystrophy", value: "Muscular Dystrophy" },
  { label: "Paranoidski Phresnia", value: "Paranoidski Phresnia" },
  { label: "PEG Fed", value: "PEG Fed" },
  { label: "Rheumatoid Arthritis", value: "Rheumatoid Arthritis" },
  { label: "Schizophrenia", value: "Schizophrenia" },
  { label: "Scoliosis", value: "Scoliosis" },
  { label: "Shashi- Pena Syndrome", value: "Shashi- Pena Syndrome" },
  { label: "Speech Impairment", value: "Speech Impairment" },
  { label: "Spinal Injury", value: "Spinal Injury" },
  {
    label: "Ventricular Septal Defect (VSD)",
    value: "Ventricular Septal Defect (VSD)",
  },
  { label: "Vision Disability", value: "Vision Disability" },
  { label: "Visual Impairment", value: "Visual Impairment" },
  { label: "VP Shunt", value: "VP Shunt" },
  { label: "Parkinson", value: "Parkinson" },
  { label: "Other", value: "other" },
];

export const FUNDIND_SOURCE_OPTIONS = [
  { label: "NDIS", value: "NDIS" },
  { label: "Self", value: "self" },
];

export const MOMENT_ADD_OPTIONS = {
  DAYS: "days",
  MONTH: "M",
};

export const FUNDING_TABS_NAME = {
  PLAN_BUDGET: "planBudget",
  SERVICE_PROVIDER_CATAGORY_BUDGET: "serviceProviderCatagoryBudget",
  SERVICE_SUPPORT: "serviceSupport",
  DELIVERED_SERVICES: "deliveredServices",
  MANUAL_DELIVERED_SERVICES: "manualDeliveredServices",
  NOTES: "notes",
};

export const FUNDING_TABS = [
  {
    name: "Plan Budget",
    value: FUNDING_TABS_NAME.PLAN_BUDGET,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Service Provider Category Budget",
    value: FUNDING_TABS_NAME.SERVICE_PROVIDER_CATAGORY_BUDGET,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Service Support",
    value: FUNDING_TABS_NAME.SERVICE_SUPPORT,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Delivered Services",
    value: FUNDING_TABS_NAME.DELIVERED_SERVICES,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Manual Delivery",
    value: FUNDING_TABS_NAME.MANUAL_DELIVERED_SERVICES,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Funding Notes",
    value: FUNDING_TABS_NAME.NOTES,
    href: "#",
    count: "",
    current: false,
  },
];

export const EXTERNAL_SERVICE_PROVIDER_TABS_NAME = {
  CASE_NOTES_LIST: "caseNotesList",
  CASE_NOTES: "caseNotes",
};

export const EXTERNAL_SERVICE_PROVIDER_TABS = [
  {
    name: "Case Notes List",
    value: EXTERNAL_SERVICE_PROVIDER_TABS_NAME.CASE_NOTES_LIST,
    href: "#",
    count: "",
    current: false,
  },
  {
    name: "Case Notes",
    value: EXTERNAL_SERVICE_PROVIDER_TABS_NAME.CASE_NOTES,
    href: "#",
    count: "",
    current: false,
  },
];

export const FUNDING_NOTES_TYPES = [
  { label: "Budget Report", value: "Budget Report" },
  { label: "General", value: "General" },
  { label: "NDIS Price Guide", value: "NDIS Price Guide" },
  { label: "Service Agreement", value: "Service Agreement" },
];

export const CLAIM_TYPE_OPTIONS = [
  { label: "STAND", value: "STAND" },
  { label: "CANC", value: "CANC" },
  { label: "REPW", value: "REPW" },
  { label: "TRAN", value: "TRAN" },
  { label: "NF2F", value: "NF2F" },
  { label: "THLT", value: "THLT" },
  { label: "IRSS", value: "IRSS" },
];

export const DELIVERED_SERVICE_STATUS_OPTIONS = [
  { label: "Cancelled", value: "cancelled" },
  { label: "Declined By Client", value: "declined_by_client" },
  { label: "Extract Generated", value: "extract_generated" },
  { label: "Extracted", value: "extracted" },
  { label: "Internal Invoice Approval", value: "internal_invoice_approval" },
  { label: "Invoiced", value: "invoiced" },
  {
    label: "Pending Appointment Completion",
    value: "Pending_appointment_completion",
  },
  { label: "Pending Client Approval", value: "Pending_client_approval" },
  { label: "Provider Paid", value: "provider_paid" },
  { label: "Self Managed", value: "selfManaged" },
  { label: "Plan Managed", value: "planManaged" },
  { label: "Ready For Extraction", value: "ready_for_extraction" },
  { label: "Ready For Invoicing", value: "ready_for_invoicing" },
];

export const CATEGORY_TYPE = {
  CORE: "core",
  CAPITAL: "capital",
  CAPACITY_BUILDING: "capacity building",
};

export const modifiedObj = { id: 1, name: "All", label: "All", value: "All" };

export const COUNT_ABBRS = ["", "K", "M", "B", "T", "Q", "E", "Z", "Y"];

export const SHIFT_STATUS = {
  PENDING: "pending",
  PUBLISHED: "published",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  NA: "N/A",
  APPROVED: "approved",
  DID_NOT_ACCEPT: "did not accept",
  EXPIRED: "expired",
};

export const TIMESHEET_STATUS = {
  PENDING: "pending",
  DISCARDED: "discarded",
  REJECTED: "rejected",
  NA: "N/A",
  APPROVED: "approved",
  PAID: "paid",
};

export const TIMESHEET_PROGRESS_STATUS = {
  CREATED: { label: "created", value: "created" },
  UN_SUBMITTED: { label: "un submitted", value: "un_submitted" },
  STARTED: { label: "started", value: "started" },
  PAY_APPROVED: { label: "pay approved", value: "pay_approved" },
  SUBMITTED: { labe: "submitted", value: "submitted" },
  FUTURE: { label: "future", value: "future" },
  PAID: { label: "paid", value: "paid" },
};

export const SHIFT_TIME_OPTIONS = [
  {
    value: "00:00",
    label: "00:00 AM",
  },
  {
    value: "00:15",
    label: "00:15 AM",
  },
  {
    value: "00:30",
    label: "00:30 AM",
  },
  {
    value: "00:45",
    label: "00:45 AM",
  },
  {
    value: "01:00",
    label: "01:00 AM",
  },
  {
    value: "01:15",
    label: "01:15 AM",
  },
  {
    value: "01:30",
    label: "01:30 AM",
  },
  {
    value: "01:45",
    label: "01:45 AM",
  },
  {
    value: "02:00",
    label: "02:00 AM",
  },
  {
    value: "02:15",
    label: "02:15 AM",
  },
  {
    value: "02:30",
    label: "02:30 AM",
  },
  {
    value: "02:45",
    label: "02:45 AM",
  },
  {
    value: "03:00",
    label: "03:00 AM",
  },
  {
    value: "03:15",
    label: "03:15 AM",
  },
  {
    value: "03:30",
    label: "03:30 AM",
  },
  {
    value: "03:45",
    label: "03:45 AM",
  },
  {
    value: "04:00",
    label: "04:00 AM",
  },
  {
    value: "04:15",
    label: "04:15 AM",
  },
  {
    value: "04:30",
    label: "04:30 AM",
  },
  {
    value: "04:45",
    label: "04:45 AM",
  },
  {
    value: "05:00",
    label: "05:00 AM",
  },
  {
    value: "05:15",
    label: "05:15 AM",
  },
  {
    value: "05:30",
    label: "05:30 AM",
  },
  {
    value: "05:45",
    label: "05:45 AM",
  },
  {
    value: "06:00",
    label: "06:00 AM",
  },
  {
    value: "06:15",
    label: "06:15 AM",
  },
  {
    value: "06:30",
    label: "06:30 AM",
  },
  {
    value: "06:45",
    label: "06:45 AM",
  },
  {
    value: "07:00",
    label: "07:00 AM",
  },
  {
    value: "07:15",
    label: "07:15 AM",
  },
  {
    value: "07:30",
    label: "07:30 AM",
  },
  {
    value: "07:45",
    label: "07:45 AM",
  },
  {
    value: "08:00",
    label: "08:00 AM",
  },
  {
    value: "08:15",
    label: "08:15 AM",
  },
  {
    value: "08:30",
    label: "08:30 AM",
  },
  {
    value: "08:45",
    label: "08:45 AM",
  },
  {
    value: "09:00",
    label: "09:00 AM",
  },
  {
    value: "09:15",
    label: "09:15 AM",
  },
  {
    value: "09:30",
    label: "09:30 AM",
  },
  {
    value: "09:45",
    label: "09:45 AM",
  },
  {
    value: "10:00",
    label: "10:00 AM",
  },
  {
    value: "10:15",
    label: "10:15 AM",
  },
  {
    value: "10:30",
    label: "10:30 AM",
  },
  {
    value: "10:45",
    label: "10:45 AM",
  },
  {
    value: "11:00",
    label: "11:00 AM",
  },
  {
    value: "11:15",
    label: "11:15 AM",
  },
  {
    value: "11:30",
    label: "11:30 AM",
  },
  {
    value: "11:45",
    label: "11:45 AM",
  },
  {
    value: "12:00",
    label: "12:00 PM",
  },
  {
    value: "12:15",
    label: "12:15 PM",
  },
  {
    value: "12:30",
    label: "12:30 PM",
  },
  {
    value: "12:45",
    label: "12:45 PM",
  },
  {
    value: "13:00",
    label: "01:00 PM",
  },
  {
    value: "13:15",
    label: "01:15 PM",
  },
  {
    value: "13:30",
    label: "01:30 PM",
  },
  {
    value: "13:45",
    label: "01:45 PM",
  },
  {
    value: "14:00",
    label: "02:00 PM",
  },
  {
    value: "14:15",
    label: "02:15 PM",
  },
  {
    value: "14:30",
    label: "02:30 PM",
  },
  {
    value: "14:45",
    label: "02:45 PM",
  },
  {
    value: "15:00",
    label: "03:00 PM",
  },
  {
    value: "15:15",
    label: "03:15 PM",
  },
  {
    value: "15:30",
    label: "03:30 PM",
  },
  {
    value: "15:45",
    label: "03:45 PM",
  },
  {
    value: "16:00",
    label: "04:00 PM",
  },
  {
    value: "16:15",
    label: "04:15 PM",
  },
  {
    value: "16:30",
    label: "04:30 PM",
  },
  {
    value: "16:45",
    label: "04:45 PM",
  },
  {
    value: "17:00",
    label: "05:00 PM",
  },
  {
    value: "17:15",
    label: "05:15 PM",
  },
  {
    value: "17:30",
    label: "05:30 PM",
  },
  {
    value: "17:45",
    label: "05:45 PM",
  },
  {
    value: "18:00",
    label: "06:00 PM",
  },
  {
    value: "18:15",
    label: "06:15 PM",
  },
  {
    value: "18:30",
    label: "06:30 PM",
  },
  {
    value: "18:45",
    label: "06:45 PM",
  },
  {
    value: "19:00",
    label: "07:00 PM",
  },
  {
    value: "19:15",
    label: "07:15 PM",
  },
  {
    value: "19:30",
    label: "07:30 PM",
  },
  {
    value: "19:45",
    label: "07:45 PM",
  },
  {
    value: "20:00",
    label: "08:00 PM",
  },
  {
    value: "20:15",
    label: "08:15 PM",
  },
  {
    value: "20:30",
    label: "08:30 PM",
  },
  {
    value: "20:45",
    label: "08:45 PM",
  },
  {
    value: "21:00",
    label: "09:00 PM",
  },
  {
    value: "21:15",
    label: "09:15 PM",
  },
  {
    value: "21:30",
    label: "09:30 PM",
  },
  {
    value: "21:45",
    label: "09:45 PM",
  },
  {
    value: "22:00",
    label: "10:00 PM",
  },
  {
    value: "22:15",
    label: "10:15 PM",
  },
  {
    value: "22:30",
    label: "10:30 PM",
  },
  {
    value: "22:45",
    label: "10:45 PM",
  },
  {
    value: "23:00",
    label: "11:00 PM",
  },
  {
    value: "23:15",
    label: "11:15 PM",
  },
  {
    value: "23:30",
    label: "11:30 PM",
  },
  {
    value: "23:45",
    label: "11:45 PM",
  },
];

//User Roles for Notification Settings

export const USER_ROLE_OPTIONS_NOTIFICATIONS_SETTINGS = [
  { label: "Manager", value: "manager" },
  { label: "Worker", value: "worker" },
  { label: "Support Cordinator", value: "support cordinator" },
];

//Key Strentgh,likes for worker profile settings
//Key Strentgh,likes for worker profile settings

export const STRENGTH_IMPROVEMENTS_OF_WORKERS = [
  { id: 1, label: "Adventurous", name: "adventurous" },
  { id: 2, label: "Detail-Oriented", name: "detail_oriented" },
  { id: 3, label: "Outgoing", name: "outgoing" },
  { id: 4, label: "Inward Focused", name: "inward_focused" },
  { id: 5, label: "Nature Loving", name: "nature_loving" },
  { id: 6, label: "Thoughtful and Reflective", name: "thoughtful_reflective" },
  { id: 7, label: "Resilient", name: "resilient" },
  { id: 8, label: "Loves Talking/Chat", name: "loves_talking" },
  { id: 9, label: "Risk-Taking", name: "risk_taking" },
  { id: 10, label: "Action-Oriented", name: "action_oriented" },
  { id: 11, label: "Broad Social Network", name: "broad_social_network" },
  { id: 12, label: "Optimistic", name: "optimistic" },
  { id: 13, label: "Impulsivity", name: "impulsivity" },
  { id: 14, label: "Emotional Reactivity", name: "emotional_reactivity" },
  { id: 15, label: "Social Relationships", name: "social_relationships" },
  { id: 16, label: "Communication Skills", name: "communication_skills" },
  { id: 17, label: "Self-Centeredness", name: "self_centeredness" },
  {
    id: 18,
    label: "Avoidance of Responsibility",
    name: "avoidance_of_responsibility",
  },
  { id: 19, label: "Risk-Averse", name: "risk_averse" },
  { id: 20, label: "Selective Socializing", name: "selective_socializing" },
  { id: 21, label: "Listener", name: "listener" },
  { id: 22, label: "Analytical", name: "analytical" },
  { id: 23, label: "Emotional Stability", name: "emotional_stability" },
  { id: 24, label: "Responsibility", name: "responsibility" },
  { id: 25, label: "Cognitive Abilities", name: "cognitive_abilities" },
  { id: 26, label: "Life Experience", name: "life_experience" },
  { id: 27, label: "Creative", name: "creative" },
];

function getRandomValue() {
  return Math.floor(Math.random() * 5) + 1; // Generates a random value between 1 and 5
}

export const RATING_STARS = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

export const HRM_FORM_NAME = {
  label: "training-register",
};

export const PAYROLL_EXPORT_STATUS = {
  NONE: "none",
  INITIATED: "initiated",
  SUCCESS: "success",
  FAILED: "failed",
  ERROR: "error",
};

export const portalTypeOptions = [
  { label: "Primary Portal", value: "blue_portal" },
  { label: "Frontline Portal", value: "black_portal" },
];

export const STRIPE_DASHBOARD_TABS = [
  {
    name: "Current Subscription",
    id: 3,
    children: [],
    roleAccess: [USER_ROLE.Sp_Admin],
  },
  {
    name: "Billing Method",
    id: 1,
    children: [],
    roleAccess: [USER_ROLE.Sp_Admin],
  },
  {
    name: "Invoices",
    id: 2,
    children: [],
    roleAccess: [USER_ROLE.Sp_Admin],
  },
];

export const StripeStatusOptions = [
  { label: "Paid", value: "paid" },
  { label: "Over Due", value: "open" },
  { label: "Draft", value: "draft" },
  { label: "canceled", value: "void" },
  // { label: "Uncollectible", value: "uncollectible" },
];

export const DOC_DOWNLOAD_URL = "https://docs.vertex360.io/";

export const SHIFT_STATUS_LIST = [

  { label: "Pending", value: "pending" },
  { label: "Published", value: "published" },
  { label: "Accepted", value: "accepted" },
  { label: "Rejected", value: "rejected" },
  { label: "N/A", value: "N/A" },
  { label: "Approved", value: "approved" },
  { label: "Did not accept", value: "did not accept" },
  { label: "Expired", value: "expired" },
];
export const SYSTEM_ROLES = [

  {
    label: "Frontline User",
    value: "sp_standard_user",
  },
  {
    label: "SP Admin",
    value: "sp_admin",
  },
  {
    label: "SP Manager",
    value: "sp_manager",
  },

];

export const DOCUMENTS_TYPE = [
  {
    label: "General Document",
    value: "general",
  },
  {
    label: "Required Document",
    value: "required",
  },
];

export const SHIFT_STATUS_LIST_IN_REPORTING = [
  { label: "All", value: "all" },
  { label: "Accepted", value: "accepted" },
  { label: "Completed", value: "completed" },
  { label: "Did not accept", value: "did not accept" },
  { label: "Expired", value: "expired" },
  { label: "Pending", value: "pending" },
  { label: "Published", value: "published" },


];

export const STATES_LIST_REPORTING = [
  { label: "All", value: "all" },
  { label: "ACT", value: "Australian Capital Territory" },
  { label: "NSW", value: "New south Wales" },
  { label: "QLD", value: "Queensland" },
  { label: "TAS", value: "Tasmania" },
  { label: "VIC", value: "Victoria" },
  { label: "WA", value: "Western Australia" },
  { label: "SA", value: "South Australia" },
  { label: "NT", value: "Northern Territory" },
];

export const PRIMARY_DISABILITY_OPTIONS_REPORTING = [
  { label: "All", value: "all" },
  { label: "Acute Brain Injury", value: "Acute Brain Injury" },
  { label: "Amputation", value: "Amputation" },
  { label: "Anxiety", value: "Anxiety" },
  { label: "Aquired Brain Injury", value: "Aquired Brain Injury" },
  {
    label: "Attention Deficit Disorder (ADHD)",
    value: "Attention Deficit Disorder (ADHD)",
  },
  { label: "Autism", value: "Autism" },
  { label: "Bi-polar Disorder", value: "Bi-polar Disorder" },
  { label: "Blind", value: "Blind" },
  {
    label: "Borderline Autism, Learning disability",
    value: "Borderline Autism, Learning disability",
  },
  {
    label: "Borderline Personality Disorder",
    value: "Borderline Personality Disorder",
  },
  { label: "Cerebral Palsy", value: "Cerebral Palsy" },
  { label: "Chronic Lung Disease", value: "Chronic Lung Disease" },
  { label: "Collapse Of Vertibra", value: "Collapse Of Vertibra" },
  { label: "Craniosynostosis", value: "Craniosynostosis" },
  { label: "Depression", value: "Depression" },
  { label: "Developmental Delay", value: "Developmental Delay" },
  { label: "Down Syndrome", value: "Down Syndrome" },
  { label: "Epileps", value: "Epileps" },
  { label: "Hearing Impairment", value: "Hearing Impairment" },
  {
    label: "Hemiparesis On The Left Side Of The Body After A Stroke Incident",
    value: "Hemiparesis On The Left Side Of The Body After A Stroke Incident",
  },
  { label: "Hypothyroidism", value: "Hypothyroidism" },
  { label: "Intellectual Disability", value: "Intellectual Disability" },
  { label: "Kabuki Syndrome", value: "Kabuki Syndrome" },
  { label: "Level 2 Autism", value: "Level 2 Autism" },
  { label: "Major Depressive Disorder", value: "Major Depressive Disorder" },
  { label: "Mental Health Issues", value: "Mental Health Issues" },
  { label: "Monoplegia", value: "Monoplegia" },
  { label: "Multiple Sclerosis", value: "Multiple Sclerosis" },
  { label: "Muscular Dystrophy", value: "Muscular Dystrophy" },
  { label: "Paranoidski Phresnia", value: "Paranoidski Phresnia" },
  { label: "PEG Fed", value: "PEG Fed" },
  { label: "Rheumatoid Arthritis", value: "Rheumatoid Arthritis" },
  { label: "Schizophrenia", value: "Schizophrenia" },
  { label: "Scoliosis", value: "Scoliosis" },
  { label: "Shashi- Pena Syndrome", value: "Shashi- Pena Syndrome" },
  { label: "Speech Impairment", value: "Speech Impairment" },
  { label: "Spinal Injury", value: "Spinal Injury" },
  {
    label: "Ventricular Septal Defect (VSD)",
    value: "Ventricular Septal Defect (VSD)",
  },
  { label: "Vision Disability", value: "Vision Disability" },
  { label: "Visual Impairment", value: "Visual Impairment" },
  { label: "VP Shunt", value: "VP Shunt" },
  { label: "Other", value: "other" },
];

export const SYSTEM_ROLES_REPORTING = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Frontline User",
    value: "sp_standard_user",
  },
  {
    label: "SP Admin",
    value: "sp_admin",
  },
  {
    label: "SP Manager",
    value: "sp_manager",
  },

];

export const TIMESHEET_STATUS_LIST_REPORTING = [
  { label: "All", value: "all" },
  { label: "Pending", value: "pending" },
  { label: "Published", value: "published" },
  { label: "Accepted", value: "accepted" },
  { label: "Rejected", value: "rejected" },

  { label: "Approved", value: "approved" },
  { label: "Did not accept", value: "did not accept" },
  { label: "Expired", value: "expired" },
];

export const StatusOptionsReporting = [
  { label: "Both", value: "all" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];