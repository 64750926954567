import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { apiPostRequest } from "../../../helpers/Requests";
import { toast } from "react-toastify";
import { AiOutlinePlus } from "react-icons/ai";
import { LoginContext } from "../../../helpers/LoginContext";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import PaginationComponent from "../../../components/common/Pagination/index";
import Search from "../../../components/common/Search/Search";
import {
  getPaginationObj,
  getUserRoleTitle,
  textTruncate,
} from "../../../Utils/utils";
import {
  emptyPaginationObj,
  POLICIES_LIST_LIMIT,
  SORT_TYPE,
} from "../../../Constants/constant";
import TableHeader from "../../../components/common/Table/TableHeader";
import { HiOutlineEye } from "react-icons/hi";
import CustomButton from "../../../components/common/Button/CustomButton";
import ModalSkeleton from "../../../components/Modals/ModalSkeleton";
import Header from "../../../components/common/Header/Header";
import moment from "moment";
const Feedback = (props) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const [name, setname] = useState(null);
  const [date, setdate] = useState(null);
  const [subject, setsubject] = useState(null);
  const [tableData, settableData] = useState(null);
  const [open, setOpen] = useState(false);
  const { getFeedbackList, feedbackObj, loading } = props;

  const [isOpen, setisOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const [feedbacks, setFeedbacks] = useState([]);
  const [search, setSearch] = useState(null);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (feedbackObj && "feedback_and_complaints" in feedbackObj) {
      setFeedbacks(feedbackObj.feedback_and_complaints);
      setPaginationObj(
        getPaginationObj(
          feedbackObj.feedback_and_complaints,
          feedbackObj.query_params
        )
      );
    } else if (feedbackObj && "submitted_forms" in feedbackObj) {
      setFeedbacks(feedbackObj.submitted_forms);
      setPaginationObj(
        getPaginationObj(feedbackObj.submitted_forms, feedbackObj.query_params)
      );
    }
  }, [feedbackObj]);

  const getFeedback = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: getPaginationObj.page,
      });
    }
    Object.assign(filters, { per_page: POLICIES_LIST_LIMIT });
    Object.assign(filters, { type: "feedback" });
    getFeedbackList(filters);
  };

  const dataSubmitHandler = async (data) => {
    setRequest({ ...request, isRequested: true });
    Object.assign(data, { type: "feedback" });
    try {
      let res = await apiPostRequest("/sp/feedback/store", data);
      if (res.status === 200) {
        const payload = {
          sort_field: sortBy,
          sort_order: sortDescending ? SORT_TYPE.ASC : SORT_TYPE.DESC,
        };
        if (search !== null) {
          Object.assign(payload, {
            search,
            page: 1,
          });
        }
        await getFeedback(payload);
        setRequest({ ...request, isRequested: false, isSuccess: true });
        toast.success(res.data.success);
        reset();
        closeModal();
      }
      if (res.status === 500) {
        throw new Error();
      }
      setRequest({ ...request, isRequested: false });
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    setSortBy(paginationObj.sort_field ? paginationObj.sort_field : null);
    if (paginationObj.sort_order)
      setSortDescending(
        paginationObj.sort_order
          ? paginationObj.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
  }, [paginationObj]);

  useEffect(() => {
    const obj = {};
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (
      !search &&
      parseInt(feedbackObj?.query_params?.page) !== paginationObj.page
    )
      handleFetch({ ...obj });
  }, [paginationObj.page]);

  const handleFetch = (obj) => {
    if (paginationObj.page) Object.assign(obj, { page: paginationObj.page });
    if (paginationObj.filter && !obj.hasOwnProperty("status")) {
      Object.assign(obj, { filter: paginationObj.filter });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filter: {
          status: [obj.status],
        },
      });
    }

    getFeedback({ ...obj });
  };

  //for calling api on refreshing the page
  useEffect(() => {
    handleFetch({
      search: search,
    });
  }, []);

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        handleFetch({
          search: search,
        }),
      600
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    dataSubmitHandler(data);
  };

  const closeModal = () => {
    setisOpen(false);
    reset();
  };
  const openModal = () => {
    setisOpen(true);
  };
  const closeModalTable = () => {
    setShowButton(true);
    setShouldTruncate(true);
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginationObj.page]);

  const modalFooterFeedBack = () => (
    <CustomButton
      type='submit'
      label='Submit'
      showLoading={request.isRequested}
      isDisabled={request.isRequested || isUserGracePeriodOver}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("allFeedBack");
        if (buttonRef) {
          buttonRef.click();
        }

        // sendEmail();
      }}
    />
  );
  const modalFooterInfo = () => (
    <CustomButton
      clickHandler={closeModalTable}
      type='button'
      label='Close'
      variant='danger'
    />
  );
  const [isopens, setIsopens] = useState(false);
  const [shouldTruncate, setShouldTruncate] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const handleClicks = () => {
    setIsopens(!isopens);
    setShouldTruncate(!shouldTruncate);
    setShowButton(false);
  };

  return (
    <div className='min-h-full'>
      <SideBar />
      <main>
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300 flex flex-col"
              : "lg:pl-64 duration-300 flex flex-col"
          }`}
        >
          <Header
            title='Feedback'
            desc='Directory of all Feedback'
            handleClick={openModal}
            btnLabel='Feedback'
            icon={<AiOutlinePlus />}
            isButton
          />
          <div>
            <div className='w-full absolute md:static'>
              <div className='flex sm:px-6 lg:px-8 justify-between items-center py-2 bg-white sticky top-20 '>
                <div className='w-1/3 md:w-2/12 lg:w-2/12'></div>
                <Search setSearch={setSearch} search={search} />
              </div>
              <div className='bg-white max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-10 pt-4'>
                <div className='flex flex-col'>
                  <div className='-my-2 -mx-4 overflow-x-auto111 scrollbar-hide1111 sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                      <div className='table-wrp shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                          <thead className='bg-blue-50 pt-2 sticky top-[131px] capitalize'>
                            <tr className=''>
                              <TableHeader
                                sortName='created_at'
                                flipSort={flipSort}
                                sortBy={sortBy}
                                sortDescending={sortDescending}
                                label={"Name"}
                              />
                              <th
                                scope='col'
                                className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                Date
                              </th>
                              <th
                                scope='col'
                                className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                Subject
                              </th>
                              <th
                                scope='col'
                                className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                Submitted By
                              </th>
                              <th
                                scope='col'
                                className='bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                User Role
                              </th>
                              <th
                                scope='col'
                                className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                Details
                              </th>
                              <th
                                scope='col'
                                className=' px-3 py-3.5 text-center text-sm font-semibold text-gray-900'
                              >
                                Action
                              </th>
                            </tr>
                          </thead>

                          <tbody className='divide-y divide-gray-200 bg-white'>
                            {loading ? (
                              <tr>
                                <td colSpan={7} className='bg-gray-50 py-2'>
                                  <CircularLoader
                                    classes='flex justify-center items-center'
                                    classloader='loader-l'
                                  />
                                </td>
                              </tr>
                            ) : feedbacks &&
                              feedbacks.data &&
                              feedbacks.data.length > 0 ? (
                              feedbacks.data.map((item, index) => (
                                <tr
                                  key={index}
                                  className='even:bg-white odd:bg-gray-50'
                                >
                                  <td
                                    title={item.name}
                                    className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'
                                  >
                                    <div className='w-[135px] truncate flex space-x-4 items-center'>
                                      {textTruncate(item.name, 14, 13)}
                                    </div>
                                  </td>

                                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {moment(item.date).format("DD-MM-YYYY")}
                                  </td>
                                  <td
                                    title={item.subject}
                                    className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 mt-1'
                                  >
                                    <div className='w-[135px] truncate flex space-x-4 items-center'>
                                      {textTruncate(item.subject, 15, 14)}
                                    </div>
                                  </td>
                                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {textTruncate(
                                      item?.created_by?.name,
                                      25,
                                      24
                                    )}
                                  </td>
                                  <td className='capitalize whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {getUserRoleTitle(
                                      item?.created_by?.user_type
                                    )}
                                  </td>
                                  <td
                                    title={item.details}
                                    className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'
                                  >
                                    <div className='w-64 truncate flex space-x-4 items-center'>
                                      {textTruncate(item.details, 25, 24)}
                                    </div>
                                  </td>
                                  <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                                    <button
                                      className=''
                                      onClick={() => {
                                        setOpen(true);
                                        settableData(item.details);
                                        setname(item.name);
                                        setdate(item.date);
                                        setsubject(item.subject);
                                      }}
                                    >
                                      <div
                                        className='icon-eye-wrapper'
                                        title='View'
                                      >
                                        <HiOutlineEye className='icon-size' />
                                      </div>
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              (feedbacks?.data?.length === 0 ||
                                feedbacks.length === 0) && (
                                <tr>
                                  <td
                                    colSpan={7}
                                    className='py-6 text-center text-red-600'
                                  >
                                    No data found !
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!(feedbacks?.data?.length === 0 || feedbacks.length === 0) && (
              <div className='bg-white sm:px-6 lg:px-8 max-w-7xl mx-auto py-4'>
                <PaginationComponent
                  page={page}
                  activeListLength={feedbacks?.data || []}
                  limit={POLICIES_LIST_LIMIT}
                  totalList={feedbacks?.total || 0}
                  handlePagination={(e) => {
                    setPage(e);
                    const obj = paginationObj;
                    obj.page = e;
                    setPaginationObj({ ...obj });
                    // handleFetch({}, sortBy);
                  }}
                  totalPages={feedbacks?.total_pages}
                />
              </div>
            )}

            {/* Detail Modal */}
            {open && (
              <ModalSkeleton
                title='Feedback Information'
                cssClass='w-[50%]'
                isOpen={open}
                closeModal={closeModalTable}
                modalFooter={modalFooterInfo()}
              >
                <>
                  <p className='mb-3 max-w-2xl text-sm text-gray-500'>
                    {`Detail of Feedback from ${name}`}
                  </p>
                  <div>
                    <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                      <dt className='text-sm font-medium text-gray-500'>
                        Name
                      </dt>
                      <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                        {name}
                      </dd>
                    </div>
                    <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                      <dt className='text-sm font-medium text-gray-500'>
                        Date
                      </dt>
                      <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                        {moment(date).format("DD-MM-YYYY")}
                      </dd>
                    </div>
                    <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                      <dt className='text-sm font-medium text-gray-500'>
                        Subjects
                      </dt>
                      <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
                        {subject}
                      </dd>
                    </div>
                    <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                      <dt className='text-sm font-medium text-gray-500'>
                        Details
                      </dt>
                      <div className='flex sm:col-span-2'>
                        <dd
                          className={`mt-1 text-sm text-gray-900 sm:mt-0 ${
                            shouldTruncate ? "truncate" : ""
                          }`}
                        >
                          {shouldTruncate
                            ? tableData.slice(0, 40) +
                              (tableData.length > 40 ? "..." : "")
                            : tableData}
                        </dd>
                        {tableData.length > 40 && showButton && (
                          <p
                            className='text-blue-900 cursor-pointer whitespace-nowrap text-sm'
                            onClick={handleClicks}
                          >
                            more
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              </ModalSkeleton>
            )}
            {/* Create Modal */}
            {isOpen && (
              <ModalSkeleton
                isOpen={isOpen}
                title='Add Feedback'
                cssClass='w-[50%]'
                closeModal={closeModal}
                modalFooter={modalFooterFeedBack()}
              >
                <div>
                  <div className='flex flex-col'>
                    <p className='text-sm text-gray-500 mb-3'>
                      Please add your Feedback here
                    </p>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} className=''>
                    <div className='grid grid-cols-2 gap-x-4'>
                      <div className='mt-1 col-span-2 sm:col-span-1'>
                        <label className='did-floating-label'>Name</label>
                        <input
                          {...register("name", {
                            required: true,
                          })}
                          className='did-floating-input'
                          type='text'
                        />

                        {errors?.name?.type === "required" && (
                          <span className='text-sm text-red-500'>
                            This field is required!
                          </span>
                        )}
                      </div>
                      <div className='mt-1 col-span-2 sm:col-span-1'>
                        <label className='did-floating-label'>Date</label>
                        <input
                          {...register("date", {
                            required: true,
                          })}
                          className='did-floating-input'
                          type='date'
                          min={moment().format("DD-MM-YYYY")}
                        />

                        {errors?.date?.type === "required" && (
                          <span className='text-sm text-red-500'>
                            This field is required!
                          </span>
                        )}
                      </div>
                      <div className='mt-1 col-span-2 sm:col-span-1'>
                        <label className='did-floating-label'>Subject</label>
                        <input
                          {...register("subject", {
                            required: true,
                          })}
                          className='did-floating-input'
                          type='text'
                        />

                        {errors?.subject?.type === "required" && (
                          <span className='text-sm text-red-500'>
                            This field is required!
                          </span>
                        )}
                      </div>
                      <div className='mt-1 col-span-2'>
                        <label className='did-floating-label'>Details</label>
                        <textarea
                          {...register("details", {
                            required: true,
                          })}
                          name='details'
                          id=''
                          rows={3}
                          className='overflow-y-auto resize-none focus:bg-[#4b6bb710] w-full rounded-md shadow border border-gray-300'
                          style={{ paddingTop: ".5rem" }}
                        ></textarea>

                        {errors?.details?.type === "required" && (
                          <span className='text-sm text-red-500'>
                            This field is required!
                          </span>
                        )}
                      </div>
                    </div>
                    <button
                      type='submit'
                      className='hidden sr-only'
                      id='allFeedBack'
                    />
                    <div>{}</div>
                  </form>
                </div>
              </ModalSkeleton>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Feedback;
