import React from "react";
import { FiArrowRight, FiArrowUpRight } from "react-icons/fi";
import { roleAccess } from "helpers/RolesPermission";
import { USER_ROLE, PARTICIPANT_PROFILE_TABS } from "Constants/constant";
import CircularLoader from "../CircularLoader/CircularLoader";
import SupportPlanItem from "./SupportPlanItem";
import { classNames, formatDateIntoDMY } from "Utils/utils";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const SupportPlan = (props) => {
  const {
    participantsInfo,
    setToggleState,
    supportPlanData,
    isLoading,
    viewInitialAssesmentForm,
    addInitialAssesmentForm,
  } = props;

  const validInputTypesForSupportPlan = [
    "text",
    "number",
    "date",
    "email",
    "checkbox",
    "select",
    "radio",
    "textarea",
    "phone",
    "address",
    "file",
    //  "signature",
    //  "table"
  ];

  return (
    <>
      <div className='min-h-full'>
        {isLoading ? (
          <CircularLoader
            classes='flex justify-center items-center'
            classloader='loader-l'
          />
        ) : (
          <div className='py-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3'>
            <div className='space-y-6 lg:col-start-1 lg:col-span-3'>
              {/* Description list*/}
              <div className='bg-blue-50 shadow sm:rounded-lg'>
                <div className='px-4 py-5 sm:px-6'>
                  <h2
                    id='applicant-information-title'
                    className='text-lg leading-6 font-bold text-gray-900'
                  >
                    Support Plan
                  </h2>
                </div>
                <div className='px-4 py-5 sm:px-6'>
                  <dl className='grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2'>
                    {roleAccess(
                      [],
                      PERMISSION_KEYS.PARTICIPANT,
                      PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOB
                    ) && (
                      <div className='sm:col-span-1'>
                        <dt className='text-sm font-bold text-gray-500'>
                          Date of Birth
                        </dt>
                        <dd
                          onClick={() => {
                            if (
                              !roleAccess([USER_ROLE.Sp_Standard_User]) &&
                              roleAccess(
                                [],
                                PERMISSION_KEYS.PARTICIPANT,
                                PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING
                                  .SHOW
                              )
                            ) {
                              setToggleState(PARTICIPANT_PROFILE_TABS[7]);
                            }
                          }}
                          className={classNames(
                            "mt-1 text-sm text-gray-900 flex items-center space-x-2",

                            participantsInfo &&
                              participantsInfo.dob &&
                              "cursor-pointer"
                          )}
                        >
                          <span
                            className={
                              participantsInfo && participantsInfo.dob
                                ? "text-blue-700"
                                : "text-red-500"
                            }
                          >
                            {participantsInfo && participantsInfo.dob
                              ? formatDateIntoDMY(participantsInfo.dob)
                              : roleAccess([USER_ROLE.Sp_Standard_User])
                              ? "No Data found!"
                              : "Add Information"}
                          </span>
                          {!roleAccess(USER_ROLE.Sp_Standard_User) &&
                            participantsInfo &&
                            !participantsInfo.dob && (
                              <span className='text-blue-600'>
                                <FiArrowUpRight />
                              </span>
                            )}
                        </dd>
                      </div>
                    )}
                    {supportPlanData &&
                      supportPlanData?.support_plan_data &&
                      supportPlanData.support_plan_data.length > 0 &&
                      supportPlanData.support_plan_data
                        .filter((inputType) =>
                          validInputTypesForSupportPlan.includes(inputType.type)
                        )
                        .map((x, index) => (
                          <SupportPlanItem
                            form_uuid={supportPlanData.form_data_uuid}
                            item={x}
                            key={index}
                            viewInitialAssesmentForm={viewInitialAssesmentForm}
                          />
                        ))}
                    <div className='sm:col-span-2 print:hidden'>
                      {!supportPlanData &&
                      roleAccess([USER_ROLE.Sp_Standard_User]) ? (
                        <dt className='text-center text-sm font-medium text-red-500 space-x-2'>
                          <span>No Data Found!</span>
                        </dt>
                      ) : (
                        <dt
                          onClick={() =>
                            supportPlanData !== null
                              ? viewInitialAssesmentForm(
                                  supportPlanData.form_data_uuid
                                )
                              : addInitialAssesmentForm()
                          }
                          role='button'
                          tabIndex={0}
                          className='text-sm font-medium text-blue-600 flex items-center space-x-2'
                        >
                          <span>
                            {supportPlanData !== null
                              ? "To edit Information on Support Plan, Please edit initial assessment form"
                              : "To add Information on Support Plan, Please submit initial assessment form"}
                          </span>
                          <span className='text-blue-600'>
                            <FiArrowRight />
                          </span>
                        </dt>
                      )}
                    </div>
                    {supportPlanData && (
                      <div className='flex flex-col space-y-4'>
                        <div className='flex items-center md:col-span-1 justify-between'>
                          <dt className='text-sm font-bold text-gray-500'>
                            Last Support Plan update
                          </dt>
                          <dd className='text-sm text-gray-900 flex space-x-2'>
                            {supportPlanData && supportPlanData.last_updated ? (
                              <>
                                {formatDateIntoDMY(
                                  supportPlanData.last_updated
                                )}
                              </>
                            ) : (
                              <>
                                {roleAccess([USER_ROLE.Sp_Standard_User]) ? (
                                  <span className='text-red-500'>
                                    No Data found!
                                  </span>
                                ) : (
                                  <>
                                    <span
                                      onClick={() =>
                                        supportPlanData !== null
                                          ? viewInitialAssesmentForm(
                                              supportPlanData.form_data_uuid
                                            )
                                          : addInitialAssesmentForm()
                                      }
                                      tabIndex={0}
                                      role='button'
                                      className='cursor-pointer text-blue-700'
                                    >
                                      Add Information
                                    </span>
                                    <span
                                      onClick={() =>
                                        supportPlanData !== null
                                          ? viewInitialAssesmentForm(
                                              supportPlanData.form_data_uuid
                                            )
                                          : addInitialAssesmentForm()
                                      }
                                      tabIndex={0}
                                      role='button'
                                      className='cursor-pointer text-blue-600'
                                    >
                                      <FiArrowUpRight />
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                          </dd>
                        </div>
                        <div className='flex items-center md:col-span-1 justify-between'>
                          <dt className='text-sm font-bold text-gray-500'>
                            Last update by
                          </dt>
                          <dd className='text-sm text-gray-900 flex space-x-2'>
                            {supportPlanData &&
                              (supportPlanData.hasOwnProperty("updated_by") &&
                              supportPlanData.updated_by ? (
                                <>{supportPlanData.updated_by}</>
                              ) : supportPlanData.created_by ? (
                                <>{supportPlanData.created_by}</>
                              ) : roleAccess([USER_ROLE.Sp_Standard_User]) ? (
                                <span className='text-red-500'>
                                  No Data found!
                                </span>
                              ) : (
                                <>
                                  <span
                                    onClick={viewInitialAssesmentForm}
                                    tabIndex={0}
                                    role='button'
                                    className='cursor-pointer text-blue-700'
                                  >
                                    Add Information
                                  </span>
                                  <span
                                    onClick={viewInitialAssesmentForm}
                                    tabIndex={0}
                                    role='button'
                                    className='cursor-pointer text-blue-600'
                                  >
                                    <FiArrowUpRight />
                                  </span>
                                </>
                              ))}
                          </dd>
                        </div>
                      </div>
                    )}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default SupportPlan;
