import React from "react";
import { useNavigate } from "react-router";
import NumberFormatter from "components/common/NumberFormatter/NumberFormatter";
import { formatDateIntoDMY } from "Utils/utils";
import { BiPencil } from "react-icons/bi";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const FundingItem = (props) => {
  const {
    fund,
    index,
    participant,
    participantOnBoarding,
    setEditFundingData,
  } = props;

  const navigate = useNavigate();

  return (
    <tr key={index} className='even:bg-white odd:bg-gray-50 text-center'>
      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm'>
        <div className='flex items-center ml-0'>
          <div
            tabIndex={0}
            role='button'
            onClick={() => {
              navigate(
                `/cos/participant-funding/${fund.uuid}/${participant.uuid}`,
                {
                  state: {
                    participant,
                    participantOnBoarding,
                  },
                }
              );
            }}
            className='font-medium text-blue-500 capitalize cursor-pointer hover:underline'
          >
            {fund.contract_id}
          </div>
        </div>
      </td>
      <td className='whitespace-nowrap text-left py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {formatDateIntoDMY(fund.start_date)}
        </div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{formatDateIntoDMY(fund.end_date)}</div>
      </td>
      <td
        className='whitespace-nowrap text-left py-4 text-sm text-gray-500'
        title={fund.total_contract_amount}
      >
        <div className='text-gray-500'>
          <NumberFormatter
            cssClass={`${
              index % 2 === 0 ? "bg-gray-50" : "bg-white"
            } p-0 text-sm`}
            prefix={"$"}
            value={fund.total_contract_amount.toString().replace("$", "")}
          />
        </div>
      </td>
      <td
        className='whitespace-nowrap text-left py-4 text-sm text-gray-500'
        title={fund.amount_allocated}
      >
        <div className='text-gray-500'>
          <NumberFormatter
            cssClass={`${
              index % 2 === 0 ? "bg-gray-50" : "bg-white"
            } p-0 text-sm`}
            prefix={"$"}
            value={fund.amount_allocated.toString().replace("$", "")}
          />
        </div>
      </td>
      <td
        className='whitespace-nowrap text-left py-4 text-sm text-gray-500'
        title={fund.amount_delivered}
      >
        <div className='text-gray-500'>
          <NumberFormatter
            cssClass={`${
              index % 2 === 0 ? "bg-gray-50" : "bg-white"
            } p-0 text-sm`}
            prefix={"$"}
            value={fund.amount_delivered.toString().replace("$", "")}
          />
        </div>
      </td>
      {/* <td
        className="whitespace-nowrap text-left py-4 text-sm text-gray-500"
        title={fund.amount_claimed}
      >
        <div className="text-gray-500">
          <NumberFormatter
            cssClass={`${
              index % 2 === 0 ? "bg-gray-50" : "bg-white"
            } p-0 text-sm`}
            prefix={"$"}
            value={fund.amount_claimed.toString().replace("$", "")}
            // value="1000000002"
          />
        </div>
      </td>
      <td
        className="whitespace-nowrap text-left py-4 text-sm text-gray-500"
        title={fund.amount_unclaimed}
      >
        <div className="text-gray-500">
          <NumberFormatter
            cssClass={`${
              index % 2 === 0 ? "bg-gray-50" : "bg-white"
            } p-0 text-sm`}
            prefix={"$"}
            value={fund.amount_unclaimed.toString().replace("$", "")}
            // value="1000000002"
          />
        </div>
      </td> */}
      <td className='whitespace-nowrap text-left py-4 text-sm text-gray-500'>
        <div className='text-gray-500' title={fund.amount_remaining}>
          {/* {`$${NumberWithInternationalSign(
            fund.amount_remaining.toString().replace("$", ""),
            true,
            3
          )}`} */}
          {
            <NumberFormatter
              cssClass={`${
                index % 2 === 0 ? "bg-gray-50" : "bg-white"
              } p-0 text-sm`}
              prefix={"$"}
              value={fund.amount_remaining.toString().replace("$", "")}
            />
          }
        </div>
      </td>
      <td className='whitespace-nowrap text-left py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          <span
            className={`${
              fund.status
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize `}
          >
            {fund.status ? "Active" : "Inactive"}
          </span>
        </div>
      </td>
      {roleAccess(
        [],
        PERMISSION_KEYS.PARTICIPANT,
        PERMISSION_KEYS_CHILDREN.PARTICIPANT.FUNDING.UPDATE
      ) && (
        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
          <div className='space-x-2 flex'>
            <span
              onClick={() => setEditFundingData(fund)}
              className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
            >
              <div className='icon-pencil-wrapper' title='Edit'>
                <BiPencil className='icon-size' />
              </div>
            </span>
          </div>
        </td>
      )}
    </tr>
  );
};

export default FundingItem;
