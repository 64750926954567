import React, { useEffect } from "react";
import swal from "sweetalert";

const SweetAlertConfirmationModal = (props) => {
  const {
    title,
    content,
    dangerMode = false,
    setIsConfirm,
    isOpen = false,
    info = "",
  } = props;

  const handleChangeAction = () => {
    swal({
      title: title,
      text: content,
      icon: info,
      buttons: ["No, take me back!", "Yes, go ahead!"],
      className: "",
      cancel: {
        text: "No, take me back!",
        value: null,
        visible: false,
        className: "",
        closeModal: true,
      },
      confirm: {
        text: "Yes, go ahead!",
        value: true,
        visible: true,
        // className: `${
        //   updatedAction === "cancelled" ? "bg-blue-600" "
        // }`,
        closeModal: true,
      },
      dangerMode: dangerMode,
    }).then((willDelete) => {
      if (willDelete) {
        setIsConfirm(true);
      } else {
        setIsConfirm(false);
      }
    });
  };

  useEffect(() => {
    if (isOpen) {
      handleChangeAction();
    }
  }, [isOpen]);

  return <></>;
};

export default SweetAlertConfirmationModal;
