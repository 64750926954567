import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { BiBlock } from "react-icons/bi";
import { FaAngleDown } from "react-icons/fa";
import { AiOutlineFileText } from "react-icons/ai";
import { classNames } from "Utils/utils";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { ImFileEmpty } from "react-icons/im";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { IoCheckmarkOutline } from "react-icons/io5";


import { IoCheckmark } from "react-icons/io5";

const SelectBox = (props) => {
  const { setUpdatedAction, disabled, forRosterInvoicing = false } = props;

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button
          disabled={disabled}
          className={`background-gradient border-gradient
          ${disabled && "disabled:opacity-50"}
          text-white shadow-sm rounded-md inline-flex w-full items-center justify-center
            px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
        >
          Action
          <FaAngleDown className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
        </Menu.Button>
      </div>

      {!disabled && (
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='py-1'>
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setUpdatedAction("provider_paid")}
                  >
                    <IoCheckmark
                      className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                      aria-hidden='true'
                    />
                    Mark As Paid
                  </span>
                )}
              </Menu.Item>
              
              {forRosterInvoicing && (
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                      onClick={() => setUpdatedAction("Void")}
                    >
                      <AiOutlineFileText
                        className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                        aria-hidden='true'
                      />
                  Mark As Void
                    </span>
                  )}
                </Menu.Item>
              )}

              {!forRosterInvoicing && (
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                      onClick={() => setUpdatedAction("revert")}
                    >
                      <AiOutlineFileText
                        className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                        aria-hidden='true'
                      />
                      Revert Case Notes
                    </span>
                  )}
                </Menu.Item>
              )}

              {!forRosterInvoicing && (
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                      onClick={() => setUpdatedAction("cancelled")}
                    >
                      <BiBlock
                        className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                        aria-hidden='true'
                      />
                      Cancel DS
                    </span>
                  )}
                </Menu.Item>
              )}
              {!forRosterInvoicing && (
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                      onClick={() => setUpdatedAction("extract_generated")}
                    >
                      <BiBlock
                        className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                        aria-hidden='true'
                      />
                      Undo Cancel DS
                    </span>
                  )}
                </Menu.Item>
              )}

            </div>

            <div className='py-1'>
              <Menu.Item>
                {({ active }) => (
                  <span
                    onClick={() => setUpdatedAction("pdf")}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <BsFillFileEarmarkPdfFill
                      className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                      aria-hidden='true'
                    />
                    Generate PDF File
                  </span>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <span
                    onClick={() => setUpdatedAction("xlsx")}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <SiMicrosoftexcel
                      className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                      aria-hidden='true'
                    />
                    Generate Excel File
                  </span>
                )}
              </Menu.Item>
              {forRosterInvoicing && (
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                      )}
                      onClick={() => setUpdatedAction("Email Invoice")}
                    >
                      <MdOutlineMarkEmailRead
                        className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                        aria-hidden='true'
                      />
                      Email Invoice
                    </span>
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  );
};
export default SelectBox;
