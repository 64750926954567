import { connect } from "react-redux";
import ManagerList from "./ManagerList";
import { getError, getLoading, getManagers } from "store/selector/sp.selector";
import { getPManagerRequest } from "store/actions/sp.actions";
import { createSPManagerRequest } from "store/actions/sp.actions.js";

const mapDispatchToProps = {
  createSPManager: createSPManagerRequest,
  getSpManager: getPManagerRequest,
};

const mapStateToProps = (state) => {
  const loading = getLoading(state);
  const error = getError(state);
  const managerList = getManagers(state);

  return {
    loading,
    error,
    managerList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerList);
