import React, { useEffect, useState } from "react";
import workerAnniversary from "../../../../assets/dashboard/worker.jpg";
import {
  baseURLImage,
  formatDateToAustralian,
  getNameProfile,
} from "Utils/utils";
import { Link } from "react-router-dom";
import { roleAccess } from "../../../../helpers/RolesPermission";
import {
  PERMISSION_KEYS,
  PERMISSION_KEYS_CHILDREN,
} from "../../../../store/constants";
import "../ExpiringDateTable/style.css";
const AnnualPerformanceCard = ({ item }) => {
  const [isDatePassed, setIsDatePassed] = useState(false);

  useEffect(() => {
    const reviewDate = new Date(item?.review_date);
    const today = new Date();
    setIsDatePassed(reviewDate < today);
  }, [item]);

  const backgroundImageStyle = {
    backgroundImage: `url(${workerAnniversary})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundRepeat: "no-repeat", // Adjust as needed
  };

  //check date if date is today
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() && date.getMonth() === today.getMonth()
    );
  };

  // Usage example:
  const reviewDate = new Date(item?.review_date);
  const isReviewToday = isToday(reviewDate);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const handleRedirection = (element) => {
    if (
      roleAccess(
        [],
        PERMISSION_KEYS.HRM,
        PERMISSION_KEYS_CHILDREN.HRM.WORKER.SHOW
      )
    ) {
      return `/worker-profile/${element?.uuid}`;
    }

    return "#";
  };

  return (
    <Link to={handleRedirection(item)}>
      <div
        className={`h-full w-full p-6 border  `}
        style={backgroundImageStyle}
      >
        <div className='flex justify-end'>
          <div className='mr-4 text-right'>
            <div className='font-lg font-bold text-gray-900 capitalize'>
              {item?.name}
            </div>
            <p className={`text-sm  text-bold ${isDatePassed ? "blink" : ""}`}>
              {formatDateToAustralian(item?.review_date)}
            </p>
          </div>
          <div className='h-12 w-12 flex-shrink-0'>
            <img
              onError={(e) => handleImageError(e, item?.name, item?.name)}
              className='object-cover h-12 w-12 rounded-full'
              src={
                item?.photo
                  ? baseURLImage(item?.photo)
                  : getNameProfile(item.name, item?.name || " ")
              }
              alt=''
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default AnnualPerformanceCard;
