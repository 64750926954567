import { connect } from "react-redux";
import {
  createOrUpdateWorkerShiftRequest,
  recurringMessageModal,
} from "store/actions/rostering.actions";
import {
  getCreateOrUpdateWorkerShiftError,
  getCreateOrUpdateWorkerShiftLoader,
  getWorkerAndParticipantOptions,
  getRecurringMessage,
  showRecurringModal,
} from "store/selector/rostering.selector";
import WorkerShift from "./WorkerShift";
import {
  getSPParticipantsListRequest,
  workerProfileDetailsFetchRequest,
} from "store/actions/sp.actions";
import {
  getSelectedWorker,
  getWorkerProfileDetailLoader,
} from "store/selector/sp.selector";

const mapDispatchToProps = {
  createOrUpdateWorkerShiftRequest,
  recurringMessageModal,
  workerProfileDetailsFetchRequest,
  getParticipantList: getSPParticipantsListRequest,
};

const mapStateToProps = (state) => {
  const workerAndParticipantOptions = getWorkerAndParticipantOptions(state);
  const createOrUpdateWorkerShiftLoader =
    getCreateOrUpdateWorkerShiftLoader(state);
  const createOrUpdateWorkerShiftError =
    getCreateOrUpdateWorkerShiftError(state);
  const recurringMessages = getRecurringMessage(state);
  const isRecurringModal = showRecurringModal(state);
  const workerProfileDetails = getSelectedWorker(state);
  const getWorkerProfileLoader = getWorkerProfileDetailLoader(state);

  return {
    workerAndParticipantOptions,
    createOrUpdateWorkerShiftLoader,
    createOrUpdateWorkerShiftError,
    recurringMessages,
    isRecurringModal,
    workerProfileDetails,
    getWorkerProfileLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkerShift);
