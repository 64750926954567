import moment from "moment";
import {
  baseURLImage,
  formatTimeSheetLength,
  getNameProfile,
  getPaginationObj,
  getPayrollPlatformByUsingWorkerId,
} from "Utils/utils";
import createSelector from "Utils/reselect";
import { SHIFT_STATUS, emptyPaginationObj } from "Constants/constant";
import _ from "lodash";

const rosteringStateData = (state) => {
  const { rostering } = state;
  return rostering || {};
};

export const getWorkerAndPartsListLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("getWorkerAndPartsListLoader") || false
);

export const getWorkerAndParticipantOptions = createSelector(
  rosteringStateData,
  (rostering) => {
    const data = rostering.get("workerAndParticipantList") || null;
    // const isSort = rostering.get("isSortCalendarShiftsItem");
    const calView = rostering.get("calendarView") || "worker";
    // console.log("data", data);
    // console.log("calView", calView);
    if (data) {
      const dataVar = data?.[`${calView}`];
      // console.log("dataVar", dataVar);
      return {
        pagination: getPaginationObj(data),
        participants: dataVar?.data.map((x) => ({
          ...x,
          label: `${x.first_name} ${x.last_name}`,
          value: x.uuid,
          icon: x.profile_picture
            ? baseURLImage(x.profile_picture)
            : getNameProfile(x.first_name, x.last_name || " "),
        })),
        // .sort((a, b) => {
        //   if (isSort === 0)
        //     return a.first_name.toLowerCase() < b.first_name.toLowerCase()
        //       ? -1
        //       : 1;
        //   return a.first_name.toLowerCase() < b.first_name.toLowerCase()
        //     ? 1
        //     : -1;
        // }),
        workers: dataVar?.data.map((x) => ({
          ...x,
          label: `${x.first_name} ${x.last_name}`,
          value: x.uuid,
          icon: x.photo
            ? baseURLImage(x.photo)
            : getNameProfile(x.first_name, x.last_name || " "),
        })),
        // .sort((a, b) => {
        //   if (isSort === 0)
        //     return a.first_name.toLowerCase() < b.first_name.toLowerCase()
        //       ? -1
        //       : 1;
        //   return a.first_name.toLowerCase() < b.first_name.toLowerCase()
        //     ? 1
        //     : -1;
        // }),
      };
    }

    return {
      pagination: { ...emptyPaginationObj },
      participants: [],
      workers: [],
    };
  }
);

export const getSearchtextOfScheduleShifts = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("searchtextOfScheduleShifts") || null
);

export const getIsSortCalendarShiftsItem = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("isSortCalendarShiftsItem")
);

export const getCalendarDates = createSelector(
  rosteringStateData,
  (rostering) => {
    const calendarStartOfDate = rostering.get("isCalendarStartOfDate");
    const scheduleCalendarView = rostering.get("scheduleCalendarView") || null;

    let date = null;
    if (calendarStartOfDate === null) {
      // date = moment().startOf("week").add(1, "days");
      return [];
    } else {
      date = moment(calendarStartOfDate, "YYYY-MM-DD");
    }

    const arr = [];

    const calendarLength = scheduleCalendarView === "weekly" ? 8 : 15;
    for (let i = 1; i < calendarLength; i += 1) {
      arr.push(date.format("YYYY-MM-DD"));
      date.add(1, "days");
    }
    return arr;
  }
);

export const getCreateOrUpdateWorkerShiftLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("createOrUpdateWorkerShiftLoader") || false
);
export const getRecurringMessage = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("recurringMessage") || {}
);
export const showRecurringModal = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("showRecurringModal") || false
);
export const getCreateOrUpdateWorkerShiftError = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("createOrUpdateWorkerShiftError") || ""
);

export const getAllPendingShifts = createSelector(
  rosteringStateData,
  (rostering) => {
    const data = rostering.get("workerAndParticipantList") || null;

    if (data) {
      const calendarView = data.hasOwnProperty("worker")
        ? "worker"
        : "participant";
      const allWorkers = data?.[`${calendarView}`]?.data || [];

      if (allWorkers) {
        const reduceData = allWorkers.reduce((obj, data) => {
          const name = `${data?.first_name} ${data?.last_name}`;
          const alterData = data?.shifts?.map((x) => {
            let modifiedShift = { ...x }; // Create a copy of the shift object

            if (calendarView === "worker") {
              modifiedShift.worker = { name: name }; // Modify the worker object
              modifiedShift.participant = {
                ...modifiedShift.participant,
                name: `${modifiedShift.participant?.first_name} ${modifiedShift.participant?.last_name}`,
              };
            } else {
              modifiedShift.participant = { name: name }; // Modify the participant object
              modifiedShift.worker = {
                ...modifiedShift.worker,
                name: `${modifiedShift.worker?.first_name} ${modifiedShift.worker?.last_name}`,
              };
            }

            return modifiedShift; // Return the modified shift object
          });

          Object.assign(obj, {
            allShifts: obj["allShifts"]
              ? [...obj.allShifts, ...alterData]
              : [...alterData],
          });
          return obj;
        }, {});
        return (
          reduceData?.allShifts
            ?.filter((x) => x.status === SHIFT_STATUS.PENDING)
            ?.sort((a, b) => a.worker_id < b.worker_id) || []
        );
      }
    }
    return null;
  }
);

const displaySingleDash = (name) => {
  if (!name || name.trim() === "") {
    return "N/A"; // Em dash character (—) used as an example
  } else {
    return name.trim();
  }
};

export const getAllShifts = createSelector(rosteringStateData, (rostering) => {
  const data = rostering.get("workerAndParticipantList") || null;

  if (data) {
    const calendarView = data.hasOwnProperty("worker")
      ? "worker"
      : "participant";
    const allWorkers = data?.[calendarView]?.data || [];

    if (allWorkers) {
      const reduceData = allWorkers.reduce((obj, data) => {
        const firstName = displaySingleDash(data?.first_name);
        const lastName = displaySingleDash(data?.last_name);

        // Combine first_name and last_name with an em dash if both are dashes
        const name =
          `${firstName !== "—" ? firstName : ""} ${
            lastName !== "—" ? lastName : ""
          }`.trim() || "—";

        const alterData = data?.shifts?.map((x) => {
          if (calendarView === "worker") {
            return {
              ...x,
              worker_name: name,
              participant_name: `${displaySingleDash(
                x.participants[0]?.first_name
              )} ${x.participants[0]?.last_name}`,
              participants: x.participants, // Include participant data for avatar display
            };
          } else {
            return {
              ...x,
              participant_name: name,
              worker_name: `${displaySingleDash(
                x.worker ? x.worker.first_name : ""
              )} ${x.worker ? x.worker.last_name : ""}`,
              participants: [], // No need for participant data in worker view
            };
          }
        });

        return obj.concat(alterData);
      }, []);

      const updatedData = reduceData.map((x) => {
        // Apply the condition to determine the new status
        if (x.status === SHIFT_STATUS.NA) {
          return { ...x, status: "In-Progress" };
        } else if (
          x.status === SHIFT_STATUS.ACCEPTED &&
          x.progress === "submitted"
        ) {
          return { ...x, status: "Completed" };
        }
        return x;
      });

      const filteredData = updatedData.filter(
        (x) =>
          x.status === SHIFT_STATUS.PENDING ||
          x.status === SHIFT_STATUS.PUBLISHED ||
          x.status === SHIFT_STATUS.ACCEPTED ||
          x.status === SHIFT_STATUS.REJECTED ||
          x.status === SHIFT_STATUS.NA ||
          x.status === SHIFT_STATUS.APPROVED ||
          x.status === SHIFT_STATUS.DID_NOT_ACCEPT ||
          x.status === SHIFT_STATUS.EXPIRED ||
          x.status === SHIFT_STATUS.COMPLETED ||
          x.status === "In-Progress" || // Include the new "In-Progress" status
          x.status === "Completed" // Include the new "Completed" status
      );

      return filteredData;
    }
  }
  return [];
});

export const getCopyWorkerShiftLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("copyShiftLoader") || false
);

export const getCopyWorkerShiftError = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("copyShiftError") || ""
);

export const getParticipantListForTimesheetLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("participantListForTimesheetLoader") || false
);

export const getParticipantListForTimesheet = createSelector(
  rosteringStateData,
  (rostering) => {
    const participants = rostering.get("participantListForTimesheet") || null;
    if (participants) {
      const participantList = participants
        .map((x) => ({
          ...x,
          label: `${x.first_name} ${x.last_name}`,
          value: x.uuid,
          icon: x.profile_picture
            ? baseURLImage(x.profile_picture)
            : getNameProfile(x.first_name, x.last_name || " "),
        }))
        .sort((a, b) =>
          a.first_name.localeCompare(b.first_name, undefined, {
            sensitivity: "base",
          })
        );

      return [
        {
          label: "All participants",
          value: "all",
          icon: null,
        },
        ...participantList,
      ];
    }
    return [];
  }
);

export const getWorkerTimesheetLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("workerTimesheetLoader") || false
);

export const getWorkerTimesheetPaginationLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("workerTimesheetPaginationLoader") || false
);

export const getWorkerTimeSheetsList = createSelector(
  rosteringStateData,
  (rostering) => {
    const search = rostering.get("workerTimeSheetsListSearch") || null;
    const timeSheetsCloned = _.cloneDeep(
      rostering.get("workerTimeSheetsList") || null
    );
    const timeSheets =
      search !== null
        ? timeSheetsCloned.filter(
            (x) =>
              x.first_name.toLowerCase().includes(search.toLowerCase()) ||
              x.last_name.toLowerCase().includes(search.toLowerCase())
          )
        : timeSheetsCloned;

    if (timeSheets) {
      let ttlPendingTimeSheets = 0;
      for (let index = 0; index < timeSheets.length; index++) {
        let pendingTimeSheets = [];
        let approvedTimeSheets = [];
        let otherTimeSheets = [];
        let parentTimesheetSeconds = 0;
        if (
          timeSheets[index]?.timeSheet &&
          timeSheets[index]?.timeSheet.length > 0
        ) {
          timeSheets[index]?.timeSheet.forEach((item, subIndex) => {
            if (item.status === SHIFT_STATUS.PENDING) {
              pendingTimeSheets.push({
                ...timeSheets[index]?.timeSheet[subIndex],
                worker_id: timeSheets[index].id,
                parent_uuid: timeSheets[index].uuid,
                worker_name: `${timeSheets[index].first_name} ${timeSheets[index].last_name}`,
              });
            } else if (item.status === SHIFT_STATUS.APPROVED) {
              approvedTimeSheets.push({
                ...timeSheets[index]?.timeSheet[subIndex],
                worker_id: timeSheets[index].id,
                parent_uuid: timeSheets[index].uuid,
                worker_name: `${timeSheets[index].first_name} ${timeSheets[index].last_name}`,
              });
            } else {
              otherTimeSheets.push({
                ...timeSheets[index]?.timeSheet[subIndex],
                worker_id: timeSheets[index].id,
                parent_uuid: timeSheets[index].uuid,
                worker_name: `${timeSheets[index].first_name} ${timeSheets[index].last_name}`,
              });
            }
            if (item.timesheet_length && item.timesheet_length.includes(":")) {
              const [hours, minutes, seconds = 0] =
                item.timesheet_length.split(":");
              const timesheetSeconds =
                parseInt(hours, 10) * 3600 +
                parseInt(minutes, 10) * 60 +
                parseInt(seconds, 10);
              parentTimesheetSeconds += timesheetSeconds;
            }
          });
        }
        Object.assign(timeSheets[index], {
          timeSheet: {
            pendingTimeSheets,
            approvedTimeSheets,
            otherTimeSheets,
            ttlTimesheetSeconds: formatTimeSheetLength(parentTimesheetSeconds),
          },
        });
        ttlPendingTimeSheets += pendingTimeSheets.length;
      }
      return {
        timeSheets,
        ttlPendingTimeSheets,
      };
    }
    return {
      ttlPendingTimeSheets: 0,
      timeSheets: [],
    };
  }
);

export const getWorkerTimesheetListPagination = createSelector(
  rosteringStateData,
  (rostering) =>
    rostering.get("workerTimeSheetsListPagination") || emptyPaginationObj
);

export const getSelectedTimesheetOfParticipant = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("selectedTimesheetOfParticipant") || null
);

export const getTimesheetCommentsListLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("timesheetCommentsListLoader") || false
);

export const getTimeSheetCommentCreateLaoder = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("timesheetCommentCreateLoader") || false
);

export const getSelectedTimeSheetComments = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("selectedTimeSheetComments") || null
);

export const getTimesheetupdateLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("timesheetupdateLoader") || false
);

export const getTimesheetStatusChangeLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("timesheetStatusChangeLoader") || null
);

export const getIsAnyPendingExist = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("isAnyPendingExist") || null
);

export const getWorkerTimeSheetExportList = createSelector(
  rosteringStateData,
  (rostering) => {
    const data = rostering.get("workerTimeSheetExportList") || null;
    if (data !== null && data.length > 0) {
      data.forEach((pItem, pIndex) => {
        let parentTimesheetSeconds = 0;
        if (pItem.timeSheet && pItem.timeSheet.length > 0) {
          pItem.timeSheet.forEach((citem, cIndex) => {
            Object.assign(pItem.timeSheet[cIndex], {
              [`${citem.export_destination}_export_status`]:
                citem.export_status,
            });
            if (
              citem.timesheet_length &&
              citem.timesheet_length.includes(":")
            ) {
              const [hours, minutes, seconds = 0] =
                citem.timesheet_length.split(":");
              const timesheetSeconds =
                parseInt(hours, 10) * 3600 +
                parseInt(minutes, 10) * 60 +
                parseInt(seconds, 10);
              parentTimesheetSeconds += timesheetSeconds;
            }
            Object.assign(data[pIndex], {
              children: [],
              ttl_timesheet_length: formatTimeSheetLength(
                parentTimesheetSeconds
              ),
              payroll_platform: getPayrollPlatformByUsingWorkerId(pItem),
            });
          });
        }
      });
      return data;
    }

    return [];
  }
);

export const getWorkerTimeSheetExportListLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("workerTimeSheetExportListLoader") || false
);

export const getSingleTimesheetDetailsLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("singleTimesheetDetailsLoader") || false
);

export const getSingleTimesheetDetails = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("singleTimesheetDetails") || null
);

export const getExportTimesheetToPayrollLoader = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("exportTimesheetToPayrollLoader") || false
);

export const getIsAuthenticateForPayroll = createSelector(
  rosteringStateData,
  (rostering) => rostering.get("isAuthenticateForPayroll") || null
);

// export const getRosterInvoicing= createSelector(rosteringStateData,(rostering)=> rostering.get("getRosterInvoicing"))
export const getRosterInvoicesLoading = createSelector(
  rosteringStateData,
  (rostering) =>
    rostering.getIn(["rosterInvoices", "rosterInvoicesLoading"]) || false
);
export const getRosterInvoicing = createSelector(
  rosteringStateData,
  (rostering) =>
    rostering.getIn(["rosterInvoices", "rosterInvoicesList"]) || null
);
