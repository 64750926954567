import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import moment from "moment";
import { useDispatch } from "react-redux";
import { loginAttempt } from "store/actions/auth.actions";
import LoaderButton from "components/common/LoaderButton/LoaderButton";
import { EMAIL_REGEX_EXP, USER_ROLE } from "Constants/constant";
import LoginScreenSection from "components/common/LoginScreenSection/LoginScreenSection";
import { apiPostRequest } from "helpers/Requests";
import { setExpireTime, setToken } from "helpers/Tokens";
import { groupByPermissionsByName, parseBooleanVal } from "Utils/utils";
import api from "store/services/auth.services";
import { toast } from "react-toastify";
import momentTimeZone from "moment-timezone";

const SignIn = () => {
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");
  const [error, setError] = useState("");
  const showPasswordHandler = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSendEmailVerifyCode = async (email) => {
    try {
      const res = await api.resendEmailVerificatioCode({ email: email });
      if (res) {
        toast.success(
          res?.message ??
            "Email verification code successfully send, Check your email"
        );
        const encode = window.btoa(encodeURIComponent(email));
        navigate(`/email-verification-code/${encode}`);
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      throw new Error(err);
    }
  };

  const login = async (data) => {
    setError("");
    setRequest({ ...request, isRequested: true, isFailed: false });
    localStorage.clear();
    sessionStorage.clear();
    try {
      const res = await apiPostRequest("login", data, null);
      if (res.status === 200) {
        // Check email is verified
        if (
          res.data &&
          res.data.user &&
          res.data.user.hasOwnProperty("email_verified")
        ) {
          handleSendEmailVerifyCode(data.email);
          return;
        }

        // Check user permissions
        if (res.data?.user?.permissions) {
          const groupedPermissions = groupByPermissionsByName(
            res.data?.user?.permissions || []
          );
          Object.assign(res?.data?.user, {
            permissions: { ...groupedPermissions },
          });
        }

        // check payment setting object
        if (res.data?.user?.payment_setting !== null) {
          Object.assign(res.data?.user, {
            is_default_card:
              res.data.user?.payment_setting?.default_payment_method_id || null,
          });
          const payment_setting = res.data.user?.payment_setting;

          // Check Trial period Modal Need to be fire or not
          if (
            parseBooleanVal(payment_setting?.on_trial) &&
            payment_setting.subscription_id === null
          ) {
            const trialEndDate = moment(
              payment_setting?.trial_end_date,
              "YYYY-MM-DD"
            );
            const tomorrow = moment().add(1, "days").startOf("day");
            const isSameDay = trialEndDate.isSame(moment(), "day");
            const isTomorrow = trialEndDate.isSame(tomorrow, "day");
            if (isTomorrow || isSameDay) {
              localStorage.setItem("isTrailEndTomorrow", false);
            }
          }

          if (
            payment_setting.current_subscription_status === "past_due" &&
            payment_setting.subscription_id !== null &&
            parseBooleanVal(payment_setting.subscription_status) === false
          ) {
            localStorage.setItem("isStripeGracePeriod", false);
          }
        }

        // check authentication activity object
        if (
          res.data &&
          res.data.user &&
          res.data.user.hasOwnProperty("authentication_activity")
        ) {
          // console.log("here");
          localStorage.setItem(
            "2FARequired",
            res.data.user.authentication_activity.otp_verified
          );
          localStorage.setItem("2FASetup", res.data.user.phone_2fa);
          localStorage.setItem(
            "authentication_uuid",
            res.data.user.authentication_activity.authentication_uuid
          );
        }

        // Sp provider setting object dispatcher
        if (
          res.data &&
          "service_provider" in res.data.user &&
          res.data.user.service_provider &&
          res.data.user.userType === USER_ROLE.Sp_Admin
        ) {
          localStorage.setItem(
            "spSettings",
            JSON.stringify(res.data.user.service_provider)
          );
          dispatch(
            loginAttempt.success({
              ...res.data.user,
              service_provider: res.data.user.service_provider,
              name: res.data.user.service_provider.name,
            })
          );
        } else {
          dispatch(loginAttempt.success(res.data.user));
        }

        const logInTime = moment().format("HH:mm");
        const warnTime = moment().add(120000, "milliseconds").format("HH:mm");
        const logoutTime = moment()
          .add(1200000, "milliseconds")
          .format("HH:mm");
        setExpireTime({ logInTime, warnTime, logoutTime });
        setRequest({ ...request, isRequested: false, isSuccess: true });

        // store in Localstorage
        localStorage.setItem(
          "isOnBoarding",
          res.data.user.userType === USER_ROLE.Sp_Standard_User &&
            !res.data.user.on_boarding
            ? false
            : true
        );
        localStorage.setItem("userType", res.data.user.userType);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        setToken(res.data.user.AccessToken);
        //

        if (parseBooleanVal(localStorage.getItem("2FARequired")) === false) {
          localStorage.setItem("step", "0.5");
          navigate("/select-otp");
          return;
        } else if (
          parseBooleanVal(localStorage.getItem("isOnBoarding")) === false
        ) {
          localStorage.setItem("step", false);
          navigate("/");
          return;
        } else {
          localStorage.setItem("step", false);
          // if (res.data && res.data.user.userType === USER_ROLE.Sp_Admin) {
          //   dispatch(getSpSettingFetchRequest());
          // }
          navigate("/dashboard");
        }
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      setError(err.message);
      console.log("err", err);
    }
  };
  const onSubmit = (data) => {
    const currentTimeZone = momentTimeZone.tz.guess();
    login({ ...data, timezone: currentTimeZone });
  };

  return (
    <>
      <div className='min-h-screen flex'>
        <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 lg:w-1/2 xl:px-24'>
          <div className='mx-auto w-full max-w-sm lg:w-96'>
            <div>
              <h2 className='mt-6 text-3xl font-semibold text-gray-900'>
                Welcome back
              </h2>
              <p className='mt-2 text-sm text-gray-500'>
                Welcome back! Please enter your details.
              </p>
            </div>

            <div className='mt-8'>
              <div className='mt-6'>
                <form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
                  <div>
                    <div className='mt-1 did-floating-label-content'>
                      <label
                        htmlFor='email'
                        className='block text-sm font-medium text-gray-700 mb-[3px]'
                      >
                        Email address
                      </label>
                      <input
                        {...register("email", {
                          setValueAs: (value) => value.trim(),
                          required: true,
                          pattern: EMAIL_REGEX_EXP,
                        })}
                        id='email'
                        name='email'
                        autoComplete='email'
                        required
                        className={`did-floating-input ${
                          request.isFailed ? "borderRed" : "border"
                        }`}
                        // placeholder="Enter Email"
                      />

                      {errors?.email?.type === "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                      {errors?.email?.type === "pattern" && (
                        <span className='text-sm text-red-500'>
                          Please enter a valid email address!
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='space-y-1'>
                    <div className=''>
                      <div className='mt-1 did-floating-label-content relative'>
                        <label
                          htmlFor='password'
                          className='block text-sm font-medium text-gray-700 mb-[3px]'
                        >
                          Password
                        </label>
                        <input
                          {...register("password", {
                            required: true,
                          })}
                          id='password'
                          name='password'
                          type={passwordType}
                          autoComplete='current-password'
                          required
                          className={`did-floating-input ${
                            request.isFailed ? "borderRed" : "border"
                          }`}
                          // placeholder="Enter Password"
                        />

                        <span
                          onClick={showPasswordHandler}
                          style={{ top: "41px", right: "10pX" }}
                          className={`pointer absolute top-1/2 -translate-y-1/2 right-4 text-blue-dark ${
                            request.isFailed ? "textRed" : "text-blue-dark"
                          }`}
                        >
                          {passwordType === "password" ? (
                            <AiFillEyeInvisible />
                          ) : (
                            <AiFillEye />
                          )}
                        </span>
                      </div>
                      {errors?.password?.type === "required" && (
                        <div className='-mt-4 mb-4 text-sm text-red-500'>
                          This field is required!
                        </div>
                      )}
                      {error && error.length > 0 && (
                        <span className='text-sm text-red-500'>{error}</span>
                      )}
                    </div>
                  </div>

                  <div className='flex items-center justify-between'>
                    <div className='flex items-center'>
                      <input
                        {...register("remember-me", {})}
                        id='remember-me'
                        name='remember-me'
                        type='checkbox'
                        className='
                        h-4
                        w-4
                        rounded
                        accent-blue-dark
                      '
                      />
                      <label
                        htmlFor='remember-me'
                        className='ml-2 block text-sm text-gray-700 font-medium'
                      >
                        Remember me
                      </label>
                    </div>

                    <div className='text-sm'>
                      <Link
                        to='/forget-password'
                        className='
                        cursor-pointer
                        font-medium
                        text-blue-dark
                        hover:text-blue-dark
                        text-sm
                      '
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                  {request.isRequested ? (
                    <LoaderButton
                      classes='w-full
                        flex
                        justify-center
                        items-center
                        py-2
                        px-4
                        border border-transparent
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-white
                        bg-blue-dark
                        hover:bg-blue-dark
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-blue-dark'
                      svgClasses='inline mr-3 w-5 h-5 text-white animate-spin'
                      textbutton='Logging in...'
                    />
                  ) : (
                    <button
                      type='submit'
                      className='
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-white
                      bg-blue-dark
                      hover:bg-blue-dark
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-dark
                    '
                    >
                      Sign in
                    </button>
                  )}

                  <div className='text-center'>
                    <p className='text-gray-500 text-sm'>
                      Don’t have an account?
                      <Link
                        to='/signup'
                        className='text-blue-dark font-medium cursor-pointer pl-1'
                      >
                        Sign up
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <LoginScreenSection
          heading={`Vertex360 has streamlined our NDIS compliance to an extent that we look forward to coming to work now !`}
          userImg='use image here'
          name='Dale Florence'
          desc='NDIS Service Provider'
        />
      </div>
    </>
  );
};

export default SignIn;
