import React from "react";
import SignatureCanvas from "react-signature-canvas";
import Modal from "../../components/common/Modal/Modal";
import { Table } from "../../components/common/Table/Table";
import { useForm } from "react-hook-form";
import _ from "lodash";
import moment from "moment";
import { EMAIL_REGEX_EXP } from "Constants/constant";

export const ReportForm = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [otherPeopleData, setOtherPeopleData] = React.useState([
    {
      input: <input type="checkbox" />,
      personName: "Usman",
      status: "Good",
      action: "Edit",
    },
  ]);

  const [witnesses, setWitnesses] = React.useState([
    {
      input: <input type="checkbox" />,
      personName: "Usman",
      status: "Good",
      action: "Edit",
    },
  ]);

  const sigCanvasManager = React.useRef({});
  const clearSignatureManager = () => sigCanvasManager.current.clear();
  const sigCanvasStaff = React.useRef({});
  const clearSignatureStaff = () => sigCanvasStaff.current.clear();

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();

  const addOtherPerson = (personData) => {
    const obj = {
      input: <input type="checkbox" />,
      personName: personData.personName,
      status: personData.status,
      action: "Edit",
    };
    const list = _.cloneDeep(otherPeopleData);
    list.push(obj);
    setOtherPeopleData(list);
  };

  // const addWitness = (personData) => {
  //   const list = _.cloneDeep(witnesses);
  //   const obj = {
  //     input: <input type="checkbox" />,
  //     personName: personData.personName,
  //     status: personData.status,
  //     action: "Edit",
  //   };
  //   list.push(obj);
  //   setWitnesses(list);
  // };

  const openModalHandler = () => {
    setShowModal(true);
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  const errorStyles = {
    color: "red",
    fontSize: 14,
  };

  const causeOfInjury = [
    { label: "Lift/Bend/Push/Pull" },
    { label: "Posture or arm usage" },
    { label: "Handheld Tools" },
    { label: "Behaviour of Participant" },
    { label: "Psychological Stress- Person" },
    { label: "Sun Exposure" },
    { label: "Involuntary Movement of Participant" },
    { label: "Vehicle Accident - Work Vehicle" },
    { label: "Psychological Stress- Bullying/Harassment" },
    { label: "Slip/trip/fall" },
    { label: "Contact with Animal/Insect" },
    { label: "Vehicle Accident - Own Vehicle" },
    { label: "Psychological Stress- Workload/Organization" },
    { label: "Hazardous Substance/Material" },
    { label: "Electric Shock" },
    { label: "Struck by Vehicle" },
    { label: "Workplace Violence" },
    { label: "Drugs/Alcohol" },
    { label: "Equipment/Machinery" },
    { label: "Others" },
  ];
  const RemedialActions = [
    { label: "Conduct Task Analysis" },
    { label: "Reinstruct person(s) Involved" },
    { label: "Improve design/construction/guarding" },
    { label: "Conduct Hazard Systems Audit" },
    { label: "Improve Skills Mix" },
    { label: "Develop/review task procedures" },
    { label: "Provide debriefing and/or counseling" },
    { label: "Improve Communication / reporting procedures" },
    { label: "Improve Work Environment" },
    { label: "Request Maintenance" },
    { label: "Provide debriefing and/or counseling" },
    { label: "Improve Communication / reporting procedures" },
    { label: "Improve Work Environment" },
    { label: "Request Maintenance" },
    { label: "Improve Security" },
    { label: "Provide, review or replace Behavioural Support Plan" },
    { label: "Develop and/or provide training" },
    { label: "Improve work organisation" },
    { label: "Provide or replace equipment/tools" },
    { label: "Request Safety Data Sheet" },
  ];

  const Outcomes = [
    { label: "Did the injured person stop work?" },
    { label: "Treated By Doctor" },
    { label: "Lodged workers with compensation claim" },
    { label: "Contacted by Return to Work coordinator" },
    { label: "Workcover notified" },
    { label: "Insurer Notified" },
    { label: "Returned to normal duties" },
    { label: "Returned to modified duties" },
    { label: "Hospitalised" },
    { label: "WHS Committee/representative notified" },
    { label: "Others" },
  ];

  const otherPeople = [
    <input type="checkbox" />,
    "Name of Person",
    "Status",
    "Action",
  ];
  // const otherPeopleData = [
  //   <input type="checkbox" />,
  //   "Ali Ahmad",
  //   "Active",
  //   "Edit",
  // ];

  return (
    <div className="bg-gray-100 py-8 px-8">
      {/* <Header /> */}

      {/* modal */}
      <Modal
        showModal={showModal}
        errorStyles={errorStyles}
        datasubmitHandler={addOtherPerson}
        closeModalHandler={closeModalHandler}
      />
      {/* modal */}

      <div className="py-8">
        {/* <SideBar/> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white border rounded-lg shadow py-4 px-8 grid grid-cols-12 gap-x-8">
            <div className="col-span-12 flex flex-col py-2">
              <h1 className="text-4xl font-bold">Incident/Injury Report</h1>
              <p className="pt-8">
                To be completed for ALL incidents and accidents where an injury
                has or could have resulted.
              </p>
              <p className="font-semibold">Incident details</p>
            </div>
            <div className="col-span-6 flex flex-col py-2">
              <label htmlFor="" className="text-gray-500">
                Primary Participant
              </label>
              <input
                {...register("primaryParticipant", {
                  required: true,
                  minLength: 6,
                  maxLength: 20,
                })}
                name="primaryParticipant"
                type="text"
                // placeholder="Syed Ahmad"
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.primaryParticipant?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
              {errors?.primaryParticipant?.type === "minLength" && (
                <span className="text-sm text-red-500">
                  Primary Participant must contain atleast 6 characters
                </span>
              )}
              {errors?.primaryParticipant?.type === "maxLength" && (
                <span className="text-sm text-red-500">
                  Primary Participant must not exceed 20 characters
                </span>
              )}
            </div>
            <div className="col-span-6 flex flex-col py-2">
              <label htmlFor="" className="text-gray-500">
                Additional Participants Involved
              </label>
              <input
                {...register("additionalParticipant", {
                  required: true,
                  minLength: 6,
                  maxLength: 20,
                })}
                name="additionalParticipant"
                type="text"
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.additionalParticipant?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
              {errors?.additionalParticipant?.type === "minLength" && (
                <span className="text-sm text-red-500">
                  Additional Participant must contain atleast 6 characters
                </span>
              )}
              {errors?.additionalParticipant?.type === "maxLength" && (
                <span className="text-sm text-red-500">
                  Additional Participant must not exceed 20 characters
                </span>
              )}
            </div>
            <div className="col-span-6 flex flex-col py-2">
              <label htmlFor="" className="text-gray-500">
                Primary Staff Member Involved
              </label>
              <input
                {...register("primaryStaffMember", {
                  required: true,
                  pattern: EMAIL_REGEX_EXP,
                })}
                name="primaryStaffMember"
                type="email"
                // placeholder="abx@gmail.com"
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.primaryStaffMember?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
              {errors?.primaryStaffMember?.type === "pattern" && (
                <span className="text-sm text-red-500">
                  Please enter a valid email address!
                </span>
              )}
            </div>
            <div className="col-span-6 flex flex-col py-2">
              <label htmlFor="" className="text-gray-500">
                Additional Staff Members Involved
              </label>
              <input
                {...register("additionalStaffMember", {
                  required: true,
                  minLength: 6,
                  maxLength: 20,
                })}
                name="additionalStaffMember"
                type="text"
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.additionalStaffMember?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
              {errors?.additionalStaffMember?.type === "minLength" && (
                <span className="text-sm text-red-500">
                  Additional Staff Members must contain atleast 6 characters
                </span>
              )}
              {errors?.additionalStaffMember?.type === "maxLength" && (
                <span className="text-sm text-red-500">
                  Additional Staff Members must not exceed 20 characters
                </span>
              )}
            </div>

            <div className="col-span-6 flex flex-col py-2">
              <label htmlFor="" className="text-gray-500">
                Location of incident
              </label>
              <input
                {...register("incidentLocation", {
                  required: true,
                  minLength: 4,
                  maxLength: 20,
                })}
                name="incidentLocation"
                type="text"
                // placeholder="Main Hall"
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.incidentLocation?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
              {errors?.incidentLocation?.type === "minLength" && (
                <span className="text-sm text-red-500">
                  Location of incidentmust contain atleast 4 characters
                </span>
              )}
              {errors?.incidentLocation?.type === "maxLength" && (
                <span className="text-sm text-red-500">
                  Location of incidents must not exceed 20 characters
                </span>
              )}
            </div>
            <div className="col-span-6 flex flex-col py-2">
              <label htmlFor="" className="text-gray-500">
                Date Of Incident
              </label>
              <input
                {...register("incidentDate", {
                  required: true,
                })}
                name="incidentDate"
                type="date"
                min={moment().format("DD-MM-YYYY")}
                // placeholder="15/12/2022"
                className="bg-gray-100 rounded px-4 py-2"
                max={new Date().toLocaleDateString("en-ca")}
              />
              {errors?.incidentDate?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
            </div>
            <div className="flex flex-col py-2 w-full col-span-12">
              {/* <p className='text-end bgred-400'>Others</p> */}
              <div className="flex justify-end">
                <button
                  className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 ease-linear transition-all duration-150 flex justify-end max-w-64 w-auto"
                  type="button"
                  onClick={openModalHandler}
                >
                  Add Others
                </button>
              </div>
              <Table
                otherPeople={otherPeople}
                otherPeopleData={otherPeopleData}
              />
              <div className="py-4 space-x-2">
                <button className="bg-red-500 text-white rounded-lg px-3 py-1">
                  Delete All
                </button>
                <button className="bg-red-500 text-white rounded-lg px-3 py-1">
                  Delete{" "}
                </button>
                {/* <button className='bg-gray-200 rounded-lg px-3 py-1'>Add Row</button> */}
              </div>
            </div>
          </div>

          {/* Section 2 */}
          <div className="py-4">
            <div className="bg-white border rounded-lg shadow py-4 px-8">
              <div className="w-1/2 flex flex-col py-2">
                <label htmlFor="" className="text-gray-500 ">
                  Out Come
                </label>
                <select
                  {...register("outcome", {
                    required: true,
                  })}
                  name="outcome"
                  id=""
                  className="bg-gray-100 rounded px-4 py-2"
                >
                  <option value="First Aid">First Aid</option>
                  <option value="Incident">Incident</option>
                </select>
                {errors?.outcome?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>
              <span htmlFor="" className="text-gray-700 font-medium">
                Advocacy Offering
              </span>
              <div className="w-1/2 flex items-center space-x-3 py-2 text-gray-500">
                <input
                  {...register("advocacy", {
                    // required: true,
                  })}
                  name="advocacy"
                  type="checkbox"
                  className="bg-gray-100 rounded px-4 py-2"
                />
                <label htmlFor="">
                  Have you offered advocates to the person?
                </label>
              </div>
              <div className="w-1/2 flex flex-col py-2">
                <label htmlFor="" className="text-gray-500">
                  If yes, Organization:
                </label>
                <input
                  {...register("advocacyOrganization", {
                    maxLength: 254,
                  })}
                  name="advocacyOrganization"
                  type="text"
                  className="bg-gray-100 rounded px-4 py-2"
                />
              </div>
            </div>
          </div>

          {/* Invoice Detail section */}
          <div className="bg-white border rounded-lg shadow py-4 px-8 flex flex-wrap space-x-4">
            <div className="grid grid-cols-2 max-w-full w-full gap-4">
              <div className="col-span-2 font-bold text-xl">
                Details of involved person
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="" className="text-gray-500">
                  First Name
                </label>
                <input
                  {...register("firstName", {
                    required: true,
                    minLength: 3,
                    maxLength: 20,
                  })}
                  name="firstName"
                  type="text"
                  // placeholder="Ali ahmad"
                  className="bg-gray-100 rounded px-4 py-2"
                />
                {errors?.firstName?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
                {errors?.firstName?.type === "minLength" && (
                  <span className="text-sm text-red-500">
                    First Name contain atleast 3 characters
                  </span>
                )}
                {errors?.firstName?.type === "maxLength" && (
                  <span className="text-sm text-red-500">
                    First Name must not exceed 20 characters
                  </span>
                )}
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="" className="text-gray-500">
                  Surname
                </label>
                <input
                  {...register("surname", {
                    required: true,
                    minLength: 3,
                    maxLength: 20,
                  })}
                  name="surname"
                  type="text"
                  // placeholder="ahmad"
                  className="bg-gray-100 rounded px-4 py-2"
                />
                {errors?.surname?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
                {errors?.firstName?.type === "minLength" && (
                  <span className="text-sm text-red-500">
                    Sur Name contain atleast 3 characters
                  </span>
                )}
                {errors?.surname?.type === "maxLength" && (
                  <span className="text-sm text-red-500">
                    Sur Name must not exceed 20 characters
                  </span>
                )}
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="" className="text-gray-500">
                  Date of Birth
                </label>
                <input
                  {...register("dateOfBirth", {
                    required: true,
                  })}
                  name="dateOfBirth"
                  max={new Date().toLocaleDateString("en-ca")}
                  type="date"
                  min={moment().format("DD-MM-YYYY")}
                  className="bg-gray-100 rounded px-4 py-2"
                />
                {errors?.surname?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="" className="text-gray-500 ">
                  Gender
                </label>
                <select
                  {...register("gender", {
                    required: true,
                  })}
                  name="gender"
                  id=""
                  className="bg-gray-100 rounded px-4 py-2"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {errors?.gender?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>

              <div className="col-span-1 flex flex-col">
                <label htmlFor="" className="text-gray-500">
                  Phone
                </label>
                <input
                  {...register("phone", {
                    required: true,
                  })}
                  name="phone"
                  type="number"
                  className="bg-gray-100 rounded px-4 py-2"
                />
                {errors?.phone?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="" className="text-gray-500">
                  Mobile
                </label>
                <input
                  {...register("mobile", {
                    required: true,
                  })}
                  name="mobile"
                  type="number"
                  className="bg-gray-100 rounded px-4 py-2"
                />
                {errors?.mobile?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="" className="text-gray-500 ">
                  Employement Status
                </label>
                <select
                  {...register("employmentStatus", {
                    required: true,
                  })}
                  name="employmentStatus"
                  id=""
                  className="bg-gray-100 rounded px-4 py-2"
                >
                  <option value="Part Time">Part Time</option>
                  <option value="Full Time">Full Time</option>
                </select>
                {errors?.employmentStatus?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>
              <div className="col-span-1 flex flex-col">
                <label htmlFor="" className="text-gray-500 ">
                  Experience in job
                </label>
                <select
                  {...register("experience", {
                    required: true,
                  })}
                  name="experience"
                  id=""
                  className="bg-gray-100 rounded px-4 py-2"
                >
                  <option value="0-2 years">0-2 years</option>
                  <option value="0-2 years">0-2 years</option>
                </select>
                {errors?.experience?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>

              <div className="col-span-2 flex flex-col pb-4">
                <label htmlFor="" className="text-gray-500 pb-1">
                  Address
                </label>
                <textarea
                  {...register("address", {
                    required: true,
                    minLength: 6,
                    maxLength: 200,
                  })}
                  name="address"
                  id=""
                  rows="3"
                  className="overflow-y-auto bg-gray-100 rounded px-4 py-2"
                ></textarea>
                {errors?.surname?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
                {errors?.firstName?.type === "minLength" && (
                  <span className="text-sm text-red-500">
                    Address contain atleast 6 characters
                  </span>
                )}
                {errors?.surname?.type === "maxLength" && (
                  <span className="text-sm text-red-500">
                    Address must not exceed 200 characters
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* Detail of witness */}
          <div className="bg-white border rounded-lg shadow py-4 px-8 grid grid-cols-12 gap-x-8 my-4">
            <div className="col-span-12 flex flex-col py-2">
              <p className="font-semibold">Details of witness/es (if any)</p>
              <p className="">Witness/witnesses</p>
            </div>
            <div className="flex flex-col py-2 w-full col-span-12">
              <div className="flex justify-end">
                <button
                  className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-4 ease-linear transition-all duration-150 flex justify-end max-w-64 w-auto"
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  Add Witness
                </button>
              </div>
              <Table otherPeople={otherPeople} otherPeopleData={witnesses} />
              <div className="py-4 space-x-2">
                <button className="bg-red-500 text-white rounded-lg px-3 py-1">
                  Delete All
                </button>
                <button className="bg-red-500 text-white rounded-lg px-3 py-1">
                  Delete{" "}
                </button>
              </div>
              <div className="col-span-12 flex flex-col py-2">
                <label htmlFor="" className="text-gray-500 pb-1">
                  What task was being performed at the time of the incident?
                </label>
                <textarea
                  {...register("tasksPerformed", {})}
                  name="tasksPerformed"
                  id=""
                  rows="3"
                  // placeholder="Participant was walking in hallway"
                  className="overflow-y-auto bg-gray-100 rounded px-4 py-2"
                ></textarea>
              </div>
              <div className="col-span-12 flex flex-col py-2">
                <label htmlFor="" className="text-gray-500 pb-1">
                  What happened? (e.g. ‘employee tripped over box’ or ‘forklift
                  hit wall’)
                </label>
                <textarea
                  {...register("happened", {})}
                  name="happened"
                  id=""
                  rows="3"
                  // placeholder="Participant stuck finger in Electric Socket and got an electric shock."
                  className="overflow-y-auto bg-gray-100 rounded px-4 py-2"
                ></textarea>
              </div>
            </div>
          </div>
          {/* End Detail of witness */}

          {/* Cause of Injury */}
          <div className="bg-white border rounded-lg shadow py-4 px-8 grid grid-cols-12 gap-x-8 my-4">
            <div className="col-span-12 flex flex-col py-2">
              <p className="font-bold">Cause of Injury</p>
            </div>
            {causeOfInjury.map((item) => {
              return (
                <div className="space-x-3 py-2 text-gray-500 col-span-4">
                  <input
                    {...register(`${item.label}`, {})}
                    name={item.label}
                    type="checkbox"
                    className="bg-gray-100 rounded px-4 py-2"
                  />
                  <label htmlFor="">{item.label}</label>
                </div>
              );
            })}
            <div className="col-span-12 flex flex-col text-gray-800 pt-8 pb-4">
              <p className="font-semibold">Nature of Injury</p>
            </div>
            <div className="col-span-6 flex flex-col text-gray-500">
              <label htmlFor="" className="text-gray-500">
                Nature of injury (e.g. burn, cut, scrape, etc.):
              </label>
              <input
                {...register("natureOfInjury", {
                  required: true,
                  maxLength: 254,
                })}
                name="natureOfInjury"
                type="text"
                // placeholder="i.e Electicity Shok"
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.natureOfInjury?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
            </div>
            <div className="col-span-6 flex flex-col text-gray-500">
              <label htmlFor="" className="text-gray-500">
                Location on body
              </label>
              <input
                {...register("locationOnBody", {})}
                name="locationOnBody"
                type="file"
                className="bg-gray-100 rounded px-4 py-2"
              />
            </div>
            <div className="col-span-12 flex flex-col text-gray-500">
              <p className="font-semibold text-gray-700 pt-8">
                Violence, Abuse, Neglect, Exploitation And Discrimination
              </p>
              <p className="pb-4">
                Does the Participant look like there has been Abuse/ Neglect/
                Exploitation/
              </p>
            </div>
            <div className="col-span-6 flex flex-col text-gray-500">
              <label htmlFor="" className="text-gray-500 ">
                Discrimination:
              </label>
              <select
                {...register("discrimination", {})}
                name="discrimination"
                id=""
                className="bg-gray-100 rounded px-4 py-2"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="col-span-12 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500 pb-1">
                if yes, Provide details
              </label>
              <textarea
                {...register("discriminationDetails", {})}
                name="discriminationDetails"
                id=""
                rows="3"
                className="bg-gray-100 overflow-y-auto rounded px-4 py-2"
              ></textarea>
            </div>
          </div>
          {/* End Cause of injury */}

          {/* Treatment */}
          <div className="bg-white border rounded-lg shadow py-4 px-8 grid grid-cols-12 gap-y-4 gap-x-8 my-4">
            <div className="col-span-12 flex flex-col pt-2">
              <p className="font-bold">Treatment</p>
            </div>
            <div className="col-span-6 flex flex-col text-gray-500">
              <label htmlFor="" className="text-gray-500 ">
                Treatment Administered
              </label>
              <select
                {...register("treatmentAdministered", {})}
                name="treatmentAdministered"
                id=""
                className="bg-gray-100 rounded px-4 py-2"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="col-span-6 flex flex-col text-gray-500">
              <label htmlFor="" className="text-gray-500 ">
                Referral required
              </label>
              <select
                {...register("referralRequired", {})}
                name="referralRequired"
                id=""
                className="bg-gray-100 rounded px-4 py-2"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="col-span-6 flex flex-col text-gray-500">
              <label htmlFor="" className="text-gray-500">
                First aid attendant Name
              </label>
              <input
                {...register("firstAidAttendantName", {
                  required: true,
                  maxLength: 254,
                })}
                name="firstAidAttendantName"
                type="text"
                // placeholder="Shehzad"
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.firstAidAttendantName?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
            </div>
          </div>
          {/* End Treatment */}

          {/* Remedial actions recommended */}
          <div className="bg-white border rounded-lg shadow py-4 px-8 grid grid-cols-12 gap-x-8 my-4">
            <div className="col-span-12 flex flex-col py-2">
              <p className="font-bold">Remedial actions recommended</p>
            </div>
            {RemedialActions.map((item) => {
              return (
                <div className="space-x-3 py-2 text-gray-500 col-span-4">
                  <input
                    {...register(`${item.label}`, {})}
                    name={item.label}
                    type="checkbox"
                    className="bg-gray-100 rounded px-4 py-2"
                  />
                  <label htmlFor="">{item.label}</label>
                </div>
              );
            })}
            <div className="col-span-12 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500 pb-1">
                What steps were taken immediately after the incident?
              </label>
              <textarea
                {...register("immediateSteps", {})}
                name="immediateSteps"
                id=""
                rows="3"
                // placeholder="Participant was given first aid, medical officers were called upon in case of emergency need."
                className="overflow-y-auto bg-gray-100 rounded px-4 py-2"
              ></textarea>
            </div>
            <div className="col-span-12 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500 pb-1">
                What, in your own words, has been implemented or planned to
                prevent recurrence?
              </label>
              <textarea
                {...register("recurrencePrevention", {})}
                name="recurrencePrevention"
                id=""
                rows="3"
                // placeholder="The socket has been shut down, in the future, the whole hallway will be void of electrical sockets."
                className="overflow-y-auto bg-gray-100 rounded px-4 py-2"
              ></textarea>
            </div>
            <div className="col-span-12 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500 pb-1">
                Electrical Socket shut down and sealed.
              </label>
              <textarea
                {...register("electricalSocket", {})}
                name="electricalSocket"
                id=""
                rows="3"
                // placeholder="The socket has been shut down, in the future, the whole hallway will be void of electrical sockets."
                className="overflow-y-auto bg-gray-100 rounded px-4 py-2"
              ></textarea>
            </div>
          </div>
          {/* End Remedial actions recommended */}

          {/* Staff Details*/}
          <div className="bg-white border rounded-lg shadow py-4 px-8 grid grid-cols-12 gap-x-8 my-4">
            <div className="col-span-12 flex flex-col py-2">
              <p className="font-bold">Staff Details</p>
            </div>
            <div className="col-span-6 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500">
                Staff Member
              </label>
              <input
                {...register("staffMember", {
                  required: true,
                  maxLength: 254,
                })}
                name="staffMember"
                type="text"
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.staffMember?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
            </div>
            <div className="col-span-6 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500">
                Position
              </label>
              <input
                {...register("position", {
                  required: true,
                  maxLength: 254,
                })}
                name="position"
                type="text"
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.position?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
            </div>
            <div className="col-span-6 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500">
                Date
              </label>
              <input
                {...register("date", {
                  required: true,
                })}
                name="date"
                type="date"
                min={moment().format("DD-MM-YYYY")}
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.date?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
            </div>
          </div>
          {/* End Staff Details*/}

          {/* Outcomes */}
          <div className="bg-white border rounded-lg shadow py-4 px-8 grid grid-cols-12 gap-x-8 my-4">
            <div className="col-span-12 flex flex-col py-2">
              <p className="font-bold">Outcomes</p>
            </div>
            {Outcomes.map((item) => {
              return (
                <div className="space-x-3 py-2 text-gray-500 col-span-4">
                  <input
                    {...register(`${item.label}`, {})}
                    name={item.label}
                    type="checkbox"
                    className="bg-gray-100 rounded px-4 py-2"
                  />
                  <label htmlFor="">{item.label}</label>
                </div>
              );
            })}
            <div className="col-span-12 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500 pb-1">
                Manager’s review comments:
              </label>
              <textarea
                {...register("managerReview", {})}
                name="managerReview"
                id=""
                rows="3"
                className="overflow-y-auto bg-gray-100 rounded px-4 py-2"
              ></textarea>
            </div>
            <div className="col-span-6 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500">
                Staff Member
              </label>
              <input
                {...register("staffMemberOutcomes", {
                  required: true,
                  maxLength: 254,
                })}
                name="staffMemberOutcomes"
                type="text"
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.staffMemberOutcomes?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
            </div>
            <div className="col-span-6 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500">
                Position
              </label>
              <input
                {...register("postionOutcomes", {
                  required: true,
                  maxLength: 254,
                })}
                name="postionOutcomes"
                type="text"
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.postionOutcomes?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
            </div>
            <div className="col-span-6 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500">
                Date
              </label>
              <input
                {...register("dateOutcomes", {
                  required: true,
                })}
                name="dateOutcomes"
                type="date"
                min={moment().format("DD-MM-YYYY")}
                className="bg-gray-100 rounded px-4 py-2"
              />
              {errors?.dateOutcomes?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
            </div>
          </div>
          {/* End Outcomes */}

          {/* Signature */}
          <div className="bg-white border rounded-lg shadow py-4 px-8 my-4">
            <div className="flex flex-col py-2">
              <p className="font-bold text-green-700">Signatures</p>
            </div>
            <div className="flex justify-between items-center">
              <div className=" flex flex-col text-gray-500 py-2 ">
                <p className="pb-2">Manager's Signature</p>
                <SignatureCanvas
                  penColor="green"
                  ref={sigCanvasManager}
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas border rounded-lg bg-gray-100",
                  }}
                />
                <div onClick={clearSignatureManager}>
                  <p className="flex items-center mt-1 cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                      />
                    </svg>
                    clear
                  </p>
                </div>
              </div>
              <div className=" flex flex-col text-gray-500 py-2 ">
                <p className="pb-2">Staff Member's Signature</p>
                <SignatureCanvas
                  ref={sigCanvasStaff}
                  penColor="green"
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas border rounded-lg bg-gray-100",
                  }}
                />
                <div onClick={clearSignatureStaff}>
                  <p className="flex items-center mt-1 cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                      />
                    </svg>
                    clear
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End Signature */}

          {/* comment */}
          <div className="bg-white border rounded-lg shadow py-4 px-8 grid grid-cols-12 gap-x-8 my-4">
            <div className="col-span-12 flex flex-col py-2">
              <p className="font-bold">Add a Comment</p>
            </div>
            <div className="col-span-12 flex flex-col text-gray-500 py-2">
              <label htmlFor="" className="text-gray-500 pb-1">
                comments:
              </label>
              <textarea
                {...register("comments", {})}
                name="comments"
                id=""
                rows="3"
                className="overflow-y-auto bg-gray-100 rounded px-4 py-2"
              ></textarea>
            </div>
            <button className="border shadow rounded-lg py-1 w-32">
              comment
            </button>
          </div>
          {/* End comments */}
          <button type="submit" className="border shadow rounded-lg py-1 w-32">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
