import React, { useContext, useState } from "react";
import { BiPencil } from "react-icons/bi";
import { HiOutlineTrash } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { classNames, formatDateIntoDMY, textTruncate } from "Utils/utils";
import Loader from "../../../../../components/common/Loader/Loader";
import { deleteClientTask } from "../../../../../store/actions/cos.actions";
import api from "../../../../../store/services/cos.services";
import { LoginContext } from "helpers/LoginContext";

const ClientTaskItem = (props) => {
  const { item, setOpenCreateTaskModal, setSelectedTask, index } = props;
  const [isLoader, setisLoader] = useState(null);
  const dispatch = useDispatch();

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const handleDelete = async (uuid) => {
    swal({
      // title: "Are you sure?",
      text: "Are you sure that you want to Delete?",
      icon: "warning",
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          setisLoader(uuid);
          const response = await api.clientTaskDelete({ uuid: uuid });
          if (response) {
            setisLoader(null);
            dispatch(deleteClientTask.success(response));
            toast.success("Client Task Deleted Successfully!");
          }
        } catch (err) {
          console.log("err", err);
          toast.error("Something went wrong!");
          setisLoader(null);
        }
      }
    });
  };

  return (
    <tr key={index} className='even:bg-white odd:bg-gray-50'>
      <td
        className='whitespace-nowrap px-3 py-4 text-sm  text-[#101828] font-medium'
        title={item.task_name}
      >
        {textTruncate(item.task_name, 20, 19)}
      </td>

      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      Allied Health Report Reading
    </td> */}

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        {item?.assigned_to?.first_name.concat(
          " ",
          item?.assigned_to?.last_name
        )}
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        {formatDateIntoDMY(item.due_date)}
      </td>

      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <span className="inline-flex items-center rounded-full  px-2.5 py-0.5 text-xs font-medium bg-[#ECFDF3] text-[#027A48]">
        {item?.priority?.title}
      </span>
    </td> */}

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <span
          className={`inline-flex items-center rounded-full  px-2.5 py-0.5 text-xs font-medium ${
            item.status === "Cancelled"
              ? "bg-red-300 text-white "
              : "bg-[#ECFDF3] text-[#027A48]"
          }  `}
        >
          {item.status}
        </span>
      </td>
      <td className='whitespace-nowrap text-center text-sm font-medium '>
        <div className='flex flex-row space-x-1 justify-center'>
          <div
            className='icon-pencil-wrapper cursor-pointer mr-2'
            title='Edit'
            onClick={() => {
              setSelectedTask(item);
              setOpenCreateTaskModal(true);
            }}
          >
            <BiPencil className='icon-size' />
          </div>
          {isLoader && isLoader === item.uuid ? (
            <Loader isSmallView cssClass='text-black' />
          ) : (
            <div
              className={classNames(
                "icon-delete-wrapper",
                isUserGracePeriodOver === true
                  ? "cursor-auto"
                  : "cursor-pointer"
              )}
              title='Delete'
              onClick={() => {
                if (isUserGracePeriodOver) {
                  return;
                }
                handleDelete(item.uuid);
              }}
            >
              <HiOutlineTrash className='icon-size' />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default ClientTaskItem;
