import { REGEX_PHONE_NUMBER } from "Constants/constant";

export const checkEmail = (value) => {
  if (value)
    return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "Invalid email address"
      : "";
  return "";
};

export const checkPhoneNo = (value) => {
  if (value) {
    return REGEX_PHONE_NUMBER.test(value) === false
      ? // ? "Phone number must start with 04, 1300 or 1800"
        "Please enter a valid phone number!"
      : "";
    // return !value.startsWith("0") ||!/^\d{10}$/.test(value) ?"phone no must start with 0 & exact 10 digits":""
  }
};

export const validateNoLeadingSpaces = (value) => {
  if (/^\s/.test(value)) {
    return "No leading spaces are allowed!";
  }
  return true;
};
