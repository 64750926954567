import { baseURLImage, getNameProfile } from "Utils/utils";
import React from "react";
import Progressbar from "./Progressbar";
import { Link } from "react-router-dom";
import "./ProgressTable.css";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const ProgressTable = ({ title, item, columnName }) => {
  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const handleRedirection = (element) => {
    if (
      title === "Participants Compliance" &&
      roleAccess(
        [],
        PERMISSION_KEYS.PARTICIPANT,
        PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
      )
    ) {
      return `/sp-profile/${element.uuid}`;
    } else if (
      title !== "Participants Compliance" &&
      roleAccess(
        [],
        PERMISSION_KEYS.HRM,
        PERMISSION_KEYS_CHILDREN.HRM.WORKER.SHOW
      )
    ) {
      return `/worker-profile/${element.uuid}`;
      // return {
      //   pathname: `/worker-profile/${element.uuid}`,
      //   state: "shoaib",
      // };
    }
    return "#";
  };

  return (
    <div>
      <div className="pl-4">
        <h3 className="text-lg font-bold">{title}</h3>
      </div>

      <div className="h-[500px] overflow-x-auto overflow-y-auto rounded-lg mt-4 pb-4 custom-scroll">
        <table className="min-w-full divide-y divide-gray-300 border ">
          {/* <thead className="bg-white sticky top-0 z-10">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-xs whitespace-nowrap font-semibold text-gray-500"
                colSpan="2"
              >
                {columnName}
              </th>
            </tr>
          </thead> */}
          <tbody className="divide-y divide-gray-200 bg-white ">
            {item && item.length > 0 ? (
              item.map((element) => {
                return (
                  <tr
                    className=" even:bg-white odd:bg-gray-50 w-1/3"
                    key={element.id}
                  >
                    <td className="  whitespace-nowrap py-4 pl-4 pr-3 text-sm">
                      <Link to={handleRedirection(element)}>
                        <div className="flex items-center cursor-pointer">
                          <div className="h-10 w-10 flex-shrink-0">
                            <img
                              onError={(e) =>
                                handleImageError(
                                  e,
                                  element?.first_name,
                                  element?.last_name
                                )
                              }
                              className="object-cover h-10 w-10 rounded-full"
                              src={
                                element?.photo || element.profile_picture
                                  ? baseURLImage(
                                      element?.photo || element.profile_picture
                                    )
                                  : getNameProfile(
                                      element?.first_name,
                                      element?.last_name || " "
                                    )
                              }
                              alt=""
                            />
                          </div>
                          <div className="ml-4">
                            <div
                              className=" font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize"
                              style={{
                                maxWidth: "100px",
                                textOverflow: "ellipsis",
                              }}
                              title={`${element?.first_name} ${element?.last_name}`}
                            >
                              {element?.first_name} {element?.last_name}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-8 pr-3 text-sm w-2/3">
                      <Progressbar
                        compliance={
                          element?.compliance_score ||
                          element?.on_boarding_progress
                        }
                        title={title}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="p-4">
                <td
                  className="whitespace-nowrap text-sm flex justify-center items-center"
                  colSpan={2}
                >
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProgressTable;