import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { FaCheck } from 'react-icons/fa'; // Import the checkmark icon

const MultiSelectDropdown = ({ data,cssClass,handleChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const CheckboxOption = ({ innerProps, label, isSelected, }) => (
    <div
      {...innerProps}
      className={`flex items-center justify-between  mt-1 p-2 ${
        isSelected ? 'bg-[#516CB8] ' : 'bg-white '
      }`}
    >
      <label className={`ml-2 block text-sm ${isSelected ? 'text-white' : 'text-gray-900'}`}>
        {label}
      </label>
      <div
        className={`mx-2 h-6 w-6 rounded-full flex items-center cursor-pointer justify-center border ${isSelected ? "bg-white":""} `}
      
        onClick={() => handleOptionToggle(label)}
      >
       {isSelected && <FaCheck  className='bg-white text-[#516cb8]'/>}
      </div>
    </div>
  );

  const handleOptionToggle = (optionLabel) => {
    const selectedOption = data.find((option) => option.label === optionLabel);

    if (!selectedOption) return;
  
    setSelectedOptions(prevSelectedOptions => {
      const isSelected = prevSelectedOptions.some(option => option === selectedOption.value);
      if (isSelected) {
        return prevSelectedOptions.filter(option => option !== selectedOption.value);
      } else {
        return [...prevSelectedOptions, selectedOption.value];
      }
    });
  };

  useEffect(()=>{
    if(selectedOptions){
      handleChange(selectedOptions)
    }

  },[selectedOptions])
 
  
  const customStyles = {
    multiValueRemove: (base, state) => ({
      ...base,
      display: 'none', // Hide the close icon for selected options
    }),
  };

  return (
    <Select
      isMulti
      options={data}
      value={data.filter((option) => selectedOptions.includes(option.value))}
      components={{ Option: CheckboxOption }}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      onMenuBlur={() => setMenuIsOpen(false)}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable={false}
      clearableValue= {false}
      styles={customStyles} // Apply custom styles to hide the close icon
      menuPlacement="auto"

    />
  );
};

export default MultiSelectDropdown;



// import React, { useState } from 'react';
// import Select, { components } from 'react-select';

// const MultiSelectDropdown = ({data}) => {


//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [menuIsOpen, setMenuIsOpen] = useState(false);

//   const customStyles = {
//     option: (provided, { isSelected }) => ({
//       ...provided,
//       display: 'flex',
//       alignItems: 'center',
//       maxWidth: '100px',
//       backgroundColor: isSelected ? 'blue' : 'white',
//       color: isSelected ? 'white' : 'black',
//     }),
//     multiValue: (provided) => ({
//       ...provided,
//       maxWidth: '100px', // Adjust the maxWidth for the wrapped container
//     }),
//   };
  

//   // const CheckboxOption = ({ innerProps, label, isSelected }) => (
//   //   <div {...innerProps} className={`flex items-center justify-between mt-1 p-4 bg ${
//   //     isSelected ?"bg-[#516CB8]":""
//   //   }`}>
//   //     <label className={`ml-2 block text-sm  ${isSelected ? "text-white":"text-gray-900"} `}>{label}</label>
//   //     <input
//   //       type="checkbox"
//   //       className={`mx-2 h-6 w-6  bg-white rounded-full ${isSelected ? "bg-white":""}`}
//   //       onChange={() => handleOptionToggle(label)}
//   //       checked={isSelected}
//   //     />
//   //   </div>
//   // );

//   const CheckboxOption = ({ innerProps, label, isSelected }) => (
//     <div
//       {...innerProps}
//       className={`flex items-center justify-between mt-1 p-4 ${
//         isSelected ? 'bg-[#516CB8] ' : 'bg-white '
//       }`}
//     >
//       <label className={`ml-2 block text-sm ${isSelected ? 'text-white' : 'text-gray-900'}`}>{label}</label>
//       <input
//         type="checkbox"
//         className={`mx-2 h-6 w-6 rounded-full`}
//         style={{ backgroundColor: isSelected ? 'red' : '',color:isSelected?"#516CB8":"red" }}
//         onChange={() => handleOptionToggle(label)}
//         checked={isSelected}
//       />
//     </div>
//   );
  

//   const handleOptionToggle = (optionLabel) => {
//     const selectedOption = data.find((option) => option.label === optionLabel);
//     if (!selectedOption) return;

//     const isSelected = selectedOptions.includes(selectedOption.value);
//     if (isSelected) {
//       setSelectedOptions(selectedOptions.filter((value) => value !== selectedOption.value));
//     } else {
//       setSelectedOptions([...selectedOptions, selectedOption.value]);
//     }
//   };

//   return (
//     <Select
//       isMulti
//       options={data}
//       value={data.filter((option) => selectedOptions.includes(option.value))}
//       components={{ Option: CheckboxOption }}
//       // styles={customStyles}
//       menuIsOpen={menuIsOpen}
//       onMenuOpen={() => setMenuIsOpen(true)}
//       onMenuClose={() => setMenuIsOpen(false)}
//       onMenuBlur={() => setMenuIsOpen(false)}
//       closeMenuOnSelect={false}
      
//       hideSelectedOptions={false}
//     />
//   );
// };

// export default MultiSelectDropdown;
