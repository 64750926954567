import {
  baseURLImage,
  getNameProfile,
  formatDateTimeWithAMPM,
  formatDateToAustralian,
  isDateBeforeToday,
} from "Utils/utils";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import InprogressShiftStatusPopover from "./InprogressShiftStatusPopover";
import AvatarGroup from "components/common/Avatar/AvatarGroup";

const ExpiringDateTable = ({ title, item, isExpirDoc = false }) => {
  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const handleRedirection = (element) => {
    if (
      isExpirDoc &&
      roleAccess(
        [],
        PERMISSION_KEYS.HRM,
        PERMISSION_KEYS_CHILDREN.HRM.WORKER.SHOW
      )
    ) {
      return `/worker-profile/${element?.worker?.uuid}`;
    } else if (
      roleAccess(
        [],
        PERMISSION_KEYS.ROSTERING,
        PERMISSION_KEYS_CHILDREN.ROSTERING.SCHEDULE
      )
    ) {
      return `/rostering/schedule`;
    } else return "#";
  };

  return (
    <div>
      <div className='pl-4'>
        <h3 className='text-lg font-bold'>{title}</h3>
      </div>
      <div className='mx-2 mt-4'>
        <div className='max-h-[500px] overflow-x-auto overflow-y-auto custom-scroll'>
          <table className='w-full min-w-[100%] bg-white border-collapse rounded-lg shadow ring-1 ring-black ring-opacity-5'>
            <thead className='sticky z-[2] top-0 bg-white'>
              <tr>
                <th className='py-3.5 border-b pl-4 pr-3 text-left text-xs whitespace-nowrap font-semibold '>
                  Worker Name
                </th>
                <th className='py-3.5 border-b pl-4 pr-3 text-left text-xs whitespace-nowrap font-semibold '>
                  {isExpirDoc ? "Document Name" : "Participant Name"}
                </th>
                <th className='py-3.5 border-b pl-4 pr-3 text-left text-xs whitespace-nowrap font-semibold '>
                  {isExpirDoc ? "Expiring Date" : "Shift Start Time"}
                </th>
              </tr>
            </thead>

            <tbody className='divide-y divide-gray-100 bg-white'>
              {item && item.length > 0 ? (
                item.map((element) => (
                  <tr className='cursor-pointer bg-white' key={element.id}>
                    <td className='whitespace-nowrap py-2 pl-4 text-sm'>
                      <Link to={handleRedirection(element)}>
                        <div className='flex items-center'>
                          <div className='relative h-10 w-10 flex-shrink-0'>
                            <img
                              onError={(e) =>
                                handleImageError(
                                  e,
                                  element?.worker?.first_name,
                                  element?.worker?.last_name
                                )
                              }
                              className='object-cover h-10 w-10 rounded-full'
                              src={
                                element?.worker.photo
                                  ? baseURLImage(element?.worker.photo)
                                  : getNameProfile(
                                      element?.worker?.first_name,
                                      element?.worker?.last_name || " "
                                    )
                              }
                              alt=''
                            />
                            {!isExpirDoc && element?.worker_start_time && (
                              <InprogressShiftStatusPopover
                                workerStartTime={element.worker_start_time}
                                startTime={element.start_time}
                              />
                            )}
                          </div>
                          <div className='ml-2'>
                            <div
                              className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                              style={{
                                maxWidth: "60px",
                                textOverflow: "ellipsis",
                              }}
                              title={`${element?.worker?.first_name} ${element?.worker?.last_name}`}
                            >
                              {element?.worker?.first_name}{" "}
                              {element?.worker?.last_name}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </td>
                    <td className='whitespace-normal px-4 py-2 text-sm text-gray-500'>
                      <div
                        className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                        style={{ maxWidth: "90px", textOverflow: "ellipsis" }}
                        title={
                          isExpirDoc
                            ? `${element?.name}`
                            : element?.participants?.length > 1
                            ? "Group Shift"
                            : `${element?.participants?.[0]?.first_name} ${element?.participants?.[0]?.last_name}`
                        }
                      >
                        {isExpirDoc ? (
                          element?.name
                        ) : element?.participants?.length > 1 ? (
                          <AvatarGroup
                            popoverCss='flex w-[100%]'
                            arrayData={element?.participants}
                            cssClass='mt-0'
                            maxLength={4}
                          />
                        ) : (
                          `${element?.participants?.[0]?.first_name} ${element?.participants?.[0]?.last_name}`
                        )}
                      </div>
                    </td>
                    <td
                      className={`whitespace-nowrap px-4 py-2 text-sm text-gray-500 ${
                        isExpirDoc && isDateBeforeToday(element?.expire_date)
                          ? "blink"
                          : ""
                      }`}
                    >
                      {isExpirDoc
                        ? formatDateToAustralian(element?.expire_date)
                        : formatDateTimeWithAMPM(element?.start_time)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className='cursor-pointer bg-white'>
                  <td
                    colSpan='3'
                    className='whitespace-nowrap py-2 px-3 pr-3 text-sm text-gray-500'
                  >
                    <div className='text-center'>No data available</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ExpiringDateTable;
