import React, { useEffect, useState, useCallback } from "react";
import SelectOption from "components/common/Select/Select";
import { RECURRING_INTERVAL } from "store/constants";
import CheckboxWithLabel from "components/common/CheckboxWithLabel";
import moment from "moment";

const RecurringShift = ({
  setRecurringType,
  recurringType,
  watchFormData,
  setRecurrInterval,
  recurrInterval,
  setRecurringDates
}) => {
  const [selectedDays, setSelectedDays] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });
  const [recurringFor, setRecurringFor] = useState(null);
  
  useEffect(() => {
    if (watchFormData?.shift_date) {
      const shiftDate = moment(watchFormData.shift_date);
      const dayName = shiftDate.format("ddd");
      setSelectedDays((prevSelectedDays) => ({
        ...prevSelectedDays,
        [dayName]: true,
      }));
    }
  }, [watchFormData?.shift_date,recurrInterval,recurringType]);

  // const handleCheckboxChange = useCallback((day, isChecked) => {
  //   setSelectedDays((prevSelectedDays) => ({
  //     ...prevSelectedDays,
  //     [day]: isChecked,
  //   }));
  // }, []);

  const handleIntervalChange = useCallback((interval) => {
    setRecurrInterval(interval);
    setRecurringType(null);
    setSelectedDays({
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
      Sun: false,
    });

    let options = [];
    if (interval.value === "weekly") {
      options = generateOptions(52, "Week");
    } else if (interval.value === "fortnight") {
      options = generateOptions(26, "Fortnight");
    } else if (interval.value === "monthly") {
      options = generateOptions(12, "Month");
    }
    setRecurringFor(options);
  }, [setRecurrInterval, setRecurringType]);

  useEffect(() => {
    if (recurringType) {
      const shiftDate = moment(watchFormData?.shift_date);
      const number = parseInt(recurringType.value.split("_")[0]);
      const label = recurringType.value.split("_")[1];

      const formattedDates = [];

      let intervalFactor = 1;
      if (label === "Week") {
        intervalFactor = 7;
      } else if (label === "Fortnight") {
        intervalFactor = 14; 
      } else if (label === "Month") {
        const daysInMonth = shiftDate.daysInMonth();
        intervalFactor = daysInMonth;
      }

      for (let i = 0; i < number * intervalFactor; i++) {
        const currentDate = shiftDate.clone().add(i, "days");
        const dayName = currentDate.format("ddd");
        if (selectedDays[dayName]) {
          formattedDates.push(currentDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
        }
      }
      setRecurringDates(formattedDates);
    }
  }, [recurringType, watchFormData?.shift_date, selectedDays, setRecurringDates]);

  const generateOptions = useCallback((count, label) => {
    return Array.from({ length: count }, (_, index) => ({
      label: `${index + 1} ${label}${index === 0 ? "" : "s"}`,
      value: `${index + 1}_${label}`,
    }));
  }, []);

  return (
    <div>
      <div className="flex items-center gap-x-3 mt-4">
        <div className="w-[50%]">
          <label className="text-gray-700 text-md font-medium mb-1">
           Please choose recurring interval
          </label>
          <div>
            <SelectOption
              data={RECURRING_INTERVAL}
              placeHolder="Recurring Interval"
              selectedOption={recurrInterval}
              handleChange={handleIntervalChange}
              isSearch={false}
              cssClass="z-8"
            />
          </div>
        </div>
        <div className="w-[50%]">
          <label className="text-gray-700 text-md font-medium mb-1">
            Repeat for
          </label>
          <div>
            <SelectOption
              data={recurringFor}
              placeHolder={`select number `}
              selectedOption={recurringType}
              handleChange={(e) => {
                setRecurringType(e);
              }}
              isSearch={false}
              cssClass="z-8"
            />
          </div>
        </div>
      </div>
      {watchFormData?.is_recurring && recurringType &&  (
        <div className="mt-6">
          <label className="text-gray-700 text-md font-medium mb-1">
            Please choose the day/days to repeat the shift
          </label>
          <div className="flex flex-row flex-wrap mt-2 w-full gap-3">
            {Object.entries(selectedDays).map(([day, isSelected]) => (
              <div key={day} className="flex items-center ">
                <CheckboxWithLabel
                  label={day}
                  checkboxId={day}
                  checked={isSelected}
                  setSelectedDays={setSelectedDays}
                  // onChange={handleCheckboxChange}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecurringShift;
