import React, { useState, useEffect } from "react";
import { checkEmail } from "../../../Utils/FormValidation";
import InputField from "../../../components/common/Input/Input";
import { hasBlankSpaces } from "../../../Utils/utils";
import CustomButton from "../../../components/common/Button/CustomButton";
import { LOADER_STATUS } from "../../../Constants/constant";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import SelectOption from "components/common/Select/Select";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { Controller, useForm } from "react-hook-form";
import SwitchWithIcon from "../../../components/common/SwitchWithIcon";

const AddManager = (props) => {
  const {
    createSPManager,
    loading,
    error,
    openModel,
    handleClose,
    fetchRoleLoader,
    roleOptions,
    selectedItem = null,
    isUserGracePeriodOver,
  } = props;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [addLoader, setAddLoader] = useState(LOADER_STATUS.NA);
  const [passwordType, setPasswordType] = useState(true);

  const { control, setValue, watch } = useForm(); // Initialize useForm to access control and setValue
  const watchFormData = watch(); // This captures the current form data
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    if (selectedItem !== null) {
      setName(selectedItem.name);
      setEmail(selectedItem.email);
      if (roleOptions) {
        setSelectedRole(
          roleOptions.find((x) => x.value === selectedItem.role_id)
        );
      }
      setValue("active_manager", selectedItem.status);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedRole && getError("role")) {
      setErrors({ ...errors, role: null });
    }
  }, [selectedRole]);

  useEffect(() => {
    if (!loading && !error && addLoader === LOADER_STATUS.PENDING) {
      handleClear();
      setAddLoader(LOADER_STATUS.COMPLATE);
      handleClose(false);
    }
  }, [loading]);

  const validateInput = () => {
    const fnError = errors;
    if (selectedRole === null) {
      Object.assign(fnError, { role: "Role is Required" });
    }
    if (!name || name.length === 0) {
      Object.assign(fnError, { name: "Name is Required" });
    }
    if (!email || email.length <= 0 || checkEmail(email)) {
      const inValid = checkEmail(email);
      if (inValid) {
        Object.assign(fnError, { email: "Invalid email" });
      } else {
        Object.assign(fnError, { email: "Email is Required" });
      }
    }
    if (selectedItem === null) {
      if (!password || password.length <= 0) {
        Object.assign(fnError, { password: "Password is Required" });
      }
      if (!confirmPassword || confirmPassword.length <= 0) {
        Object.assign(fnError, {
          confirmPassword: "Confirm Password is Required",
        });
      }
      if (password.length > 0 || confirmPassword.length > 0) {
        if (password.length < 8) {
          Object.assign(fnError, {
            password: "Password must be 8 characters",
          });
        } else if (password !== confirmPassword) {
          Object.assign(fnError, {
            password: "Password does not match",
            confirmPassword: "Confirm Password does not match",
          });
        }
      }
    }
    if (
      Object.values(fnError).filter((x) => x !== null).length > 0 ||
      checkEmail(email)
    ) {
      setErrors({ ...fnError });
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (validateInput()) {
      return;
    }
    const payload = {};

    const activeManagerValue =
      selectedItem === null ? true : watchFormData.active_manager;

    Object.assign(payload, {
      status: activeManagerValue,
    });

    if (selectedItem === null) {
      Object.assign(payload, {
        password,
        name,
        email,
      });
    } else {
      Object.assign(payload, {
        manager_id: selectedItem.id,
      });
    }

    if (selectedRole !== null) {
      Object.assign(payload, {
        role_id: selectedRole.value,
      });
    }
    // console.log(" manager", payload.active_manager);
    setAddLoader(LOADER_STATUS.PENDING);
    createSPManager(payload, selectedItem !== null);
  };

  const getError = (value) => {
    if (!value) return "";
    return errors[value] && errors[value].length > 0 ? errors[value] : "";
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (hasBlankSpaces(value) || value.length === 0) {
      setErrors({
        ...errors,
        [e.target.name]: `${
          name === "confirmPassword"
            ? "Confirm Password is Required"
            : name + " is Required"
        } `,
      });
    } else if (name === "email" && value && value.length > 0) {
      const inValid = checkEmail(value);
      if (inValid) {
        setErrors({ ...errors, email: "Invalid email Address" });
      } else {
        setErrors({ ...errors, email: null });
      }
      return;
    } else if (
      selectedItem === null &&
      (name === "password" || name === "confirmPassword") &&
      value &&
      value.length > 0
    ) {
      if (password.length < 8) {
        setErrors({ ...errors, password: "Password must be 8 characters" });
      } else if (password !== confirmPassword) {
        setErrors({
          ...errors,
          password: "Password does not match",
          confirmPassword: "Confirm Password does not match",
        });
      } else {
        setErrors({
          ...errors,
          password: null,
          confirmPassword: null,
        });
      }
    } else if (errors[e.target.name] && value.length > 0) {
      setErrors({ ...errors, [e.target.name]: null });
    }
  };

  const handleClear = () => {
    setName("");
    setPassword("");
    setConfirmPassword("");
    setEmail("");
    setErrors({});
  };

  const modalFooter = () => (
    <>
      {!fetchRoleLoader && (
        <CustomButton
          showLoading={loading}
          isDisabled={loading || isUserGracePeriodOver}
          label={selectedItem === null ? "Submit" : "Update"}
          clickHandler={handleSubmit}
        />
      )}
    </>
  );

  return (
    <ModalSkeleton
      isOpen={openModel}
      title={selectedItem === null ? "Add Manager" : "Edit Manager"}
      cssClass={"w-[40%]"}
      modalFooter={modalFooter()}
      closeModal={() => handleClose(false)}
    >
      {fetchRoleLoader ? (
        <div
          className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
        >
          <CircularLoader
            classes='flex justify-center items-center'
            classloader='loader-l'
          />
        </div>
      ) : (
        <div className=''>
          <p className='px-2 bg-white text-sm text-gray-500'>
            Please enter following details to create new manager
          </p>

          <div className='px-2 py-2 bg-white'>
            <div className='grid grid-cols-6 gap-4'>
              <div className='col-span-6 sm:col-span-3'>
                <InputField
                  isDisabled={selectedItem !== null}
                  label='Name'
                  type='text'
                  handleChange={setName}
                  value={name}
                  ErrorMessage={getError("name")}
                  blurHandler={handleBlur}
                  name='name'
                />
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <InputField
                  isDisabled={selectedItem !== null}
                  label='Email Address'
                  type='email'
                  value={email}
                  handleChange={setEmail}
                  ErrorMessage={getError("email")}
                  name='email'
                  blurHandler={handleBlur}
                />
              </div>
              {selectedItem === null && (
                <>
                  <div className='col-span-6 sm:col-span-3'>
                    <span
                      className='flex justify-end pr-2 relative top-[50px] '
                      onClick={() => setPasswordType(!passwordType)}
                    >
                      {/* <AiFillEye className="relative top-[50px] " /> */}
                      <div className='z-10'>
                        {passwordType ? (
                          <AiFillEyeInvisible className='relative top-[00px] text-blue-dark ' />
                        ) : (
                          <AiFillEye className='relative top-[00px] text-blue-dark' />
                        )}
                      </div>
                    </span>
                    <InputField
                      label='Password'
                      type={passwordType ? "password" : "text"}
                      handleChange={setPassword}
                      value={password}
                      name='password'
                      ErrorMessage={getError("password")}
                      blurHandler={handleBlur}
                    />
                  </div>
                  <div className='col-span-6 sm:col-span-3'>
                    <span
                      className='flex justify-end pr-2 relative top-[50px]'
                      onClick={() => setPasswordType(!passwordType)}
                    >
                      {/* <AiFillEye className="relative top-[50px] " /> */}
                      <div className=' z-10'>
                        {passwordType ? (
                          <AiFillEyeInvisible className='relative top-[0px] text-blue-dark' />
                        ) : (
                          <AiFillEye className='relative top-[0px] text-blue-dark' />
                        )}
                      </div>
                    </span>
                    <InputField
                      label='Confirm Password'
                      type={passwordType ? "password" : "text"}
                      handleChange={setConfirmPassword}
                      value={confirmPassword}
                      name='confirmPassword'
                      ErrorMessage={
                        getError("confirmPassword")
                        // === "confirmPassword Is Required"
                        //   ? "Confirm Password Is Required"
                        //   : getError("confirmPassword")
                      }
                      blurHandler={handleBlur}
                    />
                  </div>
                </>
              )}
              <div className='col-span-6 sm:col-span-3 mb-2'>
                <label
                  htmlFor={"Role"}
                  className='block text-sm font-medium text-gray-700'
                >
                  Role<span className='text-red-500'>*</span>
                </label>
                <SelectOption
                  isDisabled={
                    watchFormData.active_manager === false ? true : false
                  }
                  // isMulti={true}
                  isSearch={false}
                  data={roleOptions}
                  selectedOption={selectedRole}
                  handleChange={setSelectedRole}
                  errorMessage={getError("role")}
                />
              </div>

              {selectedItem !== null && (
                <div className='col-span-6 sm:col-span-3 mb-2'>
                  <label
                    htmlFor={"Role"}
                    className='block text-sm font-medium text-gray-700'
                  >
                    Active<span className='text-red-500'>*</span>
                  </label>

                  <div className='mt-2'>
                    <Controller
                      name={`active_manager`}
                      control={control}
                      defaultValue={watchFormData.active_manager ? true : false}
                      render={({ field }) => (
                        <SwitchWithIcon
                          onChange={(value) => {
                            field.onChange(value);
                            setValue(`active_manager`, value);
                            if (
                              value === false &&
                              roleOptions &&
                              selectedItem !== null &&
                              selectedItem?.role_id
                            ) {
                              setSelectedRole(
                                roleOptions.find(
                                  (x) => x.value === selectedItem.role_id
                                )
                              );
                            }
                          }}
                          checked={field.value}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </ModalSkeleton>
  );
};

export default AddManager;
