import { USER_ROLE } from "Constants/constant";
import GetItemFromLocalstorage from "Utils/GetItemFromLocalstorage";
import { parseBooleanVal } from "Utils/utils";
import moment from "moment";

export const STRIPE_CHECK_SUBSCRIPTION_STATUS = {
  SUBSCRIBED: "subscribed",
  PAYMENT_FAILED_TRAIL_EXISTS: "paymentFailedTrailExists",
  PAYMENTFAILED: "paymentFailed",
  RETRY_PAYMENT_FAILED: "retryPaymentFailed",
  TRAIL_EXPIRED: "trailExpired",
  TRAILING: "trialing",
  SUBSCRIPTION_UNPAID: "subscription_unpaid",
};

const checkPermission = (
  roles,
  currentUser,
  parentPermissionKey,
  childPermissionName,
  isAuthenticateRole
) => {
  let isRoleAuth = true;
  if (roles && Array.isArray(roles) && roles.length > 0) {
    isRoleAuth = isAuthenticateRole();
  }
  if (
    currentUser?.permissions &&
    Object.keys(currentUser?.permissions).length > 0 &&
    parentPermissionKey !== null &&
    isRoleAuth
  ) {
    const permissions = currentUser?.permissions || [];
    if (
      permissions &&
      permissions[parentPermissionKey] &&
      Object.keys(permissions[parentPermissionKey]).length === 1
    ) {
      return permissions[parentPermissionKey][0].status;
    } else if (permissions && permissions[parentPermissionKey]) {
      const isParent = permissions[parentPermissionKey].find(
        (p) => p.parent_id === null
      );
      if (
        (isParent && childPermissionName === null) ||
        (isParent && isParent.status === false)
      ) {
        return isParent.status;
      }

      if (childPermissionName !== null && permissions[parentPermissionKey]) {
        const isAuth = permissions[parentPermissionKey].find(
          (per) => per.name === childPermissionName
        );
        return isAuth ? isAuth.status : true;
      }
    }
    return true;
  } else if (
    currentUser &&
    !currentUser.hasOwnProperty("permissions") &&
    parentPermissionKey &&
    isRoleAuth
  ) {
    return true;
  }
  return isRoleAuth;
};

export const roleAccess = (
  roles,
  parentPermissionKey = null,
  childPermissionName = null
) => {
  const isAuthenticateRole = () => {
    if (!roles || !Array.isArray(roles) || roles.length === 0) {
      return false;
    }

    let isAuthenticated = false;
    roles.forEach((role) => {
      if (
        userType &&
        currentUser &&
        USER_ROLE &&
        role &&
        Object.values(USER_ROLE).indexOf(role) !== -1 &&
        role === userType &&
        currentUser.userType === userType
      ) {
        isAuthenticated = true;
        return;
      }
    });

    return isAuthenticated;
  };

  const currentUser = GetItemFromLocalstorage("user");
  const userType = localStorage.getItem("userType");

  return parentPermissionKey !== null || childPermissionName !== null
    ? checkPermission(
        roles,
        currentUser,
        parentPermissionKey,
        childPermissionName,
        isAuthenticateRole
      )
    : isAuthenticateRole();
};

export const checkSubscriptionStatus = (user) => {
  if (!user) return;
  const { payment_setting } = user;
  const trialEndDate = moment(payment_setting?.trial_end_date, "YYYY-MM-DD");
  // const trialEndDate = moment("2024-02-15").format("YYYY-MM-DD");

  const today = moment().startOf("day");
  const isPast = moment(trialEndDate).isBefore(today, "day");

  // console.log("isPast isPast", isPast);

  if (
    payment_setting?.current_subscription_status === "active" &&
    payment_setting?.subscription_status === true &&
    payment_setting.hasOwnProperty("subscription_id") &&
    payment_setting.subscription_id !== null
  ) {
    return STRIPE_CHECK_SUBSCRIPTION_STATUS.SUBSCRIBED;
  } else if (
    isPast === false &&
    parseBooleanVal(payment_setting?.subscription_status) === false &&
    payment_setting?.hasOwnProperty("subscription_id") &&
    payment_setting.subscription_id !== null &&
    (payment_setting?.current_subscription_status === "incomplete" ||
      payment_setting.current_subscription_status === "incomplete_expired")
  ) {
    return STRIPE_CHECK_SUBSCRIPTION_STATUS.PAYMENT_FAILED_TRAIL_EXISTS;
  } else if (
    isPast === true &&
    payment_setting?.subscription_status === false &&
    payment_setting.hasOwnProperty("subscription_id") &&
    payment_setting.subscription_id !== null &&
    (payment_setting?.current_subscription_status === "incomplete" ||
      payment_setting.current_subscription_status === "incomplete_expired")
  ) {
    return STRIPE_CHECK_SUBSCRIPTION_STATUS.PAYMENTFAILED;
  } else if (
    isPast === true &&
    parseBooleanVal(payment_setting.subscription_status) === false &&
    payment_setting.hasOwnProperty("subscription_id") &&
    payment_setting.subscription_id !== null &&
    payment_setting.current_subscription_status !== null &&
    payment_setting.current_subscription_status === "unpaid" &&
    payment_setting.attempt_count === null
  ) {
    return STRIPE_CHECK_SUBSCRIPTION_STATUS.SUBSCRIPTION_UNPAID;
  } else if (
    isPast === true &&
    parseBooleanVal(payment_setting.subscription_status) === false &&
    payment_setting.hasOwnProperty("subscription_id") &&
    payment_setting.subscription_id !== null &&
    payment_setting.current_subscription_status !== null &&
    payment_setting.current_subscription_status === "past_due" &&
    payment_setting.attempt_count !== null &&
    payment_setting.attempt_count > 0
  ) {
    return STRIPE_CHECK_SUBSCRIPTION_STATUS.RETRY_PAYMENT_FAILED;
  } else if (payment_setting?.on_trial === false) {
    return STRIPE_CHECK_SUBSCRIPTION_STATUS.TRAIL_EXPIRED;
  }

  return isPast
    ? STRIPE_CHECK_SUBSCRIPTION_STATUS.TRAIL_EXPIRED
    : STRIPE_CHECK_SUBSCRIPTION_STATUS.TRAILING;
};

export const isGracePeriodOver = (user) => {
  if (!user) return;
  const status = checkSubscriptionStatus(user);
  return status === STRIPE_CHECK_SUBSCRIPTION_STATUS.SUBSCRIPTION_UNPAID;
};
