import React, { useEffect, useState, useMemo } from "react";
import {
  emptyPaginationObj,
  LOADER_STATUS,
  DELIVERED_SERVICE_LIMIT,
} from "../../../../Constants/constant";
import {
  formatDateIntoDMY,
  gethoursAndMins,
  getPaginationObj,
} from "../../../../Utils/utils";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import PaginationComponent from "../../../../components/common/Pagination/index";
import TableHeader from "../../../../components/common/Table/TableHeader";
import moment from "moment";

const ManualDeliveryTable = (props) => {
  const {
    participant,
    fetchLoader,
    removeLoader = false,
    funding,
    manualDeliveries,
    getManaulDeliveriesList,
  } = props;
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [paginationObj, setPaginationObj] = useState(
    manualDeliveries ? getPaginationObj(manualDeliveries) : emptyPaginationObj
  );

  useEffect(() => {
    if (manualDeliveries) {
      setPaginationObj({
        ...getPaginationObj(manualDeliveries),
      });
    }
  }, [manualDeliveries]);

  useEffect(() => {
    if (
      !manualDeliveries ||
      (manualDeliveries &&
        Object.keys(manualDeliveries).length > 0 &&
        manualDeliveries.hasOwnProperty("data") &&
        manualDeliveries.data.length === 0)
    ) {
      fetchManualDeliveries({ page: paginationObj.page });
    }
  }, []);

  useEffect(() => {
    if (
      manualDeliveries &&
      manualDeliveries.current_page !== paginationObj.page
    ) {
      fetchManualDeliveries({ page: paginationObj.page });
    }
  }, [paginationObj.page]);

  const manualDeliveriesToMap = useMemo(() => {
    if (manualDeliveries) {
      return manualDeliveries.data;
    }
    return [];
  }, [manualDeliveries]);

  const fetchManualDeliveries = async (filters) => {
    if (!filters) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: DELIVERED_SERVICE_LIMIT });
    Object.assign(filters, {
      pt_id: participant.uuid || 0,
      funding_id: funding.uuid || 1,
    });
    if (renderLoading === LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.PENDING);
    }
    getManaulDeliveriesList(filters);
  };

  return (
    <>
      <div className='flex flex-col'>
        <div className='align-middle w-full'>
          <div className='overflow-hidden border'>
            <table className='w-full divide-y divide-gray-300 '>
              <thead className='bg-gray-50 capitalize'>
                <tr>
                  <TableHeader isSort={false} label={"Delivered ID"} />
                  <TableHeader isSort={false} label={"Plan Category"} />
                  <TableHeader isSort={false} label={"Plan Subcategory"} />
                  <TableHeader
                    isSort={false}
                    label={"Total Delivered"}
                    cssClass='text-center'
                  />
                  <TableHeader isSort={false} label={"Status"} />
                  <TableHeader isSort={false} label={"Delivered Date"} />

                  <th className='py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50'>
                    <div className='sr-only'>View</div>
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {fetchLoader ? (
                  <tr>
                    <td colSpan={8} className='bg-gray-50 py-2'>
                      <CircularLoader
                        classes='flex justify-center items-center'
                        classloader='loader-l'
                      />
                    </td>
                  </tr>
                ) : manualDeliveriesToMap &&
                  manualDeliveriesToMap.length > 0 ? (
                  manualDeliveriesToMap.map((fund, index) => (
                    <tr key={index} className='text-left hover:bg-blue-50'>
                      <td className=' py-4 px-3 text-sm '>
                        {fund.delivered_service_number}
                      </td>
                      <td className=' py-4 px-3 text-sm  capitalize'>
                        {fund?.service_category?.name}
                      </td>
                      <td className='  px-3   capitalize py-4 text-sm text-gray-500 w-[20%] overflow-hidden text-ellipsis'>
                        {fund?.service_sub_category?.title}
                      </td>

                      <td className='text-center px-3 py-4 text-sm text-gray-500 '>
                        <div className='text-gray-500'>
                          {`$${
                            fund.total_amount
                              ? parseFloat(fund.total_amount).toFixed(2)
                              : 0
                          }`}
                        </div>
                      </td>
                      <td className=' whitespace-nowrap px-3 py-4 text-sm text-gray-500 '>
                        <div
                          title={fund.status}
                          className='text-gray-500 capitalize truncate'
                        >
                          {fund.status}
                        </div>
                      </td>

                      <td className=' px-3 py-4 text-sm text-gray-500 '>
                        <div className='text-gray-500'>
                          {fund.delivered_from && fund.delivered_to_date
                            ? `${formatDateIntoDMY(
                                fund.delivered_from
                              )} - ${formatDateIntoDMY(fund.delivered_to_date)}`
                            : fund.delivered_date
                            ? formatDateIntoDMY(fund.delivered_date)
                            : "-"}
                        </div>
                      </td>
                      <td className='px-3 py-4 text-sm text-gray-500'>
                        <div className='sr-only'>Actions</div>
                      </td>
                    </tr>
                  ))
                ) : (
                  renderLoading !== LOADER_STATUS.NA &&
                  manualDeliveriesToMap.length === 0 && (
                    <tr>
                      <td colSpan={8} className='py-6 text-center text-red-600'>
                        No Data Found!
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='bg-white py-4'>
        <PaginationComponent
          page={paginationObj.page}
          activeListLength={manualDeliveriesToMap}
          limit={DELIVERED_SERVICE_LIMIT}
          totalList={
            paginationObj.totalPages === 1
              ? manualDeliveriesToMap && manualDeliveriesToMap.length
              : paginationObj.total
          }
          handlePagination={(e) => {
            const obj = paginationObj;
            obj.page = e;
            setPaginationObj({ ...obj });
          }}
          totalPages={paginationObj.totalPages}
        />
      </div>
    </>
  );
};

export default ManualDeliveryTable;
