import Header from "components/common/Header/Header";
import SideBarNew from "components/common/SideBar/SideBarNew";
import { LoginContext } from "helpers/LoginContext";

import React, { useContext } from "react";
import { classNames } from "Utils/utils";
import { useNavigate } from "react-router";
// import { FaFileInvoice, FaUsers } from 'react-icons/fa';
import RocIcon from "assets/reporting-icons/roc-reports.png";
import CaseNotesIcon from "assets/reporting-icons/casenote.png";
import ComplaintIcon from "assets/reporting-icons/complaint.png";
import CosIcon from "assets/reporting-icons/cos.png";
import FeedbackIcon from "assets/reporting-icons/feedback-reports.png";
import ParticipantExpIcon from "assets/reporting-icons/participant-expiring-doc.png";
import ParticipantListIcon from "assets/reporting-icons/participant-list-report.png";
import TimesheetIcon from "assets/reporting-icons/timsheet-report.png";
import WeeklyRocIcon from "assets/reporting-icons/weekly-roc.png";
import WorkerExpIcon from "assets/reporting-icons/worker-expiring-doc.png";
import WorkerListIcon from "assets/reporting-icons/worker-list.png";
import KypIcon from "assets/reporting-icons/kyp.png"


import {
  FaUsers,
  FaFileInvoice,

  FaRegCalendarAlt,
  FaRegFileAlt,
} from "react-icons/fa";
import { RiFeedbackFill } from "react-icons/ri";
import { MdReport, MdAssignment} from "react-icons/md";
import { TbReportMoney } from "react-icons/tb";



const Reporting = () => {
  const { sideBarPadding } = useContext(LoginContext);
  const navigate = useNavigate();

 
  const cards = [
    {
      // icon: (
      //   <FaUsers className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
      // ),
      icon:<img src={RocIcon} alt="roc weekly report"/>,
      name: "ROC Report",
      color: "bg-green-50",
      shadow: "hover:shadow-lg hover:shadow-green-100 hover:border-green-100",
      href: "reporting/roc-reports",
      bgColor: "#31BB79",
    },
    {
      // icon: (
      //   <FaUsers className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
      // ),
      name: "ROC Weekly Report",
      color: "bg-green-50",
      shadow: "hover:shadow-lg hover:shadow-green-100 hover:border-green-100",
      href: "reporting/roc-weekly-reports",
      bgColor: "#795aaa",
      icon:<img src={WeeklyRocIcon} alt="roc weekly report"/>
    },
    {
      // icon: (
      //   <FaRegCalendarAlt className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
      // ),
      icon:<img src={TimesheetIcon} alt="timsheet report"/>,
      name: "Timesheet Report",
      color: "bg-yellow-50",
      shadow: "hover:shadow-lg hover:shadow-yellow-100 hover:border-yellow-100",
      href: "reporting/timesheet-reports",
      bgColor: "#29C3E1",
    },
    
    // {
    //   icon: (
    //     <FaExclamationTriangle className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
    //   ),
    //   name: "Incident Report",
    //   color: "bg-red-50",
    //   shadow: "hover:shadow-lg hover:shadow-red-100 hover:border-red-100",
    //   href: "reporting/incident-reports",
    //   bgColor: "#ed2939",
    // },
{
      // icon: (
      //   <RiFeedbackFill className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
      // ),
      icon:<img src={FeedbackIcon} alt="feedback report"/>,
      name: "Feedback Report",
      color: "bg-purple-50",
      shadow: "hover:shadow-lg hover:shadow-purple-100 hover:border-purple-100",
      href: "reporting/feedback-reports",
      bgColor: "#eEA1E1",
    },
    {
      // icon: (
      //   <MdReport className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
      // ),
      icon:<img src={ComplaintIcon} alt="complaints report"/>,
      name: "Complaints Report",
      color: "bg-blue-50",
      shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
      href: "reporting/complaints-reports",
      bgColor: "#FFCC00",
    },
    {
      // icon: (
      //   <FaFileInvoice className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
      // ),
      icon:<img src={ParticipantListIcon} alt="participant list report"/>,
      name: "Participant List Report",
      color: "bg-fuchsia-50",
      shadow:
        "hover:shadow-lg hover:shadow-fuchsia-100 hover:border-fuchsia-100",
      href: "reporting/participant-listing-reports",
      bgColor: "#4a101F",
    },
    {
      // icon: (
      //   <TbReportMoney className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
      // ),
        icon:<img src={KypIcon} alt="cos report"/>,
      name: "KYP Report",
      color: "bg-blue-50",
      shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
      href: "reporting/kyp-reports",
      bgColor: "#89A55F",
    },
    {
      // icon: (
      //   <FaRegFileAlt className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
      // ),
      icon:<img src={ParticipantExpIcon} alt="participant expiring document report"/>,
      name: "Participant Expiring documents Report",
      color: "bg-blue-50",
      shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
      href: "reporting/participant-expiring-documents-reports",
      bgColor: "#15939E",
    },
    {
      // icon: (
      //   <MdAssignment className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
      // ),
      icon:<img src={WorkerListIcon} alt="Worker report"/>,
      name: "Worker List Report",
      color: "bg-blue-50",
      shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
      href: "reporting/worker-list-reports",
      bgColor: "#44456E",
    },
    {
      // icon: (
      //   <FaRegFileAlt className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
      // ),
      icon:<img src={WorkerExpIcon} alt="worker expiring document report"/>,
      name: "Worker Expiring documents Report",
      color: "bg-blue-50",
      shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
      href: "reporting/worker-expiring-documents-reports",
      bgColor: "#513f4f",
    },
    // {
    //   icon: (
    //     <TbReportMoney className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
    //   ),
    //   name: "Invoicing Report",
    //   color: "bg-blue-50",
    //   shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
    //   href: "reporting/invoicing-reports",
    //   bgColor: "#8C198F",
    // },
    // {
    //   icon: (
    //     <SiProgress className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
    //   ),
    //   name: "Progress Report",
    //   color: "bg-blue-50",
    //   shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
    //   href: "reporting/progress-reports",
    //   bgColor: "#963950",
    // },
    {
      // icon: (
      //   <TbReportMoney className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
      // ),
      icon:<img src={CaseNotesIcon} alt="casenote report"/>,
      name: "Case Notes Report",
      color: "bg-blue-50",
      shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
      href: "reporting/case-notes-reports",
      bgColor: "#1f2d4f",
    },
    // {
    //   icon: (
    //     <TbReportMoney className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
    //   ),
    //   name: "Funding Report",
    //   color: "bg-blue-50",
    //   shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
    //   href: "reporting/funding-reports",
    //   bgColor: "#9D96C7",
    // },
    // {
    //   icon: (
    //     <TiInfo  className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
    //   ),
    //   name: "Risk Assessment Report",
    //   color: "bg-blue-50",
    //   shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
    //   href: "reporting/invoicing-reports",
    //   bgColor: "#4d0600",
    // },
    // {
    //   icon: (
    //     <TbReportMoney className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
    //   ),
    //   name: "BSP Report",
    //   color: "bg-blue-50",
    //   shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
    //   href: "reporting/invoicing-reports",
    //   bgColor: "#8F8F8F",
    // },
    // {
    //   // icon: (
    //   //   <TbReportMoney className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
    //   // ),
    //   icon:<img src={CosIcon} alt="cos report"/>,
    //   name: "COS Report",
    //   color: "bg-blue-50",
    //   shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
    //   href: "reporting/cos-reports",
    //   bgColor: "	#b061ff",
    // },
    // {
    //   icon: (
    //     <TbReportMoney className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
    //   ),
    //   name: "Detailed Invoicing Report",
    //   color: "bg-blue-50",
    //   shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
    //   href: "reporting/invoicing-reports",
    //   bgColor: "#EEAEDD",
    // },
    // {
    //   icon: (
    //     <TbReportMoney className='w-[38px] h-[38px] px-2 py-2 text-white rounded-lg' />
    //   ),
    //   name: "Summary Invoicing Report",
    //   color: "bg-blue-50",
    //   shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
    //   href: "reporting/invoicing-reports",
    //   bgColor: "#e78279",
    // },
 
  ];

  return (
    <div className='min-h-full pb-2 '>
      <SideBarNew />
      <div
        className={`duration-300 flex flex-col ${
          sideBarPadding ? "lg:pl-[4rem]" : "lg:pl-64"
        }`}
      >
        <main className='flex-1 relative'>
          <Header title='Reporting Dashboard' />
          <div className='max-w-5xl mx-auto min-h-full px-3'>
            <div
              className={`grid mt-8  gap-5 ${
                // roleAccess([USER_ROLE.Sp_manager])
                //   ? "grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 capitalize"
                //   :
                "sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 capitalize"
              }`}
            >
              {" "}
              {cards.map((item, index) => (
                <div
                  onClick={() => {
                    if (item.href) navigate(`/${item.href}`);
                  }}
                  role='button'
                  tabIndex={0}
                  key={index}
                  className='cursor-pointer relative rounded-lg border border-[] bg-white p-1 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 capitalize'
                >
                  <div
                    className={classNames(
                      "flex-shrink-0 flex items-center justify-center w-16 h-12 py-8 text-white text-sm font-medium rounded-md"
                    )}
                    // style={{ backgroundColor: `${item.bgColor || ""}` }}
                  >
                    {item.icon}
                  </div>
                  <div className='flex-1 min-w-0'>
                    <div className='focus:outline-none'>
                      <span className='absolute inset-0' aria-hidden='true' />
                      <p className='text-sm font-medium text-gray-900 mt-3'>
                        {item.name}
                      </p>
                    </div>
                  </div>
                </div>
             
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

// 
export default Reporting

