import ACTIONS, { FETCH_STATUS } from "../constants";

export const resetSelectedParticipant = () => ({
  type: ACTIONS.RESET_SELECTED_PARTICIPANT,
});

export const resetParticipant = (data) => ({
  type: ACTIONS.RE_SET_PARTICIAPNT,
  data,
});

export const resetSelectedParticipantDocuments = () => ({
  type: ACTIONS.RESET_SELECTED_PARTICIPANT_DOCUMENTS,
});

export const getParticipant = {
  pending: {
    type: ACTIONS.GET_PARTICIAPNT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_PARTICIAPNT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_PARTICIAPNT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getParticipantCall = (state) => ({
  type: ACTIONS.GET_PARTICIPANT_CALL,
  payload: state,
});

export const getParticipantSupportPlan = {
  pending: {
    type: ACTIONS.GET_PARTICIPANT_SUPPORT_PLAN.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_PARTICIPANT_SUPPORT_PLAN.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_PARTICIPANT_SUPPORT_PLAN.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const participantSupportPlanCall = (state) => ({
  type: ACTIONS.PARTICIPANT_SUPPORT_PLAN_CALL,
  payload: state,
});

export const getParticipantQuickSnapshot = {
  pending: {
    type: ACTIONS.PARTICIPANT_QUICK_SNAPSHOT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.PARTICIPANT_QUICK_SNAPSHOT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.PARTICIPANT_QUICK_SNAPSHOT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const participantQuickSnapshotCall = (state) => ({
  type: ACTIONS.PARTICIPANT_QUICK_SNAPSHOT_CALL,
  payload: state,
});

export const participantSettingUpdate = {
  pending: {
    type: ACTIONS.PARTICIPANT_SETTING_UPDATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.PARTICIPANT_SETTING_UPDATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.PARTICIPANT_SETTING_UPDATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const participantSettingUpdateCall = (state) => ({
  type: ACTIONS.PARTICIPANT_SETTING_UPDATE_CALL,
  payload: state,
});

export const participantAppointmentList = {
  pending: {
    type: ACTIONS.PARTICIPANT_APPOINTMENT_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.PARTICIPANT_APPOINTMENT_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.PARTICIPANT_APPOINTMENT_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const participantAppointmentlistCall = (payload) => ({
  type: ACTIONS.PARTICIPANT_APPOINTMENT_LIST_CALL,
  payload,
});

export const participantAppointmentCreate = {
  pending: {
    type: ACTIONS.PARTICIPANT_APPOINTMENT_CREATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.PARTICIPANT_APPOINTMENT_CREATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.PARTICIPANT_APPOINTMENT_CREATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const participantAppointmentCreateCall = (payload) => ({
  type: ACTIONS.PARTICIPANT_APPOINTMENT_CREATE_CALL,
  payload,
});

export const participantAppointmentUpdate = {
  pending: {
    type: ACTIONS.PARTICIPANT_APPOINTMENT_UPDATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.PARTICIPANT_APPOINTMENT_UPDATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.PARTICIPANT_APPOINTMENT_UPDATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const participantAppointmentUpdateCall = (payload) => ({
  type: ACTIONS.PARTICIPANT_APPOINTMENT_UPDATE_CALL,
  payload,
});

export const participantAppointmentDeleteLocally = (data) => ({
  type: ACTIONS.PARTICIPANT_APPOINTMENT_DELETE_LOCALLY,
  data,
});

export const submittedFormUpdatePtPercentageLocally = (data) => ({
  type: ACTIONS.SUBMITTED_FORM_UPDATE_PT_PERCENTAGE_LOCALLY,
  data,
});
