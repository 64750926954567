import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { formatDateIntoDMY, selectedTabState } from "../../../Utils/utils";
import { useNavigate } from "react-router";
import CircularLoader from "../CircularLoader/CircularLoader";
import { USER_ROLE } from "../../../Constants/constant";
import { roleAccess } from "../../../helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const QuickSnapshots = ({
  settingsData,
  participantsInfo,
  setToggleState,
  cssClass,
  isQuickViewCall,
  isLoading,
  paddingLeft,
  worker = false,
}) => {
  const navigate = useNavigate();

  const handleTabs = () => {
    setToggleState
      ? setToggleState({
          name: "Settings",
          id: 13,
          children: [],
        })
      : selectedTabState.save(
          JSON.stringify({
            name: "Settings",
            id: 13,
            children: [],
          })
        );

    worker
      ? navigate(`/worker-profile/${participantsInfo.uuid}`, {
          state: {
            id: 3,
          },
        })
      : navigate(`/sp-profile/${participantsInfo.uuid}`);
  };

  const getValue = () => (
    <>
      {roleAccess([USER_ROLE.Sp_Standard_User]) ? (
        <dd className='mt-1 text-sm text-gray-900 flex items-center space-x-2'>
          <span className='print:text-red-500 text-red-500'>
            No Data found!
          </span>
        </dd>
      ) : (
        <dd
          onClick={() => {
            if (
              roleAccess(
                [],
                PERMISSION_KEYS.PARTICIPANT,
                PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
              ) &&
              roleAccess(
                [],
                PERMISSION_KEYS.PARTICIPANT,
                PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
              )
            ) {
              handleTabs();
            }
          }}
          className={`${
            roleAccess(
              [],
              PERMISSION_KEYS.PARTICIPANT,
              PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
            ) &&
            roleAccess(
              [],
              PERMISSION_KEYS.PARTICIPANT,
              PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
            ) &&
            "cursor-pointer"
          }  mt-1 text-sm text-gray-900 flex items-center space-x-2`}
        >
          <span className={`${cssClass} break-all print:text-black`}>
            {roleAccess(
              [],
              PERMISSION_KEYS.PARTICIPANT,
              PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
            ) &&
            roleAccess(
              [],
              PERMISSION_KEYS.PARTICIPANT,
              PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
            )
              ? "Add information"
              : "No Data Found!"}
          </span>
          {roleAccess(
            [],
            PERMISSION_KEYS.PARTICIPANT,
            PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.SHOW
          ) &&
            roleAccess(
              [],
              PERMISSION_KEYS.PARTICIPANT,
              PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
            ) && (
              <span className={`${cssClass}`}>
                <FiArrowUpRight />
              </span>
            )}
        </dd>
      )}
    </>
  );

  return (
    <div className='min-h-full'>
      <div
        className={` mt-8 ${cssClass} max-w-3xl mx-auto grid grid-cols-1 gap-6 ${
          isQuickViewCall ? "sm:px-0" : "sm:px-6"
        } lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3`}
      >
        <div className='space-y-6 lg:col-start-1 lg:col-span-3 '>
          <section>
            <div
              className={`${
                isQuickViewCall
                  ? "bg-white"
                  : "bg-light-green-gradient custom-box-shadow sm:rounded-lg"
              }   custom-box-shadow h-[400px] overflow-y-auto print:h-full`}
            >
              {isLoading ? (
                <CircularLoader
                  classes='h-full flex justify-center items-center'
                  classloader='loader-l'
                />
              ) : (
                <>
                  <div
                    className={`px-4 py-3 ${
                      paddingLeft ? "sm:px-0" : "sm:px-6"
                    } w-11/12`}
                  >
                    <h2
                      className={`${cssClass} text-lg leading-6 font-bold print:text-black`}
                    >
                      Quick Snapshot
                    </h2>
                    {settingsData &&
                    (settingsData.quick_support ||
                      settingsData.quick_snapshot) ? (
                      <>
                        <p
                          className={`${cssClass} mt-1 max-w-5xl text-sm break-words w-[430px] print:text-black`}
                        >
                          {settingsData.quick_support
                            ? settingsData.quick_support
                            : settingsData.quick_snapshot}
                        </p>
                      </>
                    ) : (
                      <>{getValue()}</>
                    )}
                  </div>
                  <div
                    className={`px-4 py-5  ${
                      paddingLeft ? "sm:px-0" : "sm:px-6"
                    }`}
                  >
                    <dl className='print:grid print:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>
                      <div className='print:col-span-1 sm:col-span-1'>
                        <dt
                          className={`${cssClass} text-sm font-bold print:text-black`}
                        >
                          Address
                        </dt>
                        {settingsData &&
                        (settingsData.street_no || settingsData?.address) ? (
                          <dd
                            className={`${cssClass} mt-1 text-sm break-all print:text-black`}
                          >
                            {settingsData.street_no
                              ? settingsData.street_no
                              : settingsData?.address}
                          </dd>
                        ) : (
                          <>{getValue()}</>
                        )}
                      </div>
                      <div className='print:col-span-1 sm:col-span-1'>
                        <dt
                          className={`${cssClass} text-sm font-bold print:text-black`}
                        >
                          {worker ? "Job Title" : "Primary Disability"}
                        </dt>
                        {settingsData &&
                        (settingsData.primary_disability ||
                          settingsData?.job_title) ? (
                          <dd
                            className={`${cssClass} mt-1 text-sm break-all print:text-black`}
                          >
                            {settingsData.primary_disability
                              ? settingsData.primary_disability
                              : settingsData.job_title}
                          </dd>
                        ) : (
                          <>{getValue()}</>
                        )}
                      </div>
                      {roleAccess(
                        [],
                        PERMISSION_KEYS.PARTICIPANT,
                        PERMISSION_KEYS_CHILDREN.PARTICIPANT
                          .EMERGENCY_CONTACT_NAME
                      ) && (
                        <div className='print:col-span-1 sm:col-span-1'>
                          <dt
                            className={`${cssClass} text-sm font-bold print:text-black`}
                          >
                            Emergency Contact Name
                          </dt>
                          {settingsData &&
                          settingsData.emergency_contact_name ? (
                            <dd
                              className={`${cssClass} mt-1 text-sm overflow-hidden whitespace-nowrap overflow-ellipsis print:text-black`}
                              style={{ maxWidth: "200px" }}
                            >
                              {settingsData.emergency_contact_name}
                            </dd>
                          ) : (
                            <>{getValue()}</>
                          )}
                        </div>
                      )}

                      {roleAccess(
                        [],
                        PERMISSION_KEYS.PARTICIPANT,
                        PERMISSION_KEYS_CHILDREN.PARTICIPANT
                          .EMERGENCY_CONTACT_PHONE
                      ) && (
                        <div className='print:col-span-1 sm:col-span-1'>
                          <dt
                            className={`${cssClass} text-sm font-bold print:text-black`}
                          >
                            Emergency Phone Number
                          </dt>
                          {settingsData &&
                          (settingsData.emergency_contact ||
                            settingsData.emergency_contact_phone_number) ? (
                            <dd
                              className={`${cssClass} mt-1 text-sm print:text-black`}
                            >
                              {settingsData.emergency_contact
                                ? settingsData.emergency_contact
                                : settingsData.emergency_contact_phone_number}
                            </dd>
                          ) : (
                            <>{getValue()}</>
                          )}
                        </div>
                      )}
                      {roleAccess(
                        [],
                        PERMISSION_KEYS.PARTICIPANT,
                        PERMISSION_KEYS_CHILDREN.PARTICIPANT.PRIMARY_PHONE
                      ) &&
                        participantsInfo.primary_no && (
                          <div className='sm:col-span-1 print:hidden'>
                            <dt
                              className={`${cssClass} text-sm font-bold print:text-black`}
                            >
                              Participant Phone
                            </dt>
                            {participantsInfo && participantsInfo.primary_no ? (
                              <dd
                                className={`${cssClass} mt-1 text-sm flex items-center space-x-2 print:text-black`}
                              >
                                <span
                                  className={`${cssClass} print:text-black`}
                                >
                                  {participantsInfo.primary_no}
                                </span>
                              </dd>
                            ) : (
                              <>{getValue()}</>
                            )}
                          </div>
                        )}
                      {settingsData && settingsData?.joining_date && (
                        <div className='sm:col-span-1 print:hidden'>
                          <dt
                            className={`${cssClass} text-sm font-bold print:text-black`}
                          >
                            Joining Date
                          </dt>
                          {participantsInfo && settingsData?.joining_date ? (
                            <dd
                              className={`${cssClass} mt-1 text-sm flex items-center space-x-2 print:text-black`}
                            >
                              <span className={`${cssClass} print:text-black`}>
                                {settingsData?.joining_date}
                              </span>
                            </dd>
                          ) : (
                            <>{getValue()}</>
                          )}
                        </div>
                      )}

                      {!worker &&
                        roleAccess(
                          [],
                          PERMISSION_KEYS.PARTICIPANT,
                          PERMISSION_KEYS_CHILDREN.PARTICIPANT.PRIMARY_EMAIL
                        ) && (
                          <div className='print:col-span-1 sm:col-span-1 print:hidden'>
                            <dt
                              className={`${cssClass} text-sm font-bold print:text-black`}
                            >
                              Participant Email
                            </dt>
                            {participantsInfo && participantsInfo.email ? (
                              <dd
                                className={`${cssClass} mt-1 text-sm flex items-center space-x-2 print:text-black`}
                              >
                                <span
                                  className={`${cssClass} break-all print:text-black`}
                                >
                                  {participantsInfo.email}
                                </span>
                              </dd>
                            ) : (
                              <>{getValue()}</>
                            )}
                          </div>
                        )}

                      {/* {isQuickViewCall && ( */}
                      <>
                        {!worker &&
                          roleAccess(
                            [],
                            PERMISSION_KEYS.PARTICIPANT,
                            PERMISSION_KEYS_CHILDREN.PARTICIPANT.NDIS_NUMBER
                          ) && (
                            <div className='sm:col-span-1'>
                              <dt
                                className={`${cssClass} text-sm font-bold print:text-black`}
                              >
                                NDIS No.
                              </dt>
                              {settingsData && settingsData.ndis_no ? (
                                <dd
                                  className={`${cssClass} mt-1 text-sm  flex items-center space-x-2 print:text-black`}
                                >
                                  <span className={`${cssClass} break-all`}>
                                    {settingsData.ndis_no}
                                  </span>
                                </dd>
                              ) : (
                                <>{getValue()}</>
                              )}
                            </div>
                          )}

                        {roleAccess(
                          [],
                          PERMISSION_KEYS.PARTICIPANT,
                          PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOB
                        ) && (
                          <div className='sm:col-span-1'>
                            <dt
                              className={`${cssClass} text-sm font-bold print:text-black`}
                            >
                              Date of birth
                            </dt>
                            {settingsData && settingsData.dob ? (
                              <dd
                                className={`${cssClass} mt-1 text-sm  flex items-center space-x-2 print:text-black`}
                              >
                                <span className={`${cssClass} break-all`}>
                                  {formatDateIntoDMY(settingsData?.dob)}
                                </span>
                              </dd>
                            ) : (
                              <>{getValue()}</>
                            )}
                          </div>
                        )}
                      </>
                      {/* // )} */}
                    </dl>
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default QuickSnapshots;
