import { DownloadIcon } from "@heroicons/react/solid";
import {
  baseURLImage,
  downloadFileFromServer,
  getFileExtension,
} from "Utils/utils";
import React from "react";
import { FaHandPointRight } from "react-icons/fa";

const Resume = (props) => {
  const { file } = props;

  const extensionType = getFileExtension(file);

  if (file === null) {
    return null;
  }

  if (extensionType === "doc" || extensionType === "docx") {
    return (
      <div className='p-6 space-x-2 '>
         <div className="flex mb-2 space-x-2">
        <span className="flex gap-x-2 items-center text-base font-semibold">Download Resume
        
            <div className="icon-eye-wrapper cursor-pointer" onClick={() => {
            downloadFileFromServer(file, `${file.split("/").pop()}`);
          }}>
              <DownloadIcon className="icon-size" />
            </div>
          </span>
        {/* <p
          onClick={() => {
            downloadFileFromServer(file, `${file.split("/").pop()}`);
          }}
          className='cursor-pointer text-blue-500 hover:underline'
        >
          {`  ${file.split("/").pop()}`}
        </p> */}
        </div>
        {/* <span className='font-bold'>Resume:</span> */}
        <div>
          <iframe
            title='Document'
            style={{ width: "100%", height: "600px", border: "none" }}
            src={`https://docs.google.com/gview?url=${baseURLImage(
              file
            )}&embedded=true`}
          />
        </div>
       
      </div>
    );
  }

  return (
    <div>
      <object
        data={baseURLImage(file)}
        type='application/pdf'
        width='100%'
        height='600px'
      >
        <p>
          Alternative text - include a link{" "}
          <a href={baseURLImage(file)}>to the PDF!</a>
        </p>
      </object>
    </div>
  );
};

export default Resume;
