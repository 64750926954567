import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import { authActions, generalActions, spActions } from "../actions";
import api from "../services/sp.services";
import ACTIONS from "../constants";
import { TOAST_TYPE } from "../../Constants/constant";
import GetItemFromLocalstorage from "../../Utils/GetItemFromLocalstorage";
// import { authenticatedUser } from "store/actions/auth.actions";

function* getSPParticipants(state) {
  try {
    yield put(spActions.getSPParticipantsList.pending);
    const response = yield call(api.participantListRequest, state.payload);
    if (response.data) {
      yield put(spActions.getSPParticipantsList.success(response));
    }
  } catch (error) {
    console.log("error", error);
    yield put(spActions.getSPParticipantsList.error(error));
  }
}

function* getSPApplicants() {
  try {
    yield put(spActions.getSPApplicantsList.pending);
    const response = yield call(api.applicantsListRequest);
    if (response) {
      yield put(spActions.getSPApplicantsList.success(response));
    }
  } catch (error) {
    yield put(spActions.getSPApplicantsList.error(error));
  }
}

function* getSPEmployeeDocuments() {
  try {
    yield put(spActions.getSPEmployeeDocuments.pending);
    const response = yield call(api.employeeDocumentsRequest);
    if (response) {
      yield put(spActions.getSPEmployeeDocuments.success(response));
    }
  } catch (error) {
    yield put(spActions.getSPEmployeeDocuments.error(error));
  }
}

function* getSPDashboard() {
  try {
    yield put(spActions.getSPDashboard.pending);
    const response = yield call(api.dashboardRequest);
    if (response) {
      yield put(spActions.getSPDashboard.success(response));
    }
  } catch (error) {
    yield put(spActions.getSPDashboard.error(error));
  }
}

function* getSpWorkers() {
  try {
    yield put(spActions.getSPWorker.pending);
    const response = yield call(api.spWorkerRequest);
    if (response) {
      yield put(spActions.getSPWorker.success(response));
    }
  } catch (error) {
    yield put(spActions.getSPWorker.error(error));
  }
}

function* getSPFeedbacks(state) {
  try {
    yield put(spActions.getFeedback.pending);
    const response = yield call(api.feedbackRequest, state.payload);
    if (response) {
      yield put(spActions.getFeedback.success(response));
    }
  } catch (error) {
    yield put(spActions.getFeedback.error(error));
  }
}

function* getSPComplaints(state) {
  try {
    yield put(spActions.getComplaints.pending);
    const response = yield call(api.getComplaintsRequests, state.payload);
    if (response) {
      yield put(spActions.getComplaints.success(response));
    }
  } catch (error) {
    yield put(spActions.getComplaints.error(error));
  }
}

function* getSPQuestions(state) {
  try {
    yield put(spActions.getQuestions.pending);
    const response = yield call(api.getQuestionsRequests, state.payload);
    if (response) {
      yield put(spActions.getQuestions.success(response));
    }
  } catch (error) {
    yield put(spActions.getQuestions.error(error));
  }
}

function* getFormCategories(state) {
  try {
    yield put(spActions.SpFormCategories.pending);
    const res = yield call(api.formCategoriesRequest, {
      role: state.payload?.role,
    });
    if (res) {
      yield put(spActions.SpFormCategories.success(res));
    }
  } catch (error) {
    yield put(spActions.SpFormCategories.error(error));
  }
}

function* getAllForms(state) {
  try {
    yield put(spActions.spAllForm.pending);
    const response = yield call(api.allFormsRequest, state.payload);
    if (response) {
      yield put(spActions.spAllForm.success(response));
    }
  } catch (error) {
    yield put(spActions.spAllForm.error(error));
  }
}

function* getSPDocuments(state) {
  try {
    yield put(spActions.getSPDocuments.pending);
    const response = yield call(api.DocumentsRequest, state.payload);
    if (response.data) {
      yield put(spActions.getSPDocuments.success(response));
    }
  } catch (error) {
    yield put(spActions.getSPDocuments.error(error));
  }
}

function* updateSpDocument(state) {
  try {
    yield put(spActions.updateSPDocuments.pending);
    const response = yield call(api.updateSpDocumentService, state.payload);
    if (response) {
      yield put(spActions.updateSPDocuments.success(state.payload));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Update document Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(spActions.updateSPDocuments.error(error));
  }
}

function* getHRMDocuments(state) {
  try {
    yield put(spActions.getHRMDocuments.pending);
    const response = yield call(api.DocumentsHrmRequest, state.payload);
    if (response.data) {
      yield put(spActions.getHRMDocuments.success(response));
    }
  } catch (error) {
    yield put(spActions.getHRMDocuments.error(error));
  }
}

function* getSPRiskRatingTab(state) {
  try {
    yield put(spActions.getSPRiskRatingList.pending);
    const response = yield call(api.RiskRatingTabListSPRequest, state.payload);
    if (response.data) {
      yield put(spActions.getSPRiskRatingList.success(response));
    }
  } catch (error) {
    yield put(spActions.getSPRiskRatingList.error(error));
  }
}

function* createSPManager(state) {
  try {
    yield put(spActions.createSPManager.pending);
    const response = yield call(
      state.isUpdateCall ? api.updateSpManager : api.createSPManager,
      state.payload
    );
    if (response) {
      yield put(
        spActions.createSPManager.success(
          {
            manager: state.isUpdateCall ? state.payload : response.manager,
          },
          state.isUpdateCall
        )
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: state.isUpdateCall
            ? "Manager Updated Successfully!"
            : "Manager Created Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(spActions.createSPManager.error(error.message));
  }
}

function* getSPManager() {
  try {
    yield put(spActions.SPManagerList.pending);
    const response = yield call(api.getSPManager);
    if (response) {
      yield put(spActions.SPManagerList.success(response));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(spActions.SPManagerList.error(error.message));
  }
}

function* getSPSupportPlanSubmittedFormsList(state) {
  try {
    const userType = yield localStorage.getItem("userType");
    yield put(spActions.getSupportPlanSubmittedFormsList.pending);

    const response = yield call(api.getSPSupportPlanSubmittedFormsListRequest, {
      ...state.payload,
      userType,
    });
    if (response.data) {
      yield put(spActions.getSupportPlanSubmittedFormsList.success(response));
    }
  } catch (error) {
    yield put(spActions.getSupportPlanSubmittedFormsList.error(error));
  }
}

function* getHRMTeamManagment(state) {
  try {
    yield put(spActions.getHRMTeamManagmentList.pending);
    const response = yield call(api.teamManagmentListRequest, state.payload);
    if (response.data) {
      yield put(spActions.getHRMTeamManagmentList.success(response));
    }
  } catch (error) {
    yield put(spActions.getHRMTeamManagmentList.error(error));
  }
}

function* getSPSetting(state) {
  try {
    yield put(spActions.getSpSettingFetched.pending);
    const response = yield call(api.spSettingRequest, state.payload);
    if (response.data) {
      yield put(spActions.getSpSettingFetched.success(response));
      const data = yield GetItemFromLocalstorage("user") || null;
      if (data && response.data) {
        if (data.profile_picture !== response.data.profile_picture) {
          yield (data.profile_picture = response.data.profile_picture);
        }
        if (data.logo !== response.data.logo) {
          yield (data.logo = response.data.logo);
        }
        yield (data.name = response.data.name);
        if (data.hasOwnProperty("service_provider") && data?.name) {
          yield (data.service_provider = {
            ...data.service_provider,
            ...response.data,
          });
        }
        yield localStorage.setItem("user", JSON.stringify(data));
      }
    }
  } catch (error) {
    yield put(spActions.getSpSettingFetched.error(error));
  }
}

function* spSettingUpdate(state) {
  try {
    yield put(spActions.spSettingupdated.pending);
    const response = yield call(api.spSettingUpdate, state.payload);
    if (response.data) {
      yield put(spActions.spSettingupdated.success(response.data));
      yield localStorage.setItem("spSettings", JSON.stringify(response.data));
      const data = yield GetItemFromLocalstorage("user") || null;
      if (data && response.data) {
        yield (data.profile_picture = response.data.profile_picture);
        yield (data.logo = response.data.logo);
        yield (data.name = response.data.name);
        if (data.hasOwnProperty("service_provider") && response.data) {
          yield (data.service_provider = {
            ...data.service_provider,
            ...response.data,
          });
        }
        yield localStorage.setItem("user", JSON.stringify(data));
        yield put(authActions.updateAuthUser(data));
      }

      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "SP Admin Setting Updated Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(spActions.spSettingupdated.error(error));
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message ?? "Something went Wrong!",
      })
    );
  }
}

function* workerProfileDetailsFetch(state) {
  try {
    yield put(spActions.workerProfileDetailsFetched.pending);

    const response = yield call(api.workerProfileSettingRequest, state.payload);
    if (response) {
      yield put(
        spActions.workerProfileDetailsFetched.success(response.profile)
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(spActions.workerProfileDetailsFetched.error(error));
  }
}

function* updateWorkerHourlySetting(state) {
  try {
    yield put(spActions.workerHourlyRateSettingUpdated.pending);

    const response = yield call(
      api.workerHourlyRateSettingUpdateRequest,
      state.payload
    );
    if (response.data) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Worker Setting Updated Successfully!",
        })
      );
      yield put(spActions.workerHourlyRateSettingUpdated.success(response));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(spActions.workerHourlyRateSettingUpdated.error(error));
  }
}

function* updateWorkerStatus(state) {
  try {
    yield put(spActions.workerStatusUpdated.pending);
    const response = yield call(api.workerStatusUpdateRequest, state.payload);
    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `Worker ${
            state.payload.status ? "Activated" : "Deactivated"
          } Successfully!`,
        })
      );
      yield put(spActions.workerStatusUpdated.success({ ...state.payload }));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(spActions.workerStatusUpdated.error(error));
  }
}

//Stripe
function* stripePricingTableList() {
  try {
    yield put(spActions.stripePricingTableList.pending);
    const response = yield call(api.stirpePricingTableList);
    if (response?.data) {
      yield put(spActions.stripePricingTableList.success(response.data));
    }
  } catch (error) {
    yield put(spActions.stripePricingTableList.error(error?.message ?? error));
  }
}

function* stripeSaveCardData(state) {
  try {
    yield put(spActions.stripeSaveCardData.pending);
    const response = yield call(api.stirpeSaveCardDataApi, state.payload);
    // console.log("response", response);
    if (response?.data) {
      yield put(
        spActions.stripeSaveCardData.success(response.data, state.data)
      );
      const user = yield GetItemFromLocalstorage("user");
      if (user && response?.data?.default_payment_method_id) {
        yield (user.is_default_card = response.data.default_payment_method_id);
      }

      if (
        user &&
        response?.data?.default_payment_method_id &&
        user?.payment_setting?.default_payment_method_id !==
          response?.data?.default_payment_method_id
      ) {
        yield (user.is_default_card = response.data.default_payment_method_id);
        yield (user.payment_setting = response.data);
      }

      yield localStorage.setItem("user", JSON.stringify(user));
      yield put(authActions.authenticatedUser(user));

      if (state.payload.hasOwnProperty("price_id")) {
        yield put(
          generalActions.showToast({
            type: TOAST_TYPE.DEFAULT,
            title:
              "Thank you for subscribing! Your subscription is currently being processed and will be updated shortly.",
          })
        );
      }
    }
  } catch (error) {
    // console.log("err", error);
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(spActions.stripeSaveCardData.error(error?.message ?? error));
  }
}

function* stripeListAllCard() {
  try {
    yield put(spActions.stripeListAllCard.pending);
    const response = yield call(api.stirpeListAllCardApi);
    if (response?.payment_methods) {
      yield put(
        spActions.stripeListAllCard.success(
          response?.payment_methods?.data || []
        )
      );
    }
  } catch (error) {
    yield put(spActions.stripeListAllCard.error(error?.message ?? error));
  }
}

function* stripeDefaultPaymentMethod() {
  try {
    yield put(spActions.stripeGetDefaultPaymentCard.pending);
    const response = yield call(api.stirpeGetDefaultPaymentMethod);
    if (response?.payment_setting) {
      const user = yield GetItemFromLocalstorage("user");

      yield (user.is_default_card =
        response.payment_setting.default_payment_method_id);
      yield (user.payment_setting = response.payment_setting);
      yield localStorage.setItem("user", JSON.stringify(user));
      yield put(authActions.authenticatedUser(user));
      yield put(
        authActions.updateStripePaymentSettingLocally(response?.payment_setting)
      );
      yield put(
        spActions.stripeGetDefaultPaymentCard.success(
          response?.payment_methods?.data || []
        )
      );
    }
  } catch (error) {
    yield put(
      spActions.stripeGetDefaultPaymentCard.error(error?.message ?? error)
    );
  }
}

function* spStaffComplianceSetting() {
  try {
    yield put(spActions.spStaffComplianceSettings.pending);
    const response = yield call(api.getSpStaffComplianceSettingApi);

    if (response?.worker_compliance_settings) {
      yield put(
        spActions.spStaffComplianceSettings.success(
          response?.worker_compliance_settings.reduce(
            (acc, item) => {
              if (item.type === "document") {
                acc.document.push({
                  ...item,
                  is_compliance: item.score <= 0 ? false : item.is_compliance,
                });
              } else {
                acc.form.push({
                  ...item,
                  is_compliance: item.score <= 0 ? false : item.is_compliance,
                });
              }

              return acc;
            },
            { document: [], form: [] }
          ) || { document: [], form: [] }
        )
      );
    }
  } catch (error) {
    yield put(
      spActions.spStaffComplianceSettings.error(error?.message ?? error)
    );
  }
}

function* spStaffComplianceSettingUpdate(state) {
  try {
    yield put(spActions.spStaffComplianceSettingsUpdate.pending);
    const response = yield call(
      api.updateSpStaffComplianceSettingApi,
      state.payload
    );

    if (response?.worker_compliance_settings) {
      yield put(
        spActions.spStaffComplianceSettingsUpdate.success(
          response?.worker_compliance_settings.reduce(
            (acc, item) => {
              if (item.type === "document") {
                acc.document.push(item);
              } else {
                acc.form.push(item);
              }

              return acc;
            },
            { document: [], form: [] }
          ) || { document: [], form: [] }
        )
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Worker Compliance Settings Updated Successfully.",
        })
      );
    }
  } catch (error) {
    yield put(
      spActions.spStaffComplianceSettingsUpdate.error(error?.message ?? error)
    );
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.SP_SETTING_FETCHED_CALL, getSPSetting),
    takeEvery(ACTIONS.SP_SETTING_UPDATED_CALL, spSettingUpdate),
    takeLatest(ACTIONS.SP_PARTICIPANTS_LIST_CALL, getSPParticipants),
    takeEvery(ACTIONS.SP_APPLICANTS_LIST_CALL, getSPApplicants),
    takeEvery(ACTIONS.SP_EMPLOYEE_DOCUMENTS_CALL, getSPEmployeeDocuments),
    takeEvery(ACTIONS.SP_DASHBOARD_CALL, getSPDashboard),
    takeLatest(ACTIONS.SP_WORKER_CALL, getSpWorkers),
    takeEvery(ACTIONS.SP_FEEDBACK_CALL, getSPFeedbacks),
    takeEvery(ACTIONS.SP_COMPLAINTS_CALL, getSPComplaints),
    takeEvery(ACTIONS.SP_QUESTIONS_CALL, getSPQuestions),
    takeLatest(ACTIONS.SP_FORM_CATEGORIES_CALL, getFormCategories),
    takeLatest(ACTIONS.SP_ALL_FORM_CALL, getAllForms),
    takeLatest(ACTIONS.SP_DOCUMENTS_CALL, getSPDocuments),
    takeLatest(ACTIONS.SP_DOCUMENTS_UPDATE_CALL, updateSpDocument),
    takeLatest(ACTIONS.HRM_DOCUMENTS_CALL, getHRMDocuments),
    takeLatest(ACTIONS.SP_RISK_RATING_TAB_LIST_CALL, getSPRiskRatingTab),
    takeLatest(ACTIONS.CREATE_SP_MANAGER_CALL, createSPManager),
    takeLatest(ACTIONS.SP_MANAGER_LIST_CALL, getSPManager),
    takeLatest(
      ACTIONS.SUPPORT_PLAN_SUBMITTED_FORMS_LIST_CALL,
      getSPSupportPlanSubmittedFormsList
    ),
    takeLatest(ACTIONS.HRM_TEAM_MANAGMENT_LIST_CALL, getHRMTeamManagment),

    takeLatest(
      ACTIONS.HRM_WORKER_PROFILE_DETAIL_CALL,
      workerProfileDetailsFetch
    ),
    takeLatest(
      ACTIONS.HRM_WORKER_HOURLY_SETTING_CALL,
      updateWorkerHourlySetting
    ),
    takeLatest(ACTIONS.HRM_WORKER_STATUS_UPDATE_CALL, updateWorkerStatus),
    takeEvery(ACTIONS.STRIPE_PRICING_TABLE_LIST_CALL, stripePricingTableList),
    takeLatest(ACTIONS.STRIPE_SAVE_CARD_DATA_CALL, stripeSaveCardData),
    takeEvery(ACTIONS.STRIPE_LIST_ALL_CARD_CALL, stripeListAllCard),
    takeEvery(
      ACTIONS.STRIPE_DEFAULT_PAYMENT_METHOD_CALL,
      stripeDefaultPaymentMethod
    ),
    takeEvery(
      ACTIONS.SP_STAFF_COMPLIANCE_SETTING_CALL,
      spStaffComplianceSetting
    ),
    takeLatest(
      ACTIONS.SP_STAFF_COMPLIANCE_SETTING_UPDATE_CALL,
      spStaffComplianceSettingUpdate
    ),
  ]);
}
