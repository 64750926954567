import React from 'react'

const SummaryInvoicingReport = () => {
  return (
    <div>
      SummaryInvoicingReport
      
    </div>
  )
}

export default SummaryInvoicingReport
