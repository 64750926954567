import React, { useState, useEffect } from "react";
import Select from "react-select";
import api from "store/services/sp.services";
import { capitalizeAllLetters } from "Utils/utils";

const fetchOptions = async (selectFor) => {
  try {
    const payload = {
      filters: { search: "", page: 1, per_page: 1000 },
    };
    const response =
      selectFor === "worker"
        ? await api.teamManagmentListRequest(payload)
        : await api.participantListRequest(payload);

    if (selectFor === "worker") {
      return (
        response.data?.employees?.data?.map((item) => ({
          ...item,
          label: capitalizeAllLetters(`${item.first_name} ${item.last_name}`),
          value: item.id,
        })) || []
      );
    }
    return (
      response.data?.participant?.data?.map((item) => ({
        ...item,
        label: capitalizeAllLetters(`${item.first_name} ${item.last_name}`),
        value: item.id,
      })) || []
    );
  } catch (error) {
    console.error("Error fetching options:", error);
    return [];
  }
};

// WorkerShiftCustomSelect component
const WorkerShiftCustomSelect = ({
  cssClass,
  isDisabled,
  selectedValue,
  setValue,
  name,
  clearErrors,
  errorMessage,
  isValueChanges,
  isMultiple,
  selectFor = "participant",
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadOptions = async () => {
      const fetchedOptions = await fetchOptions(selectFor);
      setOptions(fetchedOptions);
      setIsLoading(false);
    };

    loadOptions();
  }, [selectFor]);

  return (
    <>
      <Select
        isMulti={isMultiple}
        options={options}
        placeholder='Search...'
        noOptionsMessage={() => "No options"}
        menuPlacement='auto'
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        isLoading={isLoading}
        onChange={(selectedOption) => {
          if (setValue && name) {
            setValue(name, selectedOption?.value);
            if (clearErrors && errorMessage && name) {
              clearErrors(name, "");
            }
            isValueChanges(true, selectedOption);
          }
        }}
        value={
          selectedValue ? options.find((x) => x.value === selectedValue) : null
        }
        isClearable={true}
        isDisabled={isDisabled}
      />
      {errorMessage && (
        <span
          style={{
            fontSize: "13px",
            color: "red",
          }}
          className='capitalize'
        >
          {errorMessage}
        </span>
      )}
    </>
  );
};

export default WorkerShiftCustomSelect;
