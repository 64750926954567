import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiPencil, BiPlus } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import Header from "../../../components/common/Header/Header";
import ParticipantImageToolTip from "../../../components/common/Tooltip/ParticipantImageToolTip";
import QuickView from "./ParticipantQuickView";
import SelectOption from "../../../components/common/Select/Select";
import {
  LOADER_STATUS,
  SP_PARTICIPANTS_LIST_LIMIT,
  SORT_TYPE,
  StatusOptions,
} from "../../../Constants/constant";
import TableHeader from "../../../components/common/Table/TableHeader";
import Search from "../../../components/common/Search/Search";
import AvatarGroup from "../../../components/common/Avatar/AvatarGroup";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import Pagination from "components/common/Pagination";
import { formatDateIntoDMY } from "Utils/utils";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const ParticipantListTable = (props) => {
  const {
    templateList,
    isLoading,
    queryParams,
    handleFetch,
    renderLoading,
    coSelectedStatus,
    setOpenModal,
    paginationObj,
    setPaginationObj,
  } = props;

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(null);
  const [sortBy, setSortBy] = useState(queryParams.sort_field);
  const [selectedParticipant, setSelectedParticipant] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [sortDescending, setSortDescending] = useState(
    queryParams.sort_order === SORT_TYPE.DESC ? true : false
  );

  useEffect(() => {
    setSortBy(queryParams.sort_field ? queryParams.sort_field : null);
    if (queryParams.sort_order) {
      setSortDescending(
        queryParams.sort_order
          ? queryParams.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
    }
    if (queryParams.hasOwnProperty("filter")) {
      if (queryParams.filter !== null) {
        setSelectedStatus(
          queryParams.filter.status && queryParams.filter.status.length > 0
            ? StatusOptions.find(
                (x) => x.value === queryParams.filter.status[0]
              )
            : null
        );
      }
    }
    if (queryParams.search) {
      setSearch(queryParams.search);
    }
  }, [queryParams]);

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        handleFetch(
          {
            search: search,
            sc_id: coSelectedStatus?.id,
          },
          sortBy
        ),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortDescending);
    handleFetch(
      {
        sort_field: by,
        sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
        page: 1,
      },
      sortBy
    );
  };

  useEffect(() => {
    if (
      renderLoading !== LOADER_STATUS.COMPLATE ||
      templateList?.length !== 0
    ) {
      handleFetch(
        {
          sc_id: coSelectedStatus?.id,
        },
        sortBy
      );
    }
  }, []);

  return (
    <>
      <Header
        title='Participants'
        handleClick={() => setOpenModal(true)}
        btnLabel='Add New Participant'
        icon={<BiPlus />}
        isButton
      />
      <div className='max-w-7xl mx-auto'>
        <div
          style={{ paddingTop: "15px", top: "70px" }}
          className='sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center sticky py-2 bg-white z-[6]'
        >
          <div className='w-full sm:w-1/3 md:w-2/12 lg:w-2/12 mb-3 sm:mb-0'>
            <SelectOption
              isDisabled={isLoading}
              isSearch={false}
              selectedOption={selectedStatus}
              handleChange={(e) => {
                setSelectedStatus(e);
                handleFetch({ status: e.value, page: 1 }, sortBy);
              }}
              data={StatusOptions}
              cssClass='z-20'
            />
          </div>
          <Search setSearch={setSearch} search={search} />
        </div>
        <div className='sm:px-6 lg:px-8'>
          <div className='flex flex-col'>
            <div className='min-w-full'>
              <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <div className='table-wrp block'>
                  {isLoading ? (
                    <div
                      className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                    >
                      <CircularLoader
                        classes='flex justify-center items-center'
                        classloader='loader-l'
                      />
                    </div>
                  ) : (
                    <table className='min-w-full divide-y divide-gray-300'>
                      <thead
                        style={{ top: "130px" }}
                        className='bg-gray-50 sticky z-[5]'
                      >
                        <tr>
                          <TableHeader
                            sortName='first_name'
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            label={"Participant Name"}
                          />
                          {(roleAccess(
                            [],
                            PERMISSION_KEYS.PARTICIPANT,
                            PERMISSION_KEYS_CHILDREN.PARTICIPANT.PRIMARY_EMAIL
                          ) ||
                            roleAccess(
                              [],
                              PERMISSION_KEYS.PARTICIPANT,
                              PERMISSION_KEYS_CHILDREN.PARTICIPANT.PRIMARY_PHONE
                            )) && (
                            <TableHeader
                              cssClass='sm:pl-3'
                              isSort={false}
                              sortName='contact_information'
                              flipSort={flipSort}
                              sortBy={sortBy}
                              sortDescending={sortDescending}
                              label={"Contact Information"}
                            />
                          )}
                          <TableHeader
                            cssClass='sm:pl-3'
                            isSort={false}
                            sortName='status'
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            label={"Status"}
                          />
                          <TableHeader
                            cssClass='sm:pl-3 w-[20%]'
                            isSort={false}
                            sortName='workers'
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            label={"Workers"}
                          />

                          <TableHeader
                            sortName='created_at'
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            label={"Created at"}
                          />

                          <th
                            scope='col'
                            className='text-gray-900 py-3.5 pl-3 pr-4 text-left bg-blue-50 text-sm font-semibold'
                          >
                            Actions
                            <span className='sr-only'>View</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                        {templateList !== null &&
                          templateList.map((participant, index) => (
                            <tr
                              key={index}
                              className='even:bg-white odd:bg-gray-50'
                            >
                              <td className='whitespace-nowrap py-4 px-3 text-sm'>
                                <div className='flex items-center'>
                                  <div className='h-10 w-10 flex-shrink-0 group relative'>
                                    <ParticipantImageToolTip
                                      cssClass={
                                        "bg-black py-1 px-1 rounded text-white z-[9]"
                                      }
                                      participant={participant}
                                      imgCssClass={
                                        "capitalize object-cover h-10 w-10 rounded-full"
                                      }
                                    />
                                  </div>
                                  <div className='ml-4 capitalize'>
                                    <span
                                      title={`${participant.first_name} ${participant.last_name}`}
                                      onClick={() => {
                                        if (
                                          roleAccess(
                                            [],
                                            PERMISSION_KEYS.PARTICIPANT,
                                            PERMISSION_KEYS_CHILDREN.PARTICIPANT
                                              .DETAIL
                                          )
                                        ) {
                                          sessionStorage.clear();
                                          navigate(
                                            `/sp-profile/${participant.uuid}`
                                          );
                                        }
                                      }}
                                    >
                                      <p
                                        className={`w-40 truncate ${
                                          roleAccess(
                                            [],
                                            PERMISSION_KEYS.PARTICIPANT,
                                            PERMISSION_KEYS_CHILDREN.PARTICIPANT
                                              .DETAIL
                                          ) && "cursor-pointer"
                                        }`}
                                      >{`${participant.first_name} ${participant.last_name}`}</p>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              {(roleAccess(
                                [],
                                PERMISSION_KEYS.PARTICIPANT,
                                PERMISSION_KEYS_CHILDREN.PARTICIPANT
                                  .PRIMARY_EMAIL
                              ) ||
                                roleAccess(
                                  [],
                                  PERMISSION_KEYS.PARTICIPANT,
                                  PERMISSION_KEYS_CHILDREN.PARTICIPANT
                                    .PRIMARY_PHONE
                                )) && (
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {roleAccess(
                                    [],
                                    PERMISSION_KEYS.PARTICIPANT,
                                    PERMISSION_KEYS_CHILDREN.PARTICIPANT
                                      .PRIMARY_PHONE
                                  ) && (
                                    <div className='text-gray-900'>
                                      {participant.primary_no}
                                    </div>
                                  )}
                                  {roleAccess(
                                    [],
                                    PERMISSION_KEYS.PARTICIPANT,
                                    PERMISSION_KEYS_CHILDREN.PARTICIPANT
                                      .PRIMARY_EMAIL
                                  ) && (
                                    <div className='text-gray-500'>
                                      {participant.email}
                                    </div>
                                  )}
                                </td>
                              )}
                              <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                                <span
                                  className={`${
                                    participant.status === "active"
                                      ? "bg-green-100 text-green-700"
                                      : "bg-red-100 text-red-700"
                                  } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize `}
                                >
                                  {participant.status}
                                </span>
                              </td>

                              <td className='px-2 py-[11px] text-sm text-gray-500'>
                                <span>
                                  <AvatarGroup
                                    type='worker'
                                    arrayData={participant.workers}
                                    cssClass='mt-0'
                                    maxLength={10}
                                  />
                                </span>
                              </td>
                              <td className='whitespace-nowrap py-4  px-3 text-sm'>
                                {formatDateIntoDMY(participant.created_at)}
                              </td>
                              <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium'>
                                <div className='flex items-center justify-start gap-x-3'>
                                  {/* <Link
                                    to={`/sp-profile/${participant.uuid}`}
                                    className="icon-pencil-wrapper"
                                    title="Edit"
                                  >
                                    <BiPencil className="icon-size" />
                                    <span className="sr-only">
                                      {participant.uuid}
                                    </span>
                                  </Link> */}

                                  <div
                                    onClick={() => {
                                      setIsOpen(true);
                                      setSelectedParticipant(participant);
                                    }}
                                    tabIndex={0}
                                    role='button'
                                    className='icon-eye-wrapper'
                                    title='View'
                                  >
                                    <HiOutlineEye className='icon-size' />
                                    <span className='sr-only'>
                                      {participant.uuid}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        {renderLoading === LOADER_STATUS.COMPLATE &&
                          templateList?.length === 0 && (
                            <tr>
                              <td
                                colSpan={7}
                                className='py-6 text-center text-red-600'
                              >
                                No data found !
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* side over Modal */}
        {isOpen && (
          <QuickView
            paddingLeft='px-0'
            selectedParticipant={selectedParticipant}
            isOpen={isOpen}
            setIsOpen={() => {
              setIsOpen(false);
              setSelectedParticipant({});
            }}
          />
        )}
      </div>
      <div className='bg-white sm:px-6 lg:px-8 max-w-7xl mx-auto py-4'>
        <Pagination
          page={paginationObj.page}
          activeListLength={templateList}
          limit={SP_PARTICIPANTS_LIST_LIMIT}
          totalList={paginationObj.total}
          handlePagination={(e) => {
            const obj = paginationObj;
            obj.page = e;
            setPaginationObj({ ...obj });
            handleFetch({}, sortBy);
          }}
          totalPages={paginationObj.totalPages}
        />
      </div>
    </>
  );
};

export default ParticipantListTable;
