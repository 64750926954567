import React, { useEffect } from "react";
import Header from "../../../../components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import TableHeader from "components/common/Table/TableHeader";
import SwitchWithIcon from "components/common/SwitchWithIcon";
import { BsPlusLg } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import CustomButton from "components/common/Button/CustomButton";
import { useForm, Controller } from "react-hook-form";
import { apiPostRequest } from "../../../../helpers/Requests";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import { toast, ToastContainer } from "react-toastify";

const NotificationSettingTable = ({
  userRoles,
  Loading,
  getSettings,
  GetSettingsData,
  selectedStatus,
  setSelectedStatus,
  setShowTable,
  showTable,
  setLoading,
  isUserGracePeriodOver,
}) => {
  const [expandedRows, setExpandedRows] = React.useState([]);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [disableBtn, setDisableBtn] = React.useState(true);

  const { handleSubmit, control, setValue, watch, reset } = useForm({
    defaultValues: {
      switches: {},
    },
  });

  useEffect(() => {
    reset({
      switches: {},
    });
  }, [getSettings, userRoles, reset]);

  // Function to handle expanding or collapsing a row
  const handleRowClick = (index) => {
    const newExpandedRows = [...expandedRows];
    if (expandedRows.includes(index)) {
      // Row is already expanded, so collapse it
      const indexToRemove = newExpandedRows.indexOf(index);
      newExpandedRows.splice(indexToRemove, 1);
    } else {
      // Row is not expanded, so expand it
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };

  //to submit data
  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    const switches = data.switches;

    if (Object.keys(switches).length !== 0) {
      setSaveLoading(true);

      if (switches) {
        const response = {
          role_id: selectedStatus?.value,
          activities: [],
        };

        Object.entries(switches).forEach(([key, value]) => {
          const [type, id] = key.split("-");

          const activityIndex = response.activities?.findIndex(
            (activity) => activity?.activity_id === parseInt(id)
          );

          if (activityIndex !== -1) {
            response.activities[activityIndex][type] = value;
          } else {
            response.activities.push({
              activity_id: parseInt(id),
              [type]: value,
              email: true,
              sms: true,
            });
          }
        });

        apiPostRequest("/sp/notification/update-settings", response)
          .then((res) => {
            setSaveLoading(false);
            GetSettingsData();
            setDisableBtn(true);
            toast.success("Notifications settings updated successfully");
            setExpandedRows([]);
          })
          .catch((error) => {
            console.log({ error });
            setSaveLoading(false);
            setDisableBtn(true);
            toast.error("Notification settings not updated");
          });
      }
    }
  };

  // Function to handle switch changes
  const handleSwitchChange = () => {
    setDisableBtn(false);
  };

  return (
    <>
      <Header title='Notification Settings' />
      <div className='max-w-7xl mx-auto'>
        <div
          style={{ paddingTop: "15px", top: "70px" }}
          className='sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center sticky py-2 bg-white z-[6]'
        >
          <div className='w-full sm:w-1/3 md:w-2/12 lg:w-2/12 mb-3 mt-8 sm:mb-0'>
            <SelectOption
              data={userRoles}
              placeHolder='Select Role'
              selectedOption={selectedStatus}
              handleChange={(e) => {
                setSelectedStatus(e);
                setShowTable(true);
                setExpandedRows([]);
              }}
              isSearch={false}
              cssClass='z-20'
            />
          </div>
        </div>

        {showTable && (
          <div className='sm:px-6 lg:px-8'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex flex-col'>
                <div className='min-w-full'>
                  <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                    <div className='table-wrp block'>
                      {Loading ? (
                        <div
                          className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                        >
                          <CircularLoader
                            classes='flex justify-center items-center'
                            classloader='loader-l'
                          />
                        </div>
                      ) : (
                        <table className='min-w-full  divide-y divide-gray-300  capitalize'>
                          <thead
                            style={{ top: "130px" }}
                            className='bg-gray-50 sticky z-[5] capitalize'
                          >
                            <tr>
                              <TableHeader
                                isSort={false}
                                label={""}
                                cssClass='sm:pl-3 w-[40%] border '
                              />
                              {/* <TableHeader
                                cssClass="sm:pl-3 text-center border"
                                isSort={false}
                                label={"Email"}
                              />
                              <TableHeader
                                cssClass="sm:pl-3 text-center border"
                                isSort={false}
                                label={"SMS"}
                              /> */}
                              <TableHeader
                                cssClass='sm:pl-3 text-center border'
                                isSort={false}
                                label={"App"}
                              />
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                            {getSettings &&
                              getSettings.length > 0 &&
                              getSettings.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr className='bg-white  '>
                                      <td
                                        className='whitespace-nowrap  py-4 px-3  text-sm sm:pl-3 w-[40%] '
                                        onClick={() => handleRowClick(index)}
                                      >
                                        <div className='flex align-center cursor-pointer '>
                                          {expandedRows.includes(index) ? (
                                            <BiMinus className='mt-1 text-gray-600' />
                                          ) : (
                                            <BsPlusLg className='h-3 w-3 mt-1 text-gray-600' />
                                          )}
                                          <span className='ml-3'>
                                            {" "}
                                            {item.display_name}
                                          </span>
                                        </div>
                                      </td>
                                      <td className='text-center '></td>
                                      <td className='text-center '></td>
                                      <td className='text-center'></td>
                                    </tr>
                                    {expandedRows.includes(index) &&
                                      item.children.map((el, index) => {
                                        const notificationSettings =
                                          el.notification_settings[0];
                                        // const defaultEmailValue =
                                        //   notificationSettings?.email ?? true;
                                        // const defaultSmsValue =
                                        //   notificationSettings?.sms ?? true;
                                        const defaultAppValue =
                                          notificationSettings?.web_app ?? true;
                                        return (
                                          <tr
                                            className='even:bg-white odd:bg-gray-50 '
                                            key={index}
                                          >
                                            <td className='whitespace-nowrap  border py-4 px-3 text-sm sm:pl-3 w-[40%]'>
                                              <div className='flex align-center cursor-pointer '>
                                                <span className='ml-9'>
                                                  {" "}
                                                  {el.display_name}
                                                </span>
                                              </div>
                                            </td>
                                            {/* <td className="text-center border border">
                                              <Controller
                                                name={`switches.email-${el.id}`}
                                                control={control}
                                                defaultValue={defaultEmailValue}
                                                render={({ field }) => (
                                                  <SwitchWithIcon
                                                    onChange={(value) => {
                                                      field.onChange(value);
                                                      setValue(
                                                        `switches.email-${el.id}`,
                                                        value
                                                      );
                                                      handleSwitchChange();
                                                    }}
                                                    checked={field.value}
                                                  />
                                                )}
                                              />
                                            </td>
                                            <td className="text-center border">
                                              <Controller
                                                name={`switches.sms-${el.id}`}
                                                control={control}
                                                defaultValue={defaultSmsValue}
                                                render={({ field }) => (
                                                  <SwitchWithIcon
                                                    onChange={(value) => {
                                                      field.onChange(value);
                                                      setValue(
                                                        `switches.sms-${el.id}`,
                                                        value
                                                      );
                                                      handleSwitchChange();
                                                    }}
                                                    checked={field.value}
                                                  />
                                                )}
                                              />
                                            </td> */}
                                            <td className='text-center'>
                                              <Controller
                                                name={`switches.web_app-${el.id}`}
                                                control={control}
                                                defaultValue={defaultAppValue}
                                                render={({ field }) => (
                                                  <SwitchWithIcon
                                                    onChange={(value) => {
                                                      field.onChange(value);
                                                      setValue(
                                                        `switches.web_app-${el.id}`,
                                                        value
                                                      );
                                                      handleSwitchChange();
                                                    }}
                                                    checked={field.value}
                                                  />
                                                )}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </React.Fragment>
                                );
                              })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-6 flex justify-end'>
                <CustomButton
                  showLoading={saveLoading}
                  isDisabled={disableBtn || isUserGracePeriodOver}
                  variant='primary'
                  type='submit'
                  label='Save Changes'
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationSettingTable;
