import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TableHeader from '../../../components/common/Table/TableHeader';
import { textTruncate } from "../../../Utils/utils";

export const Table = (props) => {
  const { tableHeader, loading, data } = props;
  const [bodyData, setBodyData] = useState();
  console.log('loading...',loading);
  useEffect(() => {
    console.log('tableData', data);
    if (data.length > 0) {
      setBodyData(data);
    }
  }, [data]);

  useEffect(() => {}, [bodyData]);

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-gray-50 capitalize">
        <tr className="pt-2 sticky top-[8rem]">
          {tableHeader.map((item, index) => (
            item.isSort ? (
              <TableHeader
                sortName={item.sortName}
                flipSort={item.flipSort}
                sortBy={item.sortBy}
                sortDescending={item.sortDescending}
                label={item.title}
              />
            ) : (
              <th scope={item.scope} className={item.columnClass} key={index}>
                {item.title}
              </th>
            )
          ))}
        </tr>
      </thead>
      {loading ? null : (
        <tbody className="divide-y divide-gray-200 bg-white">
          {bodyData && bodyData.data && bodyData.data.map((item, index) => (
            <tr key={index} className="even:bg-white odd:bg-gray-50">
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {textTruncate(item.name, 25, 24)}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {item.date}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {textTruncate(item.subject, 25, 24)}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="w-64 truncate flex space-x-4 items-center">
                  {textTruncate(item.details, 25, 24)}
                </div>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                {/* <button 
                  className=""
                  onClick={() => {
                    setOpen(true);
                    settableData(item.details);
                    setname(item.name);
                    setdate(item.date);
                    setsubject(item.subject);
                  }}
                >
                  <div className="icon-eye-wrapper" title="View">
                    <HiOutlineEye className="icon-size" />
                  </div>
                </button> */}
              </td>
            </tr>
          ))}
            {bodyData?.data?.length === 0 ? (
              <tr>
                <td colSpan={7} className="py-6 text-center text-red-600" >
                  No data found !
                </td>
              </tr>
          ) : null}
        </tbody>
      )}
    </table>
  );
};
