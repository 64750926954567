import React, { useMemo } from "react";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";
import { Link, useLocation, useParams } from "react-router-dom";

const Breadcrumb = ({ pages }) => {
  const location = useLocation();

  const params = useParams();

  const breadPages = useMemo(() => {
    if (location.pathname === "/hrm/applicant-assessment") {
      const pageIndex = pages.findIndex(
        (p) => p.href === "/hrm/applicant-profile"
      );
      if (pageIndex !== -1) pages[pageIndex].state = location.state;
    }
    return pages;
  }, [pages]);

  return (
    <nav className='flex' aria-label='Breadcrumb'>
      <ol role='list' className='flex items-center space-x-3'>
        <li>
          <div>
            <Link
              to={pages && pages.length > 0 ? pages[0].href : "#"}
              className='text-gray-400 hover:text-gray-500'
            >
              <HomeIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
              <span className='sr-only'>Home</span>
            </Link>
          </div>
        </li>
        {breadPages
          .filter((x, index) => index !== 0)
          .map((page) => (
            <li key={page.name}>
              <div className='flex items-center'>
                <ChevronRightIcon
                  className='flex-shrink-0 h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
                <Link
                  state={{ ...page.state }}
                  to={page.href}
                  className={`${
                    page.hasOwnProperty("addAdditionalParams")
                      ? `${page.href}/${
                          Object.keys(params).length > 0 && params.uuid
                            ? params.uuid
                            : ""
                        }` === location.pathname && "activeBreadCrumb"
                      : page.href === location.pathname && "activeBreadCrumb"
                  } ml-3 text-sm font-medium text-gray-500 hover:text-gray-700`}
                  aria-current={
                    page.href === location.pathname || "" ? "page" : undefined
                  }
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
